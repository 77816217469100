import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import RichTextEditor from 'react-rte';
import {Gmaps, Marker} from 'react-gmaps';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import Directions from '@material-ui/icons/Directions';
import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';

import Markdown, { isNotNull } from '../../ui/Markdown';
import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import getLexique from '../../../locales';
import { changeSectionActivation, updateSection } from '../../../actions/websites';
import { getToolbar } from '../../../config/website';
import { config } from '../../../config/env';

class Direction extends PureComponent {

	state = { showModal: false, anchor: null, free: RichTextEditor.createEmptyValue(), additionalInfos: RichTextEditor.createEmptyValue(), address: '', lat: '', lng: '' };

	componentDidMount() {
		const { datas } = this.props;
		if (datas) this.setState(Object.assign({},
				isNotNull(datas.free) ? { free: RichTextEditor.createValueFromString(datas.free, 'markdown') } : null,
				isNotNull(datas.additionalInfos) ? { additionalInfos: RichTextEditor.createValueFromString(datas.additionalInfos, 'markdown') } : null,
				datas.address ? { address: datas.address } : null,
				datas.lat ? { lat: datas.lat } : null,
				datas.lng ? { lng: datas.lng } : null,
			)
		);
	}

	handleMenuClose = () => this.setState({anchor: null});
	handleMenuOpen = ({target}) => this.setState({anchor: target});

	handleModalOpen = () => this.setState({showModal: true, anchor: null});
	handleModalClose = () => this.setState({showModal: false});

	handleChangeActive = () => {
		const { actions:{changeSectionActivation}, active, section} = this.props;
		changeSectionActivation({active: !active, section});
	}

	onConfirm = async () => {
		this.setState({loading: true});
		const { section, actions: {updateSection} } = this.props;
		const { free, additionalInfos, address, lat, lng } = this.state;
		const query = {
			additionalInfos: additionalInfos.toString('markdown'),
			free: free.toString('markdown'),
			lat,
			lng,
			address
		};

		await updateSection({
			section,
			datas: query,
		});
		this.setState({loading: false});
		this.handleModalClose();
	}

	getToolbar = () => {
		const { toolbar } = this.props.lexique.edition;
		return getToolbar(toolbar);
	}

	onChangeText = field => value => this.setState({[field]: value});
	onChangeInput = field => ({target:{value}}) => this.setState({[field]: value});

	render() {
		const { lexique, active, datas, isMobile } = this.props;
		const { anchor, showModal, loading, ...state} = this.state;

		return (
			<div className={cn('card website_section', {
				'website_section--inactive': !active,
			})}>
				<div className="website_actions">
					<IconButton onClick={this.handleMenuOpen}>
						<MoreVert />
					</IconButton>
				</div>
				<div className="card_content website_card website_content">
					<div className="card_title">
						<span className="ico"><Directions /></span>
						<h3>{lexique.title}</h3>
					</div>
				</div>
				<div className="flex ais fw-w mt20 website_content">
					<div className="card_content website_card flex1">
						<div>
							{datas && isNotNull(datas.free) && <Typography paragraph component="div">
								<Markdown>
									{datas.free}
								</Markdown>
							</Typography> }
							<Typography paragraph>
								<strong>{datas && datas.address}</strong>
							</Typography>
							{datas && isNotNull(datas.additionalInfos) && <Typography paragraph component="div">
								<Markdown>
									{datas.additionalInfos}
								</Markdown>
							</Typography> }
						</div>
					</div>
					<div className="flex1 direction_map">
						{datas && datas.lat && datas.lng &&
						<Gmaps
							width={'100%'}
							height={'100%'}
							lat={datas.lat}
							lng={datas.lng}
							zoom={16}
							loadingMessage={'...'}
							params={{
								v: '3.exp',
								key: config.GMapKey
							}}
							onMapCreated={map => {
								map.setOptions({
									disableDefaultUI: true
								});
							}}>
							<Marker
								lat={datas.lat}
								lng={datas.lng}
								draggable={false}
							/>
						</Gmaps>
						}
					</div>
				</div>

				<Menu
					anchorEl={anchor}
					open={anchor !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem
						onClick={this.handleChangeActive}
					>
						<ListItemText primary={lexique.active} />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={this.handleChangeActive}
								checked={active}
							/>
						</ListItemSecondaryAction>
					</MenuItem>
					<MenuItem onClick={this.handleModalOpen}>
						<ListItemIcon>
							<Edit />
						</ListItemIcon>
						<ListItemText inset primary={lexique.edit} />
					</MenuItem>
				</Menu>
				<Dialog
					maxWidth="md"
					open={showModal}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.handleModalClose}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
					fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.edition.title}
					</DialogTitle>
					<DialogContent>
						<Typography variant="caption" className="mt20">{lexique.edition.labels.free}</Typography>
						<RichTextEditor
							disabled={loading}
							autoFocus
							className="rte minh bkg"
							toolbarConfig={this.getToolbar()}
							value={state.free}
							onChange={this.onChangeText('free')}
						/>
						<TextField
							label={lexique.edition.labels.address}
							value={state.address}
							onChange={this.onChangeInput('address')}
							margin="normal"
							fullWidth
							disabled={loading}
						/>
						<Typography variant="caption" className="mt20">{lexique.edition.labels.help}</Typography>
						<div className="flex ait">
							<div className="flex1 padr10">
								<TextField
									label={lexique.edition.labels.lat}
									value={state.lat}
									onChange={this.onChangeInput('lat')}
									margin="normal"
									fullWidth
									disabled={loading}
								/>
							</div>
							<div className="flex1 padl10">
								<TextField
									label={lexique.edition.labels.lng}
									value={state.lng}
									onChange={this.onChangeInput('lng')}
									margin="normal"
									fullWidth
									disabled={loading}
								/>
							</div>
						</div>
						<Typography variant="caption" className="mt20">{lexique.edition.labels.additionalInfos}</Typography>
						<RichTextEditor
							disabled={loading}
							autoFocus
							className="rte minh bkg"
							toolbarConfig={this.getToolbar()}
							value={state.additionalInfos}
							onChange={this.onChangeText('additionalInfos')}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleModalClose} color="primary" disabled={loading}>
							{lexique.edition.cancel}
						</Button>
						<Button onClick={this.onConfirm} color="primary" disabled={loading}>
							{lexique.edition.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}


const mapStateToProps = ({env, website, ui}) => ({
	lexique: getLexique(env.locale).websites.direction,
	datas: website.direction,
	section: 'direction',
	active: website.blocks ? website.blocks.indexOf('direction') > -1 : false,
	isMobile: ui.device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeSectionActivation, updateSection }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Direction);
