import React, { memo } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import FormSend from '../inbox/FormSend';

const SendUserEmail = memo(({show, isMobile, props}) => (
	<Dialog
		open={show}
		TransitionComponent={ModalTransitionZoom}
		fullWidth
		fullScreen={isMobile}
		disableBackdropClick
		disableEscapeKeyDown
		aria-labelledby="modale-send-email-title"

	>
		{ !show ? <div /> : <FormSend {...props} /> }
	</Dialog>
));

const mapStateToProps = ({ui:{device, modales:{sendUserEmail:{show, ...props}}}}) => ({
	isMobile: device.isMobile,
    show,
	props
});

export default connect(mapStateToProps)(SendUserEmail);
