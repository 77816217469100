export default {
	login: `/login`,
	dashboard: `/`,
	planning: `/planning`,
	rdvs: `/appointments`,
	users: `/users`,
	inbox: `/inbox`,
	account: `/account`,
	settings: `/settings`,
	terms: `/terms`,
	websites: `/websites`,
	logs: `/logs`,
	payments: `/payments`,
}
