import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLexique } from '../../../locales';
import Alert from '../../ui/Alert';
import { useDevices, useVideoContext } from '../hooks';

const useStyles = makeStyles(() => ({
    snackbar: {
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
    }
}));

const MediaErrorSnackbar = ({ error }) => {
    const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

    const { isAcquiringLocalTracks } = useVideoContext();

    const classes = useStyles();
    const lexique = useLexique("visio.mediaError");

    const isSnackbarOpen =
        !isAcquiringLocalTracks &&
        (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

    if (!isSnackbarOpen) return null;

    const [ title, content ] = (() => {
        if (error?.name === 'NotAllowedError') {
            return [
                lexique.NotAllowedError.title,
                lexique.NotAllowedError.body
            ]
        }

        if (error?.name === 'NotFoundError') {
            return [
                lexique.NotFoundError.title,
                lexique.NotFoundError.body,
            ]
        }

        if (error) {
            return [
                lexique.MediaError.title,
                `${error.name} ${error.message}`,
            ]
        }

        if (!hasAudioInputDevices && !hasVideoInputDevices) {
            return [
                lexique.NoAudioVideo.title,
                lexique.NoAudioVideo.body,
            ]
        }

        if (!hasAudioInputDevices) {
            return [
                lexique.NoAudio.title,
                lexique.NoAudio.body,
            ]
        }

        if (!hasVideoInputDevices) {
            return [
                lexique.NoVideo.title,
                lexique.NoVideo.body,
            ]
        }

        return []

    })();

    return (
        <div
            className={classes.snackbar}
        >
            <Alert
                severity="warning"
                title={title}
                content={content}
                md
            />
        </div>
    );
}

export default memo(MediaErrorSnackbar)