import React, { useState, memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';

import DeviceSelectionDialog from './DeviceSelectionDialog';

const useStyles = makeStyles({
    settingsButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#fff'
    }
});

const SettingsMenu = () => {
    const classes = useStyles();
    const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

    return (<>
        <IconButton
            onClick={() => setDeviceSettingsOpen(true)}
            className={classes.settingsButton}
        >
            <SettingsIcon />
        </IconButton>
        
        <DeviceSelectionDialog
            open={deviceSettingsOpen}
            onClose={() => {
                setDeviceSettingsOpen(false);
            }}
        />
    </>);
}

export default memo(SettingsMenu)