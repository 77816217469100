import React, { memo, useState, useCallback } from 'react';
import { CircularProgress, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { Mail as SendIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone'
import { Tooltip } from 'react-tippy';
import cn from "classnames";

import { useLexique } from '../../locales';
import { useDispatch } from '../../libs/redux';
import { sendFilesFromVisio } from '../../actions/appointment';

const VisioFilesSender = ({className}) => {

    const [openFileSender, setOpenFileSender] = useState(false);
    const [sendingFiles, setSendingFiles] = useState(false);
    const [files, setFiles] = useState([]);

    const onOpenFilesSender = useCallback(() => setOpenFileSender(true), []);
    const onCloseFilesSender = useCallback(() => {
        setOpenFileSender(false);
        setFiles([]);
    }, []);

    const lexique = useLexique('visio.filesSender');
    const dispatch = useDispatch();

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {

        if (rejectedFiles.length > 0) {
            window.alert(lexique.errorFiles.replace("{0}", rejectedFiles.map(f => f.file.name).join(", ")));
        }

        const getFileData = file => new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                const formattedBase64 = base64.replace(`data:${file.type};base64,`, '');
                resolve({
                    type: file.type,
                    name: file.name,
                    content: formattedBase64
                })
            };
            reader.readAsDataURL(file);
        });

        const newFiles = await Promise.all(acceptedFiles.map(getFileData));

        setFiles(files => [...files, ...newFiles]);
    }, [lexique]);

    const onDeleteFile = useCallback(id => {
        
        setFiles(files => files.filter((_f, i) => i !== id));

    }, []);

    // Dropzone
    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
        onDrop,
        accept: ['application/pdf', 'image/*'],
        multiple: true,
        disabled: sendingFiles,
    });

    const closeAll = useCallback(() => {
        setSendingFiles(false);
        setOpenFileSender(false);
        setFiles([]);
    }, []);

    // Send
    const onSendFiles = useCallback(async () => {

        setSendingFiles(true);
        await dispatch(sendFilesFromVisio({
            attachments: files
        }));
        closeAll();

    }, [dispatch, closeAll, files]);

    return (<>
        <div className={className}>
            <Tooltip position="top" title={lexique.title}>
                <Fab className='visio_control visio_control--send' onClick={onOpenFilesSender}>
                    <SendIcon />
                </Fab>
            </Tooltip>
        </div>
        <Dialog open={openFileSender} disableBackdropClick={sendingFiles} disableEscapeKeyDown={sendingFiles} onClose={onCloseFilesSender} fullWidth maxWidth="sm">
            <DialogTitle>{lexique.title}</DialogTitle>
            <DialogContent>
                <div {...getRootProps()} className='no-outline'>
                    <input {...getInputProps()} />
                    <div className={cn("visio_files-sender_dropzone", isDragAccept && 'active', isDragReject && 'reject')}>
                        {(isDragAccept || files.length === 0) && <div className="visio_files-sender-message">
                            {isDragAccept ? lexique.dropHere : lexique.chooseFile}
                        </div>}
                        <div className="visio_files-files_list">
                            {files.map((file, i) => <FileLine key={i} name={file.name} id={i} onDelete={onDeleteFile} disabled={sendingFiles} />)}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseFilesSender} disabled={sendingFiles}>{lexique.cancel}</Button>
                <Button
                    onClick={onSendFiles}
                    color="primary"
                    size="large"
                    variant="contained"
                    disableElevation
                    disabled={sendingFiles || files.length === 0}
                >
                    {lexique.send}
                    {sendingFiles && <CircularProgress className='ml5' size={15} />}
                </Button>
            </DialogActions>
        </Dialog>
    </>)

}

const FileLine = memo(({name, id, disabled, onDelete}) => {

    const _onDelete = useCallback(e => {
        e.stopPropagation();
        onDelete(id)
    }, [onDelete, id]);

    return (
        <div className="visio_files-files_list_item">
            <span className="name">{name}</span>
            <span className="delete">
                <IconButton size="small" onClick={_onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </span>
        </div>
    )

});

export default memo(VisioFilesSender);