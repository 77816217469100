import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as AvatarIcon } from '@material-ui/icons'

import AudioLevelIndicator from '../ui/AudioLevelIndicator';

import { useIsTrackSwitchedOff, usePublications, useTrack, useParticipantIsReconnecting } from '../hooks';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 0,
        overflow: 'hidden',
        marginBottom: '2em',
        '& video': {
            filter: 'none',
            objectFit: 'contain !important',
        },
        borderRadius: '4px',
        border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
        paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
        background: 'black',
        [theme.breakpoints.down('sm')]: {
            height: theme.sidebarMobileHeight,
            width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
            marginRight: '8px',
            marginBottom: '0',
            fontSize: '10px',
            paddingTop: `${theme.sidebarMobileHeight - 2}px`,
        },
    },
    innerContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        background: 'transparent',
        top: 0,
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'black',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                transform: 'scale(0.7)',
            },
        },
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1,
    },
    screenShareIconContainer: {
        background: 'rgba(0, 0, 0, 0.5)',
        padding: '0.18em 0.3em',
        marginRight: '0.3em',
        display: 'flex',
        '& path': {
            fill: 'white',
        },
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.18em 0.3em',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
    },
    infoRowBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    typeography: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
    },
    hideParticipant: {
        display: 'none',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
}));

const ParticipantInfo = ({
    participant,
    children,
    isLocalParticipant,
}) => {
    const publications = usePublications(participant);

    const audioPublication = publications.find(p => p.kind === 'audio');
    const videoPublication = publications.find(p => p.trackName.includes('camera'));

    const isVideoEnabled = Boolean(videoPublication);

    const videoTrack = useTrack(videoPublication);
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

    const audioTrack = useTrack(audioPublication);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    const classes = useStyles();

    return (<>
        <div className={classes.infoContainer}>
            <div className={classes.infoRowBottom}>
                <span className={classes.identity}>
                    <AudioLevelIndicator audioTrack={audioTrack} />
                    <Typography variant="body1" className={classes.typeography} component="span">
                        {!isLocalParticipant && participant && <span>{participant.identity}</span>}
                    </Typography>
                </span>
            </div>
        </div>
        <div className={classes.innerContainer}>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
                <div className={classes.avatarContainer}>
                    <AvatarIcon />
                </div>
            )}
            {isParticipantReconnecting && (
                <div className={classes.reconnectingContainer}>
                    <Typography variant="body1" className={classes.typeography}>
                        Reconnecting...
                    </Typography>
                </div>
            )}
            {children}
        </div>
    </>);
}

export default memo(ParticipantInfo);