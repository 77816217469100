export const RECEIVED_NEW = "messages::new::received";
export const CHANGE_FOLDER = "messages::folder::change";
export const GET_REQUEST = "messages::get::request";
export const GET_COMPLETE = "messages::get::complete";
export const GET_ALL_COMPLETE = "messages::all::get::complete";
export const RESET = "messages::display::reset";
export const UPDATE_STATE = "messages::state::update";
export const SET_UNREAD = "messages::set::unread";
export const ADD_REPLY = "messages::reply::add";
export const REMOVE = "messages::remove";
