import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _isEqual from 'lodash.isequal';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import { closeModaleEditDaySpecificSchedules, openModaleEditDaySpecificSchedulesConfirmDiscard, openModalePickSchedule } from '../../actions/ui';
import { militaryToHuman } from '../../libs/formatters';

import getLexique from '../../locales';


class EditDaySpecificSchedules extends PureComponent {

	state = { sc: [] };

	onClose = () => {
		const { day } = this.props;
		// Check if changes have been made
		const { sc } = this.state;
		const from = day.params.specificSchedules || [];

		if (!_isEqual(sc, from)) return this.warnDiscard();

		this.onCloseImmediately();
	}

	warnDiscard = () => {
		this.props.actions.openModaleEditDaySpecificSchedulesConfirmDiscard({
			onConfirm: this.onCloseImmediately,
		});
	}

	onCloseImmediately = () => {
		this.props.actions.closeModaleEditDaySpecificSchedules();
	}

	mapPropsToInnerState = () => {
		const { day } = this.props;
		if (!day) return;

		this.setState({sc: day.params.specificSchedules || []})
	}

	componentDidMount() {
		const { day } = this.props;
		if (!day) return;
		this.mapPropsToInnerState();
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.day || (oldProps.day.start !== this.props.day.start) || (!oldProps.show && this.props.show)) this.mapPropsToInnerState();
	}

	onChange = ({target:{value}}) => this.setState({value});

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onCloseImmediately();
		onConfirm({
			value: this.state.sc,
		});
	}

	blockAllAppointments = () => {
		const { plannings } = this.props;
		const appointmentsTypes = plannings[plannings.selected].appointmentsTypes;
		const sc = appointmentsTypes.map(a => ({
			id: a.id,
			value: 'blocked',
		}));
		this.setState({sc});
	}

	blockAppointment = id => () => {
		const sc = [...this.state.sc.filter(s => s.id !== id), {
			id,
			value: 'blocked',
		}];

		this.setState({sc});
	}

	chooseAllSchedulesByDefault = () => {
		this.setState({sc: []});
	}

	chooseScheduleByDefault = id => () => {
		this.setState({sc: this.state.sc.filter(s => s.id !== id)});
	}

	editSchedule = id => () => {
		const sc = this.state.sc.find(a => a.id === id);
		this.props.actions.openModalePickSchedule({
			onConfirm: this.onConfirmEditSchedule(id),
			schedule: sc ? sc.value : '',
		});
	}

	editAllSchedules = () => {
		this.props.actions.openModalePickSchedule({
			onConfirm: this.onConfirmEditAllSchedules,
			schedule: '',
		});
	}

	onConfirmEditSchedule = id => ({schedule}) => {
		const sc = [...this.state.sc.filter(s => s.id !== id), {
			id,
			value: schedule,
		}];

		this.setState({sc});
	}

	onConfirmEditAllSchedules = ({schedule}) => {
		const { plannings } = this.props;
		const appointmentsTypes = plannings[plannings.selected].appointmentsTypes;
		const sc = appointmentsTypes.map(a => ({
			id: a.id,
			value: schedule,
		}));
		this.setState({sc});
	}

	getScheduleByAppointment = appointment => {
		const { lexique } = this.props;
		// Check if we have something in state :
		const specific = this.getSpecificDaySchedule(appointment);
		if (specific) {
			if (specific === "blocked") return lexique.blocked;
			return militaryToHuman(specific);
		}

		// Check for specific period;
		const period = this.getSpecificPeriodSchedule(appointment)
		if (period !== null) {
			if (period === '') return lexique.notWorked;
			return militaryToHuman(period);
		}

		// No specific period, return default
		const regular = this.getDefaultSchedule(appointment);
		if (regular === '') return lexique.notWorked;
		return militaryToHuman(regular);
	}

	getSpecificDaySchedule = appointment => {
		const specific = this.state.sc.find(a => a.id === appointment.id);
		if (specific && specific.value !== '') return specific.value;

		return null;
	}

	getSpecificPeriodSchedule = appointment => {
		const { date } = this.props;
		const weekDay = moment(date).weekday();

		if (!appointment.specificSchedules) return null;


		const mDate = moment(date);
		// Check for specific period;
		const period = appointment.specificSchedules.find(a => moment(a.from).isSameOrBefore(mDate) && moment(a.to).isSameOrAfter(mDate));

		if (period) return period.schedules[weekDay];

		return null;
	}

	getDefaultSchedule = appointment => {
		const { date } = this.props;
		const weekDay = moment(date).weekday();
		return appointment.schedules[weekDay];
	}

	getLabelSpecificPeriod = appointment => {
		const { date, lexique, config } = this.props;

		// Check for specific period;
		const period = appointment.specificSchedules.find(a => moment(a.from).isSameOrBefore(moment(date)) && moment(a.to).isSameOrAfter(moment(date)));
		if (period) return lexique.labels.specificPeriod.replace('{0}', moment(period.from).format(config.dateFormatLongNoDay)).replace('{1}', moment(period.to).format(config.dateFormatLongNoDay))

		return null;
	}

	printDetails = appointment => {
		const { lexique } = this.props;
		const specificDay = this.getSpecificDaySchedule(appointment);
		const specificPeriod = this.getSpecificPeriodSchedule(appointment);
		const regular = this.getDefaultSchedule(appointment);

		return (
			<div>
				<TextField
					label={lexique.labels.specificDay}
					value={specificDay ? (specificDay === "blocked" ? lexique.blocked : militaryToHuman(specificDay)) : lexique.undefined}
					disabled
					margin="normal"
					fullWidth
					onChange={() => null}
				/>
				{ specificPeriod !== null && <TextField
					label={this.getLabelSpecificPeriod(appointment)}
					value={specificPeriod === '' ? lexique.notWorked : militaryToHuman(specificPeriod)}
					disabled
					margin="normal"
					fullWidth
					onChange={() => null}
				/> }
				<TextField
					label={lexique.labels.regular}
					value={regular === '' ? lexique.notWorked : militaryToHuman(regular)}
					disabled
					margin="normal"
					fullWidth
					onChange={() => null}
				/>
			</div>
		)
	}

	renderInner() {
		const { lexique, config, day, date, plannings } = this.props;

		if (!day || !plannings || !plannings[plannings.selected]) return <div />;

		const appointmentsTypes = plannings[plannings.selected].appointmentsTypes;

		if (!appointmentsTypes) return <div />

		return (
			<Fragment>
				<DialogTitle id="modale-specific-shedules-dialog-title">
					{lexique.title.replace('{0}', moment(date).format(config.dateFormatLong))}
				</DialogTitle>
				<DialogContent>
					<div className="modale-appointments-types">
						<ExpansionPanel>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<div>
									<Typography>
										{lexique.titleAll}
									</Typography>
								</div>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								{lexique.infoAll}
							</ExpansionPanelDetails>
							<Divider />
							<ExpansionPanelActions>
								<Button size="small" color="primary" onClick={this.blockAllAppointments}>
									{lexique.actions.block}
								</Button>
								<Button size="small" color="primary" onClick={this.chooseAllSchedulesByDefault}>
									{lexique.actions.regular}
								</Button>
								<Button size="small" color="primary" onClick={this.editAllSchedules}>
									{lexique.actions.specific}
								</Button>
							</ExpansionPanelActions>
						</ExpansionPanel>
					{
						appointmentsTypes.filter(a => a.activeForUser).map(a => (
						<ExpansionPanel key={a.id}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<div className="w50">
									<Typography>
										<span className="appointment_type_color" style={{backgroundColor: a.color}}></span>
										<span>{a.label}</span>
									</Typography>
								</div>
								<div>
									<Typography color="textSecondary">{this.getScheduleByAppointment(a)}</Typography>
								</div>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								{this.printDetails(a)}
							</ExpansionPanelDetails>
							<Divider />
							<ExpansionPanelActions>
								<Button size="small" color="primary" onClick={this.blockAppointment(a.id)}>
									{lexique.actions.block}
								</Button>
								<Button size="small" color="primary" onClick={this.chooseScheduleByDefault(a.id)}>
									{lexique.actions.regular}
								</Button>
								<Button size="small" color="primary" onClick={this.editSchedule(a.id)}>
									{lexique.actions.specific}
								</Button>
							</ExpansionPanelActions>
						</ExpansionPanel>
						))
					}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show, isMobile } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-day-specific-shedules-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({plannings, env, ui:{device:{isDesktop, isMobile}, modales:{editDaySpecificSchedules:{show, onConfirm, day,date}}}}) => ({
	lexique: getLexique(env.locale).modales.editDaySpecificSchedules,
	config: getLexique(env.locale).config,
    show,
	onConfirm,
	day,
	date,
	isDesktop,
	isMobile,
	plannings,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleEditDaySpecificSchedules, openModaleEditDaySpecificSchedulesConfirmDiscard, openModalePickSchedule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDaySpecificSchedules);
