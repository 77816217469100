import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@material-ui/core";
import RichTextEditor from "react-rte";
import { useDispatch } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';

import { useLexique, _lp } from '../../locales';
import { getToolbar } from '../../config/website';
import { sendMailing } from "../../actions/users";

import ButtonCircularProgress from "../ui/ButtonCircularProgress";

const EMPTY_VALUE = RichTextEditor.createEmptyValue();
const MailingMail = ({open, onClose}) => {

    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState(EMPTY_VALUE);
    const lexique = useLexique('users.mailing');
    const toolbarL = useLexique('websites.information.edition.toolbar');
    const dispatch = useDispatch();

    const _onSendMessage = useCallback(async () => {
        setLoading(true);

        await dispatch(sendMailing({
            body: message.toString('html'),
            subject,
        }));

        setLoading(false);
        setSubject('');
        setMessage(EMPTY_VALUE);
        onClose();

    }, [subject, message, dispatch, onClose]);

    const _onChangeSubject = useCallback(({target:{value}}) => setSubject(value), []);
    const _onChangeMessage = useCallback(value => setMessage(value), []);

    const toolbar = useMemo(() => {
        return getToolbar(toolbarL);
    }, [toolbarL]);

    return (
        <Dialog open={open || false} maxWidth="md" fullWidth>
            <DialogTitle
                className="send-email-header flex aic"
                disableTypography
                disableBackdropClick
		        disableEscapeKeyDown
            >
				<Typography variant="h6" className="fg1 pad10 padl20">
					{_lp(lexique.title)}
				</Typography>
				<IconButton onClick={onClose} disabled={loading}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
            <DialogContent>
                <TextField
                    label={lexique.subject}
                    value={subject}
                    disabled={loading}
                    fullWidth
                    margin="normal"
                    onChange={_onChangeSubject}
                    variant="outlined"
                    autoFocus
                />
                <RichTextEditor
                    disabled={loading}
                    className="rte minh bkg"
                    toolbarConfig={toolbar}
                    value={message}
                    onChange={_onChangeMessage}
                />
            </DialogContent>
            <DialogActions>
				<Button 
                    disabled={loading || subject.trim() === "" || message === EMPTY_VALUE}
                    onClick={_onSendMessage}
                    color="primary"
                    variant="contained"
                    size="large"
                    disableElevation
                >
					{lexique.send}
					{!loading && <SendIcon className="ml5" />}
					{loading && <ButtonCircularProgress />}
				</Button>
			</DialogActions>
        </Dialog>
    )

}


export default memo(MailingMail)
