import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import EmptySlot from './EmptySlot';

import PublicHoliday from '../../libs/public-holiday-france';
import { getUserRightsOnPlanning } from '../../libs/checkers';

const Wrapper = memo(props => {
    if (!props.currentPlanning) return null;
    return <DaySlots {...props} />
})

const STEP = 1;
const DaySlots = memo(({date, start, steps, step, onSelectSlot, planningConf, currentPlanning, user, selectedPlanning}) => {

    const [displaySteps, setDisplayedSteps] = useState(Math.min(STEP, steps));

    useEffect(() => {
        const tickIntervaler = () => {
            setDisplayedSteps(curr => {
                const next = Math.min(steps, curr + STEP);
                if (next === steps) clearInterval(intervaler);
                return next;
            })
        }
        const intervaler = setInterval(tickIntervaler, 20)
        return () => {
                if (intervaler) clearInterval(intervaler)
            }
    }, [])

    const s = moment(start, "HHmm");

    /* Check closed by params */
    const day = currentPlanning.days[moment(date).format('YYYY-MM-DD')];
    const isClosedByParam = day && day.params && day.params.isClosed;

    /* Check closed by Holiday */
    const holidayKey = PublicHoliday.getFerie(moment(date).startOf('day'));
    const isHolidayClosed = holidayKey !== null && planningConf[`publicHoliday${holidayKey}`] === true;

    const rights = getUserRightsOnPlanning(user, selectedPlanning);

    return Array.from({length: displaySteps}).map((_,i) => {
                const d = moment(s).add(i*step, "minutes").format("HHmm");
                return (
                        <EmptySlot
                            key={d}
                            slot={d}
                            date={date}
                            onSelectSlot={onSelectSlot}
                            isClosed={isHolidayClosed || isClosedByParam}
                            hasRight={rights.addAppointment}
                        />
                )
    })
})

const mapStateToProps = ({plannings, user}) => ({
	start: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.start || '0900' : '0900',
    step: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.step || 15 : 15,
    planningConf: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
    currentPlanning: plannings[plannings.selected],
    selectedPlanning: plannings.selected,
    user,
});

export default connect(mapStateToProps)(Wrapper);
