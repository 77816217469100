import React, { memo, useCallback, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@material-ui/core';

import { useLexique } from '../../locales';
import { useSelector, useDispatch } from '../../libs/redux';
import { closeModale } from '../../actions/ui';
import { sendMessageFromVisio } from '../../actions/appointment';

const VisioSendDelay = () => {

    const { show, user, planningId, eventId } = useSelector(({ui}) => ui.modales.sendVisioDelayMessage || {});
    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const lexique = useLexique('visio.delay')

    const _onClose = useCallback(() => {
        dispatch(closeModale({
            modale: 'sendVisioDelayMessage'
        }));
    }, [dispatch])

    const _setValue = useCallback(({target:{value}}) => setValue(value), []);

    const _onSendDelay = useCallback(() => {
        if (value === "") return;

        dispatch(closeModale({
            modale: 'sendVisioDelayMessage'
        }));
        dispatch(sendMessageFromVisio({
            message: lexique.preview.replace('{0}', value),
            type: 'delay',
            planningId,
            eventId
        }));
        setValue("");

    }, [dispatch, lexique, value, eventId, planningId]);

    return (
        <Dialog open={show || false} maxWidth="xs" fullWidth onClose={_onClose}>
            <DialogTitle>{lexique.title.replace('{0}', user)}</DialogTitle>
            <DialogContent>
                <div className='visio_delay_content'>
                    <span>{lexique.body1}</span>
                    <TextField className="visio_delay_input" variant='outlined' value={value} onChange={_setValue} size="small" autoFocus />
                    <span>{lexique.body2}</span>
                </div>
                {value !== "" && <div className="visio_delay_preview">
                    {lexique.preview.replace('{0}', value)}
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={_onClose}>{lexique.cancel}</Button>
                <Button variant="contained" color="primary" disableElevation onClick={_onSendDelay}>{lexique.send}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default memo(VisioSendDelay);