import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _sortBy from 'lodash.sortby';
import nl2br from 'react-nl2br';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import MailOutline from '@material-ui/icons/MailOutlined';
import Flag from '@material-ui/icons/Flag';
import Archive from '@material-ui/icons/Archive';
import Unarchive from '@material-ui/icons/Unarchive';
import Delete from '@material-ui/icons/Delete';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Restore from '@material-ui/icons/Restore';
import Reply from '@material-ui/icons/Reply';

import ReplyDrawer from './Reply';

import { archive, unarchive, unarchiveAndMarkAsUnread, markAsUnread, remove, removeForever, restore } from '../../actions/messages';

import getLexique from '../../locales';

class Details extends PureComponent {

	state = { loading: false, openReply: false };

	onReply = () => {
		this.setState({openReply: true});
	}

	onCloseReply = () => {
		this.setState({openReply: false});
	}

	doAction = action => async () => {
		this.setState({loading: true});
		await this.props.actions[action]();

		this.setState({loading: false});
	}

	renderActionBar = () => {
		const { lexique, message: {state, openedAt} } = this.props;
		const { loading } = this.state;

		if (loading) return <CircularProgress color="primary" />

		if (state === "active") return (
		<Fragment>
			<Tooltip title={lexique.reply} touchHold>
				<IconButton onClick={this.onReply}>
					<Reply />
				</IconButton>
			</Tooltip>
			<Tooltip title={lexique.archive} touchHold>
				<IconButton onClick={this.doAction("archive")}>
					<Archive />
				</IconButton>
			</Tooltip>
			{openedAt && openedAt !== '' &&
			<Tooltip title={lexique.markAsUnread} touchHold>
				<IconButton onClick={this.doAction("markAsUnread")}>
					<Flag />
				</IconButton>
			</Tooltip>
			}
			<Tooltip title={lexique.delete} touchHold>
				<IconButton onClick={this.doAction("remove")}>
					<Delete />
				</IconButton>
			</Tooltip>
		</Fragment>
		);

		if (state === "archived") return (
		<Fragment>
			<Tooltip title={lexique.reply} touchHold>
				<IconButton onClick={this.onReply}>
					<Reply />
				</IconButton>
			</Tooltip>
			<Tooltip title={lexique.unarchive} touchHold>
				<IconButton onClick={this.doAction("unarchive")}>
					<Unarchive />
				</IconButton>
			</Tooltip>
			{openedAt && openedAt !== '' &&
			<Tooltip title={lexique.unarchiveAndMarkAsUnread} touchHold>
				<IconButton onClick={this.doAction("unarchiveAndMarkAsUnread")}>
					<Flag />
				</IconButton>
			</Tooltip>
			}
			<Tooltip title={lexique.delete} touchHold>
				<IconButton onClick={this.doAction("remove")}>
					<Delete />
				</IconButton>
			</Tooltip>
		</Fragment>
		);


		return (
		<Fragment>
			<Tooltip title={lexique.restore} touchHold>
				<IconButton onClick={this.doAction("restore")}>
					<Restore />
				</IconButton>
			</Tooltip>
			<Tooltip title={lexique.deleteForever} touchHold>
				<IconButton onClick={this.doAction("removeForever")}>
					<DeleteForever />
				</IconButton>
			</Tooltip>
		</Fragment>
		);
	}

	printReplies = () => {
		const { config, lexique, message: {replies} } = this.props;
		const sortedReplies = replies ? _sortBy(replies, [o => moment(o.sentAt).unix()]) : [];

		if (sortedReplies.length === 1) return lexique.replied.replace('{0}', moment(sortedReplies[0].sentAt).format(config.datetimeFormatLong));

		if (sortedReplies.length > 1) return lexique.replies.replace('{0}', sortedReplies.length);

		return lexique.didNotRespond;
	}

	render() {
		const { lexique, config, message: {loading, id, name, email, subject, sentAt, body, state}, isMobile } = this.props;
		const { openReply } = this.state;

		if (loading) return <LinearProgress color="secondary" />;
		if (!id) return (
			<div className="inbox-placeholder">
				<MailOutline className="ico" />
				<Typography variant="caption">{lexique.chooseOne}</Typography>
			</div>
		);

		return <Fragment>
			<AppBar color="default" position="static">
		 		<Toolbar className="message-toolbar">
					<div className="message-title">
						<Typography variant="h6" color="inherit" className="mr10">
							{name}
			            </Typography>
						<Typography variant="subtitle1" color="inherit">
							{`<${email}>`}
			            </Typography>
					</div>
					<div className="fg1" />
					<div className="message-action-bar">
					{ this.renderActionBar() }
					</div>
				</Toolbar>
			</AppBar>
			<div className="mt10 flex">
				<Typography variant="caption" paragraph className="mt10 fg1">
					{lexique.sentAt}{moment(sentAt).format(config.datetimeFormatLong)}
				</Typography>
				<Typography variant="caption" paragraph className="mt10">
					{this.printReplies()}
				</Typography>
			</div>
			<Typography variant="h5" paragraph>
				{subject}
			</Typography>
			<div className="message-body pad20 mb20">
				{ nl2br(body) }
			</div>
			{state !== "deleted" && <div className="tar">
				<Button variant="contained" onClick={this.onReply} disableElevation>
					 <Reply className="mr5" />{lexique.reply}
				</Button>
			</div>}
			<ReplyDrawer
				open={openReply}
				onClose={this.onCloseReply}
				name={name}
				email={email}
				subject={subject}
				id={id}
				isMobile={isMobile}
			/>
		</Fragment>;

	}
}


const mapStateToProps = ({message, env, ui}) => ({
	lexique: getLexique(env.locale).inbox.details,
	config: getLexique(env.locale).config,
	message,
	isMobile: ui.device.isMobile
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ archive, unarchive, unarchiveAndMarkAsUnread, markAsUnread, remove, removeForever, restore }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
