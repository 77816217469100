import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import AccountDetails from './account/AccountDetails';

import getLexique from '../locales';

class Account extends PureComponent {

	render() {
		const { loading, lexique } = this.props;

		if (loading) return <LinearProgress color="secondary" />

		return (
			<div className="websites pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.title}</Typography>
				</div>
				<AccountDetails />
			</div>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).account,
	selectedPlanning: plannings.selected,
	loading: plannings[plannings.selected] ? plannings[plannings.selected].loading : true,
});


export default connect(mapStateToProps)(Account);
