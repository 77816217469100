import React, { memo } from 'react';
import { connect } from 'react-redux';
import nl2br from 'react-nl2br';

import Typography from '@material-ui/core/Typography';

import getLexique from '../../locales';

const TermsContent = memo(({lexique}) => (
	<div className="legals-content">
		{ Object.entries(lexique).map(([key, {title, body}]) => (
			<div key={key}>
				<Typography component="h6" variant="h6" className="mb10">
					{ title }
				</Typography>
				<Typography paragraph>
					{nl2br(body.split('{0}').join(window.location.hostname))}
				</Typography>
			</div>
		))}
	</div>
));


const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).terms.paragraphs,
});

export default connect(mapStateToProps)(TermsContent);
