import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { Tooltip } from 'react-tippy';
import { FormControl, InputAdornment } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import VisioIcon from '@material-ui/icons/VideocamOutlined';
import nl2br from 'react-nl2br';

import AddCircleOutline from '@material-ui/icons/AddCircleOutlined';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import { closeModaleAddEditAppointmentType, openModalePickSchedule } from '../../actions/ui';

import { militaryToHuman } from '../../libs/formatters';
import getLexique, { _lp } from '../../locales';

import {red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange} from '@material-ui/core/colors';

const COLORS = [red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange];

class AddEditAppointmentType extends PureComponent {

	initialState = {
		label: '',
		defaultPaymentAmount: '',
		activeForUser: true,
		schedules: Array(7).fill(''),
		duration:'',
		errors: [],
		popoverDom: null,
		color: '',
		onlyRealSlot: true,
		isVisio: false,
	};
	
	state = { ...this.initialState };

	mapPropsToInnerState = () => {
		const { appointment } = this.props;
		if (!appointment) return;

		const { label, activeForUser, schedules, duration, color, onlyRealSlot, isVisio, defaultPaymentAmount } = appointment;
		this.setState({label, activeForUser, schedules, duration, color, onlyRealSlot, isVisio, defaultPaymentAmount });
	}

	onClose = () => {
		this.props.actions.closeModaleAddEditAppointmentType();
		this.resetInnerState();
	}

	resetInnerState = () => {
		this.setState({ ...this.initialState });
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});

	handleChangeSwitch = field => ({target:{checked}}) => this.setState({[field]: checked});

	componentDidUpdate = oldProps => {
		if (!oldProps.show && this.props.show) this.mapPropsToInnerState();
		if (oldProps.show && !this.props.show) this.resetInnerState();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		const { label, duration, color } = this.state;
		this.setState({errors: []});
		let errors = [];
		if (label === '') errors.push('label');
		if (duration === '') errors.push('duration');
		if (color === '') errors.push('color');

		if (errors.length > 0) {
			this.setState({errors});
			return;
		}

		onConfirm(this.state);

	}

	openSchedulePicker = weekDay => () => {
		this.props.actions.openModalePickSchedule({
			onConfirm: this.onConfirmEditDaySchedule(weekDay),
			schedule: this.state.schedules[weekDay],
		});
	}

	onConfirmEditDaySchedule = weekDay => ({schedule}) => {
		const schedules = this.state.schedules.map((s, i) => i!==weekDay ? s : schedule);
		this.setState({schedules});
	}

	selectColor = color => () => {
		this.setState({color});
		this.closeColorSelector();
	}

	openColorSelector = ({target}) => {
		this.setState({popoverDom: target});
	}

	closeColorSelector = () => {
		this.setState({popoverDom: null});
	}

    render() {
		const { show, lexique, selectedPlanning, lGlobal, loading, appointment, isMobile } = this.props;
		const { label, activeForUser, duration, errors, schedules, popoverDom, color, onlyRealSlot, isVisio, defaultPaymentAmount } = this.state;

		if (!selectedPlanning) return null;

		const configPlanning = selectedPlanning.configuration;
		const durationSteps = moment().startOf('day').add(configPlanning.step, 'minutes');

		const daysInWeek = !configPlanning.showWeekEnd ? 5 : (configPlanning.showSunday ? 7 : 6);
		const isVisioEnabled = configPlanning.isVisioEnabled;
		const isPaymentEnabled = selectedPlanning.canCreatePayments;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-add-appointment-type-dialog-title"
			>
				<DialogTitle id="modale-add-appointment-type-dialog-title">
					{lexique.title[appointment ? 'edit' : 'new']}
				</DialogTitle>
				<DialogContent>
					<TextField
						required
						autoFocus
						label={lexique.label}
						value={label}
						error={errors.indexOf('label') > -1}
						onChange={this.handleChange('label')}
						fullWidth
						margin="normal"
						disabled={loading}
						className="mb20"
						variant="outlined"
					/>
					<FormControl required variant="outlined" fullWidth>
						<InputLabel htmlFor="add-appointment-type-duration" error={errors.indexOf('duration') > -1}>
							{lexique.duration}
						</InputLabel>
						<Select
							error={errors.indexOf('duration') > -1}
							id="add-appointment-type-duration"
							value={duration}
							onChange={this.handleChange('duration')}
							disabled={loading}
							label={lexique.duration}
							className={isPaymentEnabled ? '' : "mb20"}
						>
							{
								Array(24).fill().map((v, i) => {
									const dur = moment(durationSteps).add(i*configPlanning.step, 'minutes');
									return (
										<MenuItem key={dur.format()} value={(i+1)*configPlanning.step}>
											{ dur.format('H:mm') }
										</MenuItem>
									)
								})
							}
						</Select>
					</FormControl>
					{ isPaymentEnabled && (
						<TextField
							label={lexique.defaultPaymentAmount}
							value={defaultPaymentAmount}
							onChange={this.handleChange('defaultPaymentAmount')}
							fullWidth
							margin="normal"
							disabled={loading}
							className="mb20"
							variant="outlined"
							InputProps={{
								endAdornment: <InputAdornment position="end">€</InputAdornment>,
							}}
						/>
					)}
					<Button
						disabled={loading}
						variant="contained"
						onClick={this.openColorSelector}
						className="mb20"
						disableElevation
						style={color || errors.indexOf('color') > -1 ? {backgroundColor: color || '#f44336', color: '#fff'} : { }}
					>
						{ color === '' && lexique.chooseColor}
						{color !== '' && lexique.changeColor}
					</Button>
					<Popover
						open={popoverDom !== null}
						anchorEl={popoverDom}
						onClose={this.closeColorSelector}
					>
						<div className="pad20 flex fw-w color-selector-items">
							{ COLORS.map((color, i) => (
								<Fragment key={i}>
									<Button
										onClick={this.selectColor(color[500])}
										className="color-selector-item"
										style={{backgroundColor:color[500]}}
									>
										label
									</Button>
									<Button
										onClick={this.selectColor(color[700])}
										className="color-selector-item"
										style={{backgroundColor:color[700]}}
									>
										label
									</Button>
									<Button
										onClick={this.selectColor(color[900])}
										className="color-selector-item"
										style={{backgroundColor:color[900]}}
									>
										label
									</Button>
								</Fragment>
							))}
						</div>
					</Popover>
					<div />
					{isVisioEnabled && <div className="flex aic">
						<FormControlLabel
							disabled={loading}
							className="flex1"
							control={
								<Switch
									checked={isVisio}
									onChange={this.handleChangeSwitch('isVisio')}
									color="primary"
								/>
							}
							label={lexique.isVisio}
						/>
						<div>
							<Tooltip touchHold animation="perspective" position="left" html={<div className="tal pad10">{nl2br(_lp(lexique.infos.isVisio))}</div>}>
								<VisioIcon />
							</Tooltip>
						</div>
					</div>}
					<FormControlLabel
						disabled={loading}
						className="mb100"
						control={
							<Switch
								checked={activeForUser}
								onChange={this.handleChangeSwitch('activeForUser')}
								color="primary"
							/>
						}
						label={_lp(lexique.activeForUser)}
					/>
					<div className="flex aic mb20">
						<FormControlLabel
							disabled={loading}
							className="flex1"
							control={
								<Switch
									checked={onlyRealSlot}
									onChange={this.handleChangeSwitch('onlyRealSlot')}
									color="primary"
								/>
							}
							label={lexique.onlyRealSlot}
						/>
						<div>
							<Tooltip touchHold animation="perspective" position="left" html={<div className="tal pad10">{nl2br(_lp(lexique.infos.onlyRealSlot))}</div>}>
								<Info />
							</Tooltip>
						</div>
					</div>
					<Typography variant="title" paragraph>{lexique.schedules}</Typography>
					<div className="schedules-container">
						{
							Array(daysInWeek).fill().map((v, i) => (
								<div className="flex aic" key={lGlobal.days[i]}>
									<div className="w25 padl10">
										<Typography variant="body1" noWrap>{ lGlobal.days[i] }</Typography>
									</div>
									<div className="flex1">
										<Typography color="textSecondary">{
											schedules[i] !== '' ? militaryToHuman(schedules[i]) : lexique.scheduleClosed
										}</Typography>
									</div>
									<IconButton disabled={loading} onClick={this.openSchedulePicker(i)}>
										{schedules[i] !== '' ? <Edit /> : <AddCircleOutline />}
									</IconButton>
								</div>
							)
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button disabled={loading} onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm[appointment ? 'edit' : 'new']}
						{loading && <ButtonCircularProgress />}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, plannings, ui:{device, modales:{addEditAppointmentType:{show, onConfirm, appointment, loading}}}}) => ({
	isMobile: device.isMobile,
	lexique: getLexique(env.locale).modales.addEditAppointmentType,
	config: getLexique(env.locale).config,
	lGlobal: getLexique(env.locale).global,
    show,
	onConfirm,
	appointment,
	loading,
	selectedPlanning: plannings[plannings.selected],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleAddEditAppointmentType, openModalePickSchedule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAppointmentType);
