import { createAction} from 'redux-actions';
import { toast } from 'react-toastify';

import mapper from '../mappers/websites';
import * as api from '../api/websites';
import getLexique from '../locales';
import * as WEBSITES from '../constants/websites';

export const selectWebsite = createAction(WEBSITES.SELECT);
const loadingWebsite = createAction(WEBSITES.LOAD);
const loadingWebsiteCompete = createAction(WEBSITES.LOAD_COMPLETE);
const updateSectionActivation = createAction(WEBSITES.UPDATE_SECTION_ACTIVATION);
const updateSectionStore =  createAction(WEBSITES.UPDATE_SECTION);
const updateMetasStore =  createAction(WEBSITES.UPDATE_METAS);

export const getWebsite = id => async dispatch => {
	dispatch(loadingWebsite());

	const res = await api.getWebsite(id);
	const mapped = mapper.getWebsite.fromApi(res);

	dispatch(loadingWebsiteCompete(mapped));

	return true;
}

export const changeSectionActivation = ({section, active}) => async (dispatch, getState) => {
	const { website: {id, blocks}, env:{locale}, plannings:{selected} } = getState();

	const newBlocks = active ? [...blocks, section] : blocks.filter(b => b !== section);
	const query = {
		websiteId: id,
		planningId: selected,
		blocks: newBlocks
	};

	dispatch(updateSectionActivation(newBlocks));
	// DO API
	await api.changeSectionActivation(query);

	const lexique = getLexique(locale).toasts.website;
	active ? toast.success(lexique.activated, {autoClose:2000}) : toast.error(lexique.desactivated, {autoClose:2000});

	return true;
}

export const updateSection = query => async (dispatch, getState) => {
	const { website: {id}, env:{locale}, plannings:{selected} } = getState();

	const toApi = {
		...query,
		planningId: selected,
		websiteId: id,
	}
	// AWAIT API
	await api.updateSection(toApi);

	dispatch(updateSectionStore(query));

	const lexique = getLexique(locale).toasts.website;
	toast.success(lexique.edited, {autoClose:2000});
	return true;
}

export const updateMetas = query => async (dispatch, getState) => {
	const { website: {id}, env:{locale}, plannings:{selected} } = getState();

	const toApi = {
		...query,
		planningId: selected,
		websiteId: id,
	}
	// AWAIT API
	await api.updateMetas(toApi);

	dispatch(updateMetasStore(query));

	const lexique = getLexique(locale).toasts.website;
	toast.success(lexique.edited, {autoClose:2000});
	return true;
}
