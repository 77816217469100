import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CircularProgressbar } from 'react-circular-progressbar';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import getLexique from '../../locales';
import { getDriveSpace} from '../../actions/drive';
import { FREE_DRIVE_SPACE } from '../../config/drive';
import { filesize } from '../../libs/formatters';

import 'react-circular-progressbar/dist/styles.css';

class DriveSpace extends PureComponent {

	state = { usedSpace: '-', percentage: 0 };

	componentDidMount() {
		this.loadDriveSpace();
	}

	componentDidUpdate = oldProps => {
		if (oldProps.planningId !== this.props.planningId && this.props.planningId) this.loadDriveSpace();
    }

	loadDriveSpace = async () => {
		const { planningId, actions:{getDriveSpace} } = this.props;

		if (!planningId) return;

		this.setState({percentage: 0, usedSpace: '-'});
        const space = await getDriveSpace({planningId});

		const percentage = (space/FREE_DRIVE_SPACE)*100;
		const usedSpace = filesize(space);
		this.setState({percentage, usedSpace});
    }

	render() {
		const { lexique } = this.props;
		const { usedSpace, percentage } = this.state;

		const color = percentage < 60 ? 'green' : (percentage < 85 ? "orange" : "red");

		return (
			<Paper className="dashboard_widget pad20 tac">
				<Typography variant="subtitle1" className="widget_title">{lexique.title}</Typography>
				<CircularProgressbar
					className="drive-free-space"
					percentage={percentage}
					text={usedSpace}
					styles={{
						path: { stroke: color },
						text: { fill: color, fontSize: '15px' },
					}}
				/>
				<Typography className='dashboard_widget_caption' variant="caption">{lexique.caption.replace('{0}', filesize(FREE_DRIVE_SPACE))}</Typography>
			</Paper>
		);
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).dashboard.driveSpace,
	planningId: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getDriveSpace }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DriveSpace);
