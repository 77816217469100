import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import WebsiteChooser from './websites/Choose';
import WebsiteForm from './websites/Form';

import getLexique from '../locales';

class Websites extends PureComponent {

	render() {
		const { loading, lexique, id, selectedPlanning } = this.props;

		if (loading) return <LinearProgress color="secondary" />

		return (
			<div className="websites pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.title}</Typography>
				</div>
				<WebsiteChooser key={selectedPlanning} />
				{id && <WebsiteForm key={id} id={id} /> }
			</div>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).websites,
	selectedPlanning: plannings.selected,
	loading: plannings[plannings.selected] ? plannings[plannings.selected].loading : true,
	id: plannings[plannings.selected] && plannings[plannings.selected].websites && plannings[plannings.selected].websites.length > 0 ? plannings[plannings.selected].websites[0].id : null,
});


export default connect(mapStateToProps)(Websites);
