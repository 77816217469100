import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';

import { closeModaleConfirmDiscardDuplicates } from '../../actions/ui';

import getLexique from '../../locales';

class ConfirmDiscardDuplicates extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleConfirmDiscardDuplicates();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm();
	}

    render() {
		const { show, lexique } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-discard-duplicated-appointment-dialog-title"
			>
				<DialogTitle id="modale-discard-duplicates-appointment-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="error" show>
						{ lexique.body }
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{confirmDiscardDuplicates:{show, onConfirm}}}}) => ({
	lexique: getLexique(env.locale).modales.confirmDiscardDuplicates,
    show,
	onConfirm,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleConfirmDiscardDuplicates }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDiscardDuplicates);
