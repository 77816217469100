import React, { createContext, memo } from 'react';

import AttachVisibilityHandler from './AttachVisibilityHandler';
import { 
    useHandleRoomDisconnectionErrors, 
    useHandleOnDisconnect, 
    useHandleTrackPublicationFailed, 
    useLocalTracks, 
    useRoom,
    SelectedParticipantProvider,
    useHandleForceDisconnect
} from './hooks';

export const VideoContext = createContext();

const VisioProvider = ({ options, children, planningId, eventId, minified, onError = () => { }, onDisconnect = () => { } }) => {
    const onErrorCallback = error => {
        onError(error);
    };

    const {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
    } = useLocalTracks();

    const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);

    // Register onError and onDisconnect callback functions.
    useHandleRoomDisconnectionErrors(room, onError);
    useHandleTrackPublicationFailed(room, onError);
    useHandleOnDisconnect(room, onDisconnect);
    useHandleForceDisconnect(room, removeLocalAudioTrack, removeLocalVideoTrack);

    return (
        <VideoContext.Provider
            value={{
                room,
                planningId,
                eventId,
                localTracks,
                isConnecting,
                onError: onErrorCallback,
                onDisconnect,
                getLocalVideoTrack,
                getLocalAudioTrack,
                connect,
                isAcquiringLocalTracks,
                removeLocalAudioTrack,
                removeLocalVideoTrack,
                getAudioAndVideoTracks,
                minified
            }}
        >
            <SelectedParticipantProvider room={room}>
                {children}
            </SelectedParticipantProvider>
            <AttachVisibilityHandler />
        </VideoContext.Provider>
    );
}

export default memo(VisioProvider)