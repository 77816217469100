import { createAction} from 'redux-actions';
import { toast } from 'react-toastify';

import CryptoJS from 'crypto-js';
import download from 'downloadjs';

import { setDriveHash  } from './planning';
import { openModaleDrive, openDriveFileEditor  } from './ui';

import mapper from '../mappers/drive';
import * as api from '../api/drive';
import getLexique from '../locales';
import * as DRIVE from '../constants/drive';

const setPasswordInStore = createAction(DRIVE.SET_USER_PASSWORD);
const setUserInStore = createAction(DRIVE.SET_USER_ID);
const setDriveContent = createAction(DRIVE.SET_CONTENT);
const startUploadsStore = createAction(DRIVE.UPLOADS_START);
export const cleanTransfersList = createAction(DRIVE.UPLOADS_COMPLETE);
const updateUploadState = createAction(DRIVE.UPDATE_UPLOAD_STEP);
const deleteFileInStore = createAction(DRIVE.FILE_DELETE);

export const setPassword = pass => async (dispatch, getState) => {
	dispatch(setPasswordInStore(pass));

	const planningId = getState().plannings.selected;
	const hash = CryptoJS.AES.encrypt('success', pass).toString();

	const query = {
		planningId,
		hash,
	};

	dispatch(setDriveHash(query));

	await api.setPassword(query);
	return true;
}


export const openSecureDrive = id => dispatch => {
	dispatch(setUserInStore(id));
	dispatch(openModaleDrive());
}

export const tryPassword = pass => (dispatch, getState) => {
	const { plannings } = getState()
	const hash = plannings[plannings.selected].driveHash;

	const bytes = CryptoJS.AES.decrypt(hash, pass);
	const tryHash = bytes.toString(CryptoJS.enc.Utf8);

	if (tryHash !== 'success') return false;

	dispatch(setPasswordInStore(pass));
	return true;
}

export const retrieveFiles = () => async (dispatch, getState) => {
	const { plannings, drive } = getState();
	const planningId = plannings.selected;
	const { userId } = drive;

	const res = await api.retrieveFiles({planningId, userId});
	const driveContent = mapper.retrieveFiles.fromApi(res);

	dispatch(setDriveContent(driveContent));
	return true;
}

export const startUploads = ({files, folder}) => async (dispatch, getState) => {
	const { plannings, drive, env:{locale} } = getState();
	const { userPassword, userId } = drive;

	dispatch(startUploadsStore(files));

	const promises = files.map(file => new Promise(resolve => {
		
		const sendFile = async () => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				const { result } = reader;
				const crypted = CryptoJS.AES.encrypt(result, userPassword).toString();
				dispatch(updateUploadState({file: file.name, step: 'upload'}));

				// Sending to storage
				await api.uploadFile({file: file.name, type: file.type, folder, content: crypted, planningId: plannings.selected, userId });
				dispatch(updateUploadState({file: file.name, step: 'complete'}));
				resolve();
			};
		}

		sendFile();

	}));

	await Promise.all(promises);
	await dispatch(retrieveFiles());

	const lexique = getLexique(locale).drive.toast;
	toast.success(lexique.success);
	return true;
}

export const deleteFile = ({file, folder}) => async (dispatch, getState) => {
	const { plannings:{selected}, drive:{userId}, env:{locale} } = getState();

	dispatch(deleteFileInStore({file, folder}));

	await api.deleteFile({file, folder, planningId: selected, userId});
	const lexique = getLexique(locale).drive.toast;
	toast.error(lexique.deleted);
}

export const downloadFile = ({file, folder, type}) => async (dispatch, getState) => {
	const { plannings:{selected}, drive:{userId, userPassword}, env:{locale} } = getState();

	const response = await api.downloadFile({file, folder, planningId: selected, userId});
	if (!response) {
		const lexique = getLexique(locale).drive.toast;
		toast.error(lexique.errorRetrieve);
		return false;
	}

	// Decrypt file
	const bytes = CryptoJS.AES.decrypt(response, userPassword);
	const decryptedFile = bytes.toString(CryptoJS.enc.Latin1);

	// Download file
	download(decryptedFile, file, type);

	return true;
}

export const saveFile = ({title, folder, content, newFile}) => async (dispatch, getState) => {
	const { plannings:{selected}, drive:{userId, userPassword}, env:{locale} } = getState();

	const crypted = CryptoJS.AES.encrypt(content, userPassword).toString();

	await api.uploadFile({file: `${title}.txt`, type: "text/plain", folder, content: crypted, planningId: selected, userId });

	await dispatch(retrieveFiles());
	const lexique = getLexique(locale).drive.toast;
	toast.success(lexique[newFile ? 'newFileSuccess' : 'updateFileSuccess']);
	return true;
}

export const editFile = ({file, folder}) => async (dispatch, getState) => {
	const { plannings:{selected}, drive:{userId, userPassword}, env:{locale} } = getState();

	const response = await api.downloadFile({file, folder, planningId: selected, userId});
	if (!response) {
		const lexique = getLexique(locale).drive.toast;
		toast.error(lexique.errorRetrieve);
		return false;
	}

	// Decrypt file
	const bytes = CryptoJS.AES.decrypt(response, userPassword);
	const decryptedFile = bytes.toString(CryptoJS.enc.Utf8);

	dispatch(openDriveFileEditor({
		content: decryptedFile,
		newFile: false,
		file: file.replace('.txt', ''),
		folder,
	}));

	return true;
}

export const getDriveSpace = query => async () => {
	const res = await api.getDriveSpace(query);
	return mapper.getDriveSpace.fromApi(res);
}
