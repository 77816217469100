import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import cn from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Person from '@material-ui/icons/Person';
import Pets from '@material-ui/icons/Pets';
import Delete from '@material-ui/icons/Delete';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import VpnKey from '@material-ui/icons/VpnKey';
import Mail from '@material-ui/icons/Mail';

import { openModaleConfirmAcceptUser, openModaleConfirmBlockUser, openModaleSendUserEmail, closeModaleSendUserEmail } from '../../actions/ui';
import { blockUser, acceptUser, openUserDetails } from '../../actions/users';
import { openSecureDrive } from '../../actions/drive';

import getLexique from '../../locales';

import { userName } from '../../libs/formatters';
import { getUserRightsOnPlanning } from '../../libs/checkers';
import { ListItemAvatar } from '@material-ui/core';


class FamilyCard extends PureComponent {

	state = { refMenu: null, expand: false }

	openQuickActionsMenu = e => {
		this.setState({refMenu: e.target});
	}

	closeQuickActionsMenu = () => {
		this.setState({refMenu: null});
	}

	handleExpand = () => {
		this.setState({expand: !this.state.expand});
	}

	showProfile = () => {
		const { actions: {openUserDetails}, id } = this.props;
		openUserDetails(id);
	}

	openFormMail = () => {
		const { actions: {openModaleSendUserEmail, closeModaleSendUserEmail}, email, mainMemberName } = this.props;
		openModaleSendUserEmail({
			email: email,
			name: mainMemberName,
			includeQuestion: false,
			displaySwitch: false,
			onClose: closeModaleSendUserEmail,
		});
	}

	openSecureDrive = () => {
		const { actions: {openSecureDrive}, id } = this.props;
		openSecureDrive(id);
	}

	confirmAccept = () => {
		const { mainMemberName, actions:{openModaleConfirmAcceptUser} } = this.props;
		openModaleConfirmAcceptUser({
			familyName: mainMemberName,
			onConfirm: this.onConfirmAccept,
		});
	}

	onConfirmAccept = () => {
		const { selectedPlanning, actions: { acceptUser }, id} = this.props;

		acceptUser({
			planningId: selectedPlanning,
			userId: id
		});
	}

	confirmBlock = () => {
		const { mainMemberName, actions:{openModaleConfirmBlockUser} } = this.props;
		openModaleConfirmBlockUser({
			familyName: mainMemberName,
			onConfirm: this.onConfirmBlock,
		});
	}

	onConfirmBlock = () => {
		const { selectedPlanning, actions: { blockUser }, id} = this.props;

		blockUser({
			planningId: selectedPlanning,
			userId: id
		});
	}

	renderMenuItems = () => {
		const { isRequestingAccess, isBlocked, lexique, user, selectedPlanning } = this.props;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		let items = [];
		if (!isRequestingAccess) {
			items.push(<MenuItem onClick={this.showProfile} key="item-show-profile">
						   <ListItemIcon>
							   <Person />
						   </ListItemIcon>
						   <ListItemText inset primary={lexique.show} />
					   </MenuItem>);
			items.push(<Divider  key="item-show-profile-divider" />);
		}

		if (rights.sendEmail) {
			items.push(<MenuItem onClick={this.openFormMail} key="item-send-email">
					   <ListItemIcon>
						   <Mail />
					   </ListItemIcon>
					   <ListItemText inset primary={lexique.email} />
				   </MenuItem>);
			items.push(<Divider  key="item-send-email-divider" />);
		}

		if (!isRequestingAccess && rights.drive) {
			items.push(<MenuItem onClick={this.openSecureDrive} key="item-open-drive">
						   <ListItemIcon>
							   <VpnKey />
						   </ListItemIcon>
						   <ListItemText inset primary={lexique.secureDrive} />
					   </MenuItem>);
			items.push(<Divider  key="item-open-drive-divider" />);
		}

		if ((isRequestingAccess || isBlocked) && rights.acceptUser) {
			items.push(<MenuItem onClick={this.confirmAccept} key="item-confirm-user">
							<ListItemIcon>
								<VerifiedUser />
							</ListItemIcon>
							<ListItemText inset primary={lexique.confirm} />
						</MenuItem>);
		}

		if (!isBlocked && rights.blockUser) {
			items.push(<MenuItem onClick={this.confirmBlock} key="item-confirm-block">
							<ListItemIcon>
								<Delete />
							</ListItemIcon>
							<ListItemText inset primary={lexique.block} />
						</MenuItem>);
		}

		return items;
	}

	render() {
		const { lexique, family, email, phoneNumber, config, mainMemberName, isBlocked, className, hideActionMenu } = this.props;
		const { refMenu, expand } = this.state;

		if (!email) return <div className="user-card" />

		return (
			<Card className={cn("user-card", className, { 'user-card--blocked': isBlocked })}>
				<CardHeader
					avatar={
						<Avatar onClick={this.handleExpand} className="avatar--secondary">
							{family.length}
						</Avatar>
					}
					action={ hideActionMenu ? null :
						<IconButton onClick={this.openQuickActionsMenu}>
							<MoreVertIcon />
						</IconButton>
					}
					title={lexique.family.replace('{0}', mainMemberName)}
					subheader={`${email}${phoneNumber ? ` / ${phoneNumber}` : ''}`}
				/>
				<CardActions disableSpacing>
					<IconButton
						style={{
							marginLeft: 'auto'
						}}
						className={cn('button-expand', {
							'button-expand--expanded': expand
						})}
						onClick={this.handleExpand}
						aria-expanded={expand}
						aria-label="Show more"
					>
						<ExpandMore />
					</IconButton>
				</CardActions>
				<Collapse in={expand} timeout="auto" unmountOnExit>
					<Divider />
					<CardContent>
						<List dense>
							{ family.map(member => {
								let age;
								if (member.birthdate) {
									age = `${moment().diff(moment(member.birthdate), 'years')}${lexique.yo} (${moment(member.birthdate).format(config.dateFormatLong)})`;
							 	}

								return (
								<ListItem key={member.id}>
									<ListItemAvatar>
										<Avatar className={`avatar-gender gender--${member.gender}`}>
											{ member.isAnimal ? <Pets /> : member.firstName[0] }
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary={userName(member)} secondary={age || ''} />
								</ListItem>
							)}) }
						</List>
					</CardContent>
				</Collapse>
				<Menu
					anchorEl={refMenu}
					open={refMenu !== null}
					onClose={this.closeQuickActionsMenu}
					onClick={this.closeQuickActionsMenu}
				>
					{ this.renderMenuItems() }
				</Menu>
			</Card>
		)
	}
}

const mapStateToProps = ({env, plannings, user}) => ({
	lexique: getLexique(env.locale).users.family,
	config: getLexique(env.locale).config,
	selectedPlanning: plannings.selected,
	user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openModaleConfirmAcceptUser, openModaleConfirmBlockUser, acceptUser, blockUser, openUserDetails, openSecureDrive, openModaleSendUserEmail, closeModaleSendUserEmail }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyCard);
