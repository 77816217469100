import React, { useCallback, useEffect, memo, useState, useMemo } from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import Resizable from '../modules/resizable';

import { getSlotHeight, getEventTop } from '../../libs/getters';
import { getAvailableAppointments, getSlotMatchingBlockingRule } from '../../libs/checkers';
import { useSelector } from '../../libs/redux';

const emptyArr = [];

const EmptySlot = ({onSelectSlot, slot, date, isClosed, hasRight}) => {

    const [ start, step, slotSize, planningConf, dayInfos, appointmentsTypes, isTouch ] = useSelector(({plannings, ui}) => [
        plannings?.[plannings.selected]?.configuration?.start || '0900',
        plannings?.[plannings.selected]?.configuration?.step || 15,
        plannings?.[plannings.selected]?.configuration?.slotSize || 'normal',
        plannings?.[plannings.selected]?.configuration,
        plannings?.[plannings.selected]?.days?.[moment(date).format('YYYY-MM-DD')],
        plannings?.[plannings.selected]?.appointmentsTypes || emptyArr,
        ui.device.isTouch
    ]);

    const [isResizing, setIsResizing] = useState(false);
    const [slotHeight, setSlotHeight] = useState(getSlotHeight(slotSize));
    const [slotDelta, setSlotDelta] = useState(0);
    const [slotIsSelected, setSlotIsSelected] = useState(false);

    const mSlot = useMemo(() => moment(slot, "HHmm"), [slot]);
    const fullDaySlot = useMemo(() => moment(`${date} ${slot}`, 'YYYY-MM-DD HHmm'), [date, slot]);
    const stepHeight = useMemo(() => getSlotHeight(slotSize), [slotSize]);

    useEffect(() => {

        if (!slotIsSelected) return;

        const addSteps = slotDelta / stepHeight;
        const addMinutes = addSteps*step;

        onSelectSlot({
            start: fullDaySlot.format(),
            duration: (addMinutes + step)
        });

        setSlotIsSelected(false);
        setIsResizing(false);
        setSlotHeight(stepHeight);
        setSlotDelta(0);

    }, [slotIsSelected, slotDelta, stepHeight, step, onSelectSlot, fullDaySlot]);

    const _startResize = useCallback(() => setIsResizing(true), []);
    const _stopResize = useCallback(() => setSlotIsSelected(true), []);
    //const _onSelectSlot = useCallback(() => setSlotIsSelected(true), []);

    const _onResize = useCallback((_, __, ref, delta) => {
        setSlotHeight(ref.offsetHeight)
        setSlotDelta(delta.height)
    }, []);

    const _onClick = useCallback(() => setSlotIsSelected(true), []);

    const checkSlotIsBlockedByRule = useCallback(date => {
		const matchingRule = getSlotMatchingBlockingRule({
			start: date,
			day: dayInfos,
			rules: planningConf.rules,
		});
		return matchingRule ? true : false;
	}, [dayInfos, planningConf]);

    const getSlotClassnames = useMemo(() => {
		let nonBookableSlot = false;
		let bookableSlot = false;
		if (planningConf.highlightNonBookable || planningConf.highlightBookable) {
			// checking if at least one appointment is bookable for this slot
			const availables = getAvailableAppointments({
				appointments: appointmentsTypes,
				start: fullDaySlot,
				daySchedules: dayInfos && dayInfos.params ? dayInfos.params.specificSchedules : [],
			});
			if (planningConf.highlightNonBookable) nonBookableSlot = availables.length === 0;
			if (planningConf.highlightBookable) bookableSlot = availables.length > 0;
		}

		const isBlockedByRule = planningConf.highlightBlockedByRule && checkSlotIsBlockedByRule(fullDaySlot);

		return cn('time-slot', {
                '--resizing': isResizing,
				'slot--is-active': fullDaySlot.isAfter(moment()),
				'slot--is-blocked-by-rule': isBlockedByRule,
				'slot--is-past': fullDaySlot.isBefore(moment()),
				'slot--is-closed-by-day': isClosed,
				'slot--is-non-bookable': nonBookableSlot,
				'slot--is-bookable': bookableSlot,
		});
    }, [planningConf, appointmentsTypes, fullDaySlot, dayInfos, isResizing, checkSlotIsBlockedByRule, isClosed ]);

    const s = moment(start, "HHmm");
    const isPast = fullDaySlot.isBefore(moment());
    const initialTop = getEventTop({
        start: s,
        time: mSlot,
        step,
        slotSize
    });

    const addSteps = slotDelta / stepHeight;
    const addMinutes = addSteps*step;

    const allowActions = !isPast && hasRight;

    const ENABLE = useMemo(() => ({
        bottom: allowActions,
        bottomLeft: false,
        bottomRight: false,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
    }), [allowActions]);
    const GRID = useMemo(() => [1, stepHeight], [stepHeight]);
    const SIZE = useMemo(() => ({
        width: '100%',
        height: slotHeight,
    }), [slotHeight]);
    const STYLE = useMemo(() => ({
        position: 'absolute',
        top: initialTop
    }), [initialTop]);
    const HANDLE_CLASS = useMemo(() => ({
        bottom: "time-slot-handler"
    }), []);

    if (isTouch) {
        return <div
            className={getSlotClassnames}
            style={{
                position: 'absolute',
                width: '100%',
                top: initialTop,
                left: 0,
                height: `${stepHeight}px`,
            }}
            onClick={allowActions ? _onClick : null}
        />
    }

    return (
        <Resizable
            className={getSlotClassnames}
            maxWidth="100%"
            minWidth="100%"
            enable={ENABLE}
            grid={GRID}
            size={SIZE}
            style={STYLE}
            handleClasses={HANDLE_CLASS}
            onResizeStart={_startResize}
            onResizeStop={_stopResize}
            onResize={_onResize}
        >
            {isResizing && <span className="slot-label">
                {mSlot.format("HH:mm")} - {moment(mSlot).add((addMinutes+step), 'minutes').format("HH:mm")}
            </span>}
        </Resizable>
    )

}


export default memo(EmptySlot);
