import React, { memo } from 'react';

import { useRoomState } from './hooks';

import PrejoinScreen from './PrejoinScreen';
import VisioConnected from './VisioConnected';


const VisioRoom = () => {
    const roomState = useRoomState();

    if (roomState === 'disconnected') {
        return <PrejoinScreen />
    }

    return <VisioConnected />
}


export default memo(VisioRoom);