export default {
	logout: {
		title: `Déconnexion`,
		confirmation: `Êtes-vous sûr(e) de vouloir vous déconnecter ?`,
		confirm: `Se déconnecter`,
		cancel: `Annuler`
	},
	refresh: `Recharger l'application`,
	plannings: {
		yours: `Votre planning`,
		new: `Nouveau praticien`,
		search: `Recherche un praticien`
	}
}
