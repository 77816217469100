const doLogin = {
	toApi: ({email, password}) => ({
		email, password
	}),
	fromApi: ({email, token}) => ({
		email,
		token,
	}),
}

const getUser = {
	fromApi: ({id, is_pro, is_admin, email, has_access_to, first_name, last_name, particule }) => ({
		id,
		email,
		isPro: is_pro,
		isAdmin: is_admin,
		firstName: first_name,
		lastName: last_name,
		particule,
		hasAccessTo: {
			default: has_access_to ? has_access_to.default : '',
			others: has_access_to && has_access_to.others ? Object.entries(has_access_to.others).map(([key, value]) => ({
				id: key,
				particule: value.particule,
				firstName: value.first_name,
				lastName: value.last_name,
				rights: value.rights ? {
					all: value.rights.includes('all'),
					acceptUser: value.rights.includes('user_accept'),
					blockUser: value.rights.includes('user_block'),
					createUser: value.rights.includes('user_create'),
					confirmAppointments: value.rights.includes('confirm_appointments'),
					settings: value.rights.includes('settings'),
					account: value.rights.includes('account'),
					inbox: value.rights.includes('inbox'),
					sendEmail: value.rights.includes('send_email'),
					website: value.rights.includes('website'),
					drive: value.rights.includes('drive'),
					addAppointment: value.rights.includes('appointment_add'),
					editAppointment: value.rights.includes('appointment_edit'),
					deleteAppointment: value.rights.includes('appointment_delete'),
					day: value.rights.includes('day'),
					dayNote: value.rights.includes('day_note'),
					daySchedule: value.rights.includes('day_schedule'),
					dayInformation: value.rights.includes('day_information'),
					dayClose: value.rights.includes('day_close'),
				} : null,
			})) : [],
		},
	}),
}

const changePassword = {
	toApi: ({currentPassword, newPassword}) => ({
		current_password: currentPassword,
		new_password: newPassword,
	}),
}

export default {
	doLogin,
	getUser,
	changePassword,
};
