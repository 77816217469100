import {createMuiTheme} from '@material-ui/core/styles';
import Pets from '@material-ui/icons/Pets';
import Subject from '@material-ui/icons/Subject';

export default createMuiTheme({
	palette: {
		primary: {
			light: '#67daff',
			main: '#015792',
			dark: '#007ac1',
			contrastText: '#fff'
		},
		secondary: {
			light: '#ffad42',
			main: '#f57c00',
			dark: '#bb4d00',
			contrastText: '#fff'
		}
	},
	typography: {
		useNextVariants: true,
	},
});

export const defaultColorsTypes = {
	default: '#007ac1',
	blocked: '#f44336'
}

const AFIcons = {
	pets: Pets,
}
export const getAFIcon = icon => AFIcons[icon] || Subject;
