import moment from 'moment-timezone';
import { mapAppointment } from './planning';

const createAppointment = {
	toApi: ({conflicts, start, duration, information:{admin:info}, typeId, with:w, additionalField0, additionalField1, additionalField2}) => ({
		checked_practitioner_conflicts: conflicts ? conflicts.map(c => c.id) : [],
		start: moment(start).format(),
		duration,
		information: info,
		appointment_type_id: typeId,
		user_id: w.id,
		family_member_id: w.familyMemberId,
		name: w.id ? '' : w.lastName,
		phone_number: w.id ? '' : w.phoneNumber,
		additional_field_0: additionalField0 || null,
		additional_field_1: additionalField1 || null,
		additional_field_2: additionalField2 || null,
	}),
	fromApi: ({id}) => ({id})
}

const updateAppointment = {
	toApi: ({to, conflicts}) => ({
		checked_practitioner_conflicts: conflicts ? conflicts.map(c => c.id) : [],
		start: moment(to.start).format(),
		duration: to.duration,
		appointment_type_id: to.typeId,
		information_admin: to.information.admin,
		user_name: to.with.lastName,
		user_phone_number: to.with.phoneNumber,
		user_id: to.with.id,
		user_family_member_id: to.with.familyMemberId,
		additional_field_0: to.additionalField0 || null,
		additional_field_1: to.additionalField1 || null,
		additional_field_2: to.additionalField2 || null,
	}),
}


const getAppointment = {
	fromApi: app => mapAppointment(app)
}

const updateAppointmentPvpp = {
	toApi:({with:{id}, pvpp}) => ({
		user_id: id,
		pvpp: !pvpp,
	}),
}

export default {
	createAppointment,
	updateAppointment,
	getAppointment,
	updateAppointmentPvpp,
};
