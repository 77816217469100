import React, { memo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { CheckCircle as SuccessIcon, Warning as WarningIcon, Error as ErrorIcon } from '@material-ui/icons';
import cn from 'classnames';

import { usePreflightTest, useGetPreflightTokens, getNetworkCondition } from './hooks';

import ProgressIndicator from './ui/ProgressIndicator';
import { useLexique } from '../../locales';

export const TEST_DURATION = 10000;

export const NetworkCondition = {
    Red: 0,
    Yellow: 1,
    Green: 2,
}

const useStyles = makeStyles({
    preflight: {
        position: 'relative',
        marginTop: 16,
        display: 'flex',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '0.4em',
        '&.red': {
            color: '#ef9a9a'
        },
        '&.yellow': {
            color: '#ffd54f'
        },
        '&.green': {
            color: '#81c784'
        }
    },
    result: {
        color: 'white',
    },
});

const ResultItem = ({ children, icon, className }) => {
    const classes = useStyles();

    return (
        <>
            <div className={cn(classes.iconContainer, className)}>{icon}</div>
            <Typography variant="subtitle2" className={classes.result}>
                {children}
            </Typography>
        </>
    );
}

export const Result = ({ networkCondition, error }) => {

    const lexique = useLexique('visio.preflight');

    if (error) {
        return <ResultItem icon={<ErrorIcon />}>{lexique.error}</ResultItem>;
    }

    if (networkCondition === NetworkCondition.Red) {
        return (
            <ResultItem icon={<ErrorIcon />} className='red'>
                {lexique.red}
            </ResultItem>
        );
    }

    if (networkCondition === NetworkCondition.Yellow) {
        return (
            <ResultItem icon={<WarningIcon />} className='yellow'>
                {lexique.yellow}
            </ResultItem>
        );
    }

    if (networkCondition === NetworkCondition.Green) {
        return (
            <ResultItem icon={<SuccessIcon />} className='green'>
                {lexique.green}
            </ResultItem>
        );
    }

    return null;
}

const PreflightTest = () => {
    const classes = useStyles();

    const { tokens, tokenError } = useGetPreflightTokens();
    const { testFailure, testReport } = usePreflightTest(tokens?.[0], tokens?.[1]);

    const networkCondition = getNetworkCondition(testReport);

    const lexique = useLexique('visio.preflight');

    return (
        <div className={classes.preflight}>
            {!testFailure && !testReport ? (
                <>
                    <ProgressIndicator />
                    <Typography variant="subtitle2" className={classes.result}>
                        {lexique.inprogress}
                    </Typography>
                </>
            ) : (
                <Result networkCondition={networkCondition} error={tokenError || testFailure} />
            )}
        </div>
    );
}

export default memo(PreflightTest)