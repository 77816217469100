import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import cn from 'classnames';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';

import Today from '@material-ui/icons/Today';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import getLexique from '../../locales';

class CalendarToolbar extends PureComponent {

	state = { 
		selectedDate: moment(),
		openPicker: false,
	}


	openPickerDate = () => {
		this.setState({openPicker: true});
	}

	onClosePicker = () => {
		this.setState({openPicker: false})
	}

	handlePickChange = selectedDate => {
		this.setState({selectedDate, openPicker: false});
		this.props.onNavigate('DATE', selectedDate)
	}

	onChangeDate = date => () => {
		this.props.onNavigate('DATE', date)
	}

    onPrev = () => this.props.onNavigate('PREV');
    onNext = () => this.props.onNavigate('NEXT');

    render() {
        const { date, lexique, locale, planningConf } = this.props;
		const { selectedDate } = this.state;

		const m = moment(date);
		const start = moment(date).startOf('week');
		const nbDays = !planningConf.showWeekEnd ? 5 : (!planningConf.showSunday ? 6 : 7)

    	return (
    		<div className="planning_toolbar flex aic">
                <div className="flex aic tal toolbar_switch-days">
					<div>
						<IconButton onClick={this.openPickerDate}>
							<Today />
						</IconButton>
					</div>
					<div className="flex1 tac">
						{Array(nbDays).fill().map((v, i) => {
							const current = moment(start).add(i, 'days');
							return (
								<button key={current.format('YYYY-MM-DD')}
									className={cn('toolbar_switch-day', {
										'toolbar_switch-day--is-today': current.format('YYYY-MM-DD') === m.format('YYYY-MM-DD')
									})}
									onClick={this.onChangeDate(current)}
								>
									<span className="toolbar_switch-day_label">
										{current.format('dd')}
									</span>
									<span className="toolbar_switch-day_number">
										{current.format('DD')}
									</span>
								</button>
							)
						})}
					</div>
                </div>
				<div className="planning_toolbar_picker">
					<MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
						<DatePicker
							open={this.state.openPicker}
							onClose={this.onClosePicker}
							label=""
							todayLabel={lexique.today}
							showTodayButton
							format="DD/MM/YYY"
							value={selectedDate}
							onChange={this.handlePickChange}
							cancelLabel={lexique.cancel}
							autoOk
							leftArrowIcon={<KeyboardArrowLeft />}
							rightArrowIcon={<KeyboardArrowRight />}
							shouldDisableDate={date => {
								const dayOfWeek = date.weekday();
								if (dayOfWeek >= 5 && !planningConf.showWeekEnd) return true;
								if (dayOfWeek === 6 && !planningConf.showSunday) return true;

								return false;
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
    		</div>
    	)
    }
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).event.toolbar,
    config: getLexique(env.locale).config,
	locale: env.locale,
	planningConf: plannings[plannings.selected].configuration,
});

export default connect(mapStateToProps)(CalendarToolbar);
