import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import ModalTransitionZoom from './ModalTransitionZoom';
import Message from './Message';

import getLexique from '../../locales';

class BrowserChecker extends PureComponent {

	state = { open: true };

	isOutdated = () => {
		const { browser: {name, versionNumber}, initialParams } = this.props;

		switch (name) {
			case "chrome":
				return versionNumber < 60;
			case "firefox":
				return versionNumber < 60;
			case "edge":
				return versionNumber < 16;
			case "safari":
				return versionNumber < 10;
			default: {
				// Checking for initialParams
				const params = new URLSearchParams(initialParams);
				if (params.has("soft") && params.get("soft") === "native") return false;
				return true;
			}
		}
	}

	closeModal = () => {
		this.setState({open: false});
	}

	render() {
		const { lexique, browser } = this.props;
		const { open } = this.state;

		if (!browser) return null;
		if (!this.isOutdated()) return null;

		return <Dialog
			open={open}
			disableBackdropClick
			disableEscapeKeyDown
			TransitionComponent={ModalTransitionZoom}
		>
			<DialogTitle>{lexique.title}</DialogTitle>
			<DialogContent>
				<Message level="warning" show>
					{lexique.body}
				</Message>
				<p>
				{ lexique.whatToDo() }
				</p>
				<p>
					{lexique.detected(browser)}
				</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.closeModal} color="primary">
	            	{lexique.close}
	            </Button>
			</DialogActions>
		</Dialog>
	}
}

const mapStateToProps = ({user, env:{browser, locale}}) => ({
	lexique: getLexique(locale).errors.outdated,
	browser,
	initialParams: user.initialParams,
});

export default connect(mapStateToProps)(BrowserChecker);
