import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash.isequal';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Check from '@material-ui/icons/Check';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import getLexique, { _lp } from '../../locales';
import { updatePlanningConfig } from '../../actions/planning';
import PublicHoliday from '../../libs/public-holiday-france';

class Appointments extends PureComponent {

	state = Object.assign({shouldConfirmAppointment: false, shouldAcceptUser: false, preventBookingBefore: 0, allowCancelUntil: 0, bookableForNext: 0, sendReminderAt: 0, acceptOnlyCertified: false, doNotAcceptNewUser: false},
		PublicHoliday.keys.reduce((prev, key) => Object.assign(prev, {[`publicHoliday${key}`]: false}), {})
	);

	mapPropsToInnerState = () => {
		if (!this.props.configuration || Object.keys(this.props.configuration).length === 0) return;

		const { shouldConfirmAppointment, shouldAcceptUser, preventBookingBefore, allowCancelUntil, bookableForNext, sendReminderAt, acceptOnlyCertified, doNotAcceptNewUser } = this.props.configuration;

		this.setState(Object.assign({
				shouldConfirmAppointment, shouldAcceptUser, preventBookingBefore, allowCancelUntil, bookableForNext, sendReminderAt, acceptOnlyCertified, doNotAcceptNewUser
			},
			PublicHoliday.keys.reduce((prev, key) => Object.assign(prev, {[`publicHoliday${key}`]: this.props.configuration[`publicHoliday${key}`]}), {}),
		));
	}

	componentDidMount() {
		if (this.props.configuration) this.mapPropsToInnerState();
	}

	componentDidUpdate(oldProps) {
		if (!_isEqual(oldProps.configuration, this.props.configuration)) this.mapPropsToInnerState();
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});
	handleChecked = field => ({target:{checked}}) => this.setState({[field]: checked});

	submitForm = async () => {
		const { selectedPlanning, actions:{updatePlanningConfig}} = this.props;
		this.setState({loading: true});

		await updatePlanningConfig({
			planningId: selectedPlanning,
			...this.state,
		});

		this.setState({loading: false, complete: true});
		setTimeout(() => this.setState({complete: false}), 3000);
	}

	render() {
		const { lexique, config } = this.props;
		const { shouldConfirmAppointment, shouldAcceptUser, preventBookingBefore, allowCancelUntil, bookableForNext, sendReminderAt, acceptOnlyCertified, loading, complete, doNotAcceptNewUser } = this.state;

		return (
			<div className="planning-configuration-container">
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">{_lp(lexique.labels.doNotAcceptNewUser)}</Typography>
					<Switch
						checked={doNotAcceptNewUser}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('doNotAcceptNewUser')}
					/>
				</div>
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">{_lp(lexique.labels.acceptOnlyCertified)}</Typography>
					<Switch
						checked={acceptOnlyCertified}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('acceptOnlyCertified')}
					/>
				</div>
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">{_lp(lexique.labels.shouldAcceptUser)}</Typography>
					<Switch
						checked={shouldAcceptUser}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('shouldAcceptUser')}
					/>
				</div>
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">{lexique.labels.shouldConfirmAppointment}</Typography>
					<Switch
						checked={shouldConfirmAppointment}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('shouldConfirmAppointment')}
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{_lp(lexique.labels.preventBookingBefore)}</Typography>
					<TextField
						disabled={loading}
						value={preventBookingBefore}
						onChange={this.handleChange('preventBookingBefore')}
						type="number"
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{_lp(lexique.labels.allowCancelUntil)}</Typography>
					<TextField
						disabled={loading}
						value={allowCancelUntil}
						onChange={this.handleChange('allowCancelUntil')}
						type="number"
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{_lp(lexique.labels.bookableForNext)}</Typography>
					<TextField
						disabled={loading}
						value={bookableForNext}
						onChange={this.handleChange('bookableForNext')}
						type="number"
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{_lp(lexique.labels.sendReminderAt)}</Typography>
					<TextField
						disabled={loading}
						value={sendReminderAt}
						onChange={this.handleChange('sendReminderAt')}
						type="number"
					/>
				</div>
				<Divider />
				{
					Object.entries(PublicHoliday.list).map(([key, d]) =>
						<div className="form-row form-row--switch" key={key}>
							<Typography component='div' variant="body1">
								{lexique.labels.publicHoliday[key]} <span>({d.format(config.dateFormatLong)})</span>
							</Typography>
							<Switch
								checked={this.state[`publicHoliday${key}`]}
								color="primary"
								disabled={loading}
								onChange={this.handleChecked(`publicHoliday${key}`)}
							/>
						</div>
					)
				}
				<div className="form-row">
					<div />
					<div>
						<Button
							disabled={loading}
							variant="contained"
							disableElevation
							className="button--success"
							onClick={this.submitForm}
						>
							{lexique.save}
							{loading && <ButtonCircularProgress />}
							{complete && <Check className="ml10" />}
						</Button>
					</div>
				</div>
				<div className="form-row" />
			</div>
		);
	}
}

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.appointments,
	config: getLexique(env.locale).config,
	selectedPlanning: plannings.selected,
	configuration: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updatePlanningConfig }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
