import React, { memo, useCallback, useState } from 'react';
import { DialogContent, ButtonBase, Button } from '@material-ui/core';
import { InsertDriveFile as FileIcon } from '@material-ui/icons';
import cn from 'classnames';
import Video from 'twilio-video';

import { useLexique } from '../../locales';
import { useSelector, useDispatch } from '../../libs/redux';
import { closeVisio } from '../../actions/ui';

import Alert from '../ui/Alert';
import ToggleMinify from './ui/ToggleMinify';
import VisioProvider from './VisioProvider';
import VisioRoom from './VisioRoom';

const SETTINGS = {
    bandwidthProfile: {
        video: {
            mode: "collaboration",
            renderDimensions: {
                low: { width: 160, height: 90 },
                standard: { width: 1280, height: 720 },
                high: { width: 1440, height: 1080 },
            }
        }
    },
    dominantSpeaker: false,
    networkQuality: { local: 1, remote: 1 },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
}

const Visio = () => {

    const { open, planningId, eventId, minify } = useSelector(({ui}) => ui.visio); 

    if (!open || !planningId || !eventId) return null;

    return (
        <div
            className={cn('visio', minify && 'visio--minified')}
        >
            <VisioContent
                key={`${planningId}-${eventId}`}
                planningId={planningId}
                eventId={eventId}
                minified={minify}
            />
        </div>
    )

}

const VisioContent = memo(({eventId, planningId, minified}) => {

    const lexique = useLexique('visio');
    const dispatch = useDispatch();
    const [, setError] = useState(false);

    if (!Video.isSupported && !window._SPREL_ON_ELECTRON) {
        return (
            <DialogContent>
                <Alert title={lexique.noRtc.title} severity="error">
                    {lexique.noRtc.body}
                    <div style={{marginTop: 20}}>
                        <Button variant="outlined" onClick={() => dispatch(closeVisio())}>{lexique.noRtc.close}</Button>
                    </div>
                </Alert>
            </DialogContent>
        )
    }

    return (<>
            {/* <WebRTC eventId={eventId} planningId={planningId} answer={answer} candidates={candidates} /> */}
            <VisioProvider options={SETTINGS} onError={setError} planningId={planningId} eventId={eventId} minified={minified}> 
                <VisioRoom />
                <ToggleMinify minified={minified} />
            </VisioProvider>
            <ReceivedFiles minified={minified} />
        </>
    )

});

const ReceivedFiles = memo(({minified}) => {

    const files = useSelector(({ui}) => ui.visio.files);

    const lexique = useLexique('visio.filesReceiver')

    if (!files || files.length === 0 || minified) return null;

    return (
        <div className='visio_files-received'>
            <Alert severity="info" title={lexique.infos.title} content={lexique.infos.body} />
            <div className='visio_files-received_container'>
                { files.map(file => <FileItem key={file.file} {...file} /> )}
            </div>
        </div>
    )

})

const FileItem = memo(({file, url}) => {

    const lexique = useLexique('visio.filesReceiver');

    const _onClick = useCallback(() => {

        const opened = window.open(url);
        if (!opened) {
            window.alert(lexique.errorOpen)
        }

    }, [url, lexique]);

    return (
        <ButtonBase key={file.file} className='visio_files-received_file' onClick={_onClick}>
            <FileIcon className='visio_files-received_file-icon' />
            <span className='visio_files-received_file-name'>{file}</span>
        </ButtonBase>
    )
})

export default memo(Visio);