import { useState, useCallback } from "react"

export const useCoolState = d => {

    const [state, setState] = useState(d);

    const _setState = useCallback(data => setState(s => ({...s, ...data})), []);

    return [state, _setState, setState];

}