import React from 'react';
import { connect } from 'react-redux';
import nl2br from 'react-nl2br';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ModalTransitionZoom from './ModalTransitionZoom';

import getLexique from '../../locales';

const ApiErrorModale = ({lexique}) => (
	<Dialog
		open={true}
		disableBackdropClick
		disableEscapeKeyDown
		TransitionComponent={ModalTransitionZoom}
	>
		<DialogTitle>{lexique.title}</DialogTitle>
		<DialogContent>
			<DialogContentText>
				{nl2br(lexique.body)}
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => window.location.reload(true)} color="primary">
            	{lexique.close}
            </Button>
		</DialogActions>
	</Dialog>
)

const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).errors.api,
});

export default connect(mapStateToProps)(ApiErrorModale);
