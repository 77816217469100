import React, { memo, useEffect } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useDispatch } from '../libs/redux';
import { getPaymentsInfos } from '../actions/planning';
import { usePayments, usePlanning } from '../hooks/planning';
import { useLexique } from '../locales';
import { config } from '../config/env';

import RegisterToPayments from './payments/RegisterToPayments';
import PaymentsUploadDocuments from './payments/PaymentsUploadDocuments';
import WaitingForApproval from './payments/WaitingForApproval';
import PaymentsManage from './payments/PaymentsManage';

const stripePromise = loadStripe(config.stripeKey);
const Payments = () => {

    const [, loading] = usePlanning();

    const lexique = useLexique('payments');

    // Current planning is being loaded, please wait :)
    if (loading) return <LinearProgress color="secondary" />

    return (
        <div className="payments pad20">
            <div className="section_title tal">
                <Typography variant="h4">{lexique.title}</Typography>
                <Elements stripe={stripePromise}>
                    <PaymentsDetails />
                </Elements>
            </div>
        </div>
    )

}

const PaymentsDetails = () => {

    const dispatch = useDispatch();
    const payments = usePayments();

    useEffect(() => {

        // load Payments infos
        dispatch(getPaymentsInfos());

    }, [dispatch]);

    if (!payments?.loaded) return (
        <LinearProgress color="secondary" />
    )

    if (payments.noPayments) {
        return (
            <RegisterToPayments />
        )
    }

    if (payments.disabled === "requirements.pending_verification") {
        return (
            <WaitingForApproval />
        );
    }

    if (!payments.canPayout) {
        return (
            <PaymentsUploadDocuments />
        )
    }

    return (
        <PaymentsManage />
    )
}

export default memo(Payments);