const loadMessages = {
	fromApi: messages => messages ? messages.map(message => ({
		id: message.id,
		sentAt: message.sent_at,
		openedAt: message.opened_at || "",
		name: message.name,
		email: message.email,
		subject: message.subject,
		state: message.state,
	})) : [],
}
const getMessage = {
	fromApi: message => ({
		id: message.id,
		email: message.email,
		name: message.name,
		sentAt: message.sent_at,
		openedAt: message.opened_at,
		state: message.state,
		subject: message.subject,
		body: message.body,
		replies: message.replies ? Object.values(message.replies).map(reply => ({
			id: reply.id,
			sentAt: reply.sent_at,
			body: reply.body,
			subject: reply.subject,
			hasAttachment: reply.has_attachment || false,
		})) : [],
	})
}

const updateMessageState = {
	toApi: ({state}) => ({state})
}

const setMessageUnread = {
	toApi: () => ({opened_at: ""})
}

const send = {
	toApi: ({email, name, subject, body, includeQuestion, originalMessageId, attachment}) => ({
		to_email: email,
		to_name: name,
		subject,
		body,
		include_original_message: includeQuestion,
		original_message_id: originalMessageId,
		attachment,
	}),
}

export default {
	loadMessages,
	getMessage,
	updateMessageState,
	setMessageUnread,
	send,
};
