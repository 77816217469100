import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "../libs/redux"
import { getPaymentsBalance, getUnpaidPayments, getPaymentsOverPeriod, getPaymentsByUser } from "../actions/planning";

export const usePlanning = () => {
    const planning = useSelector(({plannings}) => plannings?.[plannings?.selected]);

    return [planning, !planning || planning?.loading];
}

export const useSelectedPlanningId = () => {
    const planningId = useSelector(({plannings}) => plannings.selected);
    return planningId;
}

export const usePayments = () => {
    const payments = useSelector(({plannings}) => plannings?.[plannings?.selected]?.payments);
    return payments;
}

export const useProfile = () => {
    const profile = useSelector(({plannings}) => plannings?.[plannings?.selected]?.profile);

    return profile;
}

export const useBalance = () => {
    const balance = useSelector(({plannings}) => plannings?.[plannings?.selected]?.paymentsBalance);
    const planningId = useSelectedPlanningId();
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getPaymentsBalance(planningId));

    }, [planningId, dispatch]);

    return balance;
}

export const useUnpaidPayments = () => {
    const [unpaid, setUnpaid] = useState([]);
    const [loading, setLoading] = useState(false);
    const planningId = useSelectedPlanningId();
    const dispatch = useDispatch();
    useEffect(() => {

        if (!planningId) return;

        const retrieveUnpaid = async() => {
            setLoading(true);
            const p = await dispatch(getUnpaidPayments(planningId));
            setUnpaid(p);
            setLoading(false);
        }

        retrieveUnpaid();

    }, [planningId, dispatch]);

    const onCancelItem = useCallback(id => {

        setUnpaid(items => items.map(i => {
            if (i.id !== id) return i;
            return {
                ...i,
                canceled: true,
                canceledAt: new Date(),
            }
        }))

    }, []);

    return [
        unpaid,
        loading,
        onCancelItem
    ];
}

export const usePaymentsPeriod = period => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const planningId = useSelectedPlanningId();
    const dispatch = useDispatch();
    useEffect(() => {

        if (!planningId || period === '') return;

        const retrieve = async() => {
            setLoading(true);
            const p = await dispatch(getPaymentsOverPeriod({planningId, period}));
            setItems(p);
            setLoading(false);
        }

        retrieve();

    }, [planningId, dispatch, period]);

    const onCancelItem = useCallback(id => {

        setItems(items => items.map(i => {
            if (i.id !== id) return i;
            return {
                ...i,
                canceled: true,
                canceledAt: new Date(),
            }
        }))

    }, [])

    return [
        items,
        loading,
        onCancelItem
    ];
}

export const usePaymentsByUser = userId => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const planningId = useSelectedPlanningId();
    const dispatch = useDispatch();
    useEffect(() => {

        if (!planningId || !userId) return;

        const retrieve = async() => {
            setLoading(true);
            const p = await dispatch(getPaymentsByUser({planningId, userId}));
            setItems(p);
            setLoading(false);
        }

        retrieve();

    }, [planningId, dispatch, userId]);

    const onCancelItem = useCallback(id => {

        setItems(items => items.map(i => {
            if (i.id !== id) return i;
            return {
                ...i,
                canceled: true,
                canceledAt: new Date(),
            }
        }))

    }, [])

    return [
        items,
        loading,
        onCancelItem
    ];
}