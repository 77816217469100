export default {
	title: {
		new: `Ajouter un rendez-vous`,
		edit: `Modifier un rendez-vous`,
	},
	confirm: {
		new: `Ajouter`,
		edit: `Modifier`,
	},
	block: `Bloquer les créneaux`,
	unblock: `Ouvrir le créneau`,
	cancel: `Annuler`,
	today: `Aujourd'hui`,
	errorVisio: 'Vous devez obligatoirement choisir un {p} possédant un compte sur le site pour pouvoir créer une téléconsultation.',
	fields: {
		schedule: `Date et heure du rendez-vous`,
		user: `Quel {p} ?`,
		userSearch: `Rechercher un {p}`,
		phoneNumber: `Numéro de téléphone`,
		duration: `Durée du rendez-vous`,
		appointmentType: `Motif de rendez-vous`,
		information: `Notes personnelles`,
	},
	search: {
		yo: 'ans',
		male: `Homme`,
		female: `Femme`,
		other: `Autre`,
		pets: {
			male: 'Mâle',
			female: 'Femelle',
			other: 'Autre',
		}
	}
}
