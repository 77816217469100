import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AddCircleOutline from '@material-ui/icons/AddCircleOutlined';
import Edit from '@material-ui/icons/Edit';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import { closeModaleEditAppointmentSpecificSchedule, openModalePickSchedule } from '../../actions/ui';

import { militaryToHuman } from '../../libs/formatters';
import getLexique from '../../locales';

class EditAppointmentTypeSpecificSchedule extends PureComponent {

	initialState = { from: null, to: null, schedules: Array(7).fill('') };
	state = { ...this.initialState };

	mapPropsToInnerState = () => {
		const { appointment, specificSchedule } = this.props;
		if (!appointment || !specificSchedule) return;

		const { from, to, schedules } = specificSchedule;
		this.setState({ from: moment(from), to: moment(to), schedules });
	}

	onClose = () => {
		this.props.actions.closeModaleEditAppointmentSpecificSchedule();
		this.resetInnerState();
	}

	onConfirm = () => {
		const { from, to, schedules } = this.state;
		if (from === null || to === null) return;
		const { onConfirm } = this.props;
		onConfirm({
			from: moment(from).startOf('day').format(),
			to: moment(to).endOf('day').format(),
			fromUnix: moment(from).startOf('day').unix(),
			schedules
		});
		this.onClose();
	}

	resetInnerState = () => {
		this.setState({ ...this.initialState });
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.show && this.props.show) this.mapPropsToInnerState();
		if (oldProps.show && !this.props.show) this.resetInnerState();
	}

	handleChange = field => date => {
		this.setState({[field]: date});
		if (field === "from") this.setState({to: null});
	}

	openSchedulePicker = weekDay => () => {
		this.props.actions.openModalePickSchedule({
			onConfirm: this.onConfirmEditDaySchedule(weekDay),
			schedule: this.state.schedules[weekDay],
		});
	}

	onConfirmEditDaySchedule = weekDay => ({schedule}) => {
		const schedules = this.state.schedules.map((s, i) => i!==weekDay ? s : schedule);
		this.setState({schedules});
	}

    render() {
		const { show, lexique, appointment, selectedPlanning, lGlobal, specificSchedule, locale, isMobile, isForAll } = this.props;
		const { from, to, schedules } = this.state;
		if (!appointment) return null;

		const configPlanning = selectedPlanning.configuration;
		const daysInWeek = !configPlanning.showWeekEnd ? 5 : (configPlanning.showSunday ? 7 : 6);

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-add-appointment-type-dialog-title"
			>
				<DialogTitle id="modale-add-appointment-type-dialog-title">
					{lexique.title[specificSchedule ? 'edit' : 'new']}
				</DialogTitle>
				<DialogContent>
					{ isForAll && <p>{lexique.isForAll}</p> }
					<MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
						<div className="flex flex1 mb20">
							<DatePicker
								className="flex1"
								label={lexique.labels.from}
								format="DD/MM/YYYY"
								value={from}
								onChange={this.handleChange('from')}
								cancelLabel={lexique.cancel}
								autoOk
								leftArrowIcon={<KeyboardArrowLeft />}
								rightArrowIcon={<KeyboardArrowRight />}
								shouldDisableDate={() => false}
							/>
							<DatePicker
								className="flex1"
								label={lexique.labels.to}
								format="DD/MM/YYYY"
								value={to}
								onChange={this.handleChange('to')}
								cancelLabel={lexique.cancel}
								autoOk
								leftArrowIcon={<KeyboardArrowLeft />}
								rightArrowIcon={<KeyboardArrowRight />}
								shouldDisableDate={date => moment(date).isBefore(moment(from))}
								disabled={from === null}
							/>
						</div>
					</MuiPickersUtilsProvider>

					<div className="schedules-container">
						{
							Array(daysInWeek).fill().map((v, i) => (
								<div className="flex aic" key={lGlobal.days[i]}>
									<div className="w25 padl10">
										<Typography variant="body1" noWrap>{ lGlobal.days[i] }</Typography>
									</div>
									<div className="flex1">
										<Typography color="textSecondary">{
											schedules[i] !== '' ? militaryToHuman(schedules[i]) : lexique.scheduleClosed
										}</Typography>
									</div>
									<IconButton onClick={this.openSchedulePicker(i)}>
										{schedules[i] !== '' ? <Edit /> : <AddCircleOutline />}
									</IconButton>
								</div>
							)
						)}
					</div>
				</DialogContent>
				<DialogActions>

					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>

					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm[specificSchedule ? 'edit' : 'new']}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, plannings, ui:{device, modales:{editAppointmentTypeSpecificSchedule:{isForAll, show, onConfirm, appointment, specificSchedule}}}}) => ({
	isMobile: device.isMobile,
	lexique: getLexique(env.locale).modales.editAppointmentTypeSpecificSchedule,
	config: getLexique(env.locale).config,
	lGlobal: getLexique(env.locale).global,
	locale: env.locale,
    show,
	onConfirm,
	appointment,
	specificSchedule,
	isForAll,
	selectedPlanning: plannings[plannings.selected],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleEditAppointmentSpecificSchedule, openModalePickSchedule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAppointmentTypeSpecificSchedule);
