import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _isEqual from 'lodash.isequal';
import { Tooltip } from 'react-tippy';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Check from '@material-ui/icons/Check';
import InfoOutline from '@material-ui/icons/InfoOutlined';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import getLexique from '../../locales';
import { updatePlanningConfig } from '../../actions/planning';

class Planning extends PureComponent {

	state = { start : moment().toDate(), end: moment().toDate(), showWeekEnd: false, showSunday: false, loading: false, complete: false, step: 15, rollingDays: false, highlightBookable: false, highlightNonBookable: false, highlightBlockedByRule: false, highContrastDesktop: false, highContrastMobile: false, displayEventTooltip:false, autoConfirmEventChange: false };

	mapPropsToInnerState = () => {
		if (!this.props.configuration || Object.keys(this.props.configuration).length === 0) return;

		const { start, end } = this.props.configuration;

		this.setState({
			...this.props.configuration,
			start: moment(start, 'HHmm').toDate(),
			end: moment(end, 'HHmm').toDate(),
		});
	}

	componentDidMount() {
		if (this.props.configuration) this.mapPropsToInnerState();
	}

	componentDidUpdate(oldProps) {
		if (!_isEqual(oldProps.configuration, this.props.configuration)) this.mapPropsToInnerState();
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});
	handleTimeChange = field => time => this.setState({[field]: time});
	handleChecked = field => ({target:{checked}}) => {
		this.setState({[field]: checked});
		if (field === 'showWeekEnd' && !checked) this.setState({showSunday: false});
	}

	submitForm = async () => {
		const { selectedPlanning, actions:{updatePlanningConfig}} = this.props;
		const { start, end } = this.state;
		this.setState({loading: true});

		await updatePlanningConfig({
			planningId: selectedPlanning,
			...this.state,
			start: moment(start).format('HHmm'),
			end: moment(end).format('HHmm'),
		});

		this.setState({loading: false, complete: true});
		setTimeout(() => this.setState({complete: false}), 3000);
	}

	render() {
		const { lexique, locale } = this.props;
		const { start, end, showWeekEnd, showSunday, loading, complete, step, rollingDays, highlightBookable, highlightNonBookable, highlightBlockedByRule, highContrastDesktop, highContrastMobile, displayEventTooltip, autoConfirmEventChange } = this.state;

		return (
			<div className="planning-configuration-container">
				<MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={locale}>
					<Fragment>
					<div className="form-row">
						<Typography component='div' variant="body1">{lexique.labels.start}</Typography>
						<TimePicker
							disabled={loading}
							ampm={false}
							value={start}
							onChange={this.handleTimeChange('start')}
							minutesStep={5}
						/>
					</div>
					<div className="form-row">
						<Typography component='div' variant="body1">{lexique.labels.end}</Typography>
						<TimePicker
							disabled={loading}
							ampm={false}
							value={end}
							onChange={this.handleTimeChange('end')}
							minutesStep={5}
						/>
					</div>
					<div className="form-row">
						<Typography component='div' variant="body1">{lexique.labels.step}</Typography>
						<TextField
							disabled={loading}
							value={step}
							onChange={this.handleChange('step')}
							type="number"
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.rollingDays}
							<Tooltip touchHold title={lexique.help.rollingDays}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={rollingDays}
							disabled={loading}
							color="primary"
							onChange={this.handleChecked('rollingDays')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">{lexique.labels.showWeekEnd}</Typography>
						<Switch
							checked={showWeekEnd}
							disabled={loading}
							color="primary"
							onChange={this.handleChecked('showWeekEnd')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">{lexique.labels.showSunday}</Typography>
						<Switch
							checked={showSunday}
							color="primary"
							disabled={loading || !showWeekEnd}
							onChange={this.handleChecked('showSunday')}
						/>
					</div>
					<Divider />
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.highlightBookable}
							<Tooltip touchHold title={lexique.help.highlightBookable}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={highlightBookable}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('highlightBookable')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.highlightNonBookable}
							<Tooltip touchHold title={lexique.help.highlightNonBookable}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={highlightNonBookable}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('highlightNonBookable')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.highlightBlockedByRule}
							<Tooltip touchHold title={lexique.help.highlightBlockedByRule}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={highlightBlockedByRule}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('highlightBlockedByRule')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.displayEventTooltip}
							<Tooltip touchHold title={lexique.help.displayEventTooltip}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={displayEventTooltip}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('displayEventTooltip')}
						/>
					</div>
					<Divider />
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.autoConfirmEventChange}
							<Tooltip touchHold title={lexique.help.autoConfirmEventChange}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={autoConfirmEventChange}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('autoConfirmEventChange')}
						/>
					</div>
					<Divider />
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.highContrastDesktop}
							<Tooltip touchHold title={lexique.help.highContrastDesktop}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={highContrastDesktop}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('highContrastDesktop')}
						/>
					</div>
					<div className="form-row form-row--switch">
						<Typography component='div' variant="body1">
							{lexique.labels.highContrastMobile}
							<Tooltip touchHold title={lexique.help.highContrastMobile}>
								<InfoOutline className="ico--inline ml5" />
							</Tooltip>
						</Typography>
						<Switch
							checked={highContrastMobile}
							color="primary"
							disabled={loading}
							onChange={this.handleChecked('highContrastMobile')}
						/>
					</div>
					<div className="form-row">
						<div />
						<div>
							<Button
								disabled={loading}
								variant="contained"
								className="button--success"
								onClick={this.submitForm}
								disableElevation
							>
								{lexique.save}
								{loading && <ButtonCircularProgress />}
								{complete && <Check className="ml10" />}
							</Button>
						</div>
					</div>
					<div className="form-row" />
					</Fragment>
				</MuiPickersUtilsProvider>
			</div>
		);
	}
}

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.planning,
	locale: env.locale,
	selectedPlanning: plannings.selected,
	configuration: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updatePlanningConfig }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
