export default {
	appointments: {
		confirmed: `Rendez-vous confirmé avec succès !`,
		created: `Rendez-vous créé avec succès !`,
		updated: `Rendez-vous mis à jour avec succès !`,
		deleted: `Rendez-vous supprimé avec succès !`,
		blocked: `Créneaux bloqués avec succès !`,
		unblocked: `Créneaux débloqués avec succès !`,
		moved: `Rendez-vous modifié avec succès !`,
		pvpp: `Présence modifiée avec succès !`,
	},
	planningDay: {
		opened: `Jour ouvert à la réservation avec succès !`,
		closed: `Jour fermé à la réservation avec succès !`,
		informationChanged: `Message aux {p}s changé avec succés !`,
		planningUpdated: `Plannings du jour modifiés avec succès !`
	},
	users: {
		accepted: `Famille acceptée avec succès !`,
		blocked: `Famille bloquée avec succès !`
	},
	pusher: {
		userRequest: `Nouvelle demande d'accès : Famille **{0}**`,
		appointments: {
			deleted: `Votre rendez-vous du **{0}** avec **{1}** a été annulé`,
			update: `Votre rendez-vous du **{0}** avec **{1}** a été déplacé au **{2}**`,
			new: `Nouveau rendez-vous le **{0}** avec **{1}**`,
			confirmed: `Votre rendez-vous du **{0}** avec **{1}** a été confirmé par **{2}**`
		},
		messages: {
			new: `Nouveau message de **{0}** ({1}) : **{2}**`
		},
		userWaitingInRoom: {
			body: `**{0}**, votre rendez-vous de **{1}**, vous attend dans la salle d'attente virtuelle de téléconsultation`,
			join: 'Rejoindre la téléconsultation',
			delay: `Prévenir d'un retard`,
		}
	},
	messages: {
		archived: `Message archivé`,
		unarchived: `Message désarchivé`,
		unarchivedAndMarkedAsUnread: `Message désarchivé et marqué comme non lu`,
		removed: `Message supprimé`,
		removedForever: `Message supprimé définitivement`,
		markedAsUnread: `Message marqué comme non lu`,
		restored: `Message restauré`,
		sent: `Message envoyé avec succès !`,
	},
	website: {
		activated: `Section activée !`,
		desactivated: `Section désactivée`,
		edited: `Section modifiée avec succès !`
	},
	account: {
		cardAdded: `Votre carte a été ajoutée avec succès !`,
		planUpdated: `Votre mode de paiement a été mis à jour avec succès !`
	},
	specificSchedules: {
		added: `Période d'horaires spécifiques ajoutée pour tous vos types de rendez-vous !`,
	},
	unblockSlotByRule: {
		done: `Créneau ouvert à la réservation !`
	},
	payments: {
		success: 'Facture envoyée avec succès !',
		error: 'Une erreur est survenue lors de la création de votre facture',
	}
}
