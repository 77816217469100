import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import TodayAppointments from './dashboard/TodayAppointments';
import AppointmentsToConfirm from './dashboard/AppointmentsToConfirm';
import UsersToAccept from './dashboard/UsersToAccept';
import UnreadMessages from './dashboard/UnreadMessages';
import DriveSpace from './dashboard/DriveSpace';

import getLexique from '../locales';
import { getUserRightsOnPlanning } from '../libs/checkers';
import PaymentsBalance from './dashboard/PaymentsBalance';

class Dashboard extends PureComponent {

	getPractitionerName = () => {
		const { user:{particule, firstName, lastName} } = this.props;
		if (particule) return `${particule} ${lastName}`;
		return `${firstName} ${lastName}`;
	}

	render() {
		const { lexique, user, selectedPlanning, env } = this.props;
		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		const showPayments = env && env.usePayments === true && rights.payments;

		return (
			<div className="dashboard pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.welcome.replace('{0}', this.getPractitionerName())}</Typography>
				</div>
				<div className="dashboard_widgets">
					<TodayAppointments />
					{rights.confirmAppointments && <AppointmentsToConfirm />}
					{rights.acceptUser && <UsersToAccept />}
					{rights.inbox && <UnreadMessages />}
					{rights.drive && <DriveSpace />}
					{showPayments && <PaymentsBalance />}
				</div>
			</div>
		);
	}
}


const mapStateToProps = ({env, user, ui, plannings}) => ({
	lexique: getLexique(env.locale).dashboard,
	env: plannings[plannings.selected] && plannings[plannings.selected].env ? plannings[plannings.selected].env : null,
	user: user,
	isMobile: ui.device.isMobile,
	selectedPlanning: plannings.selected,
});

export default connect(mapStateToProps)(Dashboard);
