const mapLog = log => {
	return {
		...log
	}
}

const getLogs = {
	toApi: ({period}) => ({period}),
	fromApi: logs => logs ? logs.map(mapLog) : []
}

export default {
	getLogs,
};
