import { createAction} from 'redux-actions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import { openModale } from './ui';

import * as USERS from '../constants/users';
import * as api from '../api/users';
import mapper from '../mappers/users';
import getLexique, { _lp } from '../locales';

const loadUserRequest = createAction(USERS.LOAD_REQUEST);
const loadUserComplete = createAction(USERS.LOAD_COMPLETE);
export const acceptUserComplete = createAction(USERS.ACCEPT);
export const addUserComplete = createAction(USERS.ADD);
export const blockUserComplete = createAction(USERS.BLOCK);
export const addRequestUserToStore = createAction(USERS.REQUEST_ACCESS);

export const acceptUser = query => async (dispatch, getState) => {
	await api.acceptUser(query);
	dispatch(acceptUserComplete(query));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.users;
	toast.success(_lp(lexique.accepted));
}

export const blockUser = query => async (dispatch, getState) => {
	await api.blockUser(query);
	dispatch(blockUserComplete(query));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.users;
	toast.error(_lp(lexique.blocked));
}

export const getShortInfos = query => async () => {
	const res = await api.getShortInfos(query);
	const mapped = mapper.getShortInfos.fromApi(res);
	return mapped;
}

export const openUserDetails = userId => async (dispatch, getState) => {
	const { selected } = getState().plannings;
	dispatch(loadUserRequest());
	dispatch(openModale({
		modale: 'userDetails',
		userId,
	}));

	const res = await api.getUserDetails({
		userId,
		planningId: selected,
	});
	const mapped = mapper.getUserDetails.fromApi(res);
	dispatch(loadUserComplete(mapped));
}

export const searchUser = email => async () => {
	const res = await api.searchUser(email);
	if (!res.error) {
		const mapped = mapper.searchUser.fromApi(res);
		return mapped;
	}

	return null;
}

export const addUser = user => async (dispatch, getState) => {
	const { selected } = getState().plannings;

	await api.addUser({
		planningId: selected,
		userId: user.id
	});

	const userList = user.family.map(member => ({
		id: user.id,
		isBlocked: user.isBlocked,
		email: user.email,
		phoneNumber: user.phoneNumber,
		address: user.address,
		familyMemberId: member.id,
		firstName: member.firstName,
		lastName: member.lastName,
		birthdate: member.birthdate,
		isAnimal:  member.isAnimal,
		gender: member.gender,
	}));

	dispatch(addUserComplete({planningId: selected, user, userList}));
	return true;
}

export const createUser = user => async (dispatch, getState) => {
	const { selected } = getState().plannings;

	const res = await api.createUser({
		planningId: selected,
		user,
	});

	const { uid, familyMemberId } = mapper.createUser.fromApi(res);
	const { phoneNumber, search:email, firstName, lastName, birthdate, gender } = user;
	const userToAdd = {
		id: uid,
		email,
		phoneNumber,
		address: '',
		isRequestingAccess: false,
		isBlock: false,
		since: moment().format(),
		mainMemberName: lastName,
		family: [{
			id: familyMemberId,
			firstName,
			lastName,
			birthdate,
			gender,
			isAnimal: false,
			isMain: true,
		}]
	};
	const userList = [{
		id: uid,
		isBlocked: false,
		email,
		phoneNumber,
		address: '',
		familyMemberId: familyMemberId,
		firstName,
		lastName,
		birthdate,
		isAnimal:  false,
		gender,
	}];
	dispatch(addUserComplete({planningId: selected, user: userToAdd, userList}));
}


export const sendMailing = datas => async (_, getState) => {

	const { env, plannings } = getState();

	await api.sendMailing({
		planningId: plannings.selected,
		...datas
	});

	const lexique = getLexique(env.locale).users.mailing.toasts;
	toast.success(_lp(lexique.success));

}