import React from 'react';
import { connect } from 'react-redux';
import nl2br from 'react-nl2br';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ModalTransitionZoom from './ModalTransitionZoom';

import getLexique from '../../locales';

const MaintenanceModeModale = ({lexique, message}) => (
	<Dialog
		open={true}
		disableBackdropClick
		disableEscapeKeyDown
		TransitionComponent={ModalTransitionZoom}
	>
		<DialogTitle>{lexique.title}</DialogTitle>
		<DialogContent>
			<DialogContentText>
				{nl2br(lexique.body)}
			</DialogContentText>
			<DialogContentText>
				{nl2br(message)}
			</DialogContentText>
		</DialogContent>
	</Dialog>
)

const mapStateToProps = ({env, ui}) => ({
	lexique: getLexique(env.locale).errors.maintenance,
	message: ui.maintenanceMode.message,
});

export default connect(mapStateToProps)(MaintenanceModeModale);
