import React, { memo } from 'react';
import { useVideoContext } from '../hooks';
import AudioLevelIndicator from './AudioLevelIndicator';

const LocalAudioLevelIndicator = () => {
    const { localTracks } = useVideoContext();
    const audioTrack = localTracks.find(track => track.kind === 'audio');

    return <AudioLevelIndicator audioTrack={audioTrack} />;
}


export default memo(LocalAudioLevelIndicator)