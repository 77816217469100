import global from './global';

export default {
	notAvailableOnMobile: `Désolé, le dossier sécurisé n'est pas accessible depuis un smartphone. Veuillez vous connecter depuis une tablette ou un ordinateur`,
	close: `Fermer`,
	dropHere: `Déposez vos fichiers ici`,
	upload: `Uploader des fichiers`,
	newFile: `Nouveau fichier`,
	title: `Stockage sécurisé`,
	createPassword: `Afin d'utiliser le service de stockage sécurisé, veuillez définir un mot de passe.`,
	createWarning: `ATTENTION. Ce mot de passe servira au chiffrement de vos fichiers sur le serveur. Il ne pourra EN AUCUN CAS être changé ou récupéré. En cas d'oubli de ce mot de passe, tous vos fichiers stockés seront inutilisables et ne pourront pas être restaurés.

Pour des raisons de sécurité, nous vous recommandons très fortement de ne pas utiliser le même mot de passe que votre mot de passe de connexion.`,
	createDisclaimer: `En créant ce mot de passe, et en utilisant ce service, vous acceptez les conditions d'utilisation de ce service de stockage crypté. En aucun cas Sprel ne pourra être tenu responsable des fichiers que vous stockez.`,
	createForm: {
		password: `Mot de passe`,
		passwordConfirmation: `Confirmez votre mot de passe`,
		create: `Créer mon mot de passe et accèder au service`,
		cancel: `Annuler`,
	},
	logForm: {
		infos: `Veuillez entrer votre mot de passe du stockage sécurisé pour accèder au service`,
		password: `Mot de passe`,
		cancel: `Annuler`,
		confirm: `Accèder au stockage sécurisé`,
		error: `Désolé, votre mot de passe ne semble pas être valide`,
	},
	folders: {
		noFile: `Aucun fichier`,
		oneFile: `fichier`,
		xFiles: `fichiers`,
		menu: {
			delete: `Supprimer`,
			download: `Télécharger`,
			edit: `Modifier`,
		},
		delete: {
			title: `Supprimer un fichier`,
			cancel: `Annuler`,
			confirm: `Oui, supprimer`,
			body: `Êtes-vous sur(e) de vouloir supprimer le fichier **{0}** ? Cette action est irréversible et ne pourra être annulée.`,
		},
	},
	filesUploader: {
		title: `Uploader des fichiers`,
		folder: `Dossier`,
		acceptedFiles: `Fichiers prêt pour l'upload dans le dossier sécurisé :`,
		rejectedFiles: `Fichiers non autorisés`,
		information: `Attention, une fois cryptés, les fichiers pèsent environ 2 fois plus lourd sur le serveur`,
		restrictions: `Les fichiers suivant sont acceptés : JPG, PNG, PDF, DOC, DOCX, XLS, XLSX, ODT, ODS, TXT
Taille maximale des fichiers : 5 Mo`,
		cancel: `Annuler`,
		upload: `Uploader`,
		close: `Fermer`,
	},
	toast: {
		success: `Fichiers uploadés avec succès !`,
		deleted: `Fichier supprimé avec succès !`,
		errorRetrieve: `Désolé, impossible de récupérer ce fichier...`,
		newFileSuccess: `Fichier créé avec succès !`,
		updateFileSuccess: `Fichier modifié avec succès !`,
	},
	editor: {
		save: `Enregistrer`,
		titlePlaceholder: `Nom du fichier`,
		chooseFolder: `Dossier`,
		chooseTemplate: `Nouveau fichier - Choisissez un modèle`,
		templates: {
			empty: `Fichier Vierge`,
			appointment: `Compte rendu rdv`,
			global: `Antécédents`
		},
		toolbar: global.toolbar,
		confirmClose: {
			title: `Annuler les changements ?`,
			body: `Vous avez des modifications non enregistrées. Êtes-vous sur(e) de vouloir fermer ce fichier sans sauvegarder ?`,
			confirm: `Oui, fermer le fichier`,
			cancel: `Annuler`,
		}
	}
}
