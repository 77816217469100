import { post, put, remove, get } from './index';
import mapper from '../mappers/appointment';


export const createAppointment = query => {
    const endpoint = `practitioners/${query.practitionerId}/appointments`;
    const mapped = mapper.createAppointment.toApi(query);
	return post({
        endpoint,
        params: mapped,
		catchErrors: [500,403],
    });
}

export const updateAppointment = query => {
	const endpoint = `practitioners/${query.practitionerId}/appointments/${query.from.id}`;
    const mapped = mapper.updateAppointment.toApi(query);
	return put({
        endpoint,
        params: mapped,
		catchErrors: [403],
    });
}

export const confirmAppointment = ({practitionerId, id}) => {
	const endpoint = `practitioners/${practitionerId}/appointments/${id}/confirm`;
	return put({endpoint});
}

export const deleteAppointment = ({practitionerId, id}) => {
	const endpoint = `practitioners/${practitionerId}/appointments/${id}`;
	return remove({endpoint});
}

export const getAppointment = ({practitionerId, id}) => {
    const endpoint = `practitioners/${practitionerId}/appointments/${id}`;
    return get({endpoint});
}

export const retrieveRawDatas = id => {
	const endpoint = `appointments/${id}`;
    return get({endpoint});
}

export const updateAppointmentPvpp = ({planningId, event}) => {
	const endpoint = `plannings/${planningId}/appointments/${event.id}/pvpp`;
	const mapped = mapper.updateAppointmentPvpp.toApi(event);
	return put({
        endpoint,
        params: mapped
    });
}

export const sendFilesFromVisio = ({planningId, eventId, attachments}) => post({
    endpoint: `plannings/${planningId}/appointments/${eventId}/send-files`,
    params: {
        attachments
    }
});

export const sendMessageFromVisio = ({planningId, eventId, message, type}) => post({
    endpoint: `plannings/${planningId}/appointments/${eventId}/send-message`,
    params: {
        message,
        type,
    }
});