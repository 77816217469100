import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import FileEditor from '../drive/FileEditor';

class DriveFileEditor extends PureComponent {

	renderInner() {
		if (!this.props.show) return <div />;

        return <FileEditor />
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
                maxWidth="lg"
				disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="modale-drive-editor-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({ui:{modales:{driveFileEditor:{show}}}}) => ({
    show,
});

export default connect(mapStateToProps)(DriveFileEditor);
