import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import RichTextEditor from 'react-rte';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';

import Info from '@material-ui/icons/Info';
import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';

import Markdown from '../../ui/Markdown';
import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import getLexique from '../../../locales';
import { changeSectionActivation, updateSection } from '../../../actions/websites';
import { getToolbar } from '../../../config/website';

class Information extends PureComponent {

	state = { showModal: false, anchor: null, vitalCard: { show: false, accepted: false }, free: RichTextEditor.createEmptyValue(), paymentMethods: [], showPayments: false, loading: false };

	componentDidMount() {
		const { datas } = this.props;
		if (datas) this.setState(Object.assign({},
				datas.vitalCard ? { vitalCard: datas.vitalCard } : null,
				datas.free && datas.free !== '' ? { free: RichTextEditor.createValueFromString(datas.free, 'markdown') } : null,
				datas.paymentMethods && datas.paymentMethods.length > 0 ? { paymentMethods: datas.paymentMethods, showPayments: true } : null,
			)
		);
	}

	handleMenuClose = () => this.setState({anchor: null});
	handleMenuOpen = ({target}) => this.setState({anchor: target});

	handleModalOpen = () => this.setState({showModal: true, anchor: null});
	handleModalClose = () => this.setState({showModal: false});

	handleChangeActive = () => {
		const { actions:{changeSectionActivation}, active, section} = this.props;
		changeSectionActivation({active: !active, section});
	}

	handleChangeSwitchVital = sw => ({target:{checked}}) => {
		this.setState({
			vitalCard: {
				...this.state.vitalCard,
				[sw]: checked
			}
		});
	}

	handleChangeSwitchPayments = ({target:{checked}}) => {
		this.setState({ showPayments: checked });
	}

	handleCheckPayment = method => ({target:{checked}}) => {
		const paymentMethods = checked ? [...this.state.paymentMethods, method] : this.state.paymentMethods.filter(f => f !== method);
		this.setState({ paymentMethods });
	}

	onConfirm = async () => {
		this.setState({loading: true});

		const { section, actions: {updateSection} } = this.props;
		const { vitalCard, free, showPayments, paymentMethods } = this.state;
		const query = Object.assign({
				vitalCard: {
					show: vitalCard.show,
					accepted: vitalCard.accepted,
				},
				free: free.toString('markdown')
			},
			showPayments ? { paymentMethods } : { paymentMethods: [] },
		);

		await updateSection({
			section,
			datas: query,
		});
		this.setState({loading: false});
		this.handleModalClose();
	}

	getToolbar = () => {
		const { toolbar } = this.props.lexique.edition;
		return getToolbar(toolbar);
	}

	onChangeText = value => this.setState({free: value});

	getContent = () => {
		const { datas } = this.props;
		if (!datas) return {
			free: '',
			vitalCard: {
				show: false,
				accepted: false,
			},
			paymentMethods: []
		};

		else return datas;
	}

	render() {
		const { lexique, active, isMobile } = this.props;
		const { anchor, showModal, loading, ...state } = this.state;
		const { free, vitalCard, paymentMethods } = this.getContent();

		return (
			<div className={cn('card', 'website_section', {
				'website_section--inactive': !active,
			})}>
				<div className="website_actions">
					<IconButton onClick={this.handleMenuOpen}>
						<MoreVert />
					</IconButton>
				</div>
				<div className="card_content website_card website_content">
					<div className="card_title">
						<span className="ico"><Info /></span>
						<h3>{lexique.title}</h3>
					</div>
					<div className="mt20 website_content">
						{free && free !== '' && <Typography paragraph component="div">
							<Markdown>
								{free}
							</Markdown>
						</Typography> }
						{vitalCard && vitalCard.show === true && <Typography paragraph>
							<strong>{vitalCard.accepted ? lexique.vitalCard.ok : lexique.vitalCard.nok}</strong>
						</Typography>}
						{paymentMethods && paymentMethods.length > 0 && <Typography paragraph>
							<strong>{lexique.paymentMethods.title}</strong> {paymentMethods.map(p => lexique.paymentMethods[p]).join(' / ')}
						</Typography>}
					</div>
				</div>
				<Menu
					anchorEl={anchor}
					open={anchor !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem
						onClick={this.handleChangeActive}
					>
						<ListItemText primary={lexique.active} />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={this.handleChangeActive}
								checked={active}
							/>
						</ListItemSecondaryAction>
					</MenuItem>
					<MenuItem onClick={this.handleModalOpen}>
						<ListItemIcon>
							<Edit />
						</ListItemIcon>
						<ListItemText inset primary={lexique.edit} />
					</MenuItem>
				</Menu>
				<Dialog
					maxWidth="md"
					open={showModal}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.handleModalClose}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
					fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.edition.title}
					</DialogTitle>
					<DialogContent>
						<Typography variant="caption">{lexique.edition.labels.free}</Typography>
						<RichTextEditor
							disabled={loading}
							autoFocus
							className="rte minh bkg"
							toolbarConfig={this.getToolbar()}
							value={state.free}
							onChange={this.onChangeText}
						/>
						<FormControlLabel
							disabled={loading}
							className="mt20"
							control={
								<Switch
									checked={state.vitalCard.show}
									onChange={this.handleChangeSwitchVital('show')}
									color="secondary"
								/>
							}
							label={lexique.edition.labels.showVitalCard}
						/>
						<Collapse in={state.vitalCard.show}>
							<FormControlLabel
								disabled={loading}
								className="mb20"
								control={
									<Switch
										checked={state.vitalCard.accepted}
										onChange={this.handleChangeSwitchVital('accepted')}
										color="secondary"
									/>
								}
								label={lexique.edition.labels.acceptVitalCard}
							/>
						</Collapse>
						<FormControlLabel
							disabled={loading}
							control={
								<Switch
									checked={state.showPayments}
									onChange={this.handleChangeSwitchPayments}
									color="secondary"
								/>
							}
							label={lexique.edition.labels.showPayments}
						/>
						<Collapse in={state.showPayments}>
							<FormGroup>
								<FormControlLabel
									disabled={loading}
									control={
										<Checkbox
											color="secondary"
											checked={state.paymentMethods.indexOf('cash') > -1}
											onChange={this.handleCheckPayment('cash')}
										/>
									}
									label={lexique.paymentMethods.cash}
								/>
								<FormControlLabel
									disabled={loading}
									control={
										<Checkbox
											color="secondary"
											checked={state.paymentMethods.indexOf('cheque') > -1}
											onChange={this.handleCheckPayment('cheque')}
										/>
									}
									label={lexique.paymentMethods.cheque}
								/>
								<FormControlLabel
									disabled={loading}
									control={
										<Checkbox
											color="secondary"
											checked={state.paymentMethods.indexOf('credit-card') > -1}
											onChange={this.handleCheckPayment('credit-card')}
										/>
									}
									label={lexique.paymentMethods['credit-card']}
								/>
								<FormControlLabel
									disabled={loading}
									control={
										<Checkbox
											color="secondary"
											checked={state.paymentMethods.indexOf('amex') > -1}
											onChange={this.handleCheckPayment('amex')}
										/>
									}
									label={lexique.paymentMethods.amex}
								/>
							</FormGroup>
						</Collapse>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleModalClose} color="primary" disabled={loading}>
							{lexique.edition.cancel}
						</Button>
						<Button onClick={this.onConfirm} color="primary" disabled={loading}>
							{lexique.edition.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}


const mapStateToProps = ({env, website, ui}) => ({
	lexique: getLexique(env.locale).websites.information,
	datas: website.information,
	section: 'information',
	active: website.blocks ? website.blocks.indexOf('information') > -1 : false,
	isMobile: ui.device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeSectionActivation, updateSection }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
