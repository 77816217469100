import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LinearProgress from '@material-ui/core/LinearProgress';

import Plan from './Plan';
import Invoices from './Invoices';
import Payment from './Payment';
import SprelContact from './SprelContact';

import { getBillingInfos } from '../../actions/planning';

class AccountDetails extends PureComponent {

	componentDidMount() {
		this.props.actions.getBillingInfos();
	}

	render() {
		const { loading } = this.props;

		if (loading) return <LinearProgress color="secondary" />

		return <div className="account mt40">
			<div>
				<Plan />
				<Payment />
				<SprelContact />
			</div>
			<div>
				<Invoices />
			</div>
		</div>;
	}
}


const mapStateToProps = ({plannings}) => ({
	loading: plannings[plannings.selected].billing.loading,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getBillingInfos }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
