export const LOAD_REQUEST = "planning::load::request";
export const LOAD_COMPLETE = "planning::load::complete";
export const LOAD_ERROR = "planning::load::error";
export const LOAD_DAY_REQUEST = "planning::day::load::request";
export const LOAD_DAY_COMPLETE = "planning::day::load::complete";
export const LOAD_DAYS_REQUEST = "planning::days::load::request";
export const LOAD_DAYS_COMPLETE = "planning::days::load::complete";
export const LOAD_DAY_ERROR = "planning::day::load::error";
export const SET_PLANNING = "planning::set";
export const DAY_UPDATE = "planning::day::update";
export const ADD_OTHER = "planning::add:other";

export const PATIENTS_LOAD_COMPLETE = "plannings::patients::load::complete";

export const ADD_APPOINTMENT_TYPE = "planning::appointment-type::add";
export const DELETE_APPOINTMENT_TYPE = "planning::appointment-type::delete";
export const UPDATE_APPOINTMENT_TYPE = "planning::appointment-type::update";
export const UPDATE_APPOINTMENT_TYPE_PROFILE = "planning::appointment-type::profile::update";
export const ADD_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES = "planning::appointment-type::specific-schedule::add";
export const UPDATE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES = "planning::appointment-type::specific-schedule::update";
export const DELETE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES = "planning::appointment-type::specific-schedule::delete";
export const CONFIG_UPDATE = "planning::config::update";
export const PROFILE_UPDATE = "planning::profile::update";
export const UPDATE_VIEW_DATE = "planning::view-date::update";
export const UPDATE_SCROLL_TIME = "planning:scroll-time::update";

export const BILLING_REQUEST = "planning::billing::request";
export const BILLING_COMPLETE = "planning::billing::complete";
export const BILLING_ADD_CARD = "planning::billing::card::add";
export const BILLING_PLAN_PAYMENT_UPDATE = "planning::billing::plan::payment::update";

export const SET_DRIVE_HASH = "planning::drive::hash::set";

export const GLOBAL_SPECIFIC_SHEDULES = "planning::specific-shedules::global";

export const UNBLOCK_SLOT_RULE = "planning::slot::unblock-rule";

export const PAYMENTS_REQUEST = "planning::payments::request";
export const PAYMENTS_COMPLETE = "planning::payments::complete";
export const PAYMENTS_BALANCE = "planning::balance::retrieved";