export default {
	filters: {
		title: `Historique d'activités`,
		showHistory: `Afficher les activités`,
		mode: {
			of: "de",
			about: "sur"
		},
		who: {
			all: "tout le monde",
			me: "moi",
			user: "un de mes {p}s"
		},
		for: "pour",
		period: {
			today: "aujourd'hui",
			yesterday: "hier",
			last7days: "les 7 derniers jours",
			last30days: "les 30 derniers jours",
			lastMonth: "le mois dernier"
		},
		search: "Afficher",
		picker: {
			title: `Choisissez un {p}`,
			label: `Nom du {p}`,
			placeholder: `Recherchez par nom, email, ou numéro de téléphone`
		}
	},
	content: {
		noEntries: `Aucun historique pour ces critères`,
		logs: {
			'appointment-add': `{0} a pris un rendez-vous pour {1}, le {2}`,
			'appointment-delete': `{0} a annulé le rendez-vous pour {1}, du {2}`,
			'appointment-update': `{0} a mis à jour les informations du rendez-vous du {1}`,
			'appointment-update-start': `{0} a déplacé le rendez-vous du {1} au {2}`,
			'appointment-confirm': `{0} a confirmé le rendez-vous du {1}`,
			'blocked-slot-add': `{0} a bloqué le créneau du {1}`,
			'blocked-slot-delete': `{0} a débloqué le créneau du {1}`,
			'billing-card-add': `{0} a ajouté une carte de paiement`,
			'billing-update-plan': `{0} a mis à jour le type de paiement`,
			'planning-day-close': `{0} a fermé le {1} à la réservation en ligne`,
			'planning-day-open': `{0} a ouvert le {1} à la réservation en ligne`,
			'planning-day-update-user-note': `{0} a ajouté/modifié la note d'information du {1}`,
			'planning-day-update-pro-note': `{0} a ajouté/modifié la note personnelle du {1}`,
			'planning-day-update-schedules': `{0} a ajouté/modifié le planning du {1}`,
			'unblock-slot': `{0} a autorisé le créneau du {1} à la réservation`,
			'website-update': `{0} a mis à jour une section du site {1}`,
			'website-update-infos': `{0} a mis à jour les informations du site {1}`,
			'planning-appointmentType-add': `{0} a ajouté le type de rendez-vous : {1}`,
            'planning-appointmentType-delete': `{0} a supprimé le type de rendez-vous : {1}`,
            'planning-appointmentType-update': `{0} a modifié le type de rendez-vous : {1}`,
			'planning-appointmentType-profile-update': `{0} a ajouté/modifié le profil du type de rendez-vous : {1}`,
			'planning-appointmentType-profile-delete': `{0} a supprimé le profil du type de rendez-vous : {1}`,
			'planning-configuration-update': `{0} a mis à jour la configuration du planning`,
			'planning-profile-update': `{0} a mis à jour le profil du planning`,
			'user-request-access': `{0} a demandé l'accès à la prise de rendez-vous en ligne`,
			'add-user': `{0} a ajouté la famille {1}`,
			'accept-user': `{0} a accepté la demande d'accès de la famille {1}`,
			'block-user': `{0} a bloqué la famille {1}`,
			'set-attend-true': `{0} a marqué un rendez-vous de {1} comme non présenté`,
			'set-attend-false': `{0} a marqué un rendez-vous de {1} comme présenté`,
			'manually-create-user': `{0} a créé la famille {1}`,
			'planning-appointmentType-specificSchedule-add': `{0} a ajouté une période de planning spécifique pour le type de rendez-vous : {1}`,
			'planning-appointmentType-specificSchedule-update': `{0} a mis à jour une période de planning spécifique pour le type de rendez-vous : {1}`,
			'planning-appointmentType-specificSchedule-delete': `{0} a supprimé une période de planning spécifique pour le type de rendez-vous : {1}`,
			'add-global-specific-schedules': `{0} a ajouté une période de planning spécifique pour tous les types de rendez-vous`,
			'create-user': `{0} a créé son compte Sprel`,
			'create-password': `{0} a créé un nouveau mot de passe`,
			'verify-email': `{0} a confirmé son adresse email`,
			'accept-pro-terms': `{0} a accepté les conditions d'utilisation de Sprel PRO`,
			'accept-terms': `{0} a accepté les conditions d'utilisation de Sprel`,
			'update-information': `{0} a mis à jour ses informations`,
			'family-member-update': `{0} a modifié {1} dans sa famille`,
			'family-member-add': `{0} a ajouté {1} dans sa famille`,
			'family-member-delete': `{0} a supprimé {1} de sa famille`,
			'password-reset': `{0} a demandé à changer son mot de passe (mot de passe oublié)`,
			'change-password': `{0} a changé son mot de passe`,
			'account-delete-request': `{0} a demandé la suppression de son compte`,
			'account-delete-confirm': `{0} a confirmé la suppression de son compte`,
		}
	}
}
