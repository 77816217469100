import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as MESSAGES from '../constants/messages';

const initialState = {
	loading: false,
	id: null,
};

const reducers = {
	[MESSAGES.GET_REQUEST]: state => update(state, {
		loading: { $set: true },
	} ),
	[MESSAGES.GET_COMPLETE]: (state, {payload:message}) => update(state, {
		$set: {
			...message,
			loading: false,
		}
	}),
	[MESSAGES.RESET]: state => update(state, {
		$set: {
			loading: false,
			id: null,
		}
	}),
	[MESSAGES.UPDATE_STATE]: (state, {payload:{state: st}}) => update(state, {
		state: {
			$set: st,
		}
	}),
	[MESSAGES.SET_UNREAD]: state => update(state, {
		openedAt: {
			$set: null,
		}
	}),
	[MESSAGES.ADD_REPLY]: (state, {payload:message}) => update(state, {
		replies: {
			$push: [message]
		}
	}),
	[MESSAGES.REMOVE]: state => update(state, {
		$set: {
			loading: false,
			id: null,
		}
	}),
}


export default createReducer(initialState, reducers);
