import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';

import { closeModaleError } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';

class Conflicts extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleError();
	}

	renderInner() {
		const { lexique, code } = this.props;

		if (!code) return <div />;

		return (
			<Fragment>
				<DialogTitle id="modale-error-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="error" show>
						{_lp(lexique.errors[code]) || lexique.errors.default}
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.ok}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-error-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{error:{show, code}}}}) => ({
	lexique: getLexique(env.locale).modales.error,
    show,
	code,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleError }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Conflicts);
