import React, { memo } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import FormSend from './FormSend';

export default memo(({open, isMobile, ...props}) => isMobile ? (
	<Dialog
		fullWidth
		fullScreen
		open={open}
		TransitionComponent={ModalTransitionZoom}
	>
			<FormSend {...props} />
	</Dialog>
	)
	:
	(
	<Drawer
		anchor="bottom"
		open={open}
		onClose={props.onClose}
		className="inbox-reply-drawer"
		ModalProps={{
			disableBackdropClick: true,
			disableEscapeKeyDown: true,
		}}
	>
		<FormSend {...props} />
	</Drawer>
));
