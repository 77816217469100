import { put, get } from './index';
import mapper from '../mappers/websites';

export const getWebsite = id => {
	const endpoint = `websites/${id}`;
	return get({endpoint});
}

export const changeSectionActivation = ({planningId, websiteId, ...query}) => {
	const endpoint = `plannings/${planningId}/websites/${websiteId}`;
	const params = mapper.changeSectionActivation.toApi(query);
	return put({endpoint, params});
}

export const updateSection = ({planningId, websiteId, ...query}) => {
	const endpoint = `plannings/${planningId}/websites/${websiteId}`;
	const params = mapper.updateSection.toApi(query);
	return put({endpoint, params});
}

export const updateMetas = ({planningId, websiteId, ...query}) => {
	const endpoint = `plannings/${planningId}/websites/${websiteId}/infos`;
	const params = mapper.updateMetas.toApi(query);
	return put({endpoint, params});
}
