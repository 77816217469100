import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { CallEnd as EndCallIcon } from '@material-ui/icons';

import {useVideoContext} from '../hooks';
import { closeVisioRoom } from '../../../actions/ui';
import { useLexique } from '../../../locales';

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: "#f44336",
        marginLeft: 4,
        marginRight: 4,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#ba000d',
        }
    }
}));

const EndCallButton = () => {
    const classes = useStyles();
    const { room, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
    const dispatch = useDispatch();

    const lexique = useLexique('visio');

    const handleClick = () => {
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        room.disconnect();
        dispatch(closeVisioRoom())
    };

    return (
        <Tooltip position="top" title={lexique.endCall}>
            <Fab onClick={handleClick} className={classes.button}>
                <EndCallIcon />
            </Fab>
        </Tooltip>
    );
}


export default memo(EndCallButton);