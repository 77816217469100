import React, { memo } from 'react';

const defaultStyles = {
  base: {
    position: 'absolute',
    userSelect: 'none',
    MsUserSelect: 'none',
  },
  top: {
    width: '100%',
    height: '10px',
    top: '-5px',
    left: '0px',
    cursor: 'row-resize',
  },
  right: {
    width: '10px',
    height: '100%',
    top: '0px',
    right: '-5px',
    cursor: 'col-resize',
  },
  bottom: {
    width: '100%',
    height: '10px',
    bottom: '-5px',
    left: '0px',
    cursor: 'row-resize',
  },
  left: {
    width: '10px',
    height: '100%',
    top: '0px',
    left: '-5px',
    cursor: 'col-resize',
  },
  topRight: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    cursor: 'ne-resize',
  },
  bottomRight: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '-10px',
    bottom: '-10px',
    cursor: 'se-resize',
  },
  bottomLeft: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '-10px',
    bottom: '-10px',
    cursor: 'sw-resize',
  },
  topLeft: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '-10px',
    top: '-10px',
    cursor: 'nw-resize',
  },
};

const Resizer = props => {
	const styles = {
		...defaultStyles.base,
		...defaultStyles[props.direction],
		...(props.replaceStyles || {}),
	};

	let timeout;
	const onMouseDown = e => startDrag(e);

	const onTouchStart = e => {
		if (!props.longPressThreshold || props.longPressThreshold === 0) return startDrag(e);
		e.persist();
		timeout = setTimeout(() => {
			startDrag(e);
			window.navigator.vibrate(30);
		}, props.longPressThreshold);
	}

	const onTouchEnd = () => {
		if (timeout) clearTimeout(timeout);
	}

	const startDrag = e => {
		props.onResizeStart(e, props.direction);
	}

	return (
		<div
			className={props.className}
			style={styles}
			onMouseDown={onMouseDown}
			onTouchStart={onTouchStart}
			onTouchEnd={onTouchEnd}
		>
			{props.children}
		</div>
	);
};

export default memo(Resizer);
