import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import cn from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Person from '@material-ui/icons/Person';
import Pets from '@material-ui/icons/Pets';
import VpnKey from '@material-ui/icons/VpnKey';
import Mail from '@material-ui/icons/Mail';

import { openUserDetails } from '../../actions/users';
import { openSecureDrive } from '../../actions/drive';
import { openModaleSendUserEmail, closeModaleSendUserEmail } from '../../actions/ui';
import { userName } from '../../libs/formatters';
import { getUserRightsOnPlanning } from '../../libs/checkers';

import getLexique from '../../locales';

class FamilyCard extends PureComponent {

	state = { refMenu: null }

	openQuickActionsMenu = e => {
		this.setState({refMenu: e.target});
	}

	closeQuickActionsMenu = () => {
		this.setState({refMenu: null});
	}

	showProfile = () => {
		const { actions: {openUserDetails}, id } = this.props;
		openUserDetails(id);
	}

	openSecureDrive = () => {
		const { actions: {openSecureDrive}, id } = this.props;
		openSecureDrive(id);
	}

	openFormMail = () => {
		const { actions: {openModaleSendUserEmail, closeModaleSendUserEmail}, email, firstName, lastName } = this.props;
		openModaleSendUserEmail({
			email: email,
			name: `${firstName} ${lastName}`,
			includeQuestion: false,
			displaySwitch: false,
			onClose: closeModaleSendUserEmail,
		});
	}

	render() {
		const { lexique, isAnimal, firstName, lastName, email, phoneNumber, config, gender, birthdate, showActionMenu, shortDetails, isBlocked, user, selectedPlanning } = this.props;
		const { refMenu } = this.state;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		if (!email) return <div className="user-card" />

		let age;
		if (birthdate) {
			age = `${moment().diff(moment(birthdate), 'years')}${lexique.yo} (${moment(birthdate).format(config.dateFormatLong)})`;
		}

		return (
			<Card className={cn("user-card", { 'user-card--blocked': isBlocked })}>
				<CardHeader
					avatar={
						<Avatar className={`avatar-gender gender--${gender}`}>
							{isAnimal ? <Pets /> : firstName[0] }
						</Avatar>
					}
					action={showActionMenu ?
						<IconButton onClick={this.openQuickActionsMenu}>
							<MoreVertIcon />
						</IconButton>
						: null
					}
					title={userName({firstName, lastName})}
					subheader={<span>
						{ age }
						{!shortDetails && <Fragment>
							<br />
							{email}{phoneNumber ? ` / ${phoneNumber}` : ''}
						</Fragment> }
					</span>}
				/>
				<Menu
					anchorEl={refMenu}
					open={refMenu !== null}
					onClose={this.closeQuickActionsMenu}
					onClick={this.closeQuickActionsMenu}
				>
					<MenuItem onClick={this.showProfile}>
						<ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.show} />
					</MenuItem>
					<Divider />
					{rights.sendEmail && <MenuItem onClick={this.openFormMail}>
						<ListItemIcon>
                            <Mail />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.email} />
					</MenuItem>}
					{rights.sendEmail && <Divider />}
					{rights.drive && <MenuItem onClick={this.openSecureDrive}>
						<ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.secureDrive} />
					</MenuItem>}
				</Menu>
			</Card>
		)
	}
}

FamilyCard.defaultProps = {
	showActionMenu: true,
	shortDetails: false,
}

const mapStateToProps = ({env, plannings, user}) => ({
	lexique: getLexique(env.locale).users.family,
	config: getLexique(env.locale).config,
	selectedPlanning: plannings.selected,
	user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openUserDetails, openSecureDrive, openModaleSendUserEmail, closeModaleSendUserEmail }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyCard);
