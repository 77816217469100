import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import RichTextEditor from 'react-rte';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';

import Markdown from '../../ui/Markdown';
import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import Message from '../../ui/Message';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import getLexique from '../../../locales';
import { changeSectionActivation, updateSection } from '../../../actions/websites';
import { getToolbar } from '../../../config/website';

class AdminAlert extends PureComponent {

	state = { showModal: false, anchor: null, value: RichTextEditor.createEmptyValue(), level: 'information' };

	componentDidMount() {
		const { datas } = this.props;
		if (datas) this.setState(Object.assign({},
				datas.value && datas.value !== '' ? { value: RichTextEditor.createValueFromString(datas.value, 'markdown') } : null,
				datas.level && datas.level  !== '' ? { level: datas.level } : null,
			)
		);
	}

	handleMenuClose = () => this.setState({anchor: null});
	handleMenuOpen = ({target}) => this.setState({anchor: target});

	handleModalOpen = () => this.setState({showModal: true, anchor: null});
	handleModalClose = () => this.setState({showModal: false});

	handleChangeActive = () => {
		const { actions:{changeSectionActivation}, active, section} = this.props;
		changeSectionActivation({active: !active, section});
	}

	onConfirm = async () => {
		this.setState({loading: true});
		const { section, actions: {updateSection} } = this.props;
		const { value, level } = this.state;
		const query = {
			level,
			value: value.toString('markdown')
		};

		await updateSection({
			section,
			datas: query,
		});
		this.setState({loading: false});
		this.handleModalClose();
	}

	getToolbar = () => {
		const { toolbar } = this.props.lexique.edition;
		return getToolbar(toolbar);
	}

	onChangeText = value => this.setState({value});
	onChangeLevel = level => () => this.setState({level});

	render() {
		const { lexique, active, isMobile } = this.props;
		const { anchor, showModal, loading, value, level } = this.state;

		return (
			<div className={cn('website_section', {
				'website_section--inactive': !active,
			})}>
				<Message show level={(this.props.datas && this.props.datas.level) || 'information'} className="website_content message_content">
					<Markdown>
						{this.props.datas && this.props.datas.value}
					</Markdown>
				</Message>

				<div className="website_actions">
					<IconButton onClick={this.handleMenuOpen}>
						<MoreVert />
					</IconButton>
				</div>

				<Menu
					anchorEl={anchor}
					open={anchor !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem
						onClick={this.handleChangeActive}
					>
						<ListItemText primary={lexique.active} />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={this.handleChangeActive}
								checked={active}
							/>
						</ListItemSecondaryAction>
					</MenuItem>
					<MenuItem onClick={this.handleModalOpen}>
						<ListItemIcon>
							<Edit />
						</ListItemIcon>
						<ListItemText inset primary={lexique.edit} />
					</MenuItem>
				</Menu>
				<Dialog
					maxWidth="md"
					open={showModal}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					fullScreen={isMobile}
					onClose={this.handleModalClose}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
				>
					<DialogTitle>
						{lexique.edition.title}
					</DialogTitle>
					<DialogContent>
						<Typography variant="caption">{lexique.edition.labels.level}</Typography>
						<div className="flex mt10 mb10">
							{
								['information', 'success', 'warning', 'error'].map(v => <Chip
									key={v}
							        label={lexique.edition.message}
							        onClick={this.onChangeLevel(v)}
							        className={cn('chip', `chip--${v}`, { 'chip--inactive': v !== level})}
							      />)
							}
						</div>
						<Typography variant="caption" className="mt20">{lexique.edition.labels.value}</Typography>
						<RichTextEditor
							disabled={loading}
							autoFocus
							className="rte minh bkg"
							toolbarConfig={this.getToolbar()}
							value={value}
							onChange={this.onChangeText}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleModalClose} color="primary" disabled={loading}>
							{lexique.edition.cancel}
						</Button>
						<Button onClick={this.onConfirm} color="primary" disabled={loading}>
							{lexique.edition.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}


const mapStateToProps = ({env, website, ui}, {id}) => ({
	lexique: getLexique(env.locale).websites.adminAlert,
	datas: website[id],
	section: id,
	active: website.blocks ? website.blocks.indexOf(id) > -1 : false,
	isMobile: ui.device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeSectionActivation, updateSection }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAlert);
