import React, { Fragment, memo } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';
import { Tooltip } from 'react-tippy';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import Payment from '@material-ui/icons/Payment';
import CloudDownload from '@material-ui/icons/CloudDownload';

import Markdown from '../ui/Markdown';

import getLexique from '../../locales';

const Invoices = memo(({lexique, config, invoices, upcomingInvoice}) => {

	const renderUpcoming = () => {
		if (!upcomingInvoice) return null;
		return (
		<div className="pad20 invoice_upcoming">
			<Typography variant="h5" className="widget_title">
				{lexique.upcoming}
			</Typography>
			<div className="flex invoice_upcoming_details mt20">
				<div className="flex1 invoice_upcoming_label">
					<Typography variant="h6">
						{moment.unix(upcomingInvoice.date).format(config.dateFormatLong)}
					</Typography>
					<Markdown>
						{lexique.period.replace('$1', moment.unix(upcomingInvoice.start).format(config.dateFormatLong)).replace('$2', moment.unix(upcomingInvoice.end).format(config.dateFormatLong))}
					</Markdown>
				</div>
				<div className="invoice_upcoming_price">
					<Typography variant="h4">
						{upcomingInvoice.due} €
					</Typography>
				</div>
			</div>
		</div>
		)
	}

	const renderInvoices = () => {
		if (!invoices || invoices.length === 0) return lexique.noInvoices;

		return <Fragment>
			{invoices.filter(i => i.due > 0).map(invoice => (
				<div className={cn("invoice_item flex aic", {
						'invoice--unpaid': invoice.url && !invoice.isPaid,
					})}
					key={invoice.label}
				>
					<span className="invoice_date">
						{moment.unix(invoice.date).format(config.dateFormat)}
					</span>
					<span className="invoice_ref flex1">
						{invoice.label}
					</span>
					<span className="invoice_price">
						{invoice.due} €
					</span>
					<span className="invoice_actions">
						{invoice.url && !invoice.isPaid && <Tooltip html={<span>{lexique.pay}</span>} touchHold>
							<IconButton href={invoice.url} target="_blank">
								<Payment />
							</IconButton>
						</Tooltip>}
						{invoice.pdf && <Tooltip html={<span>{lexique.download}</span>} touchHold>
							<IconButton href={invoice.pdf} target="_blank">
								<CloudDownload />
							</IconButton>
						</Tooltip>}
					</span>
				</div>
			))}
		</Fragment>

	}

	return (
		<Paper className="account_widget invoices tac">
			{ renderUpcoming() }
			<div className="pad20">
				<Typography variant="h5" className="widget_title">{lexique.title}</Typography>
				<div className="mt20">
					{ renderInvoices() }
				</div>
			</div>
		</Paper>
	)

});


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).account.invoices,
	config: getLexique(env.locale).config,
	invoices: plannings[plannings.selected].billing.invoices,
	upcomingInvoice: plannings[plannings.selected].billing.upcomingInvoice,
});


export default connect(mapStateToProps)(Invoices);
