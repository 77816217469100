import React from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import getLexique from '../../locales';

const WarningNoInternet = ({lexique}) => (
	<div className="warning-no-internet">
	 <CircularProgress size={20} style={{ color: 'white' }} />
	 <span>{lexique.noInternet}</span>
	</div>
);


const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).errors,
});

export default connect(mapStateToProps, null)(WarningNoInternet);
