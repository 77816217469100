import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as WEBSITES from '../constants/websites';

const initialState = {
	loading: false,
	id: null,
};

const reducers = {
	[WEBSITES.SELECT]: (state, {payload:id}) => update(state, {
		id: { $set: id },
	} ),
	[WEBSITES.LOAD]: state => update(state, {
		loading: { $set: true },
	} ),
	[WEBSITES.LOAD_COMPLETE]: (state, {payload:website}) => update(state, {
		$merge: {
			...website,
			loading: false,
		},
	} ),
	[WEBSITES.UPDATE_SECTION_ACTIVATION]: (state, {payload:blocks}) => update(state, {
		blocks: {
			$set: blocks,
		},
	} ),
	[WEBSITES.UPDATE_SECTION]: (state, {payload:{section, datas}}) => update(state, {
		[section]: {
			$set: datas,
		},
	} ),
	[WEBSITES.UPDATE_METAS]: (state, {payload:{title, subtitle, description}}) => update(state, {
		title: { $set: title },
		subtitle: { $set: subtitle },
		description: { $set: description },
	} ),
}


export default createReducer(initialState, reducers);
