export default {
	title: `Votre compte`,
	plan: {
		title: `Votre abonnement`,
		expire: `Votre période d'essai gratuite expire le **$1**`,
		period: `Période d'abonnement en cours : du **$1** au **$2**`,
		update: `Pour modifier votre abonnement, veuillez envoyer un email à damien@sprel.io`,
		plans: {
			'month': {
				title: 'Mensuel',
				amount: {
					2010: 40,
					2020: 60
				}
			},
			'6months': {
				title: 'Semestriel',
				amount: {
					2010: 220,
					2020: 330
				}
			},
			'year': {
				title: 'Annuel',
				amount: {
					2010: 400,
					2020: 600
				},
			},
			trial: {
				title: `Essai`,
				amount: `Gratuit`,
			}
		},
		change: {
			title: `Changez votre formule d'abonnement`,
			body: `Vous souhaitez changer votre formule d'abonnement ? Contactez **Damien Buchet** par mail sur **damien@sprel.io** ou par téléphone au **06 89 10 13 12**`,
			close: `Fermer`,
		}
	},
	invoices: {
		pay: `Régler la facture`,
		download: `Télécharger la facture`,
		upcoming: `Prochaine facture`,
		period: `Période du **$1** au **$2**`,
		title: `Vos factures`,
		noInvoices: `Vous n'avez aucune facture`,
	},
	payment: {
		title: `Paiement`,
		noCard: `Votre carte bancaire n'est pas renseigné`,
		card: `Carte bancaire enregistrée : **$1** finissant par **$2** (expire fin $3/$4)`,
		addCard: `Ajoutez votre carte bancaire`,
		updateCard: `Modifier votre carte bancaire`,
		deleteCard: `Supprimer votre carte bancaire`,
		plans: {
			auto: {
				title: `Prélèvement automatique`,
				desc: `Vous serez prélevé automatiquement sur votre carte bancaire`,
			},
			manual: {
				title: `Facture`,
				desc: `Vous recevrez une facture, que vous pourrez régler en ligne`,
			}
		},
		add: {
			title: `Ajouter une carte bancaire`,
			body: `Les données de votre carte sont gérées par notre partenaire Stripe, spécialisé dans le paiement en ligne. Vos données ne transitent à aucun moment par les serveurs de Sprel, et sont chiffrés/cryptés de bout en bout vers les serveurs sécurisés de Stripe. Sprel n'a accès qu'au type de votre carte (Visa, Mastercard,...), sa date d'expiration, ainsi que ses 4 derniers chiffres.`,
			addCard: `Ajouter la carte bancaire`,
			cancel: `Annuler`,
		},
		error: {
			title: `Désolé, action impossible`,
			bodies: {
				card: `Vous devez enregistrer votre carte bancaire avant de pouvoir choisir un prélèvement automatique`,
				plan: `Votre souscription n'est pas encore active. Vous pouvez d'ores et déjà enregistrer votre carte bancaire si vous désirez opter pour un prélèvement automatique`,
			},
			close: `Fermer`,
		}
	},
	contact: {
		title: `Votre contact`,
		email: `Email : `,
		phone: `Téléphone : `
	}
}
