import { useSelector as useSelectionRR, shallowEqual, useDispatch } from 'react-redux'
import update from 'immutability-helper';

const useSelector = selector => useSelectionRR(selector, shallowEqual);
const dig = datas => current => Object.entries(datas).reduce((p, [key, value]) => {
    // Key doesn't exists
    if (!p[key]) return update(p, {
        $merge: { [key]: value }
    });

    // Key exists, and is object (but not an array!)
    if (value instanceof Object && !(value instanceof Array)) {
        const { override, ...oValue } = value;
        if (override) return update(p, {
            [key]: { 
                $set: oValue
            }
        });
        return update(p, {
            [key]: { 
                $apply: dig(value)
            }
        });
    }

    // Key exists, and is not an object
    return update(p, {
        [key]: { 
            $set: value
         }
     });
}, current)

export { useDispatch, useSelector, dig };
