import { createAction} from 'redux-actions';

import mapper from '../mappers/logs';
import * as api from '../api/logs';
import * as LOGS from '../constants/logs';

export const updateFilter = createAction(LOGS.FILTER_UPDATE);
const startLoadLogs = createAction(LOGS.LOAD_START);
const completeLoadLogs = createAction(LOGS.LOAD_COMPLETE);

export const getLogs = () => async (dispatch, getState) => {
	const { plannings:{selected:planningId}, logs:{filters}, user:{id: myId} } = getState();

	dispatch(startLoadLogs());

	const query = {
		planningId,
		period: filters.period,
	}

	const getApi = () => {
		if (filters.who === 'all') return api.getLogs(query);

		if (filters.mode === "about") return api.getLogsAboutUser({
			...query,
			userId: filters.who === "me" ? myId : filters.pickedUser.id
		});

		return api.getLogsOfUser({
			...query,
			userId: filters.who === "me" ? myId : filters.pickedUser.id
		});
	}

	const results = await getApi();
	const mapped = mapper.getLogs.fromApi(results);
	dispatch(completeLoadLogs(mapped));
}
