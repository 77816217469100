import moment from 'moment-timezone';

export const practitionerName = ({particule, firstName, lastName}) => `${particule && particule !== '' ? `${particule} ` : ''}${firstName} ${lastName}`;

export const fromMilitary = value => value.split(',').map(v => v.split('-').map(y => `${y.substring(0,2)}h${y.substring(2,4)}`).join(' - ')).join(', ')

export const militaryToHuman = hours => hours.split(',').map(hour => hour.split('-').map(t => moment(t, "HHmm").format("HH[h]mm")).join('-')).join(', ');
export const userName = ({firstName, lastName, isAnimal}) => {
    if (isAnimal) return `${firstName} (${lastName})`;

    return firstName ? `${firstName}${lastName ? ` ${lastName}` : ''}` : lastName;
}
export const userNameReversed = ({firstName, lastName}) => firstName ? `${lastName ? `${lastName} ` : ''}${firstName}` : lastName;

export const filesize = size => {

    if (size > 1*1024*1024*1204) {
        const f = size / (1024 * 1024 * 1024);
        return `${Math.round(f*100)/100} Go`
    }

    if (size > 1*1024*1024) {
        const f = size / (1024 * 1024);
        return `${Math.round(f*100)/100} Mo`
    }

    if (size > 1*1024) {
        const f = size / 1024;
        return `${Math.round(f)} Ko`
    }

    return `${size} o`;
}
