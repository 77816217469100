import { createAction} from 'redux-actions';

import * as UI from '../constants/ui';

export const openLeftMenu = createAction(UI.LEFTMENU_OPEN);
export const closeLeftMenu = createAction(UI.LEFTMENU_CLOSE);
export const setApiError = createAction(UI.API_ERROR_SHOW);
export const setUpdateRequired = createAction(UI.API_UPDATE_REQUIRED_SHOW);
export const setMaintenanceMode = createAction(UI.SET_MAINTENANCE_MODE);

export const setDevice = createAction(UI.DEVICE_CHANGED);

export const openModale = createAction(UI.MODALE_SHOW);
export const closeModale = createAction(UI.MODALE_CLOSE);
export const updateModale = createAction(UI.MODALE_UPDATE);

export const openAppointmentQuickView = createAction(UI.QUICKVIEW_SHOW);
export const closeAppointmentQuickView = createAction(UI.QUICKVIEW_CLOSE);

export const enterDuplicationMode = createAction(UI.DUPLICATEMODE_ENTER);
export const exitDuplicationMode = createAction(UI.DUPLICATEMODE_EXIT);
export const openDuplicationDrawer = createAction(UI.DUPLICATEMODE_DRAWER_OPEN);
export const closeDuplicationDrawer = createAction(UI.DUPLICATEMODE_DRAWER_CLOSE);

export const setCalendarColumnWidth = createAction(UI.SET_CALENDAR_COLUMN_WIDTH);

export const setVisio = createAction(UI.VISIO_SET);
export const closeVisio = createAction(UI.VISIO_CLOSE);
const _receivedFilesFromVisio = createAction(UI.VISIO_FILES_RECEIVED);
export const toggleMinifyVisio = createAction(UI.VISIO_MINIFY_TOGGLE);

export const openModaleConflicts = datas => dispatch => {
	dispatch(openModale({
		modale: 'conflicts',
		...datas,
	}));
}
export const closeModaleConflicts = () => dispatch => {
	dispatch(closeModale({
		modale: 'conflicts',
	}));
}

export const openModaleError = code => dispatch => {
	dispatch(openModale({
		modale: 'error',
		code,
	}));
}

export const closeModaleError = () => dispatch => {
	dispatch(closeModale({
		modale: 'error',
	}));
}

export const openModaleConfirmDeleteAppointment = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmDeleteAppointment',
		...datas,
	}));
}

export const closeModaleConfirmDeleteAppointment = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmDeleteAppointment',
	}));
}

export const openModaleConfirmDiscardDuplicates = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmDiscardDuplicates',
		...datas,
	}));
}

export const closeModaleConfirmDiscardDuplicates = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmDiscardDuplicates',
	}));
}

export const openModaleConfirmToggleCloseDay = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmToggleCloseDay',
		...datas,
	}));
}

export const closeModaleConfirmToggleCloseDay = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmToggleCloseDay',
	}));
}

export const openModaleEditDayInformationMessage = datas => dispatch => {
	dispatch(openModale({
		modale: 'editDayInformationMessage',
		...datas,
	}));
}

export const closeModaleEditDayInformationMessage = () => dispatch => {
	dispatch(closeModale({
		modale: 'editDayInformationMessage',
	}));
}

export const openModaleEditDayNotes = datas => dispatch => {
	dispatch(openModale({
		modale: 'editDayNotes',
		...datas,
	}));
}

export const closeModaleEditDayNotes = () => dispatch => {
	dispatch(closeModale({
		modale: 'editDayNotes',
	}));
}

export const openModaleEditDaySpecificSchedules = datas => dispatch => {
	dispatch(openModale({
		modale: 'editDaySpecificSchedules',
		...datas,
	}));
}

export const closeModaleEditDaySpecificSchedules = () => dispatch => {
	dispatch(closeModale({
		modale: 'editDaySpecificSchedules',
	}));
}

export const openModaleEditDaySpecificSchedulesConfirmDiscard = datas => dispatch => {
	dispatch(openModale({
		modale: 'editDaySpecificSchedulesConfirmDiscard',
		...datas,
	}));
}

export const closeModaleEditDaySpecificSchedulesConfirmDiscard = () => dispatch => {
	dispatch(closeModale({
		modale: 'editDaySpecificSchedulesConfirmDiscard',
	}));
}

export const openModalePickSchedule = datas => dispatch => {
	dispatch(openModale({
		modale: 'pickSchedule',
		...datas,
	}));
}

export const closeModalePickSchedule = () => dispatch => {
	dispatch(closeModale({
		modale: 'pickSchedule',
	}));
}

export const openModaleDeleteAppointmentType = datas => dispatch => {
	dispatch(openModale({
		modale: 'deleteAppointmentType',
		...datas,
	}));
}

export const closeModaleDeleteAppointmentType = () => dispatch => {
	dispatch(closeModale({
		modale: 'deleteAppointmentType',
	}));
}

export const openModaleAddEditAppointmentType = datas => dispatch => {
	dispatch(openModale({
		modale: 'addEditAppointmentType',
		...datas,
	}));
}

export const closeModaleAddEditAppointmentType = () => dispatch => {
	dispatch(closeModale({
		modale: 'addEditAppointmentType',
	}));
}

export const updateModaleAddEditAppointmentType = datas => dispatch => {
	dispatch(updateModale({
		modale: 'addEditAppointmentType',
		...datas
	}));
}

export const openModaleEditAppointmentProfile = datas => dispatch => {
	dispatch(openModale({
		modale: 'editAppointmentTypeProfile',
		...datas,
	}));
}

export const closeModaleEditAppointmentProfile = () => dispatch => {
	dispatch(closeModale({
		modale: 'editAppointmentTypeProfile',
	}));
}

export const openModaleEditAppointmentSpecificSchedule = datas => dispatch => {
	dispatch(openModale({
		modale: 'editAppointmentTypeSpecificSchedule',
		...datas,
	}));
}

export const closeModaleEditAppointmentSpecificSchedule = () => dispatch => {
	dispatch(closeModale({
		modale: 'editAppointmentTypeSpecificSchedule',
	}));
}

export const openModaleConfirmAcceptUser = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmAcceptUser',
		...datas,
	}));
}

export const closeModaleConfirmAcceptUser = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmAcceptUser',
	}));
}

export const openModaleConfirmBlockUser = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmBlockUser',
		...datas,
	}));
}

export const closeModaleConfirmBlockUser = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmBlockUser',
	}));
}

export const closeModaleUserDetails = () => dispatch => {
	dispatch(closeModale({
		modale: 'userDetails',
	}));
}
export const openModaleConfirmAppointment = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmAppointment',
		...datas,
	}));
}

export const closeModaleConfirmAppointment = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmAppointment',
	}));
}

export const openModaleDrive = () => dispatch => {
	dispatch(openModale({
		modale: 'drive',
	}));
}

export const closeModaleDrive = () => dispatch => {
	dispatch(closeModale({
		modale: 'drive',
	}));
}

export const openDriveFileEditor = datas => dispatch => {
	dispatch(openModale({
		modale: 'driveFileEditor',
		...datas,
	}));
}

export const closeDriveFileEditor = () => dispatch => {
	dispatch(closeModale({
		modale: 'driveFileEditor',
	}));
}

export const openModaleSendUserEmail = datas => dispatch => {
	dispatch(openModale({
		modale: 'sendUserEmail',
		...datas,
	}));
}

export const closeModaleSendUserEmail = () => dispatch => {
	dispatch(closeModale({
		modale: 'sendUserEmail',
	}));
}

export const openModaleConfirmationChangeEvent = datas => dispatch => {
	dispatch(openModale({
		modale: 'confirmationChangeEvent',
		...datas,
	}));
}

export const closeModaleConfirmationChangeEvent = () => dispatch => {
	dispatch(closeModale({
		modale: 'confirmationChangeEvent',
	}));
}

export const openModaleAddNewUser = () => dispatch => {
	dispatch(openModale({
		modale: 'addNewUser',
	}));
}

export const openVisioRoom = query => async (dispatch, getState) => {

	// Checking if visio is already opened
	const { visio } = getState().ui;
	if (visio.open) {
		// If visio is already opened, close it and wait a little
		await dispatch(closeVisio());
		window.dispatchEvent(new Event("sprel::visio::force-close"));
		await new Promise(resolve => setTimeout(() => resolve(), 1000));
	}

	dispatch(setVisio(query));
}

export const closeVisioRoom = () => (dispatch, getState) => {

	const { plannings, ui } = getState();
	const { planningId, eventId } = ui.visio;

	dispatch(closeVisio());

	// Getting planning & checking if can create Payments
	const planning = plannings[planningId];
	if (!planning || !planning.canCreatePayments) return;

	// Looping thru all days & appointments :'( to retrieve this event.
	const event = ((Object.values(planning.days || {}).find(day => day.appointments.find(a => a.id === eventId)))?.appointments || []).find(a => a.id === eventId);

	// Could not find event, bye
	if (!event) return;

	// And finally open Payment Creation modal
	dispatch(openModale({
		modale: 'createPayment',
		planningId, 
		event,
	}));
}

export const receivedFilesFromVisio = datas => (dispatch, getState) => {

    const { planningId, eventId } = getState().ui.visio;
    if (datas.planningId !== planningId || datas.eventId !== eventId) return null;

    dispatch(_receivedFilesFromVisio(datas.files));

}