import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';
import nl2br from 'react-nl2br';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LockOpen from '@material-ui/icons/LockOpen';
import AccountBalance from '@material-ui/icons/AccountBalance';
import AccessTime from '@material-ui/icons/AccessTime';
import LockOutline from '@material-ui/icons/LockOutlined';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import MoreVert from '@material-ui/icons/MoreVert';
import Note from '@material-ui/icons/Note';

import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { openPlanningDay, closePlanningDay, updateDayInformationMessage, updateDayNotes, updateDaySpecificSchedules } from '../../actions/planning';
import { openModaleConfirmToggleCloseDay, openModaleEditDayInformationMessage, openModaleEditDayNotes, openModaleEditDaySpecificSchedules } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';
import PublicHoliday from '../../libs/public-holiday-france';
import { getUserRightsOnPlanning } from '../../libs/checkers';

class DayHeader extends PureComponent {

    state = { anchorMenu: null };

    openDayMenu = e => {
        this.setState({anchorMenu: e.currentTarget})
    }

    closeDayMenu = () => {
        this.setState({anchorMenu: null})
    }

	askToggleCloseDay = () => {
		const { actions: {openModaleConfirmToggleCloseDay}, date, currentDay} = this.props;
		openModaleConfirmToggleCloseDay({
			day: currentDay,
			date: moment(date).format('YYYY-MM-DD'),
			onConfirm: this.toggleCloseDay,
		});
	}

	toggleCloseDay = () => {
		const { date, currentDay, actions: {openPlanningDay, closePlanningDay}, selectedPlanning } = this.props;
		const isClosed = currentDay && currentDay.params && currentDay.params.isClosed;

		const day = {
			day: moment(date).format('YYYY-MM-DD'),
			planningId: selectedPlanning
		};

		if (isClosed) {
			openPlanningDay(day);
		}
		else {
			closePlanningDay(day);
		}
	}

    openEditInformationMessage = () => {
        const { actions: {openModaleEditDayInformationMessage}, currentDay, date} = this.props;
		openModaleEditDayInformationMessage({
            date: moment(date).format('YYYY-MM-DD'),
			day: currentDay,
			onConfirm: this.editInformationMessage,
		});
    }

    editInformationMessage = ({value, level}) => {
        const { date, actions: {updateDayInformationMessage}, selectedPlanning } = this.props;
        updateDayInformationMessage({
            day: moment(date).format('YYYY-MM-DD'),
			planningId: selectedPlanning,
            value,
            level
        })
    }

    openEditNotes = () => {
        const { actions: {openModaleEditDayNotes}, currentDay, date} = this.props;
		openModaleEditDayNotes({
            date: moment(date).format('YYYY-MM-DD'),
			day: currentDay,
			onConfirm: this.editNotes,
		});
    }

    editNotes = ({value}) => {
        const { date, actions: {updateDayNotes}, selectedPlanning } = this.props;
        updateDayNotes({
            day: moment(date).format('YYYY-MM-DD'),
			planningId: selectedPlanning,
            value,
        })
    }

    openEditSpecificSchedules = () => {
        const { actions: {openModaleEditDaySpecificSchedules}, currentDay, date} = this.props;
		openModaleEditDaySpecificSchedules({
            date: moment(date).format('YYYY-MM-DD'),
			day: currentDay,
			onConfirm: this.editSpecificSchedules,
		});
    }

    editSpecificSchedules = ({value}) => {
        const { date, actions: {updateDaySpecificSchedules}, selectedPlanning } = this.props;
        updateDaySpecificSchedules({
            day: moment(date).format('YYYY-MM-DD'),
			planningId: selectedPlanning,
            value,
        })
    }

	isHolidayClosed = () => {
		const { date, planningConf } = this.props;
		const m = moment(date);

		const holidayKey = PublicHoliday.getFerie(m);
		if (!holidayKey) return false;

		return planningConf[`publicHoliday${holidayKey}`] === true;
	}

    renderActionToolbar = () => {
        const { lexique, isMobile, currentDay, user, selectedPlanning } = this.props;
        const { anchorMenu } = this.state;
        if (!currentDay || !currentDay.params || currentDay.params.isPast) return null;

        const rights = getUserRightsOnPlanning(user, selectedPlanning);

        const hasInfo = currentDay.params.information && currentDay.params.information.value !== '';
        const hasNote = currentDay.params.note && currentDay.params.note !== '';

		const isHolidayClosed = this.isHolidayClosed();

        return (
            <div className="header_day_actions">
                {rights.day && <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={this.openDayMenu}
                >
                    <MoreVert />
                </IconButton>}
                <Menu
                    anchorEl={anchorMenu}
                    open={anchorMenu !== null}
                    onClose={this.closeDayMenu}
					onClick={this.closeDayMenu}
                >
                    {!isHolidayClosed && rights.dayClose && <MenuItem onClick={this.askToggleCloseDay}>
                        <ListItemIcon>
                            {currentDay.params.isClosed ? <LockOpen /> : <LockOutline /> }
                        </ListItemIcon>
                        <ListItemText inset primary={currentDay.params.isClosed ? lexique.open : lexique.lock } />
                    </MenuItem>}
					{!isHolidayClosed && rights.daySchedule && <MenuItem onClick={this.openEditSpecificSchedules}>
                        <ListItemIcon>
                            <AccessTime />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.changeSchedule } />
                    </MenuItem>}
                    {rights.dayInformation && <MenuItem onClick={this.openEditInformationMessage}>
                        <ListItemIcon>
                            <InfoOutline />
                        </ListItemIcon>
                        <ListItemText inset primary={hasInfo ? _lp(lexique[isMobile ? 'editInformationShort' : 'editInformation']) : _lp(lexique[isMobile ? 'addInformationShort' : 'addInformation']) } />
                    </MenuItem>}
                    {rights.dayNote && <MenuItem onClick={this.openEditNotes}>
                        <ListItemIcon>
                            <Note />
                        </ListItemIcon>
                        <ListItemText inset primary={hasNote ? lexique.editNote : lexique.addNote } />
                    </MenuItem>}
                </Menu>
            </div>
        )
    }

    renderSummaryState = () => {
        const { currentDay, lexique, date } = this.props;

        if (!currentDay || !currentDay.params) return null;
        const { params } = currentDay;
		const m = moment(date);
		const isHolidayClosed = this.isHolidayClosed();

        return (
            <div className="header_day_summary">
				{ isHolidayClosed && <Tooltip position="bottom" trigger="click" title={lexique.tooltips.publicHoliday[PublicHoliday.getFerie(m)]}><AccountBalance /></Tooltip> }
                { params.isClosed && <Tooltip position="bottom" trigger="click" title={lexique.tooltips.closed}><LockOutline /></Tooltip> }
                { params.specificSchedules && params.specificSchedules.length > 0 && <Tooltip position="bottom" trigger="click" title={lexique.tooltips.schedules}><AccessTime /></Tooltip> }
                { params.note && params.note !== '' && <Tooltip position="bottom" trigger="click" html={nl2br(params.note)}><Note /></Tooltip> }
                { params.information && params.information.value !== '' && <Tooltip position="bottom" trigger="click" html={<Message className="tal" show level={params.information.level} component="div"><Markdown>{params.information.value}</Markdown></Message>}><InfoOutline /></Tooltip> }
            </div>
        )
    }

	renderMobileDate = m => (
		<>
			<Typography variant="h6">{m.format(this.props.config.dateFormatLongNoYear)}</Typography>
			<Typography variant="h6">{m.format('YYYY')}</Typography>
		</>
	)

	renderDesktopDate = m => (
		<>
			<Typography>{m.format('dddd')}</Typography>
			<Typography variant="h3">{m.format('D')}</Typography>
		</>
	)

    render() {
        const { date, currentDay, isMobile } = this.props;
        if (!currentDay) return null;

		const isHolidayClosed = this.isHolidayClosed();
        const m = moment(date);

        const isToday = moment().startOf('day').isSame(moment(date).startOf('day'));

    	return (
    		<div className={cn('header_day-week', {
                'header_day--close': (currentDay.params && currentDay.params.isClosed) || isHolidayClosed,
                'header_day--today': isToday,
            })}>
    			{ isMobile ? this.renderMobileDate(m) : this.renderDesktopDate(m) }
                {(!currentDay || currentDay.loading) && <div className="loading_day_progress"><LinearProgress color="secondary" /></div> }
                { this.renderActionToolbar() }
                { this.renderSummaryState() }
    		</div>
    	)
    }
}

const mapStateToProps = ({env, plannings, ui, user}, {date}) => ({
	lexique: getLexique(env.locale).event.day,
    config: getLexique(env.locale).config,
	planningConf: plannings[plannings.selected] ? plannings[plannings.selected].configuration : {},
    selectedPlanning: plannings.selected,
    currentPlanning: plannings[plannings.selected],
	isMobile: ui.device.isMobile,
	currentDay: plannings && plannings.selected && plannings[plannings.selected].days ? plannings[plannings.selected].days[moment(date).format('YYYY-MM-DD')] : null,
	user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openPlanningDay, closePlanningDay, openModaleConfirmToggleCloseDay, openModaleEditDayInformationMessage, updateDayInformationMessage, openModaleEditDayNotes, updateDayNotes, openModaleEditDaySpecificSchedules, updateDaySpecificSchedules }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DayHeader);
