import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { closeModaleConfirmToggleCloseDay } from '../../actions/ui';

import getLexique from '../../locales';

class ConfirmToggleCloseDay extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleConfirmToggleCloseDay();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm();
	}

	renderInner() {
		const { lexique, config, day, date } = this.props;
		if (!day) return <div />;

		const isClosed = day.params && day.params.isClosed;
		const l = lexique[isClosed ? 'open' : 'close'];

		return (
			<Fragment>
				<DialogTitle id="modale-toggle-day-close-dialog-title">
					{l.title}
				</DialogTitle>
				<DialogContent>
					<Message level={isClosed ? 'information' : 'error'} show>
						<Markdown>
							{l.body.replace('{0}', moment(date).format(config.dateFormatLong))}
						</Markdown>
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{l.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-toggle-day-close-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{confirmToggleCloseDay:{show, onConfirm, day, date}}}}) => ({
	lexique: getLexique(env.locale).modales.confirmToggleCloseDay,
	config: getLexique(env.locale).config,
    show,
	onConfirm,
	day,
	date,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleConfirmToggleCloseDay }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmToggleCloseDay);
