import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import EndCallButton from './EndCallButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';
import FilesSender from '../FilesSender';

const useStyle = makeStyles(() => ({
    videoControls: {
        position: 'absolute',
        bottom: 4,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        zIndex: 10,
        alignItems: 'center',
    },
    fileSender: {
        position: 'absolute',
        left: -4,
        top: 0,
        transform: 'translateX(-100%)',
    }
}));

const ActionButtons = ({isAlone}) => {
    
    const classes = useStyle();

    return (
        <div className={classes.videoControls}>
            { !isAlone && <FilesSender className={classes.fileSender} /> }
            <ToggleAudioButton />
            <ToggleVideoButton />
            <EndCallButton />
        </div>
    )

}

export default memo(ActionButtons)