import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';
import Email from '@material-ui/icons/Email';
import ColorLens from '@material-ui/icons/ColorLens';

import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import Message from '../../ui/Message';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import getLexique from '../../../locales';
import { updateMetas, updateSection } from '../../../actions/websites';

/* IMAGES */
import duckGreen from '../../../assets/templates/duckgreen.jpg';
import blue from '../../../assets/templates/blue.jpg';
import blackWhite from '../../../assets/templates/blackwhite.jpg';
import comet from '../../../assets/templates/comet.jpg';
import cream from '../../../assets/templates/cream.jpg';
import green from '../../../assets/templates/green.jpg';
import lavender from '../../../assets/templates/lavender.jpg';
import pink from '../../../assets/templates/pink.jpg';
import purple from '../../../assets/templates/purple.jpg';
import red from '../../../assets/templates/red.jpg';
import rose from '../../../assets/templates/rose.jpg';
import sinbad from '../../../assets/templates/sinbad.jpg';

const IMGS = {
    duckGreen, blue, blackWhite, comet, cream, green, lavender, pink, purple, red, rose, sinbad
};

class TopBar extends PureComponent {

    state = {
        loading: false,
		showModalTitle: false,
        showModalTheme: false,
        showModalInbox: false,
        anchor: null,
		information: '',
        active: false,
        level: 'none',
        success: '',
        palette: 'blue',
        title: '',
        subtitle: '',
        description: '',
	};

    componentDidMount() {
		const { title, subtitle, palette, description, contactForm } = this.props;
		this.setState({
            title: title || '',
            subtitle: subtitle || '',
            description: description || '',
            palette: palette || 'blue',
            success: contactForm.success || '',
            active: contactForm.active || false,
            level: contactForm.level || 'none',
			information: contactForm.information || '',
        });
	}

    openModal = modal => () => this.setState({[`showModal${modal}`]: true, anchor: null});
    closeModal = modal => () => this.setState({[`showModal${modal}`]: false});

    handleMenuClose = () => this.setState({anchor: null});
	handleMenuOpen = ({target}) => this.setState({anchor: target});

	onChangeInput = key => ({target:{value}}) => this.setState({[key]: value});
	onChangeSwitch = key => ({target:{checked}}) => this.setState({[key]: checked});
	onChangeLevel = level => () => this.setState({level})

    onSelectTheme = palette => () => this.setState({palette});

    onConfirmTheme = async () => {
		this.setState({loading: true});
		const { actions: {updateSection} } = this.props;
		const { palette } = this.state;

		await updateSection({
			section: 'palette',
			datas: palette,
		});
		this.setState({loading: false});
		this.closeModal('Theme')();
	}

	onConfirmTitle = async () => {
		this.setState({loading: true});
		const { actions: {updateMetas} } = this.props;
		const { title, subtitle, description } = this.state;
		await updateMetas({title, subtitle, description});
		this.setState({loading: false});
		this.closeModal('Title')();
	}

	onConfirmInbox = async () => {
		this.setState({loading: true});
		const { actions: {updateSection} } = this.props;
		const { active, success, information, level } = this.state;

		await updateSection({
			section: 'contactForm',
			datas: { active, success, information, level },
		});
		this.setState({loading: false});
		this.closeModal('Inbox')();
	}

    render() {
        const { lexique, isMobile, chunk, useContactForm } = this.props;
        const { title, subtitle, description, palette, anchor, success, information, active, level, showModalTitle, showModalTheme, showModalInbox, loading } = this.state;
        return (
            <div className="website_topbar website_section">
                <div className="website_form site-title">
                    <h1>{title}</h1>
                    <h2>{subtitle}</h2>
                    <div className="website_actions">
    					<IconButton onClick={this.handleMenuOpen}>
    						<MoreVert />
    					</IconButton>
    				</div>
                </div>
                <Menu
					anchorEl={anchor}
					open={anchor !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem onClick={this.openModal('Title')}>
						<ListItemIcon>
							<Edit />
						</ListItemIcon>
						<ListItemText inset primary={lexique.title.edit} />
					</MenuItem>
                    <MenuItem onClick={this.openModal('Theme')}>
						<ListItemIcon>
							<ColorLens />
						</ListItemIcon>
						<ListItemText inset primary={lexique.theme.edit} />
					</MenuItem>
                    {useContactForm && <MenuItem onClick={this.openModal('Inbox')}>
						<ListItemIcon>
							<Email />
						</ListItemIcon>
						<ListItemText inset primary={lexique.inbox.edit} />
					</MenuItem>}
				</Menu>
                {/* THEME */}
                <Dialog
					maxWidth="md"
					open={showModalTheme}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.closeModal('Theme')}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
                    fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.theme.title}
					</DialogTitle>
					<DialogContent>
                        {chunk && <Message show level="warning">{lexique.theme.chunk}</Message>}
                        {!chunk && <div className="all_themes">
                            {
                                Object.entries(lexique.theme.themes).map(([key, t]) => (
                                    <button
										key={key}
                                        onClick={this.onSelectTheme(key.toLowerCase())}
                                        className={cn("theme-choice", {
                                            active: palette === key.toLowerCase(),
                                        })}
                                    >
                                        <span className="image">
                                            <img src={IMGS[key]} alt={t} />
                                        </span>
                                        <span className="name">{t}</span>
                                    </button>
                                ))
                            }
                        </div>}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeModal('Theme')} color="primary" disabled={loading}>
							{lexique.theme.cancel}
						</Button>
						<Button onClick={this.onConfirmTheme} color="primary" disabled={loading}>
							{lexique.theme.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
				{/* INFOS TITLE / SUBTITLE / DESCRIPTION */}
                <Dialog
					maxWidth="md"
					open={showModalTitle}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.closeModal('Title')}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
                    fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.title.title}
					</DialogTitle>
					<DialogContent>
						<Typography variant="overline" gutterBottom>{lexique.title.infos}</Typography>
						<TextField
							autoFocus
							label={lexique.title.labels.title}
							value={title}
							onChange={this.onChangeInput('title')}
							fullWidth
							variant="outlined"
							margin="normal"
							disabled={loading}
						/>
						<TextField
							label={lexique.title.labels.subtitle}
							value={subtitle}
							onChange={this.onChangeInput('subtitle')}
							fullWidth
							variant="outlined"
							margin="normal"
							disabled={loading}
						/>
						<TextField
							multiline
							rows={4}
							label={lexique.title.labels.description}
							value={description}
							onChange={this.onChangeInput('description')}
							fullWidth
							variant="outlined"
							margin="normal"
							disabled={loading}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeModal('Title')} color="primary" disabled={loading}>
							{lexique.title.cancel}
						</Button>
						<Button onClick={this.onConfirmTitle} color="primary" disabled={loading}>
							{lexique.title.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
				{/* CONTACT FORM */}
                <Dialog
					maxWidth="md"
					open={showModalInbox}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.closeModal('Inbox')}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
                    fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.inbox.title}
					</DialogTitle>
					<DialogContent>
						<FormControlLabel
							disabled={loading}
							control={
								<Switch
									checked={active}
									onChange={this.onChangeSwitch('active')}
									color="secondary"
								/>
							}
							label={lexique.inbox.labels.active}
						/>
						{active && <div className="mb10">
							{['none', 'information', 'success', 'warning', 'error'].map(v => <Chip
								key={v}
						        label={lexique.inbox[isMobile ? "messageShort" : "message"]}
						        onClick={this.onChangeLevel(v)}
						        className={`chip chip--${v}`}
						      />)}
						</div>}
						<Message show={active} level={level}>
							<TextField
								multiline
								rows={4}
								label={lexique.inbox.labels.information}
								value={information}
								onChange={this.onChangeInput('information')}
								fullWidth
								variant="outlined"
								margin="normal"
								disabled={loading}
							/>
						</Message>
						<Message show={active} level="none">
							<TextField
								multiline
								rows={4}
								label={lexique.inbox.labels.success}
								value={success}
								onChange={this.onChangeInput('success')}
								fullWidth
								variant="outlined"
								margin="normal"
								disabled={loading}
							/>
						</Message>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeModal('Inbox')} color="primary" disabled={loading}>
							{lexique.inbox.cancel}
						</Button>
						<Button onClick={this.onConfirmInbox} color="primary" disabled={loading}>
							{lexique.inbox.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({env, website, ui, plannings}) => ({
	lexique: getLexique(env.locale).websites.topbar,
    chunk: website.chunk,
    isMobile: ui.device.isMobile,
	useContactForm: plannings[plannings.selected].env.contactForm,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updateMetas, updateSection }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
