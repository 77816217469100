import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
 import cn from 'classnames';

import { useOnlyMeInRoom, useVideoContext, useVisioEventPatient, useParticipants } from './hooks';
import { useLexique } from '../../locales';

import ReconnectingNotification from './ui/ReconnectingNotification';
import ActionButtons from './ui/ActionButtons';
import Participant from './Participant';

const useStyle = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative',
    },
    myVideo: {
        position: 'absolute',
        width: 320,
        height: 320*9/16,
        bottom: 8,
        right: 8,
        transition: 'all 0.2s ease-out',
        transform: 'translate(0,0)'
    },
    isAlone: {
        bottom: '50%',
        right: '50%',
        width: 800,
        transform: 'translate(50%, 50%)',
        height: 800*9/16,
        border: '1px solid #707070'
    },
    minified: {
        width: 120,
        height: 120*9/16,
    },
    patientName: {
        position: "absolute",
        top: 8,
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#fff',
        '& span': {
            fontWeight: "bold",
        }
    },
    patientVideo: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
    },
}));

const VisioConnected = () => {

    const classes = useStyle();
    const patientName = useVisioEventPatient();
    const aloneInRoom = useOnlyMeInRoom();

    return (
        <div className={classes.container}>
            {!aloneInRoom && <PatientVideo />}
            <MyVideoPreview isAlone={aloneInRoom} />
            <ReconnectingNotification />
            <ActionButtons isAlone={aloneInRoom} />
            {aloneInRoom && <AloneAlert patientName={patientName} />}
        </div>
    );
}

const AloneAlert = memo(({patientName}) => {

    const lexique = useLexique('visio');
    const classes = useStyle();

    if (!patientName || patientName === "") return null;

    return (
        <div className={classes.patientName}>
            {lexique.waiting} <span>{patientName}</span>
        </div>
    )
})

const MyVideoPreview = memo(({isAlone}) => {

    const {
        room: { localParticipant },
        minified
    } = useVideoContext();
    
    
    const classes = useStyle();

    return (
        <div className={cn(classes.myVideo, (isAlone && !minified) && classes.isAlone, minified && classes.minified)}>
            <Participant participant={localParticipant} isLocalParticipant />
        </div>
    );
});

const PatientVideo = memo(() => {

    const participants = useParticipants();
    const { minified } = useVideoContext();
    
    const classes = useStyle();

    const participant = participants[0];

    return (
        <div className={cn(classes.patientVideo, minified && classes.minified)}>
            <Participant participant={participant} />
        </div>
    );
})

export default memo(VisioConnected);