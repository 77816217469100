import React, { memo, useCallback } from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';
import { useLexique } from '../../locales';

const CheckoutForm = ({onSubmit, onDatas, onCancel, loading}) => {

	const stripe = useStripe();
  	const elements = useElements();

	const handleSubmitCard = useCallback(async ev => {
		ev.preventDefault();

		if (!stripe || !elements) return;

		onSubmit();

		const cardElement = elements.getElement(CardElement);

		const res = await stripe.createSource(cardElement, {
			type: 'card',
			currency: 'eur',
		});
		onDatas(res);
	}, [stripe, elements, onDatas, onSubmit]);

	const lexique = useLexique("account.payment.add");

	return (
		<form onSubmit={handleSubmitCard} disabled={loading}>
			<Typography variant="caption" paragraph>{lexique.body}</Typography>
			<CardElement style={{ base: { fontSize: '18px'}}} />
			<div className="flex aic mt20">
				<Button onClick={onCancel} color="primary" disabled={loading}>
					{lexique.cancel}
				</Button>
				<div className="flex1" />
				<Button type="submit" color="primary" variant="contained" disabled={loading} disableElevation>
					{lexique.addCard}
					{loading && <ButtonCircularProgress />}
				</Button>
			</div>
		</form>
	);
}

export default memo(CheckoutForm);
