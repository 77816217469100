import { get } from './index';
export const getPreflightToken = (identity, room) => get({
    endpoint: `visio/preflight`,
    params: {
        identity,
        room,
    }
});

export const getToken = (planningId, eventId) => get({
    endpoint: `plannings/${planningId}/appointments/${eventId}/visio-auth`,
});