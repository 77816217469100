import React, { memo } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import getLexique from '../../locales';

import logo from '../../assets/logo.png';

const SprelContact = memo(({lexique}) => (
	<Paper className="account_widget plan pad20 tac sprel_contact">
		<Typography variant="h5" className="widget_title">
			{lexique.title}
			<span className="logo">
				<img src={logo} alt="Logo Sprel" />
			</span>
		</Typography>
		<div className="sprel_contact">
			<div className="name">Damien Buchet</div>
			<div>
				<span className="label">{lexique.email}</span>
				<span className="value"><a href="mailto:damien@sprel.io">damien@sprel.io</a></span>
			</div>
			<div>
				<span className="label">{lexique.phone}</span>
				<span className="value"><a href="tel:+33689101312">+33 6 89 10 13 12</a></span>
			</div>
		</div>
	</Paper>
))

const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).account.contact,
});


export default connect(mapStateToProps)(SprelContact);
