import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import nl2br from 'react-nl2br';
import Color from 'color';
import cn from 'classnames';
import { Tooltip } from 'react-tippy';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Alarm from '@material-ui/icons/Alarm';
import Schedule from '@material-ui/icons/Schedule';
import Assignment from '@material-ui/icons/Assignment';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Note from '@material-ui/icons/Note';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Person from '@material-ui/icons/Person';
import Queue from '@material-ui/icons/Queue';
import Edit from '@material-ui/icons/Edit';
import VisioIcon from '@material-ui/icons/VideocamOutlined';
import Code from '@material-ui/icons/Code';
import Pets from '@material-ui/icons/Pets';
import VpnKey from '@material-ui/icons/VpnKey';
import Mail from '@material-ui/icons/Mail';
import PaymentIcon from '@material-ui/icons/CreditCard';

import getLexique, { _lp } from '../../locales';
import { openAppointment, confirmAppointment, deleteAppointment, retrieveRawDatas, updateAppointmentPvpp } from '../../actions/appointment';
import { closeAppointmentQuickView, openModaleConfirmDeleteAppointment, enterDuplicationMode, openModaleConfirmAppointment, openModaleSendUserEmail, closeModaleSendUserEmail, setVisio, openModale } from '../../actions/ui';
import { openUserDetails } from '../../actions/users';
import { openSecureDrive } from '../../actions/drive';
import { defaultColorsTypes, getAFIcon } from '../../config/theme';
import { userName } from '../../libs/formatters';
import { getUserRightsOnPlanning } from '../../libs/checkers';
import { isVisioOpen } from '../../libs/visio';

class EventQuickView extends PureComponent {

	state= { loading: false };

	renderConfirmationDate = () => {
		const { event: {confirmed: {at, by}}, lexique, config:{datetimeFormatLong} } = this.props;

		if (!at || at === '') return lexique.notConfirmed;

		if (by === '') return lexique.confirmedAuto;

		return lexique.confirmedBy.replace('{0}', moment(at).format(datetimeFormatLong)).replace('{1}', by);
	}

	retrieveRawAppointmentDatas = () => {
		const { actions: {retrieveRawDatas}, event:{id} } = this.props;
		retrieveRawDatas(id);
	}

	askDeleteAppointment = () => {
		const { actions:{openModaleConfirmDeleteAppointment}, event } = this.props;
		if (event.typeId === "blocked") {
			this.onConfirmDeleteAppointment();
			return;
		}

		openModaleConfirmDeleteAppointment({
			event,
			onConfirm: this.onConfirmDeleteAppointment
		});
	}

	onConfirmDeleteAppointment = () => {
		const { actions: {closeAppointmentQuickView, deleteAppointment}, event, selectedPlanning} = this.props;
		closeAppointmentQuickView();
		deleteAppointment({
			...event,
			practitionerId: selectedPlanning,
		});
	}

	onClickUserEmail = () => {
		const { event: { with:user }, actions: { openModaleSendUserEmail, closeModaleSendUserEmail }, onClose } = this.props;
		openModaleSendUserEmail({
			email: user.email,
			name: `${user.firstName} ${user.lastName}`,
			includeQuestion: false,
			displaySwitch: false,
			onClose: closeModaleSendUserEmail,
		});
		onClose();
	}

	onClickUser = () => {
		const { event: { with: { id } }, actions: { openUserDetails }, onClose } = this.props;
		openUserDetails(id);
		onClose();
	}

	onClickUserFolder = () => {
		const { event: { with: { id } }, actions: { openSecureDrive }, onClose } = this.props;
		openSecureDrive(id);
		onClose();
	}

	openAppointment = () => {
		const { event, actions:{openAppointment, closeAppointmentQuickView}, selectedPlanning } = this.props;
		openAppointment({
			...event,
			practitionerId: selectedPlanning,
			day: moment(event.start).format('YYYY-MM-DD'),
			start: moment(event.start),
		});
		closeAppointmentQuickView();
	}

	confirmConfirmAppointment = () => {
		const { actions:{openModaleConfirmAppointment}, event } = this.props;
		openModaleConfirmAppointment({
			event,
			onConfirm: this.onConfirmAppointment
		});
	}

	onConfirmAppointment = () => {
		const { event, actions:{confirmAppointment}, selectedPlanning } = this.props;
		confirmAppointment({
			id: event.id,
			practitionerId: selectedPlanning,
			day: moment(event.start).format('YYYY-MM-DD'),
		});
	}

	enterDuplicateMode = () => {
		const {actions:{enterDuplicationMode, closeAppointmentQuickView}, event} = this.props;
		closeAppointmentQuickView();
		enterDuplicationMode(event);
	}

	handleChangePVPP = async () => {
		const { event, actions } = this.props;
		this.setState({loading: true});
		await actions.updateAppointmentPvpp(event);
		this.setState({loading: false});
	}

	renderAdditionFields = () => {
		const { appointmentsAdditionalFields, event } = this.props;

		if (!appointmentsAdditionalFields || appointmentsAdditionalFields.length === 0) return null;

		return appointmentsAdditionalFields.map((aaf, i) => {
			const field = event[`additionalField${i}`];
			if (!field || field === "") return null;
			if (!aaf || aaf.label === '') return null;

			const Icon = getAFIcon(aaf.icon);

			return <div className="event_popover_add-field" key={aaf.label}>
				<Typography component="div" className="flex ait" paragraph>
					<Tooltip title={aaf.label} touchHold position="bottom">
						<Icon className="mr10" />
					</Tooltip>
					<span>{field}</span>
				</Typography>
			</div>
		});
	}

	openVisioRoom = () => {
		const { selectedPlanning, actions, event } = this.props;
		actions.setVisio({
			planningId: selectedPlanning, 
			eventId: event.id,
		});
		actions.closeAppointmentQuickView();
	}

	openCreatePayment = () => {
		const { selectedPlanning, actions, event } = this.props;

		actions.openModale({
			modale: 'createPayment',
			planningId: selectedPlanning, 
			event,
		});
		actions.closeAppointmentQuickView();

	}

	render() {
		const { event, config, onClose, lexique, appointmentsTypes, isAdmin, user, selectedPlanning, canCreatePayments } = this.props;
		if (!event) return null;

		const { loading } = this.state;
		const { start, duration, with: {firstName, lastName, email, id:userId, phoneNumber, isAnimal, main}, typeId, information, isPast, created, confirmed, pvpp } = event;
		const { dateFormatLong, datetimeFormatLong, timeFormat } = config;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		const type = appointmentsTypes.find(a => a.id === typeId);
		const color = type ? type.color : defaultColorsTypes[typeId === "blocked" ? 'blocked' : 'default'];
		const label = type ? type.label : '-';
		const isVisio = isVisioOpen(type, event);

		const mStart = moment(start);
		const mEnd = moment(start).add(duration, 'minutes');

		const isBlocked = typeId === "blocked";

		return (
			<div className={cn("event_popover_content", {
				'event_popover_content--past': isPast || isBlocked,
			})}>
				<div className="event_popover_header" style={{backgroundColor: color}}>
					<div className="event_popover_actions">
						{isAdmin && <IconButton onClick={this.retrieveRawAppointmentDatas}>
							<Code />
						</IconButton>}
						{userId && userId !== '' && rights.sendEmail && <Tooltip touchHold position="top" title={_lp(lexique.tooltips.sendEmail)}>
							<IconButton onClick={this.onClickUserEmail}>
								<Mail />
							</IconButton>
						</Tooltip>}
						{userId && userId !== '' && <Tooltip touchHold position="top" title={_lp(lexique.tooltips.profile)}>
							<IconButton onClick={this.onClickUser}>
								<Person />
							</IconButton>
						</Tooltip>}
						{userId && userId !== '' && rights.drive && <Tooltip touchHold position="top" title={_lp(lexique.tooltips.secureFolder)}>
							<IconButton onClick={this.onClickUserFolder}>
								<VpnKey />
							</IconButton>
						</Tooltip>}
						{!isBlocked && rights.addAppointment && <Tooltip touchHold position="top" title={lexique.tooltips.duplicate}>
							<IconButton onClick={this.enterDuplicateMode}>
								<Queue />
							</IconButton>
						</Tooltip>}
						{!isPast && rights.deleteAppointment && <Tooltip touchHold position="top" title={lexique.tooltips[typeId === "blocked" ? 'remove' : 'delete']}>
							<IconButton onClick={this.askDeleteAppointment}>
								<Delete />
							</IconButton>
						</Tooltip>}
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<div className="event_popover_title">
						<Typography variant="h5">
							{!isBlocked && isAnimal && <Pets className="mr5 ico--inline ico--inline--short" />}
							{isBlocked ? lexique.blockedSlots : userName({firstName, lastName, isAnimal})}
						</Typography>
						{main && <Typography variant="h6">
							{userName(main)}
						</Typography>}
						{email && <Typography variant="subtitle1">
							{email}
						</Typography>}
						{phoneNumber && <Typography variant="subtitle1">
							<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
						</Typography>}
					</div>
				</div>
				<div className="event_floating-buttons">
					{!isPast && !isBlocked && rights.editAppointment && <div className="event_edit">
						<Tooltip touchHold position="right" title={lexique.tooltips.edit}>
							<Fab color="primary" aria-label="edit" style={{backgroundColor: Color(color).lighten(0.2)}} elevation={20} onClick={this.openAppointment}>
								<Edit />
							</Fab>
						</Tooltip>
					</div> }
					{!isBlocked && isVisio && <div className="event_join-visio">
						<Tooltip touchHold position="left" title={lexique.tooltips.joinVisio}>
							<Fab color="primary" aria-label="edit" style={{backgroundColor: Color(color).darken(0.4)}} elevation={20} onClick={this.openVisioRoom}>
								<VisioIcon />
							</Fab>
						</Tooltip>
					</div> }
					{ userId && userId !== '' && canCreatePayments && <div className="event_create-payment">
						<Tooltip touchHold position="left" title={lexique.tooltips.payments}>
							<Fab color="primary" aria-label="edit" style={{backgroundColor: Color(color).darken(0.2)}} elevation={20} onClick={this.openCreatePayment}>
								<PaymentIcon />
							</Fab>
						</Tooltip>
					</div>}
				</div>
				<div className="event_popover_body">
					{/* ADDITIONAL FIELDS */}
					{this.renderAdditionFields()}
					<Typography component="div" className="flex aic" paragraph>
						<Schedule className="mr10" />
						<span>{mStart.format(dateFormatLong)}, {mStart.format(timeFormat)} - {mEnd.format(timeFormat)}</span>
					</Typography>
					{!isBlocked && <Typography component="div" className="flex aic" paragraph>
						{type?.isVisio ? <VisioIcon className="mr10" /> : <Assignment className="mr10" /> }
						<span>{label}</span>
					</Typography>}
					{information && information.user && information.user !== '' && <Typography component="div" className="flex ait" paragraph>
						<InfoOutline className="mr10" />
						<span>{nl2br(information.user)}</span>
					</Typography>}
					{information && information.admin && information.admin !== '' && <Typography component="div" className="flex ait" paragraph>
						<Note className="mr10" />
						<span>{nl2br(information.admin)}</span>
					</Typography>}
					{!isBlocked && <Typography component="div" className="flex ait" paragraph>
						<Alarm className="mr10" />
						<span>
							{lexique.createdBy.replace('{0}', moment(created.at).format(datetimeFormatLong)).replace('{1}', created.by)}<br />
							{ this.renderConfirmationDate() }
						</span>
					</Typography>}
				</div>
				{!confirmed.at && !isPast && !isBlocked && rights.confirmAppointments && <div className="event_popover_confirm">
					<Button onClick={this.confirmConfirmAppointment} variant="contained" color="primary" disableElevation>
						{lexique.confirm}
					</Button>
				</div>}
				{userId && userId !== "" && <div className="event_popover_pvpp">
					<FormControlLabel
						disabled={loading}
						control={
							<Switch
								color="secondary"
								checked={pvpp}
								onChange={this.handleChangePVPP}
							/>
						}
						label={_lp(lexique.pvpp)}
					/>
				</div>}
			</div>
		)
	}
}


const mapStateToProps = ({env, plannings, user}) => ({
	lexique: getLexique(env.locale).event,
	config: getLexique(env.locale).config,
	appointmentsTypes: plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes : [],
	appointmentsAdditionalFields: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.appointmentsAdditionalFields : [],
	selectedPlanning: plannings.selected,
	canCreatePayments: plannings[plannings.selected]?.canCreatePayments,
	isAdmin: user && user.isAdmin,
	user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openAppointment, confirmAppointment, closeAppointmentQuickView, openModaleConfirmDeleteAppointment, deleteAppointment, enterDuplicationMode, openUserDetails, openModaleConfirmAppointment, retrieveRawDatas, openSecureDrive, openModaleSendUserEmail, closeModaleSendUserEmail, updateAppointmentPvpp, setVisio, openModale }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EventQuickView);
