import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccountCircle as AvatarIcon } from '@material-ui/icons'
import cn from 'classnames';

import { useVideoContext } from './hooks';

import LocalAudioLevelIndicator from './ui/LocalAudioLevelIndicator';
import VideoTrack from './VideoTrack';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        height: 0,
        overflow: 'hidden',
        paddingTop: `${(9 / 16) * 100}%`,
        background: 'black',
    },
    innerContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    identityContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.18em 0.3em',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                transform: 'scale(0.7)',
            },
        },
    },
}));

const LocalVideoPreview = ({className}) => {
    const classes = useStyles();
    const { localTracks } = useVideoContext();

    const videoTrack = localTracks.find(track => track.name.includes('camera'));

    return (
        <div className={cn(classes.container, className)}>
            <div className={classes.innerContainer}>
                {videoTrack ? (
                    <VideoTrack track={videoTrack} isLocal />
                ) : (
                    <div className={classes.avatarContainer}>
                        <AvatarIcon />
                    </div>
                )}
            </div>

            <div className={classes.identityContainer}>
                <span className={classes.identity}>
                    <LocalAudioLevelIndicator />
                </span>
            </div>
        </div>
    );
}

export default memo(LocalVideoPreview);