import { createAction} from 'redux-actions';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import * as APPOINTMENT from '../constants/appointment';
import getLexique from '../locales';

import { loadPlanningDay } from './planning';
import mapper from '../mappers/appointment';
import * as api from '../api/appointment';
import { practitionerName } from '../libs/formatters';
import { checkEventsConflicts } from '../libs/checkers';

export const closeAppointment = createAction(APPOINTMENT.CLOSE);
export const openAppointment = createAction(APPOINTMENT.OPEN);
export const openNewAppointment = createAction(APPOINTMENT.OPEN_NEW);
export const updateStoreAppointmentType = createAction(APPOINTMENT.CHANGE_TYPE_ID);
export const updateStoreDuration = createAction(APPOINTMENT.CHANGE_DURATION);
export const updateStoreStartDate = createAction(APPOINTMENT.CHANGE_START_DATE);
export const updateStorePhoneNumber = createAction(APPOINTMENT.CHANGE_PHONE_NUMBER);
export const updateStoreInfoAdmin = createAction(APPOINTMENT.CHANGE_INFO_ADMIN);
export const updateStoreUserName = createAction(APPOINTMENT.CHANGE_NAME);
export const selectStoreUser = createAction(APPOINTMENT.CHOOSE_USER);
export const updateField = createAction(APPOINTMENT.UPDATE_FIELD);

export const updateStoreAppointment = createAction(APPOINTMENT.UPDATE);
export const deleteStoreAppointment = createAction(APPOINTMENT.DELETE);
export const createStoreAppointment = createAction(APPOINTMENT.CREATE);
export const addAppointmentToConfirmToStore = createAction(APPOINTMENT.ADD_TO_CONFIRM_LIST);
export const removeAppointmentToConfirmFromStore = createAction(APPOINTMENT.REMOVE_FROM_CONFIRM_LIST);
export const confirmStoreAppointment = createAction(APPOINTMENT.CONFIRM);

export const addDuplicateEvent = createAction(APPOINTMENT.DUPLICATE_ADD);
export const removeDuplicateEvent = createAction(APPOINTMENT.DUPLICATE_REMOVE);

const duplicatesSaveRequest = createAction(APPOINTMENT.DUPLICATES_SAVE_REQUEST);
const duplicateSaveComplete = createAction(APPOINTMENT.DUPLICATE_SAVE_COMPLETE);

export const updateFieldAppointment = ({field, value}) => dispatch => {
    switch (field) {
        case 'typeId':
            dispatch(updateStoreAppointmentType(value.target.value));
            break;
		case 'auto-typeId':
			dispatch(updateStoreAppointmentType(value));
			if (value === "blocked") {
				dispatch(updateStorePhoneNumber(''));
				dispatch(updateStoreUserName(''));
			}
			break;
        case 'duration':
            dispatch(updateStoreDuration(value.target.value));
            break;
        case 'auto-duration':
            dispatch(updateStoreDuration(value));
            break;
        case 'start':
            dispatch(updateStoreStartDate(value.format()));
            break;
        case 'name':
            dispatch(updateStoreUserName(value.target.value));
            break;
        case 'phoneNumber':
            dispatch(updateStorePhoneNumber(value.target.value));
            break;
        case 'infoAdmin':
            dispatch(updateStoreInfoAdmin(value.target.value));
            break;
        case 'user':
            dispatch(selectStoreUser(value));
            break;
        default:
			dispatch(updateField({field, value: value.target.value}));
    }
}

export const checkConflicts = ({event, to}) => async (dispatch, getState) => {
    let { plannings } = getState();
    let planning = plannings[plannings.selected];

    const {start} = to;

    let dayPlanningTo = planning.days[moment(start).format('YYYY-MM-DD')];
    if (!dayPlanningTo) {
        await dispatch(loadPlanningDay({
            planningId: plannings.selected,
            day: moment(start).format('YYYY-MM-DD'),
        }));
        plannings = getState().plannings;
        planning = plannings[plannings.selected];
        dayPlanningTo = planning.days[moment(start).format('YYYY-MM-DD')];
    }

    return checkEventsConflicts({
        appointments: dayPlanningTo.appointments,
        id: event.id,
        to
    });
}

export const resetAppointmentInConfirmList = ({event, planningId}) => async dispatch => {

    if (event.confirmed.at === '') {
        await dispatch(removeAppointmentToConfirmFromStore({
            planningId,
            id: event.id
        }));

        dispatch(addAppointmentToConfirmToStore({
            planningId,
            event
        }));
    }
}

export const moveAppointmentTo = ({conflicts, event, to:{start, end}}) => async (dispatch, getState) => {
    // We assume we don't need to recheck loadPlanningDay as this function should not be called before checkConflicts;
    // This function dhould only be called via drag method
    const toDay = moment(start).format('YYYY-MM-DD');
    const eventDay = moment(event.start).format('YYYY-MM-DD');

    const { plannings, env } = getState();
    const newDuration = moment.duration(moment(end).diff(moment(start))).asMinutes();

	// API will handle itself if it should only update or delete/create, with send mail
	// Error case:
	// 	- User already has an appointment scheduled at new start
	const res = await api.updateAppointment({
        conflicts,
		practitionerId: plannings.selected,
		from: {
			...event,
			start: moment(event.start).format()
		},
		to: {
			...event,
			start: moment(start).format(),
            duration: newDuration,
		}
	});

	if (res.error) {
		return res;
	}

    if (toDay === eventDay) {
        // Moving to same day, updating start date
        const datas = {
            planningId: plannings.selected,
            day: toDay,
            id: event.id,
            event: {
                ...event,
                start: moment(start).format(),
                duration: newDuration
            }
        }
        dispatch(updateStoreAppointment(datas));
    }
    else {
        // Different day, we should remove the first appointment and create the second in store
        dispatch(deleteStoreAppointment({
            planningId: plannings.selected,
            day: eventDay,
            id: event.id,
        }));

        dispatch(createStoreAppointment({
            planningId: plannings.selected,
            day: toDay,
            id: event.id,
            event: {
                ...event,
                start,
                duration: newDuration,
            }
        }));

        dispatch(resetAppointmentInConfirmList({
            event: {
                ...event,
                start,
                duration: newDuration
            },
            planningId: plannings.selected,
        }));
    }

    // Toast
	const lexique = getLexique(env.locale).toasts.appointments;
	toast.success(lexique.moved);

	return true;
}

export const createAppointment = event => async (dispatch, getState) => {
    // This function should be called after checkConflicts
    const res = await api.createAppointment(event);
    if (!res.error) {
        // success !
        const { id } = mapper.createAppointment.fromApi(res);
		const { user, env, plannings } = getState();

		const getFinalEvent = () => {
			if (!event.with.isAnimal) return event;

			const retrievedUser = plannings[plannings.selected].users.find(u => u.id === event.with.id);
			const mainUser = retrievedUser.family.find(m => m.isMain);

			return {
				...event,
				with: {
					...event.with,
					main: {
						firstName: mainUser.firstName,
						lastName: mainUser.lastName,
					}
				}
			};
		}

		const finalEvent = getFinalEvent();

        dispatch(createStoreAppointment({
            planningId: event.practitionerId,
            day: moment(event.start).format('YYYY-MM-DD'),
            id,
            event: {
                ...finalEvent,
				created: {
					at: moment().format(),
					byUser: false,
					by: practitionerName(user)
				},
				isPast: false,
				confirmed: {
					at: moment().format(),
					by: '',
				},
                id,
            }
        }));

		// Toast
		const lexique = getLexique(env.locale).toasts.appointments;
		if (event.typeId === "blocked") toast.error(lexique.blocked);
		else toast.success(lexique.created);

        return true;
    }
    else {
        return res;
    }
}

export const deleteAppointment = event => async (dispatch, getState) => {
	// API CALl DELETE
	await api.deleteAppointment(event);

    const { env, plannings } = getState();
    const day = moment(event.start).format('YYYY-MM-DD');

    if (plannings[plannings.selected] && plannings[plannings.selected].days && plannings[plannings.selected].days[day] && plannings[plannings.selected].days[day].appointments) {
    	// Dispatch to store
    	dispatch(deleteStoreAppointment({
    		planningId: event.practitionerId,
    		day,
    		id: event.id,
    	}));
    }

    dispatch(removeAppointmentToConfirmFromStore({
        planningId: event.practitionerId,
        id: event.id,
    }));

	// Toast
	const lexique = getLexique(env.locale).toasts.appointments;
	if (event.typeId === "blocked") toast.success(lexique.unblocked);
	else toast.error(lexique.deleted);
}

export const confirmAppointment = query => async (dispatch, getState) => {
	// API CALl CONFIRM
	await api.confirmAppointment(query);

	// Dispatch to store
	const { user, env, plannings } = getState();

    if (plannings[plannings.selected] && plannings[plannings.selected].days && plannings[plannings.selected].days[query.day]) {
    	dispatch(confirmStoreAppointment({
            planningId: query.practitionerId,
            id: query.id,
            day: query.day,
            confirmed: {
                at: moment().format(),
                by: practitionerName(user),
            }
        }));
    }

    dispatch(removeAppointmentToConfirmFromStore({
        planningId: query.practitionerId,
        id: query.id,
    }));

	// Toast
	const lexique = getLexique(env.locale).toasts.appointments;
	toast.success(lexique.confirmed);
}

export const updateAppointment = query => async (dispatch, getState) => {
	const res = await api.updateAppointment(query);
	if (res.error) {
		return res;
	}

	// we should check if data has changed
	const fromDay = moment(query.from.start).format('YYYY-MM-DD');
	const toDay = moment(query.to.start).format('YYYY-MM-DD');
	if (fromDay !== toDay) {
		// So we should delete this one from store and recreate it at right place
		dispatch(deleteStoreAppointment({
            planningId: query.practitionerId,
            day: fromDay,
            id: query.from.id,
        }));

        dispatch(createStoreAppointment({
            planningId: query.practitionerId,
            day: toDay,
            id: query.from.id,
            event: {
                ...query.to,
            }
        }));
	}
	else {
		// We just need to update current appointment
		const datas = {
            planningId: query.practitionerId,
            day: toDay,
            id: query.from.id,
            event: {
                ...query.to,
            }
        }
        dispatch(updateStoreAppointment(datas));
	}

    dispatch(resetAppointmentInConfirmList({
        event: {
            ...query.to,
            id: query.from.id,
        },
        planningId: query.practitionerId,
    }));

	// Toast
	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.appointments;
	toast.success(lexique.updated);
	return true;
}

export const confirmDuplicateAppointments = ({appointments, practitionerId}) => async dispatch => {
	// Move all duplicated event to status "loading" & prevent closing drawer
	dispatch(duplicatesSaveRequest());

	await Promise.all(appointments.map(async a =>  {
		const res = await dispatch(createAppointment({
			...a,
			practitionerId
		}));
		return dispatch(duplicateSaveComplete({
			id: a.id,
			status: res.error ? "error" : "success"
		}));
	}));

	return true;
}

export const getAppointment = query => async () => {
    const res = await api.getAppointment(query);
    const mapped = mapper.getAppointment.fromApi(res);
    return mapped;
}

export const retrieveRawDatas = id => async () => {
	console.log(`Loading datas for event Id: ${id}`); // eslint-disable-line
	const res = await api.retrieveRawDatas(id);
	console.log(res); // eslint-disable-line
}

export const updateAppointmentPvpp = event => async (dispatch, getState) => {
	const { plannings, env } = getState();

	await api.updateAppointmentPvpp({
		planningId: plannings.selected,
		event,
	});

	dispatch(updateStoreAppointment({
		planningId: plannings.selected,
		day: moment(event.start).format('YYYY-MM-DD'),
		id: event.id,
		event: {
			...event,
			pvpp: !event.pvpp
		}
	}));

	const lexique = getLexique(env.locale).toasts.appointments;
	toast.success(lexique.pvpp);
	return true;
}

export const sendFilesFromVisio = ({attachments}) => async (_, getState) => {

    const { planningId, eventId } = getState().ui.visio;

    await api.sendFilesFromVisio({
        planningId, 
        eventId,
        attachments
    })

    return true;
}

export const sendMessageFromVisio = ({message, type, eventId, planningId}) => async () => {

    await api.sendMessageFromVisio({
        planningId, 
        eventId,
        message,
        type,
    });

    return true;

}