import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment-timezone';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Today from '@material-ui/icons/Today';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../ui/ButtonCircularProgress';
import Message from '../ui/Message';
import FamilyCard from '../users/FamilyCard';

import { closeModale } from '../../actions/ui';
import { searchUser, addUser, createUser } from '../../actions/users';

import getLexique, { _lp } from '../../locales';

const MODALE_ID = "addNewUser";

class AddNewUser extends PureComponent {

    initialState = { search: '', result: null, loading: false, complete: false, alreadyInBase: false, errors: [], addForm: false, phoneNumber: '', birthdate: {}, firstName: '', lastName: '' };
    state = this.initialState;

    onClose = () => {
        this.props.actions.closeModale({modale: MODALE_ID});
        setTimeout(this.resetState, 500);
    }

    resetState = () => this.setState(this.initialState);

    createNewUser = async () => {
		const { firstName, lastName, phoneNumber, gender, birthdate } = this.state;
		let errors = [];
		if (firstName.trim() === "") errors.push('firstName');
		if (lastName.trim() === "") errors.push('lastName');
		if (phoneNumber.trim() === "") errors.push('phoneNumber');
		if (gender === "") errors.push('gender');
		if (!birthdate) errors.push('birthdate');

		if (errors.length > 0) {
			this.setState({errors});
			return;
		}

		// Create new user
		this.setState({loading: true});
		await this.props.actions.createUser(this.state);
		this.setState({loading: false});
		this.onClose();
    }

	showAddForm = () => this.setState({addForm: true});

    addUserToMyList = async () => {
        this.setState({loading: true});
        await this.props.actions.addUser(this.state.result);
        this.onClose();
    }

    onSearch = async () => {
        const { search } = this.state;
        const { users } = this.props;
        if (search.trim() === '' || !isEmail(search)) {
            this.setState({errors: ['email']});
            return;
        }

        this.setState({loading: true, complete: false, alreadyInBase: false, result: null, errors: []});
        const res = await this.props.actions.searchUser(search);
        let alreadyInBase = false;
        if (res) {
            // Checking if user already exists in our base
            alreadyInBase = users.findIndex(u => u.id === res.id) > -1;
        }

        this.setState({loading: false, complete: true, alreadyInBase, result: res});
    }

    onKeyPress = e => {
        if (e.key === 'Enter') this.onSearch();
    }

    handleChangeEmail = ({target:{value:search}}) => this.setState({search, complete: false, alreadyInBase: false, result: null, errors:[]});

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});

	handleChangeDate = birthdate => this.setState({birthdate})

	searchForm = () => {
		const { lexique } = this.props;
		const { search, result, loading, complete, alreadyInBase, errors } = this.state;
		return (
		<DialogContent className="padt10">
			<TextField
				className="mb20"
				error={errors.indexOf('email') > -1}
				label={lexique.email}
				value={search}
				onChange={this.handleChangeEmail}
				fullWidth
				variant="outlined"
				disabled={loading}
				onKeyPress={this.onKeyPress}
				autoFocus
				InputProps={{
					endAdornment: (
					<InputAdornment position="end">
						{ !complete && loading ? <CircularProgress size={20} /> :
							<IconButton onClick={this.onSearch}>
								<SearchIcon />
							</IconButton>
						}
					</InputAdornment>
					),
				}}
			/>
			{complete && result &&
				<FamilyCard {...result} className="full-width" hideActionMenu />
			}
			{complete && alreadyInBase &&
				<Message level="information" show className="mt20">
					{_lp(lexique.alreadyInYours)}
				</Message>
			}
			{complete && !result &&
				<Message level="information" show className="mt10">
					{_lp(lexique.doNotExists)}
				</Message>
			}
		</DialogContent>
		);
	}

	addForm = () => {
		const { lexique, config, locale } = this.props;
		const { search, loading, errors, firstName, lastName, phoneNumber, gender, birthdate } = this.state;
		return (
		<DialogContent className="padt10">
			<TextField
				className="mb20"
				label={lexique.email}
				value={search}
				fullWidth
				variant="outlined"
				disabled
				required
			/>
			<TextField
				className="mb20"
				required
				error={errors.indexOf('firstName') > -1}
				label={lexique.firstName}
				value={firstName}
				onChange={this.handleChange('firstName')}
				autoFocus
				fullWidth
				variant="outlined"
				disabled={loading}
			/>
			<TextField
				className="mb20"
				required
				error={errors.indexOf('lastName') > -1}
				label={lexique.lastName}
				value={lastName}
				onChange={this.handleChange('lastName')}
				fullWidth
				variant="outlined"
				disabled={loading}
			/>
			<FormControl component="fieldset"
				required
				disabled={loading}
				error={errors.indexOf('gender') > -1}
				className="mb20"
			>
				<FormLabel component="legend">{lexique.gender}</FormLabel>
				<RadioGroup
					row
					aria-label={lexique.gender}
					name="gender"
					value={gender}
					onChange={this.handleChange('gender')}
				>
					<FormControlLabel value="female" control={<Radio color="primary" />} label={lexique.options.female} />
					<FormControlLabel value="male" control={<Radio color="primary" />} label={lexique.options.male} />
					<FormControlLabel value="other" control={<Radio color="primary" />} label={lexique.options.other} />
				</RadioGroup>
			</FormControl>
			<TextField
				required
				className="mb20"
				error={errors.indexOf('phoneNumber') > -1}
				label={lexique.phoneNumber}
				value={phoneNumber}
				onChange={this.handleChange('phoneNumber')}
				fullWidth
				variant="outlined"
				disabled={loading}
			/>
			<MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
				<DatePicker
					className="mt20 mb20"
					id="register-birthdate"
					name="birthdate"
					disabled={loading}
					error={errors.indexOf('birthdate') > -1}
					onChange={this.handleChangeDate}
					value={birthdate}
					disableFuture
					openToYearSelection
					autoOk
					leftArrowIcon={<KeyboardArrowLeft />}
					rightArrowIcon={<KeyboardArrowRight />}
					keyboardIcon ={<Today />}
					cancelLabel={lexique.calendar.cancel}
					okLabel={lexique.calendar.ok}
					format={config.dateFormat}
					label={lexique.birthdate}
					invalidDateMessage={lexique.calendar.invalid}
					placeholder={lexique.calendar.ddmmyyyy}
					fullWidth
					required
					variant="outlined"
					maxDate={moment().format('YYYY-MM-DD')}
		        />
			</MuiPickersUtilsProvider>
			<Message level="information" show className="mt10">
				{_lp(lexique.infosCreate)}
			</Message>
		</DialogContent>
		);
	}

    render() {
        const { show, lexique, isMobile } = this.props;
        const { result, loading, complete, alreadyInBase, addForm } = this.state;
        return (
        <Dialog
            open={show}
            TransitionComponent={ModalTransitionZoom}
            fullWidth
			fullScreen={isMobile}
            onClose={this.onClose}
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}
        >
            <DialogTitle>
                {_lp(lexique.title)}
            </DialogTitle>
            {addForm ? this.addForm() : this.searchForm()}
			<DialogActions>
				<Button onClick={this.onClose} color="primary" disabled={loading}>
					{lexique.close}
				</Button>
				{complete && !result && !addForm &&
					<Button onClick={this.showAddForm} color="primary" disabled={loading} variant="contained" size="large" disableElevation>
						{_lp(lexique.create)}
					</Button>
				}
				{complete && !result && addForm &&
					<Button onClick={this.createNewUser} color="primary" disabled={loading} variant="contained" size="large" disableElevation>
						{_lp(lexique.create)}
						{complete && loading && <ButtonCircularProgress />}
					</Button>
				}
				{complete && result && !alreadyInBase &&
					<Button onClick={this.addUserToMyList} color="primary" disabled={loading} variant="contained" size="large" disableElevation>
						{_lp(lexique.addToMyList)}
						{complete && loading && <ButtonCircularProgress />}
					</Button>
				}
			</DialogActions>
        </Dialog>
        );
    }
}

const mapStateToProps = ({plannings, env, ui:{device, modales:{[MODALE_ID]:{show}}}}) => ({
	isMobile: device.isMobile,
	lexique: getLexique(env.locale).modales[MODALE_ID],
	config: getLexique(env.locale).config,
	locale: env.locale,
    users: plannings[plannings.selected] && plannings[plannings.selected].users ? plannings[plannings.selected].users : [],
    show,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModale, searchUser, addUser, createUser }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUser);
