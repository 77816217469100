import React, { memo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Event from './Event';
import { getUserRightsOnPlanning } from '../../libs/checkers';

const DayEvents = memo(({dayAppointments, user, selectedPlanning, onSelectEvent, onMoveEvent, dayUpdatedAt, days, date, duplicatedEvents, duplicateMode}) => {

    const rights = getUserRightsOnPlanning(user, selectedPlanning);

    const getDayDuplicatedEvent = () => {
        return duplicatedEvents.filter(a => moment(a.start).format('YYYY-MM-DD') === date).map(a => ({...a, isADuplicated: true}));
    }

    const appointments = duplicateMode ? [...dayAppointments, ...getDayDuplicatedEvent()] : dayAppointments;

    return appointments.map(app => (
        <Event
            key={`${app.id}-${moment(app.updatedAt).format('YYYYMMDD-HHmmss')}-${moment(dayUpdatedAt).format('YYYYMMDD-HHmmss')}`}
            onSelectEvent={onSelectEvent}
            onMoveEvent={onMoveEvent}
            dayAppointments={dayAppointments}
            hasRight={rights.editAppointment}
            event={app}
            days={days}
            date={date}
        />
    ))
})

const mapStateToProps = ({plannings, user, appointment, ui}, {date}) => ({
    dayAppointments: plannings[plannings.selected].days && plannings[plannings.selected].days[moment(date).format('YYYY-MM-DD')] ? plannings[plannings.selected].days[moment(date).format('YYYY-MM-DD')].appointments : [],
    dayUpdatedAt: plannings[plannings.selected].days && plannings[plannings.selected].days[moment(date).format('YYYY-MM-DD')] ? plannings[plannings.selected].days[moment(date).format('YYYY-MM-DD')].updatedAt : '',
    selectedPlanning: plannings.selected,
    user,
    duplicatedEvents: appointment.duplicates,
    duplicateMode: ui.duplicateMode.show,
});

export default connect(mapStateToProps)(DayEvents);
