import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import cn from 'classnames';

import Toolbar from './CalendarToolbar';
import Week from './Week';

const Calendar = memo(({onSelectSlot, onSelectEvent, onMoveEvent, isDesktop, highContrastDesktop, highContrastMobile}) => {
    const [startDate, changeDate] = useState(moment());

    const onChangeDate = (code, date) => {

        switch (code) {
            case 'PREV':
                return changeDate(moment(startDate).subtract(7, 'days'));
            case 'NEXT':
                return changeDate(moment(startDate).add(7, 'days'));
            default:
                return changeDate(date);
        }
    }

    const highConstrast = (isDesktop && highContrastDesktop) || (!isDesktop && highContrastMobile);

    return <div className={cn("calendar-container", {
        'high--contrast': highConstrast
    })}>
        <Toolbar onNavigate={onChangeDate} date={startDate} />
        <Week
            date={startDate}
            onSelectSlot={onSelectSlot}
            onSelectEvent={onSelectEvent}
            onMoveEvent={onMoveEvent}
        />
    </div>
});

const mapStateToProps = ({plannings, ui}) => ({
	highContrastDesktop: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.highContrastDesktop : false,
	highContrastMobile: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.highContrastMobile : false,
    isDesktop: ui.device.isDesktop,
});

export default connect(mapStateToProps)(Calendar);
