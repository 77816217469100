import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Queue from '@material-ui/icons/Queue';

import UtilityViews from './UtilityViews';

import SprelCalendar from '../calendar/Calendar';

import { openNewAppointment, checkConflicts, moveAppointmentTo, addDuplicateEvent, removeDuplicateEvent } from '../../actions/appointment';
import { openModaleConflicts, openModaleError, openAppointmentQuickView, openDuplicationDrawer, openModaleConfirmationChangeEvent } from '../../actions/ui';
import { updateViewDate } from '../../actions/planning';
import getLexique from '../../locales';
import { randString } from '../../libs/random';
import { getUserRightsOnPlanning } from '../../libs/checkers';

class Calendar extends PureComponent {

	state = { selectedTouchSlot: null }

	moveEvent = async ({ event, start, end }) => {
		const { actions: { checkConflicts, openModaleConflicts, openModaleError, openModaleConfirmationChangeEvent }, planningConf, user, selectedPlanning } = this.props;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);
		if (!rights.editAppointment) return;

		const s = moment(start);
		const e = moment(end);
		const now = moment();
		if (s.isBefore(now)) {
			openModaleError('move/slot/past');
			return;
		}

		const eventEnd = moment(event.start).add(event.duration, 'minutes');
		if (s.isSame(moment(event.start)) && e.isSame(moment(eventEnd))) return;

		const conflicts = await checkConflicts({event, to: {start, end}});

		if (conflicts.length === 0) {
			// Check if event start has changed, and if we have to ask for confirmation
			const { autoConfirmEventChange } = planningConf;
			if (!moment(event.start).isSame(moment(start)) && !autoConfirmEventChange) {
					openModaleConfirmationChangeEvent({
						event: event,
						eventTo: start,
						onConfirm: this.askMoveAppointmentTo({event, to: {start, end}}),
					});
					return;
			}
			this.moveAppointmentTo({event, to: {start, end}});
		}
		else {
			const newDuration = moment.duration(moment(end).diff(moment(start))).asMinutes();
			openModaleConflicts({
				event: {
					...event,
					start: moment(start),
					duration: newDuration,
				},
				conflicts,
				onConfirm: this.askMoveAppointmentTo({event, conflicts, to: {start, end}}),
			})
		}
	}

	askMoveAppointmentTo = datas => () => {
		this.moveAppointmentTo(datas);
	}

	moveAppointmentTo = async ({conflicts, event, to: {start, end}}) => {
		const { actions: { moveAppointmentTo, openModaleError } } = this.props;
		const res = await moveAppointmentTo({conflicts, event, to: {start, end}});
		if (res.error) {
			openModaleError(`move/${res.code}`);
		}
	}

	onSelectEvent = selectedEvent => {
		const { selectedPlanning, actions: {openAppointmentQuickView}, duplicateMode} = this.props;
		if (duplicateMode) return;

		const { id, start } = selectedEvent;
		openAppointmentQuickView({
			planningId: selectedPlanning,
			day: moment(start).format('YYYY-MM-DD'),
			appointmentId: id,
		});
	}

	getDefaultAppointmentType = () => {
		const { planningConf, appointmentsTypes } = this.props;
		const { defaultAppointmentId } = planningConf;
		if (!defaultAppointmentId || defaultAppointmentId === '') return '';

		// Getting default appointment
		const app = appointmentsTypes.find(a => a.id === defaultAppointmentId);
		if (!app) return '';

		return defaultAppointmentId;
	}

	getDefaultDurationByDefaultAppointmentType = dDur => {
		const { planningConf, appointmentsTypes } = this.props;
		const { defaultAppointmentId } = planningConf;
		if (!defaultAppointmentId || defaultAppointmentId === '') return dDur;

		// Getting default appointment
		const app = appointmentsTypes.find(a => a.id === defaultAppointmentId);
		if (!app) return dDur;

		return app.duration > dDur ? app.duration : dDur;
	}

	onSelectSlot = async ({start, duration}) => {
		const { duplicateMode, step } = this.props;
		const today = moment();
		const s = moment(start);

		if (s.isBefore(today)) return;
		const { actions:{openNewAppointment, openModaleConflicts, checkConflicts}, selectedPlanning,  duplicatingEvent} = this.props;

		if (duplicateMode) {
			// We are in duplicate mode
			// Check conflicts
			const conflicts = await checkConflicts({event: duplicatingEvent, to: {
				start,
				end: moment(start).add(duplicatingEvent.duration, "minutes").format()
			}});

			if (conflicts.length === 0) {
				this.addEventToDuplicateQueueAt(start);
			}
			else {
				openModaleConflicts({
					event: {
						...duplicatingEvent,
						start: moment(start).format(),
					},
					conflicts,
					onConfirm: this._addEventToDuplicateQueueAt(start),
				})
			}
			return;
		}

		const rangePicked = duration > step;

		openNewAppointment({
			practitionerId: selectedPlanning,
			day: s.format('YYYY-MM-DD'),
			id: '',
			start: s.format(),
			duration: this.getDefaultDurationByDefaultAppointmentType(duration),
			rangePicked: rangePicked,
			information: {
				user: '',
				admin: ''
			},
			typeId: this.getDefaultAppointmentType(),
			with: {
				id: '',
				email: '',
				phoneNumber: '',
				familyMemberId: '',
				firstName: '',
				lastName: ''
			},
		})
	}

	_addEventToDuplicateQueueAt = start => () => this.addEventToDuplicateQueueAt(start)
	addEventToDuplicateQueueAt = start => {
		const { actions: {addDuplicateEvent}, duplicatingEvent } = this.props;
		addDuplicateEvent({
			...duplicatingEvent,
			id: randString(20),
			start: moment(start).format(),
			isPast: false,
		})
	}

	openDrawerDuplicatedAppointments = () => {
		this.props.actions.openDuplicationDrawer();
	}

	renderFloatingActionButton = () => {
		const { duplicateMode, lexique, duplicatedEvents } = this.props;

		if (!duplicateMode) return null;

		return (
			<>
				<Badge color="secondary" badgeContent={duplicatedEvents.length}>
					<Fab key="fab" onClick={this.openDrawerDuplicatedAppointments} color="primary" aria-label="add">
						<Queue />
					</Fab>
				</Badge>
				<div className="floating-information-message">
					{lexique.duplicateMode.body}
				</div>
			</>
		)
	}

	render() {
		const { currentPlanning } = this.props;

		if (!currentPlanning || currentPlanning.loading) return (
			<div className="pad20 w100"><LinearProgress color="secondary" /></div>
		)

		return (
			<div className="planning_calendar flex1">
				<SprelCalendar
					onSelectSlot={this.onSelectSlot}
					onSelectEvent={this.onSelectEvent}
					onMoveEvent={this.moveEvent}
				/>
				{/*FLOATING ACTION BUTTON */}
				<div className="planning_calendar_fab">
					{this.renderFloatingActionButton()}
				</div>
				{/* Utility views */}
				<UtilityViews />
			</div>
		)
	}
}



const mapStateToProps = ({env, plannings, ui, appointment, user}) => ({
	lexique: getLexique(env.locale).calendar,
	planningConf: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
	selectedPlanning: plannings.selected,
	currentPlanning: plannings[plannings.selected],
	appointmentsTypes : plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes : [],
	duplicateMode: ui.duplicateMode.show,
	duplicatedEvents: appointment.duplicates,
	duplicatingEvent: appointment.event,
	step: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.step || 15 : 15,
	user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openNewAppointment, checkConflicts, moveAppointmentTo, openModaleConflicts, openModaleError, openAppointmentQuickView, addDuplicateEvent, removeDuplicateEvent, openDuplicationDrawer, updateViewDate, openModaleConfirmationChangeEvent }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
