import React, { memo } from 'react';

import { Tooltip  as MuiTooltip } from '@material-ui/core';

const Tooltip = ({title, position, children}) => {

    return (
        <MuiTooltip title={title} placement={position}>
            {children}
        </MuiTooltip>
    );

}

export default memo(Tooltip)