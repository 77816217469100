import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import CheckCircle from '@material-ui/icons/CheckCircle';

import Markdown from '../ui/Markdown';
import Message from '../ui/Message';
import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import getLexique from '../../locales';

class Plan extends PureComponent {

	state = { showDialog: false };

	renderPlanDateDetails = () => {
		const { plan, lexique, trial, endOfTrial, config } = this.props;
		if (trial) return lexique.expire.replace('$1', moment(endOfTrial).format(config.dateFormatLong));

		if (!plan) return '';

		return lexique.period.replace('$1', moment.unix(plan.start).format(config.dateFormatLong)).replace('$2', moment.unix(plan.end).format(config.dateFormatLong));
	}

	selectPlan = plan => () => this.props.plan && plan !== this.props.plan.interval && this.setState({showDialog: true});
	hideModal = () => this.setState({showDialog: false});

	render() {
		const { plan, lexique, trial, pricingVersion } = this.props;
		const { showDialog } = this.state;
		return (
			<Paper className="account_widget plan pad20 tac">
				<Typography variant="h5" className="widget_title">{lexique.title}</Typography>
				<div className="account_plans">
					{trial && <div className='account_plan plan--active' key="trial">
						<Typography variant="h6">
							{lexique.plans.trial.title}
						</Typography>
						<Typography variant="h4">
							{lexique.plans.trial.amount}
						</Typography>
						<div className="ico"><CheckCircle /></div>
					</div>}
					{ ["month", "6months", "year"].map(cp => <button className={cn('account_plan', {'plan--active': plan && cp === plan.interval})} key={cp} onClick={this.selectPlan(cp)}>
						<Typography variant="h6">
							{lexique.plans[cp].title}
						</Typography>
						<Typography variant="h4">
							{lexique.plans[cp].amount[pricingVersion]} €
						</Typography>
						{plan && cp === plan.interval && <div className="ico"><CheckCircle /></div>}
					</button>) }
				</div>
				<Message show level="information">
					<Markdown>
						{ this.renderPlanDateDetails() }
					</Markdown>
				</Message>
				<Typography variant="caption" className="mt20 tal">{lexique.update}</Typography>
				<Dialog
					open={showDialog}
					onClose={this.hideModal}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
				>
					<DialogTitle>
						{lexique.change.title}
					</DialogTitle>
					<DialogContent>
						<Markdown>
							{lexique.change.body}
						</Markdown>
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={this.hideModal}>{lexique.change.close}</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		)
	}

}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).account.plan,
	config: getLexique(env.locale).config,
	plan: plannings[plannings.selected].billing.plan,
	trial: plannings[plannings.selected].billing.trial,
	endOfTrial: plannings[plannings.selected].billing.endOfTrial,
	pricingVersion: plannings[plannings.selected].billing.pricingVersion,
});


export default connect(mapStateToProps)(Plan);
