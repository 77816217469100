import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import store from 'store';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import Refresh from '@material-ui/icons/Refresh';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { VERSION } from '../../version';

import getLexique from '../../locales';
import { doLogout } from '../../actions/user';
import { openLeftMenu, openModale } from '../../actions/ui';
import { setPlanning, loadPlanning } from '../../actions/planning';
import { practitionerName } from '../../libs/formatters';
import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import logo from '../../assets/logo-white.png';

class AppHeader extends PureComponent {

	state = {
		anchorEl: null,
		anchorElSwitch: null,
		askLogout: false,
	};

	openDisconnectConfirmation = () => {
		this.closeMenuUser();
		this.setState({askLogout: true});
	}

	closeDisconnectConfirmation = () => {
		this.setState({askLogout: false});
	}

	doLogout = () => {
		const { doLogout } = this.props.actions;
		this.setState({askLogout: false});
		doLogout();
	}

	openMenuUser = ({currentTarget}) => this.setState({anchorEl: currentTarget});
	closeMenuUser = () => this.setState({anchorEl: null});
	openLeftMenu = () => this.props.actions.openLeftMenu();
	openMenuSwitchPlanning = ({currentTarget}) => this.setState({anchorElSwitch: currentTarget});
	closeMenuSwitchPlanning = () => this.setState({anchorElSwitch: null});

	componentDidMount() {
		this.loadPlanning();
	}

	componentDidUpdate = oldProps => {
		if (oldProps.selectedPlanning !== this.props.selectedPlanning) this.loadPlanning();
	}

	switchPlanning = id => () => {
		this.closeMenuSwitchPlanning();
		this.props.actions.setPlanning(id);
	}

	loadPlanning = () => {
		const { currentPlanning, selectedPlanning, actions:{loadPlanning} } = this.props;
		if (!selectedPlanning || selectedPlanning === '') return;
		if (!currentPlanning) {
			loadPlanning({
				id: selectedPlanning
			});
		}
	}

	createPractitioner = () => {
		this.closeMenuSwitchPlanning();
		this.props.actions.openModale({
			modale: 'adminAddPractitioner',
		});
	}

	searchPractitioner = () => {

	}

	reloadApp = () => {
		store.clearAll();
		window.location.reload(true);
	}

	getLabelCurrentPlanning = () => {
		const { hasDefaultPlanning, defaultPlanning, selectedPlanning, otherPlannings, lexique } = this.props;

		if (!selectedPlanning || selectedPlanning === '') return '';

		if (defaultPlanning === selectedPlanning && hasDefaultPlanning) return lexique.plannings.yours;

		const planning = otherPlannings.find(p => p.id === selectedPlanning);
		return practitionerName(planning);
	}

	renderPlanningSwitcher = () => {
		const { otherPlannings, hasDefaultPlanning, defaultPlanning, selectedPlanning, lexique, isAdmin } = this.props;
		const { anchorElSwitch } = this.state;
		if ((!otherPlannings || otherPlannings.length === 0) && !isAdmin) return null;

		const currentPlanning = this.getLabelCurrentPlanning();

		return (
			<div>
				<Button size="large" color="inherit" onClick={this.openMenuSwitchPlanning}>{currentPlanning}</Button>
				<Menu
					id="menu-switch-planning"
					anchorEl={anchorElSwitch}
					open={anchorElSwitch !== null}
					onClose={this.closeMenuSwitchPlanning}
				>
					{hasDefaultPlanning && <MenuItem key={defaultPlanning} onClick={this.switchPlanning(defaultPlanning)} disabled={defaultPlanning === selectedPlanning}>
						{lexique.plannings.yours}
					</MenuItem>}
					{otherPlannings.map(p => (
						<MenuItem key={p.id} onClick={this.switchPlanning(p.id)} disabled={selectedPlanning === p.id}>
							{ practitionerName(p) }
						</MenuItem>
					))}
					{isAdmin && <Divider /> }
					{isAdmin && <MenuItem onClick={this.createPractitioner}>
						{ lexique.plannings.new }
					</MenuItem> }
					{isAdmin && <Divider /> }
					{isAdmin && <MenuItem onClick={this.searchPractitioner}>
						{ lexique.plannings.search }
					</MenuItem> }
				</Menu>
			</div>
		);
	}

	renderLogged = () => {
		const { lexique, isMobile } = this.props;
		const { anchorEl, askLogout } = this.state;

		return (
			<Toolbar className="app-header">
				{isMobile && <IconButton color="inherit" aria-label="Menu" onClick={this.openLeftMenu}>
					<MenuIcon />
				</IconButton>}
				{!isMobile && <Typography variant="h6" component="h1" color="inherit" className="main-header">
					<img className="app-header_logo" src={logo} alt="Sprel Logo" />
				</Typography> }
				<div className={cn('flex1', { 'ml20': !isMobile })}>
					{ this.renderPlanningSwitcher() }
				</div>
				<IconButton color="inherit" aria-label="Menu" onClick={this.openMenuUser}>
					<MoreVert />
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={anchorEl}
					open={anchorEl !== null}
					onClose={this.closeMenuUser}
				>
					<MenuItem onClick={this.openDisconnectConfirmation}>
						<ListItemIcon>
							<PowerSettingsNew />
						</ListItemIcon>
						<ListItemText inset primary={lexique.logout.title} />
					</MenuItem>
					<MenuItem onClick={this.reloadApp}>
						<ListItemIcon>
							<Refresh />
						</ListItemIcon>
						<ListItemText inset primary={lexique.refresh} />
					</MenuItem>
					<MenuItem onClick={null} disabled className="tar">
						<ListItemText className="tar" secondary={`Sprel ${VERSION}`} />
					</MenuItem>
				</Menu>
				<Dialog
					open={askLogout}
					TransitionComponent={ModalTransitionZoom}
					onClose={this.closeDisconnectConfirmation}
					aria-describedby="alert-dialog-logout-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-logout-description">
							{lexique.logout.confirmation}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDisconnectConfirmation} color="primary">
							{lexique.logout.cancel}
						</Button>
						<Button onClick={this.doLogout} color="primary">
							{lexique.logout.confirm}
						</Button>
					</DialogActions>
				</Dialog>
			</Toolbar>
		);
	}

	render() {
		const { logged } = this.props;
		if (!logged) return null;
		return (
			<div>
				<AppBar position="fixed">
					{ this.renderLogged() }
				</AppBar>
				<AppBar position="static" className="no-shadow">
					<Toolbar className="app-header">
						<Typography variant="h6" component="h1" color="inherit" className="flex1 main-header" />
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}


const mapStateToProps = ({env, user, ui, plannings}) => ({
	isAdmin: user.isAdmin,
	logged: env.logged,
	lexique: getLexique(env.locale).header,
	isMobile: ui.device.isMobile,
	selectedPlanning: plannings.selected,
	hasDefaultPlanning: user && user.hasAccessTo && user.hasAccessTo.default,
	defaultPlanning: plannings.default,
	otherPlannings: user && user.hasAccessTo && user.hasAccessTo.others ? user.hasAccessTo.others : [],
	currentPlanning: plannings[plannings.selected],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ doLogout, openLeftMenu, setPlanning, loadPlanning, openModale }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
