import store from 'store';
import _uniqBy from 'lodash.uniqby';
import moment from 'moment-timezone';

import { get, put, post, remove } from './index';

import mapper from '../mappers/planning';

export const loadPlanning = query => {
    const endpoint = `plannings/${query.id}`;
	return get({endpoint});
}

export const getPlanningPatients = async query => {

	// Loading practitioner patients
	const storedDatas = store.get(`sprel_${query.id}`) || [];
    const endpoint = `plannings/${query.id}/patients`;
	const res = await get({
		endpoint,
		params: {
			from: storedDatas && storedDatas.updated && storedDatas.users && storedDatas.users.length > 0 ? storedDatas.updated || null : null,
		}
	});

	const storedUsers = storedDatas ? storedDatas.users || [] : [];
	const allUsers = _uniqBy([...res.users, ...storedUsers], 'id');
	const toReturn = {
		...res,
		users: allUsers,
	}

	try {
		store.set(`sprel_${query.id}`, {
			updated: moment().format(),
			users: allUsers
		});
	} catch (e) {
		 // eslint-disable-next-line
		console.error("An error has occured while saving patients to localStorage:", e)
	}

	return toReturn;
}

export const loadPlanningDay = query => {
    const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	return get({endpoint});
}

export const loadPlanningDays = ({planningId, days}) => {
    const endpoint = `plannings/${planningId}/days`;
    const mapped = mapper.loadPlanningDays.toApi(days);

    return get({
		endpoint,
		params: mapped
	});
}

export const closePlanningDay = query => {
	const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	const mapped = mapper.closePlanningDay.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const openPlanningDay = query => {
	const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	const mapped = mapper.openPlanningDay.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const updateDayInformationMessage = query => {
	const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	const mapped = mapper.updateDayInformationMessage.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const updateDayNotes = query => {
	const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	const mapped = mapper.updateDayNotes.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const updateDaySpecificSchedules = query => {
	const endpoint = `plannings/${query.planningId}/days/${query.day}`;
	const mapped = mapper.updateDaySpecificSchedules.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const createPlanning = query => {
	const endpoint = `plannings`;
	const mapped = mapper.createPlanning.toApi(query);
	return post({
		endpoint,
		params: mapped
	});
}

export const createAppointmentType = query => {
	const endpoint = `plannings/${query.planningId}/appointments`;
	const mapped = mapper.createAppointmentType.toApi(query);
	return post({
		endpoint,
		params: mapped
	});
}

export const deleteAppointmentType = ({planningId, appointmentTypeId}) => {
	const endpoint = `plannings/${planningId}/appointments/${appointmentTypeId}`;
	return remove({
		endpoint,
	});
}

export const updateAppointmentType = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}`;
	const mapped = mapper.updateAppointmentType.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const updateAppointmentTypeProfile = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}/profile`;
	const mapped = mapper.updateAppointmentTypeProfile.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const deleteAppointmentTypeProfile = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}/profile`;
	return remove({
		endpoint
	});
}

export const addAppointmentTypeSpecificSchedule = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}/specific-schedules`;
	const mapped = mapper.addAppointmentTypeSpecificSchedule.toApi(query);
	return post({
		endpoint,
		params: mapped
	});
}

export const updateAppointmentTypeSpecificSchedule = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}/specific-schedules/${query.specificScheduleId}`;
	const mapped = mapper.updateAppointmentTypeSpecificSchedule.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const deleteAppointmentTypeSpecificSchedule = query => {
	const endpoint = `plannings/${query.planningId}/appointments/${query.appointmentTypeId}/specific-schedules/${query.specificScheduleId}`;
	return remove({
		endpoint
	});
}

export const updatePlanningConfig = query => {
	const endpoint = `plannings/${query.planningId}/configuration`;
	const mapped = mapper.updatePlanningConfig.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const updatePlanningProfile = query => {
	const endpoint = `plannings/${query.planningId}/profile`;
	const mapped = mapper.updatePlanningProfile.toApi(query);
	return put({
		endpoint,
		params: mapped
	});
}

export const getBillingInfos = planningId => {
	const endpoint = `plannings/${planningId}/billing`;
	return get({
		endpoint,
	});
}

export const addCreditCardInformation = ({planningId, ...query}) => {
	const endpoint = `plannings/${planningId}/billing/add-card`;
	const params = mapper.addCreditCardInformation.toApi(query);
	return post({
		endpoint,
		params,
	});
}

export const updatePlanPayment =  ({planningId, ...query}) => {
	const endpoint = `plannings/${planningId}/billing/update-payment`;
	const params = mapper.updatePlanPayment.toApi(query);
	return put({
		endpoint,
		params,
	});
}

export const addGlobalSpecificShedule = ({planningId, datas}) => {
    const endpoint = `plannings/${planningId}/specific-schedules`;
	const params = mapper.addGlobalSpecificShedule.toApi(datas);
	return post({
		endpoint,
		params,
	});
}

export const unblockSlotByRule = ({day, planningId, ...datas}) => {
    const endpoint = `plannings/${planningId}/days/${day}/unblock-slot`;
	const params = mapper.unblockSlotByRule.toApi(datas);
	return post({
		endpoint,
		params,
	});
}

export const getPaymentsInfos = planningId => get({
	endpoint: `plannings/${planningId}/payments`,
	catchErrors: [404],
});

export const registerToPayments = ({planningId, ...datas}) => post({
	endpoint: `plannings/${planningId}/payments`,
	params: mapper.registerToPayments.toApi(datas),
	catchErrors: [500],
});

export const uploadLegalsDocuments = ({planningId, ...datas}) => post({
	endpoint: `plannings/${planningId}/payments/documents-files`,
	params: mapper.uploadLegalsDocuments.toApi(datas),
	catchErrors: [500],
});

export const updateLegalsDocuments = ({planningId, ...datas}) => post({
	endpoint: `plannings/${planningId}/payments/documents`,
	params: mapper.updateLegalsDocuments.toApi(datas),
	catchErrors: [500],
});

export const createPayment = ({planningId, eventId, ...datas}) => post({
	endpoint: `plannings/${planningId}/appointments/${eventId}/payments`,
	params: mapper.createPayment.toApi(datas),
	catchErrors: [500, 422],
});

export const getPaymentsBalance = pId => get({
	endpoint: `plannings/${pId}/payments/balance`
});

export const getUnpaidPayments = pId => get({
	endpoint: `plannings/${pId}/payments/unpaid`
});

export const getPaymentsOverPeriod = ({planningId, ...datas}) =>  get({
	endpoint: `plannings/${planningId}/payments/search`,
	params: mapper.getPaymentsOverPeriod.toApi(datas),
});

export const getPaymentsByUser = ({planningId, userId}) =>  get({
	endpoint: `plannings/${planningId}/users/${userId}/payments`,
});

export const cancelPayment = ({planningId, id}) =>  remove({
	endpoint: `plannings/${planningId}/payments/${id}`,
});

export const updatePaymentIban = ({planningId, ...datas}) => put({
	endpoint: `plannings/${planningId}/payments`,
	params: mapper.updatePaymentIban.toApi(datas),
});