import moment from 'moment-timezone';
import { mapAppointments, mapUser } from './planning';


const getShortInfos = {
	fromApi: user => mapUser(user),
}

const getUserDetails = {
	fromApi: user => Object.assign({},
		mapUser(user),
		{
			appointments: user.appointments ? mapAppointments(user.appointments) : []
		}
	),
}

const searchUser = {
	fromApi: user => mapUser(user),
	toApi: email => ({email})
}

const createUser = {
	fromApi: ({uid, family_member_id}) => ({uid, familyMemberId: family_member_id}),
	toApi: ({search, firstName, lastName, gender, birthdate, phoneNumber}) => ({
		first_name: firstName,
		email: search,
		last_name: lastName,
		phone_number: phoneNumber,
		birthdate: moment(birthdate).format(),
		gender
	}),
}

const sendMailing = {
	toApi: ({subject, body}) => ({ subject, body})
}

export default {
	getShortInfos,
	getUserDetails,
	searchUser,
	createUser,
	sendMailing
};
