import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import ButtonCircularProgress from './ui/ButtonCircularProgress';
import ModalTransitionZoom from './ui/ModalTransitionZoom';
import Message from './ui/Message';
import TermsContent from './terms/TermsContent';

import history from '../config/history';
import getLexique from '../locales';
import { doLogin, acceptTerms } from '../actions/user';
import { isEmail } from '../libs/validators';

import logo from '../assets/logo.png';

class Login extends PureComponent {

	state = {
		expanded: false,
		loginEmail: this.props.email,
		loginPassword: '',
		loadingConnection: false,
		errorConnection: [],
		shouldAcceptCGU: false,
		loadingCGUAcceptance: false,
	}

	handleChange = ({target:{name, value}}) => {
		this.setState({[name]: value})
	}


	handleConnection = async () => {
		const { loginEmail, loginPassword } = this.state;
		const { doLogin } = this.props.actions;

		let errors = [];

		if (loginEmail === '' || loginPassword === '') {
			errors = [loginEmail === '' ? 'emailEmpty' : null, loginPassword === '' ? 'passwordEmpty' : null]
		}

		if (loginEmail !== '' && !isEmail(loginEmail)) {
			errors = ['emailInvalid'];
		}

		if (errors.length > 0) {
			this.setState({errorConnection: errors});
			return;
		}

		this.setState({
			loadingConnection: true,
			errorConnection: [],
		});

		const res = await doLogin({
			email: loginEmail,
			password: loginPassword,
		});

		this.setState({ loadingConnection: false })
		if (res.error) {
			if (res.code === 'require-terms-acceptance') {
				this.setState({shouldAcceptCGU: true});
			}
			else {
				this.setState({
					errorConnection: [res.status.toString()],
				});
			}
		}
		else {
			history.replace('/');
		}
	}

	acceptTerms = async () => {
		this.setState({loadingCGUAcceptance: true});
		await this.props.actions.acceptTerms();
		history.replace('/');
	}

	handleKeyPressConnection = e => {
		if (e.key === 'Enter') this.handleConnection();
	}

	render() {
		const { loginEmail, loginPassword, loadingConnection, errorConnection, shouldAcceptCGU, loadingCGUAcceptance } = this.state;
		const { lexique } = this.props;

		return (
			<div className="box box--login">
				<div className="logo-login tac mb20 prel">
					<div className="prel d-ib">
						<img src={logo} alt="Sprel Logo" />
						<div className="logo-login-pro">PRO</div>
					</div>
				</div>
				<Card>
					<div className="box_header box_header--primary">
						<Typography variant="h4" component="h2">{lexique.title}</Typography>
					</div>
					<CardContent>
						<TextField
							autoFocus={loginEmail === ''}
							required
							type="email"
							error={errorConnection.indexOf('emailEmpty') > -1 || errorConnection.indexOf('emailInvalid') > -1}
							disabled={loadingConnection}
							id="login-email"
							name="loginEmail"
							label={lexique.email}
							value={loginEmail}
							onChange={this.handleChange}
							margin="normal"
							variant="outlined"
							fullWidth
							helperText={loginEmail !== '' && errorConnection.indexOf('emailInvalid') > -1 ? lexique.errors.emailInvalid : ''}
							onKeyPress={this.handleKeyPressConnection}
						/>
						<TextField
							required
							autoFocus={loginEmail !== ''}
							error={errorConnection.indexOf('passwordEmpty') > -1}
							disabled={loadingConnection}
							type='password'
							id="login-password"
							name="loginPassword"
							label={lexique.password}
							value={loginPassword}
							onChange={this.handleChange}
							margin="normal"
							variant="outlined"
							fullWidth
							onKeyPress={this.handleKeyPressConnection}
						/>
						<Message level="error" show={errorConnection.indexOf('403') > -1}>{lexique.errors['403-login']}</Message>
					</CardContent>
					<CardActions className="tar">
						<Button onClick={this.handleConnection} disabled={loadingConnection} variant="contained" size="large" color="primary" disableElevation>
							{lexique.login}
							{loadingConnection && <ButtonCircularProgress />}
						</Button>
					</CardActions>
				</Card>
				<Dialog
					open={shouldAcceptCGU}
					onClose={null}
					TransitionComponent={ModalTransitionZoom}
					disableBackdropClick
					disableEscapeKeyDown
				>
					<DialogTitle>{lexique.cgu.title}</DialogTitle>
					<DialogContent>
						<TermsContent />
					</DialogContent>
					<DialogActions>
						<Button color="primary" disabled={loadingCGUAcceptance} onClick={this.acceptTerms}>
							{lexique.cgu.accept}
							{loadingCGUAcceptance && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = ({env, user}) => ({
	lexique: getLexique(env.locale).login,
	email: user ? user.email || '' : '',
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ doLogin, acceptTerms }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
