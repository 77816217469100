import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _sortBy from 'lodash.sortby';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Schedule from '@material-ui/icons/Schedule';
import LocationOn from '@material-ui/icons/LocationOn';
import Phone from '@material-ui/icons/Phone';
import VisioIcon from '@material-ui/icons/VideocamOutlined';

import { militaryToHuman } from '../../libs/formatters';
import getLexique, { _lp } from '../../locales';

import { closeAppointmentQuickView, closeDuplicationDrawer } from '../../actions/ui';

class AppointmentsTypes extends PureComponent {

	static contextTypes = {
		swipeableViews: PropTypes.object.isRequired,
	};

	state = { expand: false, anchorMenu: null, expandedSchedule: null };

	getCurrentSchedule = () => {
		const { lexique, appointment } = this.props;

		// Check for specific period;
		const period = this.getSpecificPeriodSchedule(appointment)
		if (period) {
			if (period === '') return lexique.notWorked;
			return militaryToHuman(period);
		}

		// No specific period, return default
		const regular = this.getDefaultSchedule(appointment);
		if (regular === '') return lexique.notWorked;
		return militaryToHuman(regular);
	}

	getSpecificPeriod = () => {
		const { appointment } = this.props;
		const date = moment();

		if (!appointment.specificSchedules) return null;

		// Check for specific period;
		const period = appointment.specificSchedules.find(a => moment(a.from).isSameOrBefore(date) && moment(a.to).isSameOrAfter(date));
		if (period) return period;

		return null;
	}

	getDefaultSchedule = () => {
		const { appointment } = this.props;
		return appointment.schedules;
	}

	handleExpand = () => {
		this.setState({expand: !this.state.expand});
		this.startRefreshSwiperHeight();
	}

	closeActionMenu = () => {
		this.setState({anchorMenu: null});
	}

	openActionMenu = e => {
		this.setState({anchorMenu: e.target});
	}

	refreshSwiperHeight = () => {
		if (!this.refresh) return;
		this.context.swipeableViews.slideUpdateHeight();
		window.requestAnimationFrame(this.refreshSwiperHeight);
	}

	startRefreshSwiperHeight = () => {
		this.refresh = true;
		window.requestAnimationFrame(this.refreshSwiperHeight);
		setTimeout(() => this.refresh = false, 500);
	}

	handleExpandSpecificSchedule = panel => (e, expanded) => {
		this.setState({
			expandedSchedule: expanded ? panel : null,
		});
		this.startRefreshSwiperHeight();
	}

	render() {
		const { appointment, lexique, selectedPlanning, lGlobal, config, onEdit, onDelete, onEditProfile, onEditSpecificSchedule, onDeleteSpecificSchedule } = this.props;
		const { expand, anchorMenu, expandedSchedule } = this.state;

		const configPlanning = selectedPlanning.configuration;
		const daysInWeek = !configPlanning.showWeekEnd ? 5 : (configPlanning.showSunday ? 7 : 6);

		const specificSchedules = this.getSpecificPeriod();
		const schedules = specificSchedules ? specificSchedules.schedules : this.getDefaultSchedule();

		return (
			<Card className="appointment-type-item">
				<CardHeader
					avatar={
						<Avatar style={{backgroundColor: appointment.color}}>
							{ appointment?.isVisio ? <VisioIcon /> : appointment.label[0] }
						</Avatar>
					}
					title={appointment.label}
            		subheader={_lp(lexique[appointment.activeForUser ? 'allowedByUser' : 'notAllowedByUser'])}
					action={
						<IconButton onClick={this.openActionMenu}>
							<MoreVertIcon />
						</IconButton>
					}
				/>
				<Menu
					id="simple-menu"
					anchorEl={anchorMenu}
					open={anchorMenu !== null}
					onClose={this.closeActionMenu}
					onClick={this.closeActionMenu}
				>
					<MenuItem onClick={onEdit}>
						<ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.edit} />
					</MenuItem>
					<Divider />
					<MenuItem onClick={onDelete}>
						<ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.delete} />
					</MenuItem>
				</Menu>
				<CardContent>
					{ specificSchedules && <Typography variant="caption">
						{lexique.period.replace('{0}', moment(specificSchedules.from).format(config.dateFormatLongNoDay)).replace('{1}', moment(specificSchedules.to).format(config.dateFormatLongNoDay))}
					</Typography> }
					{
						<SummarySchedules
							className="schedules-container--margin"
							daysInWeek={daysInWeek}
							lGlobal={lGlobal}
							schedules={schedules}
							lexique={lexique}
						/>
					}
				</CardContent>
				<CardActions disableSpacing className="flex">
					{appointment.profile && <LocationOn className="ico--grey" />}
					{appointment.specificSchedules && appointment.specificSchedules.length > 0 && (
						<Badge color="secondary" badgeContent={appointment.specificSchedules.length}>
							<Schedule className="ico--grey" />
						</Badge>
					 )}
					<IconButton
						style={{
							marginLeft: 'auto'
						}}
						className={cn('button-expand', {
							'button-expand--expanded': expand
						})}
						onClick={this.handleExpand}
						aria-expanded={expand}
						aria-label="Show more"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expand} timeout="auto" unmountOnExit>
					<CardContent>
						{ /* Specific Schedules */}
						<Typography variant="body1" className="mb10">
							{lexique.specificSchedules}
						</Typography>
						<div className="appointment-specific-schedules">
							{
								!appointment.specificSchedules || appointment.specificSchedules.length === 0 ? <Typography variant="caption">{lexique.noSpecificSchedule}</Typography> : <Fragment>
									{ _sortBy(appointment.specificSchedules, 'fromUnix').map(sc => (
										<ExpansionPanel
											key={sc.id}
											expanded={expandedSchedule === sc.id}
											onChange={this.handleExpandSpecificSchedule(sc.id)}
										>
											<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
												<Typography>{lexique.periodShort.replace('{0}', moment(sc.from).format(config.dateFormatLongNoDay)).replace('{1}', moment(sc.to).format(config.dateFormatLongNoDay))}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<SummarySchedules
													className="schedules-container--margin w100"
													daysInWeek={daysInWeek}
													lGlobal={lGlobal}
													schedules={sc.schedules}
													lexique={lexique}
												/>
											</ExpansionPanelDetails>
											<ExpansionPanelActions>
												<Button
													color="primary"
													onClick={onDeleteSpecificSchedule(sc.id)}
												>
													{lexique.deleteSpecificSchedule}
												</Button>
												<div className="flex1" />
												<Button
													color="primary"
													onClick={onEditSpecificSchedule(sc.id)}
												>
													{lexique.editSpecificSchedule}
												</Button>
											</ExpansionPanelActions>
										</ExpansionPanel>
									)) }
								</Fragment>
							}
						</div>
						<Button onClick={onEditSpecificSchedule(null)} className="mt10" color="primary" size="small" fullWidth>{lexique.addSpecificSchedule}</Button>
						<Divider className="mt10 mb10" />
						{ /* Specific Profile */}
						<Typography variant="body1" className="mb10">
							{lexique.specificProfile}
						</Typography>
						{!appointment.profile && <Fragment>
							<Typography variant="caption">{lexique.noProfile}</Typography>
							<Button onClick={onEditProfile} className="mt10" color="primary" size="small" fullWidth>{lexique.addProfile}</Button>
						</Fragment>}
						{appointment.profile && <Fragment>
							<Typography><LocationOn className="ico--inline ico--grey" /> {appointment.profile.address}</Typography>
							<Typography className="mt10"><Phone className="ico--inline ico--grey" /> {appointment.profile.phoneNumber}</Typography>
							<Button onClick={onEditProfile} className="mt10" color="primary" size="small" fullWidth>{lexique.editProfile}</Button>
						</Fragment>}
					</CardContent>
				</Collapse>
			</Card>
		);
	}
}

const SummarySchedules = ({daysInWeek, lGlobal, schedules, lexique, className}) => (
	<div className={`schedules-container ${className || ''}`}>
		{
			Array(daysInWeek).fill().map((v, i) => (
				<div className="flex aic" key={lGlobal.days[i]}>
					<div className="w33 padl10">
						<Typography variant="body1" noWrap>{ lGlobal.days[i] }</Typography>
					</div>
					<div className="flex1">
						<Typography color="textSecondary">{
							schedules[i] !== '' ? militaryToHuman(schedules[i]) : lexique.scheduleClosed
						}</Typography>
					</div>
				</div>
			)
		)}
	</div>
)

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.appointmentType,
	config: getLexique(env.locale).config,
	selectedPlanning: plannings[plannings.selected],
	lGlobal: getLexique(env.locale).global,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeAppointmentQuickView, closeDuplicationDrawer }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsTypes);
