import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Markdown from '../ui/Markdown';

import { closeModaleEditDayNotes } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';


class EditDayNotes extends PureComponent {

	state = { value: '' };

	onClose = () => {
		this.props.actions.closeModaleEditDayNotes();
	}

	mapPropsToInnerState = () => {
		const { day } = this.props;
		if (!day) return;
		this.setState({
			value: day.params && day.params.note ? day.params.note : '',
		});
	}

	componentDidMount() {
		const { day } = this.props;
		if (!day) return;
		this.mapPropsToInnerState();
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.day || (oldProps.day.start !== this.props.day.start) || (!oldProps.show && this.props.show)) this.mapPropsToInnerState();
	}

	onChange = ({target:{value}}) => this.setState({value});

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm({
			value: this.state.value,
		});
	}

	onRemove = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm({
			value: '',
		});
	}

	renderInner() {
		const { lexique, config, day, isMobile } = this.props;

		if (!day) return <div />;

		const { value } = this.state;

		return (
			<Fragment>
				<DialogTitle id="modale-day-note-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Typography component="div">
						<Markdown>
							{_lp(lexique.body.replace('{0}', moment(day.day).format(config.dateFormatLong)))}
						</Markdown>
					</Typography>

					<TextField
						autoFocus
						multiline
						rows={4}
						id="day-notes"
						label={lexique.label}
						value={value}
						onChange={this.onChange}
						fullWidth
						variant="outlined"
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onRemove} color="primary">
						{isMobile ? lexique.removeShort : lexique.remove}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show, isMobile } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-day-note-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{device:{isMobile}, modales:{editDayNotes:{show, onConfirm, day}}}}) => ({
	lexique: getLexique(env.locale).modales.editDayNotes,
	config: getLexique(env.locale).config,
    show,
	onConfirm,
	day,
	isMobile
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleEditDayNotes }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDayNotes);
