import React, { memo } from 'react';
import { Tooltip } from 'react-tippy';
import { PictureInPictureAlt as MinimizeIcon, Fullscreen as MaximizeIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toggleMinifyVisio } from '../../../actions/ui';
import { useLexique } from '../../../locales';
import { useRoomState } from '../hooks';


const ToggleMinify = ({minified}) => {

    const dispatch = useDispatch();
    const lexique = useLexique('visio');
    const roomState = useRoomState();

    if (roomState === 'disconnected') return null;

    return (
        <div className="visio_toggle-minify">
            <Tooltip position="left" title={lexique[minified ? "maximize" : 'minimize']}>
                <IconButton onClick={() => dispatch(toggleMinifyVisio())}>
                    {minified ? <MaximizeIcon /> : <MinimizeIcon />}
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default memo(ToggleMinify);