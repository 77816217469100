import { createAction} from 'redux-actions';
import Cookies from 'universal-cookie';
import store from 'store';

import { setPlanning } from './planning';

import { COOKIES } from '../config/env';
import * as USER from '../constants/user';
import * as api from '../api/user';
import mapper from '../mappers/user';
import { setUser, unsetUser } from '../libs/sentry';
import { VERSION } from '../version';

const doLoginRequest = createAction(USER.LOGIN_REQUEST);
const doLoginSuccess = createAction(USER.LOGIN_SUCCESS);

const getUserInfosRequest = createAction(USER.INFOS_REQUEST);
const getUserInfosSuccess = createAction(USER.INFOS_SUCCESS);
export const setUserToken = createAction(USER.SET_TOKEN);
export const setUserEmail = createAction(USER.SET_EMAIL);
const disconnectUserSuccess = createAction(USER.DISCONNECT_SUCCESS);
export const setUserInitialParams = createAction(USER.INIT_PARAMS);

const setUserContext = ({user, initialParams}) => {

	const getVersion = () => {
		const paramsUrl = initialParams;
		const params = new URLSearchParams(paramsUrl);
		if (params.has("soft")) return `${params.get("soft")} ${params.get('v')}`;

		return "web";
	}

	// Raven
	setUser({
		email: user.email,
		id: user.id,
		plateform: getVersion(),
		version: VERSION,
	});
}

const unsetUserContext = () => {
	// Raven
	unsetUser();
}

export const doLogin = infos => async dispatch => {

	dispatch(doLoginRequest());

	const res = await api.doLogin(infos);

	if (!res.error) {

		// Success !
		const mapped = mapper.doLogin.fromApi(res);
		// We set token in redux and save it to cookies
		const cookies = new Cookies();
		cookies.set(COOKIES.email, infos.email);
		await dispatch(setUserToken(mapped.token));

		// Time to get current user infos
		const gu = await dispatch(getUserInfos());

		return gu;
	}
	else {
		// Handle Errors
		return res;
	}
}

export const acceptTerms = () => async dispatch => {
	await api.acceptTerms();
	const res = await dispatch(getUserInfos());
	return res;
}

export const getUserInfos = () => async (dispatch, getState) => {
	dispatch(getUserInfosRequest());

	const { env, user } = getState();

	// Checking if we have pushToken & device passed as url params, so we have to save them
	const paramsUrl = user.initialParams;
	const params = new URLSearchParams(paramsUrl);
	if (params.has('pushToken') && params.has('device')) {
		// We are in mobile app, we need to register it. No need to await thought
		const deviceString = decodeURIComponent(params.get('device'));
		const device = JSON.parse(deviceString);

		api.manageDevice({
			pushToken: params.get('pushToken'),
			...device
		});
	}

	const res = await api.getUser();

	if (res && !res.error) {
		const mapped = mapper.getUser.fromApi(res);

		setUserContext({
			user: mapped,
			token: env.token,
			initialParams: user.initialParams
		});
		dispatch(getUserInfosSuccess(mapped));
		dispatch(doLoginSuccess());

		const defaultPlanning = mapped.hasAccessTo.default || mapped.hasAccessTo.others[0].id;
		dispatch(setPlanning(defaultPlanning));
		return true;
	}
	else {
		return res;
	}
}

export const doLogout = () => dispatch => {
	api.doLogout();

	store.clearAll();

	unsetUserContext();
	dispatch(disconnectUser());

	return true;
}

export const disconnectUser = () => dispatch => {
	dispatch(disconnectUserSuccess());
}
