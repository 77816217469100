import React, { memo } from 'react';

import Filters from './logs/Filters';
import Logs from './logs/Logs';

export default memo(() => (
	<div className="logs">
		<Filters />
		<Logs />
	</div>
));
