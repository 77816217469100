import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import { closeModale } from '../../../actions/ui';
import { createPlanning } from '../../../actions/planning';

import getLexique from '../../../locales';
const fields = ['particule', 'firstName', 'lastName', 'phoneNumber', 'email'];

class AddPractitioner extends PureComponent {

	state = { loading: false };

	onClose = () => {
		this.props.actions.closeModale({
			modale: 'adminAddPractitioner',
		})
	}

	onConfirm = async () => {
		this.setState({loading: true});
		await this.props.actions.createPlanning(this.state);
		this.setState({loading: false});
		this.setState(fields.reduce((p, c) => ({...p, [c]: ''}) , {}));
		this.onClose();
	}

	updateField = field => ({target:{value}}) => {
		this.setState({[field]: value});
	}

    render() {
		const { show, lexique } = this.props;
		const { loading } = this.state;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-day-note-dialog-title"
				disableBackdropClick={loading}
				disableEscapeKeyDown={loading}
			>
				<DialogTitle>{lexique.title}</DialogTitle>
				<DialogContent>
					{ fields.map(field => (
						<TextField
							disabled={loading}
							key={field}
							label={lexique.fields[field]}
							value={this.state[field] || ''}
							onChange={this.updateField(field)}
							fullWidth
							margin="normal"
						/>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary" disabled={loading}>
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" disabled={loading}>
						{lexique.confirm}
						{loading && <ButtonCircularProgress />}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{adminAddPractitioner:{show}}}}) => ({
	lexique: getLexique(env.locale).modales.adminAddPractitioner,
	config: getLexique(env.locale).config,
    show,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModale, createPlanning }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPractitioner);
