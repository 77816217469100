import React, { memo } from 'react';
import { useLexique } from '../../locales';

import Alert from '../ui/Alert';

const WaitingForApproval = () => {

    const lexique = useLexique("payments.waiting");

    return (
        <Alert severity="warning" title={lexique.title} md content={lexique.body} />
    )

}

export default memo(WaitingForApproval);