import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, Button, CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';

import { closeModale } from '../../actions/ui';
import { useSelector, useDispatch } from '../../libs/redux';
import { useCoolState } from '../../hooks/react';
import { usePlanning } from '../../hooks/planning';
import { createPayment } from '../../actions/planning';
import { useLexique } from '../../locales';
import Markdown from '../ui/Markdown';
import Alert from '../ui/Alert';
import { PaymentItem } from './PaymentsManage';

const CreatePayment = () => {

    const datas = useSelector(({ui}) => ui.modales.createPayment);
    const dispatch = useDispatch();

    const [state, setState, _setState] = useCoolState({
        amount: "",
        realAmount: 0,
        fees: 0,
        error: false,
        confirm: false,
        loading: false,
        alreadyExists: null,
    });

    const [planning] = usePlanning();

    const _onSubmitFirst = useCallback(() => {

        const amountFormatted = state.amount.replace(',', '.');
        const amount = parseFloat(amountFormatted, 10);
        const isError = isNaN(amount) || amount < 10;
        setState({
            error: isError,
            confirm: !isError,
            realAmount: amount,
            fees: Math.ceil(amount*100*0.04)/100,
        });

    }, [setState, state]);


    const eventId = datas?.event?.id;
    const planningId = planning?.id;
    const _onSubmitPayment = useCallback(async() => {

        if (!eventId) return;

        setState({
            loading: true,
        });

        const res = await dispatch(createPayment({
            amount: state.realAmount*100,
            planningId,
            eventId,
            force: state.alreadyExists && state.alreadyExists.length > 0,
        }));

        setState({
            loading: false,
            confirm: false,
            alreadyExists: null,
        });

        if (!res.error) {
            dispatch(closeModale({
                modale: 'createPayment'
            }));
        }
        else {
            if (res.status === 422) {
                setState({
                    confirm: true,
                    alreadyExists: res.payments
                });
            }
        }

    }, [state.realAmount, eventId, planningId, dispatch, setState, state.alreadyExists]);

    const name = (() => {

        if (!planning) return null;
        if (!datas.event) return;

        return `${datas.event.with.firstName} ${datas.event.with.lastName}`;

    })();

    const typeDetails = useMemo(() => {

        if (!planning) return null;
        if (!datas.event) return;

        const type = planning.appointmentsTypes.find(a => a.id === datas.event.typeId);

        return type;

    }, [planning, datas.event]);

    const _onChangeAmount = useCallback(({target:{value}}) => {
        setState({amount: value, confirm: false, error: false, alreadyExists: null,});
    }, [setState]);

    const defaultAmount = typeDetails?.defaultPaymentAmount;
    const typeLabel = typeDetails?.label;
    const start = datas.event?.start || new Date();

    useEffect(() => {

        if (!datas.show) return;
        // Set default amount by appointment type
        setState({amount: defaultAmount || "25"});

    }, [datas.show, defaultAmount, setState])

    const _onClose = useCallback(() => {
        setState({
            loading: false,
            confirm: false,
            error: false,
            alreadyExists: null,
        });
        dispatch(closeModale({
            modale: 'createPayment'
        }));
    }, [dispatch, setState]);

    const _onCancelId = useCallback(id => {

        _setState(state => ({
            ...state,
            alreadyExists: state.alreadyExists.filter(a => a.id !== id)
        }));

    }, [_setState]);

    const lexique = useLexique('payments.create');
    const config = useLexique('config');

    const existingPayments = state.alreadyExists && state.alreadyExists.length > 0;

    return (
        <Dialog open={datas.show} onClose={_onClose} fullWidth maxWidth="sm" disableBackdropClick={state.loading} disableEscapeKeyDown={state.loading}>
            <DialogTitle>{lexique.title.replace('{0}', name || '')}</DialogTitle>
            <DialogContent>
                <Markdown>
                    {lexique.amount.replace('{0}', typeLabel).replace('{1}', moment(start).format(config.datetimeFormatLong))}
                </Markdown>
                <TextField
                    className="payments_create"
                    value={state.amount}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={_onChangeAmount}
                    disabled={state.loading}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                />
                {state.error && <Alert severity="error" content={lexique.error} />}
                {state.confirm && !existingPayments && <Alert severity="info" md title={lexique.confirm.title} content={lexique.confirm.body.replace('{0}', state.realAmount).replace('{1}', name || '').replace('{2}', state.fees)} />}
                {existingPayments&& <Alert severity="warning" md title={lexique.alreadyExisting.title} content={lexique.alreadyExisting.body} />}
                {state.alreadyExists && <div className="payments_alread_existing">
                    {state.alreadyExists.map(i => <PaymentItem key={i.id} {...i} short onCanceled={_onCancelId} />)}
                </div>}
            </DialogContent>
            <DialogActions>
                <Button disabled={state.loading} onClick={_onClose}>{lexique.cancel}</Button>
                {!state.confirm && <Button variant="contained" disableElevation size="large" color="primary" onClick={_onSubmitFirst}>{lexique.cta}</Button>}
                {state.confirm && <Button disabled={state.loading} variant="contained" disableElevation size="large" color="primary" onClick={_onSubmitPayment}>
                    {existingPayments ? lexique.alreadyExisting.cta : lexique.confirm.cta}
                    {state.loading && <CircularProgress className='ml5' size={15} />}
                </Button>}
            </DialogActions>
        </Dialog>
    );

}

export default memo(CreatePayment);