import React, { memo, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { getMessage } from '../../../actions/messages';

import Message from '../../ui/Message';
import getLexique from '../../../locales';

const Desktop = memo(({lexique, active, messages, config, actions, activeId}) => (
	<div className="inbox-list-emails">
		{active === 'deleted' &&
		<div className="pad20">
			<Message level="warning" show>
				{lexique.messagesDeletion}
			</Message>
		</div>
		}
		{messages.length === 0 && <Typography variant="body2">
			{lexique.noMessage}
		</Typography>}
		{messages.map(message => (
			<Fragment key={message.id}>
				<Button
					onClick={() => actions.getMessage(message.id)}
					className={cn('inbox-message-button', {
						'button--active': message.id === activeId,
					})}
					color={message.id === activeId ? "primary" : (!message.openedAt ? "secondary" : "inherit")}
				>
					<Typography variant="caption">
						{moment(message.sentAt).format(config.datetimeFormatLong)}
					</Typography>
					<Typography variant="h6" className="inbox-message-button-title">
						{message.name}
					</Typography>
					<Typography variant="subtitle1">
						{message.subject}
					</Typography>
				</Button>
				<Divider />
			</Fragment>
		))}
	</div>
));


const mapStateToProps = ({env, plannings, message}) => ({
	lexique: getLexique(env.locale).inbox.list,
	config: getLexique(env.locale).config,
	active: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.active : '',
	activeId: message.id,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getMessage }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
