const setPassword = {
	toApi: ({hash}) => ({ hash }),
}

const retrieveFiles = {
	fromApi: ({files}) => ({
		files: files.map(file => {
			const [folder, filename] = file.filename.split('/');
			const splitPoints = filename.split('.');
			const ext = splitPoints[splitPoints.length-1].toLowerCase();
			return {
				filename,
				folder,
				ext,
				type: file.type,
				size: parseInt(file.size, 10),
			}
		})
	}),
}


const getDriveSpace = {
	fromApi: ({space_used}) => space_used,
}

export default {
	setPassword,
	retrieveFiles,
	getDriveSpace,
};
