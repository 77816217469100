import { createAction} from 'redux-actions';
import { toast } from 'react-toastify';

import mapper from '../mappers/messages';
import * as api from '../api/messages';
import getLexique from '../locales';
import * as MESSAGES from '../constants/messages';

export const addMessageToStore = createAction(MESSAGES.RECEIVED_NEW);
const changeStoreMessageFolder = createAction(MESSAGES.CHANGE_FOLDER);
const getMessageRequest = createAction(MESSAGES.GET_REQUEST);
const getMessageComplete = createAction(MESSAGES.GET_COMPLETE);
export const resetDisplayedMessage = createAction(MESSAGES.RESET);
const updateMessageStateStore = createAction(MESSAGES.UPDATE_STATE);
const setMessageUnreadStore = createAction(MESSAGES.SET_UNREAD);
const addReplyToMessage = createAction(MESSAGES.ADD_REPLY);
const removeForeverStore = createAction(MESSAGES.REMOVE);
const loadMessagesComplete = createAction(MESSAGES.GET_ALL_COMPLETE)

export const changeMessageFolder = folder => (dispatch, getState) => {

	const { selected } = getState().plannings;

	dispatch(changeStoreMessageFolder({
		planningId: selected,
		folder,
	}));
}


export const getMessage = id => async (dispatch, getState) => {

	const { selected } = getState().plannings;

	dispatch(getMessageRequest());
	const res = await api.getMessage({
		planningId: selected,
		messageId: id
	});

	const mapped = mapper.getMessage.fromApi(res);
	dispatch(getMessageComplete({
		...mapped,
		planningId: selected,
	}));
}

const updateMessageState = state => async (dispatch, getState) => {
	const { plannings: {selected}, message: {id} } = getState();

	const query = {
		planningId: selected,
		messageId: id,
		state,
	};

	await api.updateMessageState(query);
	dispatch(updateMessageStateStore(query));
	return true;
}

const setMessageUnread = () => async (dispatch, getState) => {
	const { plannings: {selected}, message: {id} } = getState();

	const query = {
		planningId: selected,
		messageId: id,
	};

	await api.setMessageUnread(query);
	dispatch(setMessageUnreadStore(query));
	return true;
}

export const archive = () => async (dispatch, getState) => {
	await dispatch(updateMessageState("archived"));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.info(lexique.archived, {autoClose:2000});
	return true;
}

export const unarchive = () => async (dispatch, getState) => {
	await dispatch(updateMessageState("active"));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.info(lexique.unarchived, {autoClose:2000});
	return true;
}

export const unarchiveAndMarkAsUnread = () => async (dispatch, getState) => {
	await dispatch(updateMessageState("active"));
	await dispatch(setMessageUnread());

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.info(lexique.unarchivedAndMarkedAsUnread, {autoClose:2000});
	return true;
}

export const markAsUnread = () => async (dispatch, getState) => {
	await dispatch(setMessageUnread());

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.info(lexique.markedAsUnread, {autoClose:2000});
	return true;
}

export const remove = () => async (dispatch, getState) => {
	await dispatch(updateMessageState("deleted"));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.error(lexique.removed, {autoClose:2000});
	return true;
}

export const removeForever = () => async (dispatch, getState) => {
	const { plannings: {selected}, message: {id}, env } = getState();

	const query = {
		planningId: selected,
		messageId: id,
	};

	await api.removeForever(query);

	// Dispatch to store
	dispatch(removeForeverStore(query));

	const lexique = getLexique(env.locale).toasts.messages;
	toast.error(lexique.removedForever, {autoClose:2000});
	return true;
}

export const restore = () => async (dispatch, getState) => {
	await dispatch(updateMessageState("active"));

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.messages;
	toast.success(lexique.restored, {autoClose:2000});
	return true;
}

export const send = query => async (dispatch, getState) => {
	const { plannings: {selected}, env } = getState();

	await api.send({
		...query,
		planningId: selected
	});

	if (query.originalMessageId) {
		dispatch(addReplyToMessage({
			subject: query.subject,
			body: query.body,
			hasAttachment: query.attachment ? true : false,
		}));
	}

	const lexique = getLexique(env.locale).toasts.messages;
	toast.success(lexique.sent, {autoClose:2000});

	return true;
}

export const loadMessages = () => async (dispatch, getState) => {
	const { selected } = getState().plannings;
	const res = await api.loadMessages({planningId: selected});
	const messages = mapper.loadMessages.fromApi(res);
	dispatch(loadMessagesComplete({planningId: selected, messages}));
}
