import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { bindActionCreators } from 'redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import PaymentIcon from '@material-ui/icons/Payment';
import CheckCircle from '@material-ui/icons/CheckCircle';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import FormAddCard from './FormAddCard';

import Markdown from '../ui/Markdown';
import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import { addCreditCardInformation, updatePlanPayment } from '../../actions/planning';

import getLexique from '../../locales';
import { config } from '../../config/env';


const stripePromise = loadStripe(config.stripeKey);

class Payment extends PureComponent {

	state = { showModal: false, loadingAddCard: false, showModalError: false, errorType: "" };

	showModalAddCard = () => this.setState({showModal: true});
	hideModalAddCard = () => this.setState({showModal: false});

	hideModalError = () => this.setState({showModalError: false});

	selectPayment = p => async () => {
		const { plan, creditCard } = this.props;
		const currentPlan = plan && creditCard ? plan.type || "manual" : "manual";
		if (p === currentPlan) return;

		if (!plan || !plan.id || plan.id === "") {
			this.setState({showModalError: true, errorType: "plan"});
			return;
		}

		if (p === "auto" && !creditCard) {
			this.setState({showModalError: true, errorType:"card"});
			return;
		}

		// Change plan payment
		await this.props.actions.updatePlanPayment(p);
	}

	onSubmitCard = () => this.setState({loadingAddCard: true});

	onCardDatas = async datas => {
		if (!datas.source || datas.type === "error") {
			this.setState({loadingAddCard: false});
			return true;
		}

		const { card, id } = datas.source;
		if (!card || !id) {
			this.setState({loadingAddCard: false});
			return true;
		}

		// We have card datas > We link the card
		await this.props.actions.addCreditCardInformation(datas.source);
		this.hideModalAddCard();
		this.setState({loadingAddCard: false});
		return true;
	}

	renderCreditCard = () => {
		const { creditCard, lexique, isMobile } = this.props;

		if (!creditCard || !creditCard.brand) return <div className="flex aic">
			<div className="flex1">{ lexique.noCard }</div>
			<Button onClick={this.showModalAddCard} color="primary" variant="contained" disableElevation>{lexique.addCard}</Button>
		</div>

		return <Fragment>
			<div className="flex aic">
				<PaymentIcon className="mr10" />
				<div className="flex1">
					<Markdown>
					{
						lexique.card
						  .replace('$1', creditCard.brand)
						  .replace('$2', creditCard.last4)
						  .replace('$3', creditCard.expMonth)
						  .replace('$4', creditCard.expYear)
					}
					</Markdown>
				</div>
			</div>
			<div className="flex aic mt10">
				<div className="flex1" />
				<Button fullWidth={isMobile} color="primary" variant="contained" disableElevation onClick={this.showModalAddCard}>{lexique.updateCard}</Button>
			</div>
		</Fragment>
	}

	renderPlan = () => {
		const {lexique, creditCard, plan} = this.props;

		const currentPlan = plan && creditCard ? plan.type || "manual" : "manual";

		return <div className="account_plans">
			{["auto", "manual"].map(cp => <button className={cn('account_plan', {'plan--active': currentPlan === cp})} key={cp} onClick={this.selectPayment(cp)}>
				<Typography variant="h6">
					{lexique.plans[cp].title}
				</Typography>
				<Typography variant="body2" className="mt10">
					{lexique.plans[cp].desc}
				</Typography>
				{currentPlan === cp && <div className="ico"><CheckCircle /></div>}
			</button>)}
		</div>
	}

	render() {
		const { lexique, isMobile } = this.props;
		const { showModal, loadingAddCard, showModalError, errorType } = this.state;
		return (
			<Paper className="account_widget credit-card pad20 tac">
				<Typography variant="h5" className="widget_title">{lexique.title}</Typography>
				<div className="mt20">
					{ this.renderPlan() }
				</div>
				<div className="mt20">
					{ this.renderCreditCard() }
				</div>
				<Dialog
					open={showModal}
					onClose={this.hideModalAddCard}
					TransitionComponent={ModalTransitionZoom}
					disableBackdropClick={loadingAddCard}
					disableEscapeKeyDown={loadingAddCard}
					fullWidth
					fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.add.title}
					</DialogTitle>
					<DialogContent>
						<Elements stripe={stripePromise}>
							<FormAddCard
								loading={loadingAddCard}
								onSubmit={this.onSubmitCard}
								onDatas={this.onCardDatas}
								onCancel={this.hideModalAddCard}
							/>
						</Elements>
					</DialogContent>
				</Dialog>
				<Dialog
					open={showModalError}
					onClose={this.hideModalError}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
				>
					<DialogTitle>
						{lexique.error.title}
					</DialogTitle>
					<DialogContent>
						{lexique.error.bodies[errorType]}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.hideModalError}>
							{lexique.error.close}
						</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		)
	}
}


const mapStateToProps = ({env, plannings, ui}) => ({
	lexique: getLexique(env.locale).account.payment,
	creditCard: plannings[plannings.selected].billing.creditCard,
	plan: plannings[plannings.selected].billing.plan,
	isMobile: ui.device.isMobile
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ addCreditCardInformation, updatePlanPayment }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
