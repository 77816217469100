import React, { memo } from 'react';
import ParticipantInfo from './participant/Info';
import ParticipantTracks from './participant/Tracks';

const Participant = ({
    participant,
    videoOnly,
    isLocalParticipant,
}) => {
    return (
        <ParticipantInfo
            participant={participant}
            isLocalParticipant={isLocalParticipant}
        >
            <ParticipantTracks
                participant={participant}
                videoOnly={videoOnly}
                isLocalParticipant={isLocalParticipant}
            />
        </ParticipantInfo>
    );
}

export default memo(Participant);