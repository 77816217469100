export const INTERNET_CHANGED = "ui::internet::change";
export const LEFTMENU_OPEN = "ui::left-menu::open";
export const LEFTMENU_CLOSE = "ui::left-menu::close";
export const DEVICE_CHANGED = "ui::device::changed";
export const API_ERROR_SHOW = "ui::api::error:show";
export const API_UPDATE_REQUIRED_SHOW = "ui::api::error::update::required::show";
export const SET_MAINTENANCE_MODE = "ui::maintenance::set";

export const MODALE_SHOW = "ui::modale::show";
export const MODALE_CLOSE = "ui::modale::close";
export const MODALE_UPDATE = "ui::modale::update";

export const QUICKVIEW_SHOW = "ui::appointment::quickview::show";
export const QUICKVIEW_CLOSE = "ui::appointment::quickview::close";

export const DUPLICATEMODE_ENTER = "ui::appointment::duplicate::enter";
export const DUPLICATEMODE_EXIT = "ui::appointment::duplicate::exit";

export const DUPLICATEMODE_DRAWER_OPEN = "ui::appointment::duplicate::drawer::open";
export const DUPLICATEMODE_DRAWER_CLOSE = "ui::appointment::duplicate::drawer::close";
export const SET_CALENDAR_COLUMN_WIDTH = "ui::calendar::column::width";

export const VISIO_SET = "ui::visio::set";
export const VISIO_ANSWER = "ui::visio::set::answer";
export const VISIO_CLOSE = "ui::visio::close";
export const VISIO_MINIFY_TOGGLE = "ui::visio::minify::toggle";
export const VISIO_FILES_RECEIVED = "ui::visio::files::received";