import React, { memo } from 'react';
import { connect } from 'react-redux';

import Desktop from './CalendarToolbarDesktop';
import Mobile from './CalendarToolbarMobile';

 const CalendarToolbar = memo(({isMobile, ...props}) => isMobile ? <Mobile {...props} /> : <Desktop {...props} />)


const mapStateToProps = ({ui}) => ({
	isMobile: ui.device.isMobile,
});

export default connect(mapStateToProps)(CalendarToolbar);
