import React, { memo } from 'react';
import { FormControl, MenuItem, Typography, Select, Grid } from '@material-ui/core';
import AudioLevelIndicator from './AudioLevelIndicator';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants/visio';
import { useDevices, useMediaStreamTrack, useVideoContext } from '../hooks';

const AudioInputList = () => {
    const { audioInputDevices } = useDevices();
    const { localTracks } = useVideoContext();

    const localAudioTrack = localTracks.find(track => track.kind === 'audio');
    const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
    const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

    function replaceTrack(newDeviceId) {
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
        localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    }

    return (
        <div>
            <Typography variant="subtitle2" gutterBottom>
                Audio Input
            </Typography>
            <Grid container alignItems="center" justify="space-between">
                <div className="inputSelect">
                    {audioInputDevices.length > 1 ? (
                        <FormControl fullWidth>
                            <Select
                                onChange={e => replaceTrack(e.target.value)}
                                value={localAudioInputDeviceId || ''}
                                variant="outlined"
                            >
                                {audioInputDevices.map(device => (
                                    <MenuItem value={device.deviceId} key={device.deviceId}>
                                        {device.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <Typography>
                            {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
                        </Typography>
                    )}
                </div>
                <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
            </Grid>
        </div>
    );
}


export default memo(AudioInputList);