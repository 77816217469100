import React, { memo } from 'react';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import nl2br from 'react-nl2br';
import Markdown from './Markdown';

const Alert = ({severity, title, action, ...props}) => (
    <MuiAlert severity={severity} action={action}>
        { title && <AlertTitle>{ title }</AlertTitle> }
        <AlertContent {...props} />
    </MuiAlert>
);

const AlertContent = memo(({withBr, md, content, children}) => {
    if (withBr) {
        return nl2br(content)
    }
    if (md) {
        return (
            <Markdown>
                {withBr && content ? nl2br(content) : (content || children)}
            </Markdown>
        )
    }

    return content || children
});

export default memo(Alert);