export default {
	days: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
	months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	jsError: {
		title: `Oups...`,
		body1: `Nous sommes désolé, il semblerait que l'application ait crashé.`,
		body2: `Veuillez recharger la page pour continuer à utiliser l'application.`,
		close: `Recharger`,
	},
	notAuthorized: {
		title: `Erreur`,
		body: `Vous n'êtes pas autorisé à vous connecter à cet espace.`,
		close: `Déconnexion`,
	},
	toolbar: {
		bold: `Gras`,
		italic: `Italique`,
		underline: `Souligné`,
		strikethrough: `Barré`,
		title1: `Titre 1`,
		title2: `Titre 2`,
		title3: `Titre 3`,
		normal: `Normal`,
		ul: `Liste`,
		ol: `Liste ordonnée`,
	}
}
