import moment from 'moment-timezone';

const paques = y => {
	const Y = y || year();
	const a = Y % 19;
    const b = Math.floor(Y / 100);
    const c = Y % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = (h + l + 7 * m + 114);
    const n = Math.floor(n0 / 31) - 1;
    const p = n0 % 31 + 1;
    const date = new Date(Y, n, p);
    return moment(date);
}

const lundiDePaques = y => {
	const Y = y || year();
	return moment(paques(Y)).add(1, "days");
}

const ascension = y => {
	const Y = y || year();
	return moment(paques(Y)).add(39, "days");
}

const pentecote = y => {
	const Y = y || year();
	return moment(paques(Y)).add(50, "days");
}

const jourDeLAn = y => {
	const Y = y || year();
	return moment(`1-1-${Y}`, "DD-MM-YYYY");
}

const feteDuTravail = y => {
	const Y = y || year();
	return moment(`1-5-${Y}`, "DD-MM-YYYY");
}

const victoireDesAllies = y => {
	const Y = y || year();
	return moment(`8-5-${Y}`, "DD-MM-YYYY");
}

const feteNationale = y => {
	const Y = y || year();
	return moment(`14-7-${Y}`, "DD-MM-YYYY");
}

const assomption = y => {
	const Y = y || year();
	return moment(`15-8-${Y}`, "DD-MM-YYYY");
}

const toussaint = y => {
	const Y = y || year();
	return moment(`1-11-${Y}`, "DD-MM-YYYY");
}

const armistice = y => {
	const Y = y || year();
	return moment(`11-11-${Y}`, "DD-MM-YYYY");
}

const noel = y => {
	const Y = y || year();
	return moment(`25-12-${Y}`, "DD-MM-YYYY");
}

const year = () => new Date().getFullYear();

const keys = ["jourDeLAn", "lundiDePaques", "feteDuTravail", "victoireDesAllies", "ascension", "pentecote", "feteNationale", "assomption", "toussaint", "armistice", "noel"];

const list = {
	"jourDeLAn": jourDeLAn(),
	"lundiDePaques": lundiDePaques(),
	"feteDuTravail": feteDuTravail(),
	"victoireDesAllies": victoireDesAllies(),
	"ascension": ascension(),
	"pentecote": pentecote(),
	"feteNationale": feteNationale(),
	"assomption": assomption(),
	"toussaint": toussaint(),
	"armistice": armistice(),
	"noel": noel(),
}

const getFerie = date => {
	const day = Object.entries(list).find(([, d]) => date.isSame(d));
	if (day) return day[0];
	return null;
}

const isFerie = date => {
	return Object.values(list).some(v => date.isSame(v));
}

export default {
	keys,
	list,
	getFerie,
	isFerie
}
