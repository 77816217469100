import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as USER from '../constants/user';
import * as PLANNING from '../constants/planning';

const initialState = {
	initialParams: ''
};

const reducers = {
	[USER.INFOS_SUCCESS]: (state, {payload:user}) => update(state, {
		$merge: user,
	} ),
	[USER.SET_EMAIL]: (state, {payload:email}) => update(state, {
		email: { $set: email },
	} ),
	[USER.DISCONNECT_SUCCESS]: state => update(state, {
		$set: {
			initialParams: state.initialParams,
			email: state.email
		},
	} ),
	[USER.INIT_PARAMS]: (state, {payload:params}) => update(state, {
		initialParams: { $set: params },
	} ),
	[PLANNING.ADD_OTHER]: (state, {payload:planning}) => update(state, {
		hasAccessTo: {
			others: { $push: [planning]}
		},
	} ),
}


export default createReducer(initialState, reducers);
