import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _sortBy from 'lodash.sortby';
import _deburr from 'lodash.deburr';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

import Search from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';


import UsersToConfirm from './users/UsersToConfirm';
import FamilyCard from './users/FamilyCard';
import UserCard from './users/UserCard';

import { randString } from '../libs/random';
import { userName } from '../libs/formatters';
import { getUserRightsOnPlanning } from '../libs/checkers';
import getLexique, { _lp } from '../locales';
import { openModaleAddNewUser } from '../actions/ui';
import MailingMail from './users/MailingMail';

class Users extends PureComponent {

	state = { showFamily: true, showBlocked: false, search: '', openMailing: false, }

	openModaleAddNewUser = () => this.props.actions.openModaleAddNewUser();

	renderByUser() {
		const { usersList, useAnimals } = this.props;
		const { showBlocked, search } = this.state;

		const deburredSearch = _deburr(search).toLowerCase();

		const users = usersList.filter(({isBlocked, firstName, lastName, email, phoneNumber, isAnimal}) => {
			if (!showBlocked && isBlocked) return false;
			if (isAnimal && !useAnimals) return false;

			// No search, no need to filer
			if (search === '') return true;

			// Search among email
			if (_deburr(email).indexOf(deburredSearch) > -1) return true;

			// Search among phone Number
			if (_deburr(phoneNumber).indexOf(deburredSearch) > -1) return true;


			const deburred = _deburr(userName({firstName, lastName})).toLowerCase();
			return deburred.indexOf(deburredSearch) > -1

		});

		const members = [..._sortBy(users, ['lastName']), {id: randString()}]
		const displayed = members.slice(0,50);

		return <>
			{ displayed.map(user => <UserCard key={`${user.id}-${user.familyMemberId}`} {...user} />) }
			{ this.renderHasMore(members, displayed, 'search') }
		</>
	}

	renderByFamily() {
		const { users } = this.props;
		const { showBlocked, search } = this.state;

		const deburredSearch = _deburr(search).toLowerCase();

		const families = users.filter(({isRequestingAccess, isBlocked, family, email, phoneNumber}) => {
			if (isRequestingAccess) return false;

			if (!showBlocked && isBlocked) return false;

			// No search, no need to filer
			if (search === '') return true;

			// Search among email
			if (_deburr(email).indexOf(deburredSearch) > -1) return true;

			// Search among phone Number
			if (_deburr(phoneNumber).indexOf(deburredSearch) > -1) return true;

			// Search among family members
			return family.findIndex(f => {
				const deburred = _deburr(userName(f)).toLowerCase();
				return deburred.indexOf(deburredSearch) > -1;
			}) > -1;
		})

		const members = [..._sortBy(families, ['mainMemberName']), {id: randString()}];
		const displayed = members.slice(0,50);

		return <>
			{ displayed.map(user => <FamilyCard key={user.id} {...user} />) }
			{ this.renderHasMore(members, displayed, 'family') }
		</>
	}

	renderHasMore = (u, d, l) => {
		if ((u.length-1) <= d.length) return null;
		const { lexique } = this.props;

		return <div className="users_has-more">
			<span>+{u.length- 1 - d.length}</span> {_lp(lexique[l].hasMore)}
		</div>
	}

	handleChange = field => () => {
		this.setState({[field]: !this.state[field]});
	}

	updateSearch = ({target:{value}}) => this.setState({search: value});

	onCloseMailing = () => this.setState({openMailing: false});
	onOpenMailing = () => this.setState({openMailing: true});

	render() {
		const { lexique, loading, user, selectedPlanning, isMobile } = this.props;
		const { showFamily, showBlocked, search, openMailing } = this.state;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		if (loading) return <LinearProgress color="secondary" />

		return (
			<div className="users pad20">
				{rights.acceptUser && <UsersToConfirm />}
				<div className="section_title tal flex">
					<Typography variant="h4" className="flex1">{_lp(lexique.title)}</Typography>
					{rights.createUser && (<>
						<Button 
							className="add-user"
							variant="contained"
							color="secondary"
							onClick={this.openModaleAddNewUser}
							disableElevation
						>
							<PersonAddIcon className="mr5" />{_lp(lexique.addNew)}
						</Button>
						{!isMobile && (<>
							<Button 
								className="send-mailing ml5"
								variant="outlined"
								color="primary"
								onClick={this.onOpenMailing}
								disableElevation
							>
								<MailIcon className="mr5" />{_lp(lexique.sendMailing)}
							</Button>
							<MailingMail open={openMailing} onClose={this.onCloseMailing} />
						</>)}
					</>)}
				</div>
				<Paper className="users-search-bar mt20 pad20 mb20">
					<TextField
						className="mb20"
						onChange={this.updateSearch}
						value={search}
						label={_lp(lexique.search.label)}
						placeholder={lexique.search.placeholder}
						fullWidth
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
						}}
					/>
					<FormGroup row>
						<FormControlLabel
							control={
								<Switch
									checked={showFamily}
									onChange={this.handleChange('showFamily')}
								/>
							}
							label={_lp(lexique.showFamily)}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={showBlocked}
									onChange={this.handleChange('showBlocked')}
								/>
							}
							label={_lp(lexique.showBlocked)}
						/>
					</FormGroup>
				</Paper>
				<div className="users-container mt20">
					{ showFamily ? this.renderByFamily() : this.renderByUser() }
				</div>
			</div>
		)
	}
}


const mapStateToProps = ({env, user, plannings, ui}) => ({
	lexique: getLexique(env.locale).users,
	isMobile: ui.device.isMobile,
	loading: !plannings[plannings.selected] || plannings[plannings.selected].loading === true || !plannings[plannings.selected].users,
	users: plannings[plannings.selected] && plannings[plannings.selected].users ? plannings[plannings.selected].users : [],
	usersList: plannings[plannings.selected] && plannings[plannings.selected].usersList ? plannings[plannings.selected].usersList : [],
	useAnimals: plannings[plannings.selected] && plannings[plannings.selected].env ? plannings[plannings.selected].env.useAnimals || false : false,
	user,
	selectedPlanning: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openModaleAddNewUser }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Users);
