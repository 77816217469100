export default {
    createdBy: `Pris le {0} par {1}`,
    confirmedBy: `Confirmé le {0} par {1}`,
    confirmedAuto: `Confirmé automatiquement`,
    notConfirmed: `Non confirmé`,
	blockedSlots: `Créneaux bloqués`,
	confirm: `Confirmer le rendez-vous`,
	pvpp: `Le {p} ne s'est pas présenté`,
    day: {
        lock: `Fermer la prise de rendez-vous`,
        open: `Ouvrir la prise de rendez-vous`,
        changeSchedule: `Modifier le planning du jour`,
        addInformation: `Ajouter un message d'information pour vos {p}s`,
        editInformation: `Modifier le message d'information à vos {p}s`,
		addInformationShort: `Ajouter un message d'information`,
        editInformationShort: `Modifier le message d'information`,
        addNote: `Ajouter une note personnelle`,
        editNote: `Modifier la note personnelle`,
        tooltips: {
            closed: `Jour fermé à la réservation en ligne`,
            schedules: `Vous avez défini des horaires spécifiques pour ce jour`,
			publicHoliday: {
				"jourDeLAn": "Jour de l'an",
				"lundiDePaques": "Lundi de Pâques",
				"feteDuTravail": "Fête du Travail",
				"victoireDesAllies": "Victoire des Alliés",
				"ascension": "Jeudi de l'Ascension",
				"pentecote": "Lundi de Pentecôte",
				"feteNationale": "Fête nationale",
				"assomption": "Assomption",
				"toussaint": "La Toussaint",
				"armistice": "Armistice",
				"noel": "Noël",
			}
        }
    },
	toolbar: {
		openDatePicker: `Aller au jour...`,
		cancel: `Annuler`,
		today: `Aujourd'hui`,
	},
	duplicate: {
		confirm: `Dupliquer`,
		discard: `Annuler la duplication`,
		title: `Vous dupliquez le rendez-vous`,
		titleTo: `Vers les créneaux`,
		remove: `Supprimer`,
		close: `Fermer`,
	},
    tooltips: {
        duplicate: `Dupliquer vers...`,
        delete: `Annuler le rendez-vous`,
		remove: `Supprimer le créneau bloqué`,
        profile: `Afficher le profil du {p}`,
        edit: `Modifier le rendez-vous`,
        secureFolder: `Dossier sécurisé`,
        sendEmail: `Envoyer un email`,
		call: `Appeler`,
		joinVisio: 'Rejoindre la téléconsultation',
		payments: 'Envoyer une facture',
    }
}
