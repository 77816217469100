import React, { memo } from 'react';

import DayHeader from './DayHeader';


const AgendaHeader = memo(({days, displayDays}) => {
    return <div className="agenda--header">
        <div className="header--column header--gutter" />
        {days.map(d => <div key={d} className={`header--column days--${displayDays}`}>
            <DayHeader date={d} />
        </div>)}
    </div>
})


export default AgendaHeader;
