import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _deburr from 'lodash.deburr';

import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import SearchIcon from '@material-ui/icons/Search';

import { updateFilter, getLogs } from '../../actions/logs';
import getLexique, { _lp } from '../../locales';
import { userName as userNameFormatter } from '../../libs/formatters';


const LogsFilterWrapper = memo(({usersList, ...props}) => {

    if (!usersList || usersList.length === 0) return null;

    return (
        <LogsFilters usersList={usersList} {...props} />
    )

});

const LogsFilters = memo(({actions, filters, lexique, usersList}) => {

    const [ showPicker, openPicker ] = useState(false);
    const [ userName, setUserName ] = useState("");

    const onClosePicker = () => openPicker(false);
    const updateUserName = ({target:{value}}) => setUserName(value);

    const handleChange = filter => ({target:{value}}) => {
        if (filter === "who" && value === "user") {
            actions.updateFilter({filter: "pickedUser", value: null});
            openPicker(true);
            return;
        }
        actions.updateFilter({filter, value});
    }

    const selectUser = user => () => {
        openPicker(false);
        actions.updateFilter({filter: "who", value: "user"});
        actions.updateFilter({filter: "pickedUser", value: user});

    }

    const userFiltered = userName.trim().length > 2 ? usersList.filter(u => {
        const search = _deburr(userName.trim()).toLowerCase();
        if (_deburr(`${u.lastName} ${u.firstName}`).toLowerCase().indexOf(search) > -1) return true;
        if (_deburr(`${u.firstName} ${u.lastName}`).toLowerCase().indexOf(search) > -1) return true;
        if (u.email.toLowerCase().indexOf(search) > -1) return true;
        if (u.phoneNumber.toLowerCase().indexOf(search) > -1) return true;
        return false;
    }).slice(0,10) : [];

    return (<>
    <div className="section_title tal">
        <Typography variant="h4">{lexique.title}</Typography>
    </div>
	<Paper className="logs-filters">
        <span>{lexique.showHistory}</span>
        <FormControl variant="outlined">
            <Select
                value={filters.mode}
                onChange={handleChange('mode')}
                input={<OutlinedInput />}
            >
                <MenuItem value="of">{lexique.mode.of}</MenuItem>
                <MenuItem value="about">{lexique.mode.about}</MenuItem>
            </Select>
        </FormControl>
        <FormControl variant="outlined">
            <Select
                value={filters.who}
                onChange={handleChange('who')}
                input={<OutlinedInput />}
            >
                <MenuItem value="all">{lexique.who.all}</MenuItem>
                <MenuItem value="me">{lexique.who.me}</MenuItem>
                <MenuItem value="user">{filters.who === "user" && filters.pickedUser ? userNameFormatter(filters.pickedUser) : _lp(lexique.who.user)}</MenuItem>
            </Select>
        </FormControl>
        <span>{lexique.for}</span>
        <FormControl variant="outlined">
            <Select
                value={filters.period}
                onChange={handleChange('period')}
                input={<OutlinedInput />}
            >
                <MenuItem value="today">{lexique.period.today}</MenuItem>
                <MenuItem value="yesterday">{lexique.period.yesterday}</MenuItem>
                <MenuItem value="last7days">{lexique.period.last7days}</MenuItem>
                <MenuItem value="last30days">{lexique.period.last30days}</MenuItem>
                <MenuItem value="lastMonth">{lexique.period.lastMonth}</MenuItem>
            </Select>
        </FormControl>
        <Button variant="contained" color="secondary" onClick={actions.getLogs} disableElevation>
            <SearchIcon className="mr5" />
            {lexique.search}
        </Button>
        <Dialog open={showPicker} onClose={onClosePicker} fullWidth>
            <DialogTitle>
                {_lp(lexique.picker.title)}
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    autoFocus
                    label={_lp(lexique.picker.label)}
                    placeholder={lexique.picker.placeholder}
                    value={userName}
                    onChange={updateUserName}
                    margin="normal"
                    variant="outlined"
                />
                {userName !== "" && userFiltered.length > 0 && <div className="picker-content">
                    <List dense>
                        {userFiltered.map(user => (
                            <ListItem key={`${user.id}-${user.familyMemberId}`} button divider onClick={selectUser(user)}>
                                <ListItemText
                                    primary={userNameFormatter(user)}
                                    secondary={`${user.email} / ${user.phoneNumber}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>}
            </DialogContent>
        </Dialog>
	</Paper>
    </>
    );
});

const mapStateToProps = ({env, logs, plannings}) => ({
	lexique: getLexique(env.locale).logs.filters,
    filters: logs.filters,
    usersList: plannings && plannings[plannings.selected] && plannings[plannings.selected].usersList ? plannings[plannings.selected].usersList : []
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updateFilter, getLogs }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterWrapper);
