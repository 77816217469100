import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip } from 'react-tippy';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Info from '@material-ui/icons/Info';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import { closeModaleEditAppointmentProfile } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';

class EditAppointmentTypeProfile extends PureComponent {

	initialState = { address: '', phoneNumber: '', lat: '', lng: '' };
	state = { ...this.initialState };

	mapPropsToInnerState = () => {
		const { appointment } = this.props;
		if (!appointment || !appointment.profile) return;

		const { address, phoneNumber, geoLocation } = appointment.profile;
		const geoSplitted = geoLocation ? geoLocation.split(',') : ['', ''];
		this.setState({address, phoneNumber, lat:geoSplitted[0], lng: geoSplitted[1] });
	}

	onClose = () => {
		this.props.actions.closeModaleEditAppointmentProfile();
		this.resetInnerState();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		const { address, phoneNumber, lat, lng } = this.state;
		onConfirm({
			address,
			phoneNumber,
			geoLocation: lat !== '' && lng !== '' ? `${lat},${lng}` : null,
		});
		this.onClose();
	}

	onDelete = () => {
		const { onConfirm } = this.props;
		onConfirm(null);
		this.onClose();
	}

	resetInnerState = () => {
		this.setState({ ...this.initialState });
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.show && this.props.show) this.mapPropsToInnerState();
		if (oldProps.show && !this.props.show) this.resetInnerState();
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});

    render() {
		const { show, lexique, appointment, isMobile } = this.props;
		const { address, phoneNumber, lat, lng } = this.state;
		if (!appointment) return null;
    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-add-appointment-type-dialog-title"
			>
				<DialogTitle id="modale-add-appointment-type-dialog-title">
					{lexique.title[appointment.profile ? 'edit' : 'new']}
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						label={lexique.labels.address}
						value={address}
						onChange={this.handleChange('address')}
						fullWidth
						margin="normal"
						className="mb20"
					/>
					<div className="flex aic">
						<Typography variant="body1" className="flex1">{lexique.labels.geoLocation}</Typography>
						<Tooltip touchHold animation="perspective" position="left" html={<div className="tal pad10">{_lp(lexique.infos)}</div>}>
							<Info className="ico--grey" />
						</Tooltip>
					</div>
					<div className="flex aic">
						<TextField
							label={lexique.labels.lat}
							value={lat}
							onChange={this.handleChange('lat')}
							margin="normal"
							className="mb20 mr10"
						/>
						<TextField
							label={lexique.labels.lng}
							value={lng}
							onChange={this.handleChange('lng')}
							margin="normal"
							className="mb20 mr10"
						/>
					</div>
					<TextField
						label={lexique.labels.phoneNumber}
						value={phoneNumber}
						onChange={this.handleChange('phoneNumber')}
						fullWidth
						margin="normal"
						className="mb20"
					/>
				</DialogContent>
				<DialogActions>

					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					{appointment.profile && <Fragment>
						<div className="flex1" />
						<div classNamem="flex1 tal">
							<Button onClick={this.onDelete} color="primary">
								{lexique.delete}
							</Button>
						</div>
					</Fragment>}

					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm[appointment.profile ? 'edit' : 'new']}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{device, modales:{editAppointmentTypeProfile:{show, onConfirm, appointment}}}}) => ({
	isMobile: device.isMobile,
	lexique: getLexique(env.locale).modales.editAppointmentTypeProfile,
    show,
	onConfirm,
	appointment,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleEditAppointmentProfile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAppointmentTypeProfile);
