export default {
	title: `Conditions générales d'utilisation du site`,
	paragraphs: {
		object: {
			title: `Objet`,
			body: `Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement juridique des modalités de mise à disposition des services Sprel et leur utilisation par « l'Utilisateur ».

	Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur souhaitant accéder au site. Elles constituent le contrat entre le site et l'Utilisateur. L'accès au site par l'Utilisateur signifie son acceptation des présentes conditions générales d'utilisation.

	En cas de non-acceptation des conditions générales d'utilisation stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.

	Sprel se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes conditions générales d'utilisation.`
		},
		legals: {
			title: `Mentions légales`,
			body: `L'édition, le développement et la maintenance de Sprel sont assurés par Damien Buchet - auto-entrepreneur - N° SIRET : 528253313

	Le site Sprel est hébergé par Google Cloud Plateform.`
		},
		definition: {
			title: `Définitions`,
			body: `La présente clause a pour objet de définir les différents termes essentiels du contrat :

	Utilisateur : ce terme désigne toute personne qui utilise le site ou l'un des services proposés par le site.
	Contenu utilisateur : ce sont les données transmises par l'Utilisateur au sein du site.
	Membre : l'Utilisateur devient membre lorsqu'il est identifié sur le site.
	Identifiant et mot de passe : c'est l'ensemble des informations nécessaires à l'identification d'un Utilisateur sur le site. L'identifiant et le mot de passe permettent à l'Utilisateur d'accéder à des services réservés aux membres du site. Le mot de passe est confidentiel.`
		},
		access: {
			title: `Accès aux services`,
			body: `Le site est accessible sur abonnement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.

	Les services accessibles à un Utilisateur en période d'abonnement en cours sont les suivants :
	- Un site internet dédié avec un nom de domaine personnalisé, sous réserve de disponibilité.
	- Un planning public permettant à des tiers de prendre rendez-vous en ligne, suivant les paramétrages définis par l'Utilisateur

	Le site met en œuvre tous les moyens mis à sa disposition pour assurer un accès de qualité à ses services. L'obligation étant de moyens, Sprel ne s'engage pas à atteindre ce résultat.

	Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du réseau ou du serveur n'engage pas la responsabilité de Sprel.

	L'accès aux services du site peut à tout moment faire l'objet d'une interruption, d'une suspension, d'une modification sans préavis pour une maintenance ou pour tout autre cas. L'Utilisateur s'oblige à ne réclamer aucune indemnisation suite à l'interruption, à la suspension ou à la modification du présent contrat.

	L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l'adresse contact@sprel.fr`
		},
		subscription: {
			title: `Abonnement aux services`,
			body: `Le service Sprel est accessible sous forme d'abonnement SaaS (Software as a Service).
	L'accès aux services peut être pris pour une durée de :
	- 1 mois (au tarif de 60€ TTC)
	- 6 mois (au tarif de 330€ TTC)
	- 1 an (au tarif de 600€ TTC)

Un abonnement en cours de validité est requis pour pouvoir accèder aux services, et pour que les utilisateurs de Sprel puissent prendre rendez-vous.

Suivant la formule choisi, une facture sera automatiquement emise tous les mois / 6 mois / an, afin de régler la souscription. L'Utilisateur disposera d'une période de 15 jours pour régler cette facture, faute de quoi Sprel pourra suspendre les services et l'accès aux services de l'Utilisateur.

L'Utilisateur a la possibilité de rentrer ses informations de carte bancaire sur le site, afin d'être prélevé automatiquement du montant de son abonnement.
Les coordonées de carte bancaire ne sont en aucun stockées, ni ne transitent par Sprel, qui utilise le prestataire Stripe pour gérer toute la facturation. Les informations de carte bancaires sont donc stockées sur les serveurs sécurisés de Stripe, avec toutes les sécuritées obligatoires à ce genre de service.

L'utilisateur pourra à tout moment modifier ou annuler son abonnement en contactant Damien Buchet par téléphone au 0689101312 ou par mail sur damien@sprel.io

Toute période d'abonnement commencée est dûe et ne pourra être remboursée, l'abonnement prendra fin à la fin de la période d'abonnement.`
		},
		property: {
			title: `Propriété intellectuelle`,
			body: `Les marques, logos, signes et tout autre contenu du site font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.

L'Utilisateur sollicite l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus.

L'Utilisateur s'engage à une utilisation des contenus du site dans un cadre strictement privé. Une utilisation des contenus à des fins commerciales est strictement interdite.

Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.

Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site. L'Utilisateur ne reçoit aucune justification et notification préalablement à la suppression ou à la modification du contenu Utilisateur.`
		},
		personalDatas: {
			title: `Données personnelles`,
			body: `L'adresse électronique pourra être utilisée par le site pour l'administration, la gestion et l'animation du service.

Sprel assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.

Sprel s'engage à ne pas vendre ou même communiquer vos données personnelles à des sociétés tierces.
Les cookies utilisés sur le site ne servent qu'a retenir votre identification, et vous permettre de prendre rendez-vous. Ils ne sont en aucun cas utilisés à des fins publicitaires.

En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit par mail à privacy@sprel.fr

Les données personnelles collectées sont les suivantes : Prénom, nom, adresse, adresse mail, numéro de téléphone, date de naissance.
Ces données sont nécéssaires au bon fonctionnement du service.

Un délégué à la protection des données : Damien Buchet, contact@sprel.io, est à votre disposition pour toute question relative à la protection de vos données personnelles.`
		},
		responsability: {
			title: `Responsabilité et force majeure`,
			body: `L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite.

Tout usage du service par l'Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l'objet d'une indemnisation au profit de Sprel.

Le site s'engage à mettre en œuvre tous les moyens nécessaires afin de garantir au mieux la sécurité et la confidentialité des données (données cryptées et protection https).

La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.`
		},
		links: {
			title: `Liens hypertextes`,
			body: `Des liens hypertextes sortants peuvent être présents sur le site, cependant les pages web où mènent ces liens n'engagent en rien la responsabilité de Sprel qui n'a pas le contrôle de ces liens ni de leur contenu.

L'Utilisateur s'interdit donc à engager la responsabilité du site concernant le contenu et les ressources relatives à ces liens hypertextes sortants.`
		},
		evolution: {
			title: `Évolution du contrat`,
			body: `Sprel se réserve à tout moment le droit de modifier les clauses stipulées dans le présent contrat.`,
		},
		publication: {
			title: `Publication par l'Utilisateur`,
			body: `L'Utilisateur s'engage à fournir des informations vraies, exactes, à jour et complètes sur son identité tout comme son planning. Il s'engage notamment à ne pas créer une fausse identité de nature à induire Sprel ou les tiers en erreur et à ne pas usurper l'identité d'une autre personne physique.
L'Utilisateur s'engage à mettre immédiatement à jour, en cas de modification, les données qu'il a communiquées initialement.

Dans l'hypothèse où l'Utilisateur fournirait des informations fausses, inexactes, périmées, incomplètes, trompeuses ou de nature à induire en erreur, Sprel pourra, immédiatement sans préavis ni indemnité, suspendre ou résilier le compte Utilisateur de l'Utilisateur et lui refuser l'accès, de façon temporaire ou définitive.`
		},
		drive: {
			title: `Utilisation du stockage sécurisé`,
			body: `Sprel met à disposition de l'Utilisateur un espace de stockage sécurisé. Le service est accessible par mot de passe indépendant du mot de passe de connexion. Ce mot de passe sert de clé de chiffrement afin de crypter les fichiers avant envoi sur le serveur. Le chiffrement est alors effectué de bout en bout, sans interception ou déchiffrage possible depuis les serveurs Sprel.

Ce mot de passe ne pourra en aucun cas être changé ou récupéré. L'oubli du mot de passe rendra tous les fichiers stockés inutilisables et irrécupérables. Sprel ne pourra en aucun cas être tenu responsable de la perte des fichiers suite à la perte du mot de passe par l'Utilisateur.

Les fichiers stockés sur le service sont à l'entière discrétion de l'Utilisateur. Sprel ne pourra en aucun cas être tenu responsable de la teneur et du contenu de ces fichiers.`
		},
		visio: {
			title: 'Téléconsultation',
			body: `Sprel met à disposition des praticiens une plateforme de téléconsultation. Chaque rendez-vous marqué en tant que téléconsultation créé un lien unique, avec salle d'attente virtuelle, entre le praticien et le patient. Le flux vidéo est direct entre le praticien et le patient, et ne transite en aucun cas par les serveurs de Sprel.

Les pièces jointes envoyées par le praticien vers le patient sont envoyés directement par email, et ne sont à aucun moment stockés sur les serveurs de Sprel

Les pièces jointes envoyées par le patient vers le praticien sont stockée dans des dossiers sécurisés temporaires, et son automatiquement détruis à la fin de la téléconsultation`
		},
		payments: {
			title: 'Facturation de vos rendez-vous',
			body: `Les services de paiement pour les praticiens officiant sur Sprel sont fournis par Stripe et soumis à l’Accord sur les comptes Stripe Connected (Stripe Connected Account Agreement), qui inclut les Modalités de service de Stripe (l’ensemble étant appelé les “Conditions Générales d’Utilisation Stripe” - “Stripe Services Agreement”.) En agréant aux présentes Conditions Générales d’Utilisation ou en continuant à opérer en tant que praticien officiant sur Sprel, vous acceptez d’être lié aux Conditions Générales d’Utilisation Stripe, celles-ci pouvant occasionnellement faire l’objet de modifications de la part de Stripe. Du fait que Sprel permette d’effectuer les paiements via Stripe, vous acceptez de fournir à Sprel des informations précises et complètes sur vous et votre activité, et autorisez Sprel à partager ces informations ainsi que celles concernant les transactions effectuées via la solution de paiement fournie par Stripe.

Les frais de fonctionnement inhérents au paiement en ligne sont de 4% par transaction. Les paiements collectés via Sprel sont stockés dans des portefeuilles virtuels par Stripe, puis sont versés sur les comptes des praticiens chaque fin de mois`
		}
	},
}
