export const CLOSE = "appointment::close";
export const OPEN = "appointment::open";
export const OPEN_NEW = "appointment::open::new";
export const CHANGE_NAME = "appointment::change::name";
export const CHOOSE_USER = "appointment::choose::user";
export const CHANGE_TYPE_ID = "appointment::change::typeId";
export const CHANGE_START_DATE = "appointment::change::startDate";
export const CHANGE_DURATION = "appointment::change::duration";
export const CHANGE_PHONE_NUMBER = "appointment::change::phoneNumber";
export const CHANGE_INFO_ADMIN = "appointment::change::information::admin";
export const UPDATE_FIELD = "appointment::change::field";

export const UPDATE = "appointment::update";
export const DELETE = "appointment::delete";
export const CREATE = "appointment::create";
export const CONFIRM = "appointment::confirm";

export const DUPLICATE_ADD = "appointment::duplicate::add";
export const DUPLICATE_REMOVE = "appointment::duplicate::remove";

export const DUPLICATES_SAVE_REQUEST = "appointment::duplicates::save::request";
export const DUPLICATE_SAVE_COMPLETE = "appointment::duplicate::save::complete";

export const ADD_TO_CONFIRM_LIST = "appointment::to-be-confirmed-list::add";
export const REMOVE_FROM_CONFIRM_LIST = "appointment::to-be-confirmed-list::remove";
