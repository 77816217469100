import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import nl2br from 'react-nl2br';
import { Tooltip } from 'react-tippy';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Person from '@material-ui/icons/Person';
import Today from '@material-ui/icons/Today';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Delete from '@material-ui/icons/Delete';
import Schedule from '@material-ui/icons/Schedule';
import Assignment from '@material-ui/icons/Assignment';
import Alarm from '@material-ui/icons/Alarm';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Pets from '@material-ui/icons/Pets';
import VisioIcon from '@material-ui/icons/VideocamOutlined';

import { openUserDetails } from '../../actions/users';
import { updateViewDate } from '../../actions/planning';
import { confirmAppointment, deleteAppointment } from '../../actions/appointment';
import { openModaleConfirmDeleteAppointment, openModaleConfirmAppointment } from '../../actions/ui';

import { userName } from '../../libs/formatters';
import getLexique, { _lp } from '../../locales';
import { defaultColorsTypes } from '../../config/theme';

import history from '../../config/history';
import routes from '../../config/routes';

class AppointmentCard extends PureComponent {

	showProfile = () => {
		const { actions: {openUserDetails}, with:{id} } = this.props;
		openUserDetails(id);
	}

	gotoDay = () => {
		const { actions: {updateViewDate}, start } = this.props;
		updateViewDate(moment(start));
		history.push(routes.planning);
	}

	askDeleteAppointment = () => {
		const { actions:{openModaleConfirmDeleteAppointment}, ...event } = this.props;

		openModaleConfirmDeleteAppointment({
			event,
			onConfirm: this.onConfirmDeleteAppointment
		});
	}

	onConfirmDeleteAppointment = () => {
		const { actions: {deleteAppointment}, selectedPlanning, ...event} = this.props;
		deleteAppointment({
			...event,
			practitionerId: selectedPlanning,
		});
	}

	confirmConfirmAppointment = () => {
		const { actions:{openModaleConfirmAppointment}, ...event } = this.props;
		openModaleConfirmAppointment({
			event,
			onConfirm: this.onConfirmAppointment
		});
	}

	onConfirmAppointment = () => {
		const { id, start, actions:{confirmAppointment}, selectedPlanning } = this.props;
		confirmAppointment({
			id,
			practitionerId: selectedPlanning,
			day: moment(start).format('YYYY-MM-DD'),
		});
	}

	render() {
		const { lexique, start, duration, created, information, type, with:{firstName, lastName, email, phoneNumber, isAnimal}, config:{dateFormatLong, timeFormat, datetimeFormatLong} } = this.props;

		const mStart = moment(start);
		const mEnd = moment(start).add(duration, 'minutes');

		const useType = type || {
			color: defaultColorsTypes.default,
			label: '-'
		}

		return (
			<Card className="appointment-card event_popover_content event_popover_content--past">
				<div className="event_popover_header" style={{backgroundColor: useType.color}}>
					<div className="event_popover_actions">
						<Tooltip touchHold position="top" title={_lp(lexique.tooltips.profile)}>
							<IconButton onClick={this.showProfile}>
								<Person />
							</IconButton>
						</Tooltip>
						<Tooltip touchHold position="top" title={lexique.tooltips.gotoDay}>
							<IconButton onClick={this.gotoDay}>
								<Today />
							</IconButton>
						</Tooltip>
						<Tooltip touchHold position="top" title={lexique.tooltips.delete}>
							<IconButton onClick={this.askDeleteAppointment}>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>
					<div className="event_popover_title">
						<Typography variant="h5">
							{isAnimal && <Pets className="mr5 ico--inline ico--inline--short" />}
							{ userName({firstName, lastName, isAnimal})}
						</Typography>
						<Typography variant="subtitle1">
							{ email }
						</Typography>
						<Typography variant="subtitle1">
							<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
						</Typography>
					</div>
				</div>
				<div className="event_popover_body">
					<Typography component="div" className="flex aic" paragraph>
						<Schedule className="mr10" />
						<span>{mStart.format(dateFormatLong)}, {mStart.format(timeFormat)} - {mEnd.format(timeFormat)}</span>
					</Typography>
					<Typography component="div" className="flex aic event-appointmentType" paragraph>
						<Assignment className="mr10" />
						<span className="label">{useType.label}</span>
						{useType?.isVisio && <span className="icon"><VisioIcon /></span>}
					</Typography>
					{information && information.user && information.user !== '' && <Typography component="div" className="flex ait" paragraph>
						<InfoOutline className="mr10" />
						<span>{nl2br(information.user)}</span>
					</Typography>}
					<Typography component="div" className="flex ait" paragraph>
						<Alarm className="mr10" />
						<span>{lexique.createdBy.replace('{0}', moment(created.at).format(datetimeFormatLong))}</span>
					</Typography>
					<div className="event_popover_confirm">
						<Button onClick={this.confirmConfirmAppointment} variant="contained" color="primary" className="button--success" disableElevation>
							<CheckCircle className="mr10" /> {lexique.confirm}
						</Button>
					</div>
				</div>
			</Card>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).appointmentsToConfirm.card,
	config: getLexique(env.locale).config,
	selectedPlanning: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openUserDetails, updateViewDate, confirmAppointment, deleteAppointment, openModaleConfirmDeleteAppointment, openModaleConfirmAppointment }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCard);
