import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import RichTextEditor from 'react-rte';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import Phone from '@material-ui/icons/Directions';
import MoreVert from '@material-ui/icons/MoreVert';
import Edit from '@material-ui/icons/Edit';

import Markdown, { isNotNull } from '../../ui/Markdown';
import ModalTransitionZoom from '../../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../../ui/ButtonCircularProgress';

import getLexique from '../../../locales';
import { changeSectionActivation, updateSection } from '../../../actions/websites';
import { getToolbar } from '../../../config/website';

class Contact extends PureComponent {

	state = { 	showModal: false, anchor: null,
				free: RichTextEditor.createEmptyValue(),
				phoneNumbers: {},
			};

	componentDidMount() {
		const { datas } = this.props;
		if (datas) this.setState(Object.assign({},
				isNotNull(datas.free) ? { free: RichTextEditor.createValueFromString(datas.free, 'markdown') } : null,
				datas.phoneNumbers && Object.keys(datas.phoneNumbers).length > 0 ? { phoneNumbers : datas.phoneNumbers } : null,
			)
		);
	}

	handleMenuClose = () => this.setState({anchor: null});
	handleMenuOpen = ({target}) => this.setState({anchor: target});

	handleModalOpen = () => this.setState({showModal: true, anchor: null});
	handleModalClose = () => this.setState({showModal: false});

	handleChangeActive = () => {
		const { actions:{changeSectionActivation}, active, section} = this.props;
		changeSectionActivation({active: !active, section});
	}

	onConfirm = async () => {
		this.setState({loading: true});
		const { section, actions: {updateSection} } = this.props;
		const { free, phoneNumbers } = this.state;
		const query = {
			free: free.toString('markdown'),
			phoneNumbers,
		};

		await updateSection({
			section,
			datas: query,
		});
		this.setState({loading: false});
		this.handleModalClose();
	}

	getToolbar = () => {
		const { toolbar } = this.props.lexique.edition;
		return getToolbar(toolbar);
	}

	onChangeText = field => value => this.setState({[field]: value});
	onChangeInput = field => ({target:{value}}) => this.setState({
		phoneNumbers: {
			...this.state.phoneNumbers,
			[field]: value
		}
	});

	render() {
		const { lexique, active, datas, isMobile } = this.props;
		const { anchor, showModal, loading, ...state } = this.state;

		return (
			<div className={cn('card', 'website_section', {
				'website_section--inactive': !active,
			})}>
				<div className="website_actions">
					<IconButton onClick={this.handleMenuOpen}>
						<MoreVert />
					</IconButton>
				</div>
				<div className="card_content website_card website_content">
					<div className="card_title">
						<span className="ico"><Phone /></span>
						<h3>{lexique.title}</h3>
					</div>
					<div className="mt20">
						{datas && isNotNull(datas.free) && <Typography paragraph component="div">
							<Markdown>
								{datas.free}
							</Markdown>
						</Typography> }
						{ datas && datas.phoneNumbers && <Typography component="div">
							{ Object.entries(datas.phoneNumbers).map(([type, value]) => value && value !== '' && <div key={type}>
								<span className="contact-label">{lexique.phoneNumbers[type]}</span><span className="contact-value">{value}</span>
							</div>) }
						</Typography> }
					</div>
				</div>

				<Menu
					anchorEl={anchor}
					open={anchor !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem
						onClick={this.handleChangeActive}
					>
						<ListItemText primary={lexique.active} />
						<ListItemSecondaryAction>
							<Switch
								color="secondary"
								onChange={this.handleChangeActive}
								checked={active}
							/>
						</ListItemSecondaryAction>
					</MenuItem>
					<MenuItem onClick={this.handleModalOpen}>
						<ListItemIcon>
							<Edit />
						</ListItemIcon>
						<ListItemText inset primary={lexique.edit} />
					</MenuItem>
				</Menu>
				<Dialog
					maxWidth="md"
					open={showModal}
					TransitionComponent={ModalTransitionZoom}
					fullWidth
					onClose={this.handleModalClose}
					disableBackdropClick={loading}
					disableEscapeKeyDown={loading}
					fullScreen={isMobile}
				>
					<DialogTitle>
						{lexique.edition.title}
					</DialogTitle>
					<DialogContent>
						<Typography variant="caption" className="mt20">{lexique.edition.labels.free}</Typography>
						<RichTextEditor
							disabled={loading}
							autoFocus
							className="rte minh bkg"
							toolbarConfig={this.getToolbar()}
							value={state.free}
							onChange={this.onChangeText('free')}
						/>
						<div className="mt20">
						{
							['direct', 'emergency', 'secretariat', 'portable'].map(type => <TextField
								key={type}
								label={lexique.phoneNumbers[type]}
								value={state.phoneNumbers[type] || ''}
								onChange={this.onChangeInput(type)}
								margin="normal"
								fullWidth
								disabled={loading}
							/>)
						}
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleModalClose} color="primary" disabled={loading}>
							{lexique.edition.cancel}
						</Button>
						<Button onClick={this.onConfirm} color="primary" disabled={loading}>
							{lexique.edition.confirm}
							{loading && <ButtonCircularProgress />}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}


const mapStateToProps = ({env, website, ui}) => ({
	lexique: getLexique(env.locale).websites.contact,
	datas: website.contact,
	form: website.contactForm,
	section: 'contact',
	active: website.blocks ? website.blocks.indexOf('contact') > -1 : false,
	isMobile: ui.device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeSectionActivation, updateSection }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
