import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ToastContainer, Flip, toast } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Cookies from 'universal-cookie';
import cn from "classnames";
import moment from 'moment-timezone';
import 'moment/locale/fr';

import { VERSION } from '../version';

import theme from '../config/theme';
import routes from '../config/routes';
import firebase from '../config/firebase';
import logError from '../libs/sentry';
import { COOKIES } from '../config/env';
import { getUserInfos, setUserToken, setUserEmail, setUserInitialParams } from '../actions/user';

import ScrollTop from './ui/ScrollTop';
import DeviceChecker from './ui/DeviceChecker';
import BrowserChecker from './ui/BrowserChecker';
import JsError from './ui/JsError';
import NotAuthorized from './ui/NotAuthorized';
import WarningNoInternet from './ui/WarningNoInternet';
import ApiErrorModale from './ui/ApiErrorModale';
import ApiUpdateModale from './ui/ApiUpdateModale';
import MaintenanceModeModale from './ui/MaintenanceModeModale';

import Login from './Login';
import Planning from './Planning';
import Dashboard from './Dashboard';
import Modales from './Modales';
import Settings from './Settings';
import Users from './Users';
import AppointmentsToConfirm from './AppointmentsToConfirm';
import Terms from './Terms';
import Inbox from './Inbox';
import Websites from './Websites';
import Account from './Account';
import Logs from './Logs';
import Payments from './Payments';

import AppHeader from './ui/AppHeader';
import ToolBar from './ui/ToolBar';
import Timer from './controllers/Timer';
import Pusher from './controllers/Pusher';

import logo from '../assets/logo.png';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css';
import '../scss/fonts.scss';
import '../scss/index.scss';
import '../scss/mobile.scss';
import '../scss/dashboard.scss';
import '../scss/planning.scss';
import '../scss/appointment.scss';
import '../scss/settings.scss';
import '../scss/users.scss';
import '../scss/inbox.scss';
import '../scss/website.scss';
import '../scss/palettes.scss';
import '../scss/account.scss';
import '../scss/drive.scss';
import '../scss/calendar.scss';
import '../scss/logs.scss';
import '../scss/visio.scss';
import '../scss/payments.scss';
import '../scss/clients-themes.scss';


class SprelClientPro extends PureComponent {

	state = { mounted: false, isJsError: false };

	componentDidCatch(error, errorInfo) {
		logError(error, errorInfo);
		this.setState({isJsError: true});
	}

	listenFirebaseAuthChanged = () => {
		const { setUserToken } = this.props.actions;
		return new Promise(resolve => {
			firebase.auth().onAuthStateChanged(async user => {
				if (user) {
					const token = await user.getIdToken();
					await setUserToken(token);
				}
			    resolve(user ? true : false);
			});
		});
	}

	componentDidMount = async () => {

		// Set Moment
		moment.tz.setDefault('Europe/Paris')

		const { getUserInfos, setUserEmail, setUserInitialParams } = this.props.actions;
		const { userId } = this.props;

		setUserInitialParams(window.location.search.substring(1))

		const cookies = new Cookies();
		const cookieEmail = cookies.get(COOKIES.email);
		if (cookieEmail && cookieEmail !== '') {
			await setUserEmail(cookieEmail);
		}

		const isUserFirebaseLogged = await this.listenFirebaseAuthChanged();
		if (isUserFirebaseLogged) {
			// Getting user informations
			if (!userId || userId === '') await getUserInfos();
		}

		this.setState({mounted: true});
	}

	render() {
		const { logged, hasInternet, hasApiError, authorized, selectedPlanning, hasUpdateRequired, maintenanceMode, locale } = this.props;
		const { mounted, isJsError } = this.state;

		if (!mounted) return (
			<div className="root app_splash-screen">
				<CssBaseline />
				<div className="tac mt40">
					<div className="app_main-loader">
						<img className="app-header_logo" src={logo} alt="Sprel Logo" />
						<div className="logo-login-pro">PRO</div>
					</div>
				</div>
				<div className="tac mt20">
					<div className="app-loader">
						<LinearProgress />
					</div>
				</div>
				{ !hasInternet && <WarningNoInternet />}
				<div className='app_splash_version'>
					version {VERSION}
				</div>
			</div>
		);

		if (isJsError) return <JsError />

		if (logged && !authorized) return <NotAuthorized />

		moment.locale(locale);

		return (
			<div className={cn('root', `root--${selectedPlanning}`)}>
				<CssBaseline />
				<ScrollTop>
					<MuiThemeProvider theme={theme}>
						<div className="app">
							{logged && <AppHeader />}
							{logged && <ToolBar />}
							<main className="playground">
								<Switch>
									{!logged && <Route exact path={routes.login} component={Login} />}
									{!logged && <Redirect to={routes.login} />}
									{logged && <Route exact path={routes.dashboard} component={Dashboard} />}
									{logged && <Route exact path={routes.planning} component={Planning} />}
									{logged && <Route exact path={routes.settings} component={Settings} />}
									{logged && <Route exact path={routes.users} component={Users} />}
									{logged && <Route exact path={routes.rdvs} component={AppointmentsToConfirm} />}
									{logged && <Route exact path={routes.inbox} component={Inbox} /> }
									{logged && <Route exact path={routes.websites} component={Websites} /> }
									{logged && <Route exact path={routes.account} component={Account} /> }
									{logged && <Route exact path={routes.logs} component={Logs} /> }
									{logged && <Route exact path={routes.payments} component={Payments} /> }
									<Route exact path={routes.terms} component={Terms} />
									{logged && <Redirect to={routes.dashboard} />}
								</Switch>
							</main>
							{logged && <Modales />}
							<ToastContainer
								position={toast.POSITION.BOTTOM_LEFT}
								autoClose={4000}
								closeOnClick
								pauseOnHover
								transition={Flip}
								draggablePercent={40}
								closeButton={false}
							/>
						</div>
					</MuiThemeProvider>
				</ScrollTop>
				<DeviceChecker />
				<BrowserChecker />
				{logged && <Timer />}
				{logged && <Pusher />}
				{ !hasInternet && <WarningNoInternet />}
				{ hasApiError && <ApiErrorModale />}
				{ hasUpdateRequired && <ApiUpdateModale />}
				{ maintenanceMode && <MaintenanceModeModale />}
			</div>
		);
	}
}

const mapStateToProps = ({env, user, ui, plannings}) => ({
	locale: ui.locale,
	logged: env.logged,
	userId: user.id,
	authorized: env.logged && user && user.isPro,
	hasInternet: env.hasInternet,
	hasApiError: ui.apiError.show,
	hasUpdateRequired: ui.apiUpdateRequired.show,
	maintenanceMode: ui.maintenanceMode.show,
	selectedPlanning: plannings.selected || ''
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getUserInfos, setUserToken, setUserEmail, setUserInitialParams }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SprelClientPro));
