import { post, put, remove, get } from './index';
import mapper from '../mappers/messages';

export const loadMessages = ({planningId}) => {
	const endpoint = `plannings/${planningId}/messages`;
	return get({endpoint});
}

export const getMessage = ({planningId, messageId}) => {
	const endpoint = `plannings/${planningId}/messages/${messageId}`;
	return get({endpoint});
}

export const updateMessageState = ({planningId, messageId, ...query}) => {
	const endpoint = `plannings/${planningId}/messages/${messageId}`;
	const params = mapper.updateMessageState.toApi(query);
	return put({endpoint, params});
}

export const setMessageUnread = ({planningId, messageId}) => {
	const endpoint = `plannings/${planningId}/messages/${messageId}`;
	const params = mapper.setMessageUnread.toApi();
	return put({endpoint, params});
}

export const send = ({planningId, ...query}) => {
	const endpoint = `plannings/${planningId}/send-email`;
	const params = mapper.send.toApi(query);
	return post({endpoint, params});
}

export const removeForever = ({planningId, messageId}) => {
	const endpoint = `plannings/${planningId}/messages/${messageId}`;
	return remove({endpoint});
}
