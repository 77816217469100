export const defaultBlocksPositions = {
	adminTopAlert: 0,
	information: 1,
	direction: 2,
	schedule: 3,
	contact: 4,
	school: 5,
	pricing: 6,
	free: 7,
	free2: 8,
	free3: 9,
	adminBottomAlert: 10,
}


export const getToolbar = lexique => {
	return {
		display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'LINK_BUTTONS'/*, 'IMAGE_BUTTON'*/],
		INLINE_STYLE_BUTTONS: [
			{label: lexique.bold, style: 'BOLD'},
			{label: lexique.italic, style: 'ITALIC'},
			{label: lexique.underline, style: 'UNDERLINE'},
			{label: lexique.strikethrough, style: 'STRIKETHROUGH'},
		],
		BLOCK_TYPE_BUTTONS: [
			{label: lexique.ul, style: 'unordered-list-item'},
			{label: lexique.ol, style: 'ordered-list-item'}
		],
		BLOCK_TYPE_DROPDOWN: [
			{label: lexique.normal, style: 'unstyled'},
			{label: lexique.title1, style: 'header-one'},
			{label: lexique.title2, style: 'header-two'},
			{label: lexique.title3, style: 'header-three'}
		],
	};
}
