import React, { useCallback, useRef, memo } from 'react';
import { Tooltip } from 'react-tippy';
import { makeStyles, IconButton } from '@material-ui/core';

import { VideocamOff as VideoOffIcon, Videocam as VideoOnIcon } from '@material-ui/icons';

import { useDevices, useLocalVideoToggle } from '../hooks';
import { useLexique } from '../../../locales';

const useStyle = makeStyles(() => ({
    icon: {
        color: '#fff',
        border: '1px solid #fff',
        marginLeft: 4,
        marginRight: 4,
    }
}))

const ToggleVideoButton = ({ disabled }) => {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    const lexique = useLexique('visio.localControls');
    const classes = useStyle();

    return (
        <Tooltip position="top" title={lexique[`cam${isVideoEnabled ? 'Off' : 'On'}`]}>
            <IconButton
                onClick={toggleVideo}
                disabled={!hasVideoInputDevices || disabled}
                className={classes.icon}
            >
                { isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon /> }
            </IconButton>
        </Tooltip>
    )
}

export default memo(ToggleVideoButton);