import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as UI from '../constants/ui';
import * as PLANNING from '../constants/planning';
import * as APPOINTMENT from '../constants/appointment';

const MODALES = ['conflicts', 'error', 'confirmDeleteAppointment', 'confirmDiscardDuplicates', 'confirmToggleCloseDay', 'editDayInformationMessage', 'editDayNotes', 'editDaySpecificSchedules', 'editDaySpecificSchedulesConfirmDiscard', 'pickSchedule', 'adminAddPractitioner', 'deleteAppointmentType', 'addEditAppointmentType', 'editAppointmentTypeProfile', 'editAppointmentTypeSpecificSchedule', 'confirmAcceptUser', 'confirmBlockUser', 'userDetails', 'confirmAppointment', 'drive', 'driveFileEditor', 'sendUserEmail', 'confirmationChangeEvent', 'addNewUser', 'debugModale', 'createPayment'];

const initialState = {
	openMenu: false,
	calendarColumnWidth: 0,
	apiError: {
		show: false,
		code: ''
	},
	apiUpdateRequired: {
		show: false,
	},
	maintenanceMode: {
		show: false,
		message: '',
	},
	device: {
		type: "desktop",
		isDesktop: true,
		isSmallDesktop: false,
		isTabletLandscape: false,
		isTabletPortrait: false,
		isTablet: false,
		isMobile: false,
		isTouch: false,
		isMobileDisplay: false,
	},
	quickView: {
		show: false,
		planningId: null,
		day: null,
		appointmentId: null,
	},
	duplicateMode: {
		show: false,
		showDrawer: false,
		loading: false,
	},
	visio: {
		open: false,
		planningId: null,
		eventId: null,
		minify: false,
		files: [],
	},
	modales: MODALES.reduce((p, c) => Object.assign(p, {[c]: {show: false}}), {}),
};

const reducers = {
	[UI.LEFTMENU_OPEN]: state => update(state, {
		openMenu: { $set: true }
	} ),
	[UI.LEFTMENU_CLOSE]: state => update(state, {
		openMenu: { $set: false }
	} ),
	[UI.DEVICE_CHANGED]: (state, {payload:device}) => update(state, {
		device: {
			type: { $set: device },
			isDesktop: { $set: device === "desktop" },
			isSmallDesktop: { $set: device === "desktop-small" },
			isTabletLandscape: { $set: device === "tablet-landscape" },
			isTabletPortrait: { $set: device === "tablet-portrait" },
			isTablet: { $set: ['tablet-portrait', 'tablet-landscape'].indexOf(device) > -1 },
			isMobile: { $set: device === "mobile" },
			isTouch: { $set: ['mobile', 'tablet-portrait', 'tablet-landscape'].indexOf(device) > -1 },
			isMobileDisplay: { $set: ['mobile', 'tablet-portrait'].indexOf(device) > -1 },
		}
	} ),
	[UI.API_ERROR_SHOW]: (state, {payload:code}) => update(state, {
		apiError: {
			show: { $set: true },
			code: { $set: code }
		}
	}),
	[UI.API_UPDATE_REQUIRED_SHOW]: state => update(state, {
		apiUpdateRequired: {
			show: { $set: true },
		}
	}),
	[UI.SET_MAINTENANCE_MODE]: (state, {payload:message}) => update(state, {
		maintenanceMode: {
			show: { $set: true },
			message: { $set: message },
		}
	}),
	[PLANNING.SET_PLANNING]: state => update(state, {
		quickView: {
			show: { $set: false },
		},
		duplicateMode: {
			show: { $set: false },
			showDrawer: { $set: false },
		}
	}),
	[UI.MODALE_SHOW]: (state, {payload:{modale, ...datas}}) => update(state, {
		modales: {
			[modale]: {
				$set: {
					show: true,
					...datas
				}
			}
		}
	}),
	[UI.MODALE_UPDATE]: (state, {payload:{modale, ...datas}}) => update(state, {
		modales: {
			[modale]: {
				$merge: {
					...datas
				}
			}
		}
	}),
	[UI.MODALE_CLOSE]: (state, {payload:{modale}}) => update(state, {
		modales: {
			[modale]: {
				show: { $set: false },
			}
		}
	}),
	[UI.QUICKVIEW_SHOW]: (state, {payload:{planningId, day,	appointmentId}}) => update(state, {
		quickView: {
			$set: {
				show: true,
				planningId,
				day,
				appointmentId,
			}
		}
	}),
	[UI.QUICKVIEW_CLOSE]: state => update(state, {
		quickView: {
			show: { $set: false },
		}
	}),
	[UI.DUPLICATEMODE_ENTER]: state => update(state, {
		duplicateMode: {
			show: { $set: true },
		}
	}),
	[UI.DUPLICATEMODE_EXIT]: state => update(state, {
		duplicateMode: {
			show: { $set: false },
			loading: { $set: false },
			showDrawer: { $set: false },
		}
	}),
	[UI.DUPLICATEMODE_DRAWER_OPEN]: state => update(state, {
		duplicateMode: {
			showDrawer: { $set: true },
		}
	}),
	[UI.DUPLICATEMODE_DRAWER_CLOSE]: state => update(state, {
		duplicateMode: {
			showDrawer: { $set: false },
		}
	}),
	[APPOINTMENT.DUPLICATES_SAVE_REQUEST]: state => update(state, {
		duplicateMode: {
			loading: { $set: true },
		}
	}),
	[UI.SET_CALENDAR_COLUMN_WIDTH]: (state, {payload: height}) => update(state, {
		calendarColumnWidth: { $set: height }
	}),
	[UI.VISIO_SET]: (state, {payload: visioDatas}) => update(state, {
		visio: { 
			$set: {
				...visioDatas,
				connected: false,
				open: true,
				files: [],
			}
		}
	}),
	[UI.VISIO_CLOSE]: state => update(state, {
		visio: {
			$merge: initialState.visio
		}
	}),
	[UI.VISIO_MINIFY_TOGGLE]: state => update(state, {
		visio: {
			minify: {
				$set: !state.visio.minify
			}
		}
	}),
	[UI.VISIO_FILES_RECEIVED]: (state, {payload: files}) => update(state, {
		visio: {
			files: {
				$push: files
			}
		}
	}),
}


export default createReducer(initialState, reducers);
