import { defaultBlocksPositions } from '../config/website';
import { is } from '../libs/validators';

const mapperBlocks = {
	fromApi: {
		admin_top_alert: 'adminTopAlert',
		admin_bottom_alert: 'adminBottomAlert',
		contact_form: 'contactForm',
	},
	toApi: {
		adminTopAlert: 'admin_top_alert',
		adminBottomAlert: 'admin_bottom_alert',
		contactForm: 'contact_form',
	}
}

const mapBlock = {
	fromApi: block => Object.keys(mapperBlocks.fromApi).indexOf(block) > -1 ? mapperBlocks.fromApi[block] : block,
	toApi: block => Object.keys(mapperBlocks.toApi).indexOf(block) > -1 ? mapperBlocks.toApi[block] : block,
}


const getWebsite = {
	fromApi: ({id, blocks, information, admin_top_alert, admin_bottom_alert, direction, schedule, contact, contact_form, pricing, school, free, free2, free3, blocks_position, configuration, palette, title, subtitle, chunk, description}) => ({
		id,
		blocks: blocks ? blocks.map(block => mapBlock.fromApi(block)) : [],
		blocksPosition: blocks_position || defaultBlocksPositions,
		information: information ? {
			vitalCard: information.vital_card,
			paymentMethods: information.payment_methods,
			free: information.free,
		} : null,
		adminTopAlert: admin_top_alert,
		adminBottomAlert: admin_bottom_alert,
		direction: direction ? {
			address: direction.address,
			lat: direction.lat,
			lng: direction.lng,
			additionalInfos: direction.additional_infos,
			free: direction.free,
		} : null,
		schedule: schedule ? {
			free: schedule.free,
			days: schedule.days || [],
		} : null,
		contact: contact ? {
			free: contact.free,
			phoneNumbers: contact.phone_numbers,
		} : null,
		configuration: configuration ? {
			useContentFree2: configuration.use_content_free_2 || false,
			useContentFree3: configuration.use_content_free_3 || false,
		} : { },
		contactForm: contact_form ? {
			active: contact_form.active,
			level: contact_form.level || 'none',
			information: contact_form.information,
			success: contact_form.success,
		} : {
			active: false,
			level: 'none',
			information: '',
			success: '',
		},
		pricing,
		school,
		free,
		free2,
		free3,
		palette,
		title,
		subtitle,
		description,
		chunk: chunk || false,
	}),
}

const changeSectionActivation = {
	toApi: ({blocks}) => ({
		section: 'blocks',
		datas: blocks ? blocks.map(block => mapBlock.toApi(block)) : [],
	}),
}

const updateSection = {
	toApi: ({section, datas}) => ({
		section: mapBlock.toApi(section),
		datas: typeof datas === "string" ? datas : Object.assign({},
			is(datas.vitalCard) ? { vital_card: datas.vitalCard } : null,
			is(datas.free) ? { free: datas.free } : null,
			is(datas.paymentMethods) ? { payment_methods: datas.paymentMethods } : null,
			is(datas.value) ? { value: datas.value } : null,
			is(datas.level) ? { level: datas.level } : null,
			is(datas.address) ? { address: datas.address } : null,
			is(datas.additionalInfos) ? { additional_infos: datas.additionalInfos } : null,
			is(datas.lat) ? { lat: datas.lat } : null,
			is(datas.lng) ? { lng: datas.lng } : null,
			is(datas.phoneNumbers) ? { phone_numbers: datas.phoneNumbers } : null,
			is(datas.days) ? { days: datas.days } : null,
			is(datas.information) ? { information: datas.information } : null,
			is(datas.success) ? { success: datas.success } : null,
			is(datas.active) ? { active: datas.active } : null,
		),
	}),
}

const updateMetas = {
	toApi: ({title, subtitle, description}) => ({title, subtitle, description})
}

export default {
	getWebsite,
	changeSectionActivation,
	updateSection,
	updateMetas,
};
