import moment from 'moment-timezone';

export const getAvailableAppointments = ({appointments, start, daySchedules}) => {
    const day = moment(start).format('YYYY-MM-DD');
    const weekDay = moment(day).weekday();

    const availables = appointments.filter(app => {
        // Looping thru all appointments
        if (!app.activeForUser) return false;
        // Cheking day appointment schedule
        const daySchedule = daySchedules ? daySchedules.find(ds => ds.id === app.id) : null;
        if (daySchedule) {
            if (daySchedule.value === "blocked") return false;
            if (daySchedule.value !== '') return isAppointmentAvailable({start, period: daySchedule.value});
        }

        // Checking for particular period
        const specificSchedules = app.specificSchedules ? app.specificSchedules.find(ss => moment(day).isSameOrAfter(moment(ss.from)) && moment(day).isSameOrBefore(moment(ss.to))) : null;
        if (specificSchedules) {
            const ssDay = specificSchedules.schedules[weekDay];
            if (ssDay === '') return false;
            return isAppointmentAvailable({start, period: ssDay});
        }

        // Default period
        const defaultPeriod = app.schedules[weekDay];
        if (defaultPeriod === '') return false;
        return isAppointmentAvailable({start, period: defaultPeriod});
    });

    return availables;

}

export const isAppointmentAvailable = ({start, period}) => {
    const slices = period.split(',');

    const day = moment(start).format('YYYY-MM-DD');
    const slot = moment(start);

    return slices.findIndex(slice => {
        const parts = slice.split('-');
        const scPartStart = moment(`${day} ${parts[0]}`, 'YYYY-MM-DD HHmm');
        const scPartEnd = moment(`${day} ${parts[1]}`, 'YYYY-MM-DD HHmm');
        return slot.isSameOrAfter(scPartStart) && slot.isBefore(scPartEnd);
    }) > -1
}


export const getUserRightsOnPlanning = (user, planningId) => {

	const rights = ['acceptUser', 'blockUser', 'createUser', 'confirmAppointments', 'addAppointment', 'editAppointment', 'deleteAppointment', 'settings', 'account', 'inbox', 'sendEmail', 'website', 'drive', 'day', 'dayNote', 'daySchedule', 'dayInformation', 'dayClose', 'logs', 'payments'];

	const getRights = b => rights.reduce((p, c) => Object.assign({}, p, {[c]: b}), {});

    if (user.hasAccessTo.default === planningId || user.isAdmin) return getRights(true);

    const other = user.hasAccessTo.others.find(o => o.id === planningId);
	if (!other || !other.rights) return getRights(false);

	if (other.rights.all) return getRights(true);

    return other.rights;
}

export const getSlotMatchingBlockingRule = ({start, rules, day}) => {
	if (!rules || rules.length === 0) return null;

	const matchingRule = rules.find(rule => {
		switch (rule.type) {
			case "blockBeforeDate": {
				const beforeDate = moment(rule.value).startOf("day");
				return moment(start).startOf('day').isBefore(beforeDate);
			}
			case "blockAfterDate": {
				const afterDate = moment(rule.value).endOf("day");
				return moment(start).startOf('day').isAfter(afterDate);
			}
			case "emergency": {
				if (rule.appointmentsTypesIds) return false;
				if (rule.startOf === "day") {
					const openAt = moment(start).startOf('day').subtract(rule.minutes, 'minutes');
					const slotOpened = moment().isAfter(openAt);
					if (!slotOpened && moment(start).minute() === rule.minutes) return true;
				}
				return false;
			}
			case "manualEmergency": {
				if (moment(start).minute() !== rule.minutes) return false;

				if (!day || !day.unblockedSlots || day.unblockedSlots.length === 0) return true;

				const isUnblocked = day.unblockedSlots.find(us => moment(us).isSame(moment(start)));

				return isUnblocked ? false : true;
			}
			default:
				return false;
		}
	});

	return matchingRule;
}

export const checkEventsConflicts = ({appointments, id, to:{start, end}}) => {
    const conflicts = appointments.filter(a => {
        if (a.id === id) return false;    // Checking himself

        const checkStart = moment(a.start).unix();
        const checkEnd = moment(a.start).add(a.duration, "minutes").unix();
        const toStart = moment(start).unix();
        const toEnd = moment(end).unix();

		if (toStart <= checkStart && toStart < checkEnd && toEnd > checkStart && toEnd >= checkEnd) return true;
		if (toStart <= checkStart && toStart < checkEnd && toEnd > checkStart && toEnd <= checkEnd) return true;
		if (toStart >= checkStart && toStart < checkEnd && checkEnd > checkStart && checkEnd >= toEnd) return true;
		if (toStart >= checkStart && toStart < checkEnd && toEnd > checkEnd && checkEnd <= toEnd) return true;

        return false;
    });

    return conflicts;
}
