import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _sortBy from 'lodash.sortby';

import LinearProgress from '@material-ui/core/LinearProgress';

import Information from './modules/Information';
import AdminAlert from './modules/AdminAlert';
import FreeZone from './modules/FreeZone';
import Direction from './modules/Direction';
import Contact from './modules/Contact';
import Schedule from './modules/Schedule';
import TopBar from './modules/TopBar';

import getLexique from '../../locales';
import { getWebsite } from '../../actions/websites';

class WebsiteForm extends PureComponent {

	componentDidMount() {
		const { id, actions: {getWebsite}} = this.props;
		if (!id) return;

		getWebsite(id);
	}

	renderSection = section => {
		const { configuration } = this.props.website;
		switch (section) {
			case "direction":
				return <Direction key={section} />;
			/*case "team":
				return <Team key={section} members={website.team} title={lexique.team} expertises={lexique.expertises} />;*/
			case "schedule":
				return <Schedule key={section} />;
			case "contact":
				return <Contact key={section} />;
			case "information":
				return <Information key={section} />;
			/*
			case "relations":
				return <Relations key={section} title={lexique.relations} relations={website.relations} />;
			*/
			case "adminTopAlert":
			case "adminBottomAlert":
				return <AdminAlert key={section} id={section} />
			case "pricing":
			case "school":
			case "free":
				return <FreeZone key={section} id={section} />
			case "free2":
				return configuration.useContentFree2 ? <FreeZone key={section} id={section} /> : null;
			case "free3":
				return configuration.useContentFree3 ? <FreeZone key={section} id={section} /> : null;
			default:
				return null;
		}
	}

	render() {
		const { loading, blocks, blocksPosition, palette } = this.props.website;
		if (!blocks) return null;

		if (loading) return <LinearProgress color="secondary" />

		const allBlocks = Object.keys(blocksPosition);
		const sortedBlocks = _sortBy(allBlocks, [o => blocksPosition[o]]);

		return (
			<div className={`website--palette--${palette || 'blue'}`}>
				<div className="website_header" />
				<TopBar {...this.props.website} />
				<div className="website_form">
					{ sortedBlocks.map(block => this.renderSection(block)) }
				</div>
			</div>
		)
	}
}


const mapStateToProps = ({env, website}) => ({
	lexique: getLexique(env.locale).websites,
	website,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getWebsite }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteForm);
