import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import browser from 'browser-detect';

import { randString } from '../libs/random';

import * as USER from '../constants/user';
import * as ENV from '../constants/env';

const initialState = {
	hasInternet: true,
	locale: 'fr-FR',
	browser: browser(),
	token: null,
	logged: false,
	appToken: randString(10),
};

const reducers = {
	[ENV.INTERNET_CHANGED]: (state, {payload:has}) => update(state, {
		hasInternet: { $set: has }
	} ),
	[USER.DISCONNECT_SUCCESS]: state => update(state, {
		token: { $set: null },
		logged: { $set: false }
	} ),
	[USER.LOGIN_SUCCESS]: state => update(state, {
		logged: { $set: true }
	} ),
	[USER.SET_TOKEN]: (state, {payload:token}) => update(state, {
		token: { $set: token }
	} ),
}


export default createReducer(initialState, reducers);
