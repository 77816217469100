import { get } from './index';
import mapper from '../mappers/logs';

export const getLogs = ({planningId, ...query}) => {
	const endpoint = `plannings/${planningId}/logs`;
	const params = mapper.getLogs.toApi(query);
	return get({endpoint, params});
}

export const getLogsAboutUser = async ({planningId, userId, ...query}) => {
	const endpoint = `plannings/${planningId}/logs/about/${userId}`;
	const params = mapper.getLogs.toApi(query);
	return get({endpoint, params});
}

export const getLogsOfUser = async ({planningId, userId, ...query}) => {
	const endpoint = `plannings/${planningId}/logs/of/${userId}`;
	const params = mapper.getLogs.toApi(query);
	return get({endpoint, params});
}
