import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as DRIVE from '../constants/drive';
import * as PLANNING from '../constants/planning';

const initialState = {
	userId: null,
	loading: false,
	userPassword: '',
	files: [],
	appointments: [],
	transfering: {},
};

const reducers = {
	[PLANNING.LOAD_REQUEST]: state => update(state, {
		userPassword: { $set: '' },
		userId: { $set: null },
	} ),
	[DRIVE.SET_USER_ID]: (state, {payload: userId}) => update(state, {
		loading: { $set: true },
		userId: { $set: userId },
		files: { $set: [] },
		appointments: { $set: [] },
	} ),
	[DRIVE.SET_USER_PASSWORD]: (state, {payload: userPassword}) => update(state, {
		userPassword: { $set: userPassword },
	} ),
	[DRIVE.SET_CONTENT]: (state, {payload: {files, appointments}}) => update(state, {
		loading: { $set: false },
		files: { $set: files },
		appointments: { $set: appointments },
	} ),
	[DRIVE.UPLOADS_START]: (state, {payload: files}) => update(state, {
		transfering: { $set: files.reduce((p, c) => Object.assign(p, {[c.name]: 'crypt'}), {}) },
	} ),
	[DRIVE.UPLOADS_COMPLETE]: state => update(state, {
		transfering: { $set: {} },
	} ),
	[DRIVE.UPDATE_UPLOAD_STEP]: (state, {payload: {file, step}}) => update(state, {
		transfering: {
			[file]: { $set: step }
		},
	} ),
	[DRIVE.FILE_DELETE]: (state, {payload: {file, folder}}) => update(state, {
		files: { $set: state.files.filter(f => f.filename !== file || f.folder !== folder) },
	} ),
}


export default createReducer(initialState, reducers);
