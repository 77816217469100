import moment from 'moment-timezone';
import { is } from '../libs/validators';
import PublicHoliday from '../libs/public-holiday-france';

const getPublicHoliday = conf => PublicHoliday.keys.reduce((prev, key) => Object.assign(prev, {
	[`publicHoliday${key}`]: conf[`public_holiday_${key}`] || false
}), {});

const loadPlanning = {
	fromApi: ({configuration, env, id, appointments_types, appointments_to_confirm, profile, websites, drive_hash, unread_messages, payments}) => ({
		id,
		profile: {
			address: profile.address || '',
			geoLocation: profile.geo_location || '',
			phoneNumber: profile.phone_number || '',
			firstName: profile.first_name,
			lastName: profile.last_name,
			email: profile.email,
		},
		env: {
			patient: env.patient,
			contactForm: env.contact_form,
			websites: env.websites,
			usePayments: env.use_payments,
			useAnimals: env.use_animals || false,
		},
		configuration: Object.assign({
			start: configuration.start,
			end: configuration.end,
			step: configuration.step,
			showWeekEnd: configuration.show_week_end,
			showSunday: configuration.show_sunday,
			rollingDays: configuration.rolling_days,
			shouldConfirmAppointment: configuration.should_confirm_appointment,
			shouldAcceptUser: configuration.should_accept_user,
			preventBookingBefore: configuration.prevent_booking_before,
			allowCancelUntil: configuration.allow_cancel_until,
			bookableForNext: configuration.bookable_for_next,
			sendReminderAt: configuration.send_reminder_at,
			acceptOnlyCertified: configuration.accept_only_certified,
			highlightBookable: configuration.highlight_bookable || false,
			highlightNonBookable: configuration.highlight_non_bookable || false,
			highlightBlockedByRule: configuration.highlight_blocked_by_rule || false,
			highContrastDesktop: configuration.high_contrast_desktop || false,
			highContrastMobile: configuration.high_contrast_mobile || false,
			notificationsEmail: configuration.notifications_email || '',
			notificationEmailOnAdd: configuration.notification_email_on_add || false,
			notificationEmailOnCancel: configuration.notification_email_on_cancel || false,
			appointmentsAdditionalFields: configuration.appointments_additional_fields || [],
			doNotAcceptNewUser: configuration.do_not_accept_new_user || false,
			defaultAppointmentId: configuration.default_appointment_id || '',
			displayEventTooltip: configuration.display_event_tooltip || false,
			autoConfirmEventChange: configuration.auto_confirm_event_change || false,
			rules: mapRules(configuration.rules),
			isVisioEnabled: configuration.visio_enabled || false,
		}, getPublicHoliday(configuration)),
		appointmentsTypes: appointments_types ? Object.entries(appointments_types).map(([key, value]) => ({
			id: key,
			label: value.label,
			color: value.color,
			duration: value.duration,
			schedules: value.schedules,
			activeForUser: value.active_for_user,
			onlyRealSlot: value.only_real_slot,
			isVisio: value.is_visio ?? false,
			defaultPaymentAmount: value.default_payment_amount,
			profile: value.profile ? {
				address: value.profile.address,
				geoLocation: value.profile.geo_location,
				phoneNumber: value.profile.phone_number,
			} : null,
			specificSchedules: value.specific_schedules ? Object.entries(value.specific_schedules).map(([key, v]) => ({
				id: key,
				fromUnix: moment(v.from).unix(),
				...v
			})) : [],
		})) : [],
		appointmentsToConfirm: appointments_to_confirm ? mapAppointments(appointments_to_confirm) : [],
		inbox: {
			active: 'emails',
			messages: null,
			unread: unread_messages,
		},
		websites: websites ? Object.entries(websites).map(([key, value]) => ({
			id: key,
			url: value,
		})) : [],
		billing: {
			loading: false,
			loaded: false,
		},
		payments: {
			loading: false,
			loaded: false,
		},
		paymentsBalance: {
			amount: 0
		},
		canCreatePayments: payments?.account_id !== undefined && payments?.account_id !== null,
		driveHash: drive_hash || '',
	}),
}

const mapRules = rules => rules ? rules.map(rule => Object.assign({
		type: rule.type,
	},
	rule.value ? { value: rule.value } : null,
	rule.minus ? { minus: rule.minus } : null,
	rule.minutes ? { minutes: rule.minutes } : null,
	rule.start_of ? { startOf: rule.start_of } : null,
	rule.appointments_types_ids ? { appointmentsTypesIds: rule.appointments_types_ids } : null,
)) : []

export const getPlanningPatients = {
	fromApi: ({id, users}) => ({
		id,
		users: users ? users.map(user => mapUser(user)) : [],
		usersToAccept: users ? users.filter(user => user.status === 'pending').map(user => user.id) : [],
		usersList: users ? users.reduce((p, user) => [...p, ...mapUserList(user)], []) : [],
	}),
}

export const mapUserList = user => Object.entries(user.family).map(([memberId, member]) => ({
	id: user.id,
	isBlocked: user.status === 'blocked',
	email: user.email,
	phoneNumber: user.information.phone_number,
	address: user.information.address,
	familyMemberId: memberId,
	firstName: member.first_name,
	lastName: member.last_name,
	birthdate: member.birthdate,
	isAnimal:  member.is_animal || false,
	gender: member.gender,
}))

export const mapUser = user => ({
	id: user.id,
	email: user.email,
	phoneNumber: user.information.phone_number,
	address: user.information.address,
	isCertified: user.is_certified,
	isRequestingAccess: user.status === 'pending',
	isBlocked: user.status === 'blocked',
	since: user.since || '',
	mainMemberName: Object.entries(user.family).find(([, member]) => member.first_created === true)[1].last_name,
	family: Object.entries(user.family).map(([memberId, member]) => ({
		id: memberId,
		firstName: member.first_name,
		lastName: member.last_name,
		birthdate: member.birthdate,
		gender: member.gender,
		isAnimal: member.is_animal || false,
		isMain: member.first_created || false,
	})),
})

const loadPlanningDay = {
	fromApi: ({id, params, appointments, day}) => ({
    	planningId: id,
    	day,
		appointments: appointments ? mapAppointments(appointments) : [],
		unblockedSlots: params ? params.unblocked_slots || [] : [],
		params: params ? {
            isClosed: params.is_closed === null || params.is_closed === undefined ? false : params.is_closed,
			isPast: params.is_past || moment(day).isBefore(moment().startOf("day")),
			specificSchedules: params.specific_schedules || [],
			note: params.note || '',
            information: params.information_user || '',
        } : {
			isClosed: false,
			isPast: moment(day).isBefore(moment().startOf("day")),
			specificSchedules: [],
			note: '',
			information: '',
		},
	})
}

const loadPlanningDays = {
	fromApi: days => days ? days.map(loadPlanningDay.fromApi) : [],
	toApi: days => ({days}),
}

export const mapAppointments = appointments => appointments.map(app => mapAppointment(app));

export const mapAppointment = app => ({
	id: app.id,
	start: app.start,
	additionalField0: app.additional_field_0 || null,
	additionalField1: app.additional_field_1 || null,
	additionalField2: app.additional_field_2 || null,
	pvpp: app.pvpp || false,
	updatedAt: moment().format(),
    created: {
        at: app.created_at,
        byUser: app.created_by_user,
        by: app.created_by
    },
    confirmed: {
		at: app.confirmed_at || '',
		by: app.confirmed_by || '',
	},
    duration: app.duration,
    isPast: app.is_past || moment(app.start).isBefore(moment()),
    information: {
		user: app.information_user,
		admin: app.information_admin
	},
    typeId: app.appointment_type_id,
    with: {
        id: app.user_id,
        email: app.user_email || '',
		familyMemberId: app.user_family_member_id || '',
        firstName: app.user_first_name || '',
        lastName: app.user_last_name || app.user_name || '',
		isAnimal: app.user_is_animal || false,
		phoneNumber: app.user_phone_number || '',
		main: app.user_main ? {
			firstName: app.user_main.first_name,
			lastName: app.user_main.last_name,
		} : null,
    },
});

const closePlanningDay = {
	toApi: () => ({is_closed: true}),
}

const openPlanningDay = {
	toApi: () => ({is_closed: false}),
}

const updateDayInformationMessage = {
	toApi: ({value, level}) => ({information_user: {value, level}}),
}

const updateDayNotes = {
	toApi: ({value}) => ({note: value}),
}

const updateDaySpecificSchedules = {
	toApi: ({value}) => ({specific_schedules: value}),
}

const createPlanning = {
	fromApi: ({id}) => ({id}),
	toApi: ({firstName, lastName, particule, phoneNumber, email}) => ({
		first_name: firstName || '',
		last_name: lastName || '',
		particule: particule || '',
		phone_number: phoneNumber || '',
		email: email || '',
	})
}

const createAppointmentType = {
	fromApi: ({id}) => ({id}),
	toApi: ({label, color, duration, schedules, activeForUser, onlyRealSlot, isVisio, defaultPaymentAmount}) => ({
		label,
		color,
		duration,
		schedules,
		active_for_user: activeForUser,
		only_real_slot: onlyRealSlot,
		is_visio: isVisio,
		default_payment_amount: defaultPaymentAmount || ""
	})
}

const updateAppointmentType = {
	toApi: ({label, color, duration, schedules, activeForUser, onlyRealSlot, isVisio, defaultPaymentAmount}) => ({
		label,
		color,
		duration,
		schedules,
		active_for_user: activeForUser,
		only_real_slot: onlyRealSlot,
		is_visio: isVisio,
		default_payment_amount: defaultPaymentAmount || ""
	})
}

const updateAppointmentTypeProfile = {
	toApi: ({profile}) => ({
		address: profile.address,
		phone_number: profile.phoneNumber,
		geo_location: profile.geoLocation,
	}),
}

const addAppointmentTypeSpecificSchedule = {
	fromApi: ({id}) => ({id}),
	toApi: ({from, to, schedules}) => ({
		from, to, schedules
	}),
}

const updateAppointmentTypeSpecificSchedule = {
	toApi: ({from, to, schedules}) => ({
		from, to, schedules
	}),
}

const updatePlanningConfig = {
	toApi: ({start, end, step, showWeekEnd, showSunday, shouldConfirmAppointment, shouldAcceptUser, preventBookingBefore, allowCancelUntil, bookableForNext, sendReminderAt, acceptOnlyCertified, rollingDays, highlightBookable, highlightNonBookable, highlightBlockedByRule, highContrastDesktop, highContrastMobile, notificationsEmail, notificationEmailOnAdd, notificationEmailOnCancel, doNotAcceptNewUser, displayEventTooltip, autoConfirmEventChange, ...publicHoliday}) => Object.assign({},
		start ? { start } : null,
		end ? { end } : null,
		step ? { step: parseInt(step, 10) } : null,
		is(showWeekEnd) ? { show_week_end: showWeekEnd } : null,
		is(rollingDays) ? { rolling_days: rollingDays } : null,
		is(showSunday) ? { show_sunday: showSunday } : null,
		is(acceptOnlyCertified) ? { accept_only_certified: acceptOnlyCertified } : null,
		is(shouldConfirmAppointment) ? { should_confirm_appointment: shouldConfirmAppointment } : null,
		is(shouldAcceptUser) ? { should_accept_user: shouldAcceptUser } : null,
		is(preventBookingBefore) ? { prevent_booking_before: parseInt(preventBookingBefore, 10) } : null,
		is(allowCancelUntil) ? { allow_cancel_until: parseInt(allowCancelUntil, 10) } : null,
		is(sendReminderAt) ? { send_reminder_at: parseInt(sendReminderAt, 10) } : null,
		is(bookableForNext) ? { bookable_for_next: parseInt(bookableForNext, 10) } : null,
		is(highlightBookable) ? { highlight_bookable: highlightBookable } : null,
		is(highlightNonBookable) ? { highlight_non_bookable: highlightNonBookable } : null,
		is(highlightBlockedByRule) ? { highlight_blocked_by_rule: highlightBlockedByRule } : null,
		is(highContrastDesktop) ? { high_contrast_desktop: highContrastDesktop } : null,
		is(highContrastMobile) ? { high_contrast_mobile: highContrastMobile } : null,
		is(notificationsEmail) ? { notifications_email: notificationsEmail } : null,
		is(notificationEmailOnAdd) ? { notification_email_on_add: notificationEmailOnAdd } : null,
		is(notificationEmailOnCancel) ? { notification_email_on_cancel: notificationEmailOnCancel } : null,
		is(doNotAcceptNewUser) ? { do_not_accept_new_user: doNotAcceptNewUser } : null,
		is(displayEventTooltip) ? { display_event_tooltip: displayEventTooltip } : null,
		is(autoConfirmEventChange) ? { auto_confirm_event_change: autoConfirmEventChange } : null,
		updateHolidayConfig(publicHoliday)
	),
}

const updateHolidayConfig = config => PublicHoliday.keys.reduce((prev, key) => Object.assign(prev,
	is(config[`publicHoliday${key}`]) ? { [`public_holiday_${key}`]: config[`publicHoliday${key}`] } : null
), {});

const updatePlanningProfile = {
	toApi: ({address, geoLocation, phoneNumber}) => Object.assign({},
		is(address) ? { address } : null,
		is(geoLocation) ? { geo_location: geoLocation } : null,
		is(phoneNumber) ? { phone_number: phoneNumber } : null,
	),
}

const getBillingInfos = {
	fromApi: ({trial, end_of_trial, pricing_version, sources, subscriptions, invoices, upcoming_invoice}) => ({
		trial,
		endOfTrial: end_of_trial,
		pricingVersion: pricing_version || '2010',
		creditCard: sources && sources.data && sources.data.length > 0 ? mapCreditCard(sources.data[0].card || sources.data[0]) : null,
		plan: subscriptions && subscriptions.data && subscriptions.data.length > 0 ? {
			id: subscriptions.data[0].id,
			type: subscriptions.data[0].billing === "send_invoice" ? "manual" : "auto",
			start: subscriptions.data[0].current_period_start,
			end: subscriptions.data[0].current_period_end,
			label: subscriptions.data[0].plan.nickname,
			amount: subscriptions.data[0].plan.amount / 100,
			interval: getInterval(subscriptions.data[0].plan),
		} : null,
		invoices: invoices && invoices.data ? invoices.data.map(invoice => mapInvoice(invoice)) : [],
		upcomingInvoice: upcoming_invoice ? mapInvoice(upcoming_invoice) : null,
	})
}

const mapCreditCard = card => ({
	brand:card.brand,
	expMonth: card.exp_month,
	expYear: card.exp_year,
	last4: card.last4,
	id: card.id,
})

const getInterval = plan => {
	if (plan.interval === "month") {
		return plan.interval_count === 1 ? "month" : "6months";
	}

	if (plan.interval === 'year') return "year";

	return "unknown";
}

const mapInvoice = invoice => ({
	date: invoice.created,
	due: invoice.amount_due / 100,
	paid: invoice.amount_paid / 100,
	isPaid: invoice.paid,
	url: invoice.hosted_invoice_url,
	pdf: invoice.invoice_pdf,
	label: invoice.number,
	id: invoice.id,
	start: invoice.lines.data[0].period.start,
	end: invoice.lines.data[0].period.end,
});

const addCreditCardInformation = {
	toApi: ({id}) => ({
		source_id: id,
	}),
	fromApi: card => mapCreditCard(card),
}

const updatePlanPayment = {
	toApi: ({plan, id}) => ({
		plan_method: plan,
		plan_id: id,
	}),
}

const addGlobalSpecificShedule = {
	toApi: ({from, to, schedules}) => ({
		from,
		to,
		schedules,
	}),
	fromApi: ({id}) => ({id}),
}

const unblockSlotByRule = {
	toApi: ({start}) => ({slot: start}),
}

const getPaymentsInfos = {
	fromApi: ({error, account}) => error ? ({noPayments: true}) : ({
		id: account.id,
		canCharge: account.charges_enabled,
		canPayout: account.payouts_enabled,
		disabled: account.requirements.disabled_reason,
		iban: {
			country: account.external_accounts.data[0].country,
			last4:  account.external_accounts.data[0].last4,
		},
		documents: {
			required: account.requirements.currently_due,	//individual.verification.additional_document, individual.verification.document
			pending: account.requirements.pending_verification
		},

	})
}

const registerToPayments = {
	toApi: datas => ({
		account_token: datas.tokenAccount,
		person_token: datas.tokenPerson,
		iban_token: datas.tokenIban,
		email: datas.email,
	}),
	fromApi: ({id}) => ({id})
}

const uploadLegalsDocuments = {
	toApi: datas => ({
		id_front: datas.frontId,
		id_back: datas.backId,
		add_front: datas.frontAdd
	}),
	fromApi: datas => ({
		frontIdToken: datas.id_front_id,
		backIdToken: datas.id_back_id,
		frontAddToken: datas.add_front_id
	})
}

const updateLegalsDocuments = {
	toApi: ({token}) => ({
		person_token: token.id
	}),
}

const createPayment = {
	toApi: ({amount, force}) => ({amount, force}),
	fromApi: ({payments}) => ({
		payments: getUnpaidPayments.fromApi(payments)
	})
}

const getPaymentsBalance = {
	fromApi: ({pending, available, payouts}) => {
		const p = (pending || []).reduce((p, c) => p+c.amount, 0);
		const a = (available || []).reduce((p, c) => p+c.amount, 0);
		const t = (payouts?.data || []).filter(p => p.status === 'in_transit').reduce((p, c) => p+c.amount, 0)
		return {
			transit: t,
			pending: p,
			available: a,
			amount: p+a+t,
		}
	},
}

const getUnpaidPayments = {
	fromApi: items => (items || []).map(i => ({
		id: i.id,
		start: i.appointment_start,
		createdAt: i.created_at,
		lastReminderSentAt: i.last_reminder_sent_at,
		paid: i.paid,
		paidAt: i.paid_at,
		canceled: i.canceled,
		canceledAt: i.canceled_at,
		userId: i.user_id,
		userName: i.user_name,
		amount: i.amount,
		fees: Math.ceil(i.amount*0.04),
		finalAmount: Math.floor(i.amount*0.96),
		planningId: i.practitioner_id,
		receiptUrl: i.receipt_url,
	})),
}

const getPaymentsOverPeriod = {
	fromApi: getUnpaidPayments.fromApi,
	toApi: ({period}) => ({start: period}),
}

const getPaymentsByUser = {
	fromApi: getUnpaidPayments.fromApi,
}

const updatePaymentIban = {
	toApi: datas => ({
		iban_token: datas.tokenIban,
	})
}

export default {
	loadPlanning,
	getPlanningPatients,
    loadPlanningDay,
	loadPlanningDays,
	closePlanningDay,
	openPlanningDay,
	updateDayInformationMessage,
	updateDayNotes,
	updateDaySpecificSchedules,
	createPlanning,
	createAppointmentType,
	updateAppointmentType,
	updateAppointmentTypeProfile,
	addAppointmentTypeSpecificSchedule,
	updateAppointmentTypeSpecificSchedule,
	updatePlanningConfig,
	updatePlanningProfile,
	getBillingInfos,
	addCreditCardInformation,
	updatePlanPayment,
	addGlobalSpecificShedule,
	unblockSlotByRule,
	getPaymentsInfos,
	registerToPayments,
	uploadLegalsDocuments,
	updateLegalsDocuments,
	createPayment,
	getPaymentsBalance,
	getUnpaidPayments,
	getPaymentsOverPeriod,
	getPaymentsByUser,
	updatePaymentIban
};
