import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AddCircleOutline from '@material-ui/icons/AddCircleOutlined';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutlined';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import { closeModalePickSchedule } from '../../actions/ui';

import getLexique from '../../locales';


class PickSchedule extends PureComponent {

	state = { schedule: '', errors: [] };

	onClose = () => {
		this.setState({errors: []});
		this.props.actions.closeModalePickSchedule();
	}

	mapPropsToInnerState = () => {
		const { schedule } = this.props;
		this.setState({
			schedule: schedule || this.state.schedule || '0800-1200,1500-1900',
		});
	}

	componentDidMount() {
		this.mapPropsToInnerState();
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.show && this.props.show) this.mapPropsToInnerState();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		const { errors } = this.state;
		if (errors.length > 0) return;
		this.onClose();
		onConfirm({
			schedule: this.state.schedule,
		});
	}

	onDelete = async () => {
		await this.setState({schedule: ''});
		this.onConfirm();
	}

	checkErrors = schedule => {
		let errors = [];

		const parts = schedule.split(',');
		parts.forEach((part, i) => {
			const sections = part.split('-');
			if (i > 0) {
				const previousSections = parts[i-1].split('-');
				// Checking start is after previous end
				if (moment(sections[0], 'HHmm').isBefore(moment(previousSections[1], 'HHmm'))) errors.push(...[`${i}-0`, `${(i-1)}-1`]);
			}

			// Checking Start if previous End
			if (moment(sections[1], 'HHmm').isBefore(moment(sections[0], 'HHmm'))) errors.push(...[`${i}-0`, `${(i)}-1`]);
		});

		this.setState({errors});
	}

	handleTimeChange = (id, id2) => time => {
		const schedule = this.state.schedule.split(',').map((parts, i) => parts.split('-').map((part, j) => {
			if (id === i && id2 === j) return moment(time).format('HHmm');
			return part;
		}).join('-')).join(',');

		this.setState({schedule});

		this.checkErrors(schedule);
	}

	addNewLine = () => {
		const schedule = `${this.state.schedule},'0000-0000'`;
		this.setState({
			schedule
		});

		this.checkErrors(schedule);
	}

	removeLine = i => () => {
		const schedule = this.state.schedule.split(',').filter((p, id) => i !== id).join(',');
		this.setState({schedule});
		this.checkErrors(schedule);
	}

    render() {
		const { show, lexique, locale } = this.props;
		const { schedule, errors } = this.state;
		const parts = schedule.split(',');

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-pick-schedule-dialog-title"
			>
				<DialogTitle id="modale-pick-schedule-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
						<Fragment>
					{
						parts.map((v, i) => {
							const values = v.split('-');
							return (
								<div className="flex mb10" key={`picker-${i}`}>
									<TimePicker
										className="flex1"
							            ampm={false}
							            label={lexique.from}
							            value={moment(values[0], 'HHmm').toDate()}
							            onChange={this.handleTimeChange(i, 0)}
										error={errors.indexOf(`${i}-0`) > -1}
										minutesStep={5}
									/>
									<TimePicker
										className="flex1"
							            ampm={false}
							            label={lexique.to}
							            value={moment(values[1], 'HHmm').toDate()}
							            onChange={this.handleTimeChange(i, 1)}
										error={errors.indexOf(`${i}-1`) > -1}
										minutesStep={5}
									/>
									{ i > 0 && <IconButton onClick={this.removeLine(i)}>
										<RemoveCircleOutline />
									</IconButton> }
								</div>
							)
						})
					}
						<IconButton onClick={this.addNewLine}>
							<AddCircleOutline />
						</IconButton>
						</Fragment>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<div className="flex1" />
					<Button onClick={this.onDelete} color="primary">
						{lexique.delete}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{pickSchedule:{show, onConfirm, schedule}}}}) => ({
	lexique: getLexique(env.locale).modales.pickSchedule,
	locale: env.locale,
    show,
	onConfirm,
	schedule,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModalePickSchedule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PickSchedule);
