import { useEffect, useRef, useState, useCallback } from "react";
import DetectRTC from 'detectrtc';

import { useDispatch } from "./redux";
//import moment from "moment";

const CONFIG = {
    iceServers: [
	  	{ urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19305" ] },
	  	{ urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
		{ urls: 'stun:stun.services.mozilla.com' },
		{ urls: 'turn:numb.viagenie.ca', credential: 'D3176tJN!TRL^4In%lUp', username: 'damien+viagenie@sprel.io' },
  	],
}

export const useVisio = ({planningId, eventId}) => {

	const [ localStream, errorPerm ] = useLocalStream();
	const [ remoteStream ] = useRemoteStream();
	const { setLocalStream, setAnswerAndCandidates, connected } = usePeerConnection({planningId, eventId, remoteStream});

	useEffect(() => {

		if (!localStream || !remoteStream) return;
		setLocalStream(localStream);

	}, [localStream, setLocalStream, remoteStream])

	return {
		localStream,
		remoteStream,
		errorPerm,
		setAnswerAndCandidates,
		connected
	}
}

const useRemoteStream = () => {
	const [stream] = useState(new MediaStream());

	useEffect(() => () => {
		if (!stream) return;
		stream.getTracks().forEach(track => {
			track.stop();
		});
	}, [stream]);

	return [
		stream,
	]
}

const useLocalStream = () => {

	const interv = useRef();
	const [stream, setStream] = useState(null);
	const [errorPerm, setErrorPerm] = useState(false);

    const initStream = useCallback(async () => {

		try {
			const s = await navigator.mediaDevices.getUserMedia({
        		video: DetectRTC.hasWebcam,
				audio: DetectRTC.hasMicrophone
			});
			clearInterval(interv.current);
			setStream(s);
			setErrorPerm(false);
		}
		catch (e) {
			console.error("Error LocalStream:", e) // eslint-disable-line
            setErrorPerm(true);
            if (!interv.current) interv.current = setInterval(initStream, 1000);
        }
	}, []);

	useEffect(() => {

        initStream();

        return () => {
            clearInterval(interv.current);
        }

	}, [initStream]);

	useEffect(() => () => {
		if (!stream) return;
		stream.getTracks().forEach(track => {
			track.stop();
		});
	}, [stream]);
	
	return [
		stream,
		errorPerm,
	]
}

const usePeerConnection = ({planningId, eventId, remoteStream}) => {

	const peerConnection = useRef();
	const [localStream, setLocalStream] = useState(null);
	const [answer, setAnswer] = useState(null);
	const dispatch = useDispatch();
	const [candidates, setCandidates] = useState([]);
	const [candidatesReady, setCandidatesReady] = useState(false);
	const [offer, setOffer] = useState(null);
	const [remoteCandidates, setRemoteCandidates] = useState(null);
	const [connected, setConnected] = useState(false);

	const onICECandidate = useCallback(event => {
        if (!event.candidate) {
			setCandidatesReady(true);
            return;
        }
		setCandidates(c => [...c, event.candidate]);
    }, []);

	const onConnectionTrack = useCallback(event => {
		event.streams[0].getTracks().forEach(track => {
			remoteStream.addTrack(track);
		});
		setConnected(true);
	}, [remoteStream]);

	useEffect(() => {
		if (!localStream || !remoteStream) return;

		const initConnection = async () => {

            peerConnection.current = new RTCPeerConnection(CONFIG);
			peerConnection.current.addEventListener('icecandidate', onICECandidate);
			peerConnection.current.addEventListener('track', onConnectionTrack);
			localStream.getTracks().forEach(track => {
				peerConnection.current.addTrack(track, localStream);
			});

			const offer = await peerConnection.current.createOffer();
			await peerConnection.current.setLocalDescription(offer);
			setOffer(offer);
		};

		initConnection();

    }, [localStream, remoteStream, onICECandidate, onConnectionTrack]);
    
    useEffect(() => {

        if (!answer) return;

        const setConnectionAnswer = async () => {
			await peerConnection.current.setRemoteDescription(new RTCSessionDescription(answer));

			remoteCandidates.forEach(async c => {
				await peerConnection.current.addIceCandidate(new RTCIceCandidate(c));
			});
        }
        setConnectionAnswer();

	}, [answer, remoteCandidates]);
	
	useEffect(() => {

		if (!offer || !candidatesReady) return;

		const pushToBackEnd = async () => {
			setCandidatesReady(false);
		}

		pushToBackEnd()

	}, [offer, candidates, planningId, eventId, candidatesReady, dispatch]);

	const setAnswerAndCandidates = useCallback(({answer, candidates}) => {

		setAnswer(answer);
		setRemoteCandidates(candidates);

	}, []);

	useEffect(() => () => {
		if (!peerConnection || !peerConnection.current) return;
		peerConnection.current.removeEventListener('icecandidate', onICECandidate);
		peerConnection.current.removeEventListener('track', onConnectionTrack);
		peerConnection.current.close();
	}, [onICECandidate, onConnectionTrack]);

	return {
		peerConnection,
		setLocalStream,
		setAnswerAndCandidates,
		connected,
	}

}



export const isVisioOpen = (appointmentType/*, event*/) => {
    if (!appointmentType?.isVisio) return false;

    // Check for date. Open 15mins before
    /*const diff = moment(event.start).diff(moment(), "minutes");
    
	if (diff > 15) return false;
	if (diff < -90) return false;*/

    return true;
}