import queryString from 'jquery-param';
import randomstring from 'randomstring';
import { getDispatcher, getStore } from '../config/store';
import firebase from '../config/firebase';
import { config } from '../config/env';
import { doLogout, setUserToken } from '../actions/user';
import { setHasInternet } from '../actions/env';
import { setApiError, setUpdateRequired, setMaintenanceMode } from '../actions/ui';
import logError from '../libs/sentry';

export const get = async datas => await callApi({...datas, method: 'GET'});

export const post = async datas => await callApi({...datas, method: 'POST'});

export const put = async datas => await callApi({...datas, method: 'PUT'});

/* Hell yeah, I cannot use "delete".... */
export const remove = async datas => await callApi({...datas, method: 'DELETE'});

const getBPC = () => {
	const url = window.location.search.substring(1);
	const params = new URLSearchParams(url);
	return params.get('bpc') || '';
}

const callApi = async datas => {
	const { endpoint, params, method, catchErrors } = datas;
	const dispatch = getDispatcher();
	const { token, appToken, hasInternet } = getStore().env;

	let api = `${config.api}/${endpoint}`;
	const ob = {
            mode: 'cors',
			cache: 'no-store',
			method,
            headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token && token !== '' ? token : randomstring.generate(10)}`,
				'App-Token': appToken,
				'App-id': 'pro',
				'Api-Version': config.apiVersion,
				'Bypass-code': getBPC(),
			}
        };


	if ((method === "GET") && params && Object.keys(params).length > 0) {
		api = `${api}?${queryString(params)}`;
	}
	if (method !== "GET") {
		ob.body = JSON.stringify(params);
	}

	// Making API Call
	let response;
	try {
		response = await fetch(api, ob);
	} catch (e) {
		// Not Connected to Internet
		if (hasInternet) dispatch(setHasInternet(false));

		return new Promise(resolve => setTimeout(() => resolve(callApi(datas)), 7000));
	}

	if (!hasInternet) dispatch(setHasInternet(true));

	// Checking reponse statut
	if (response.status < 200 || response.status >= 300) {
		const json = await response.json();
		const {code} = json;
		if (catchErrors && catchErrors.indexOf(response.status) > -1) {
			return ({
				error: true,
				status: response.status,
				code,
				...json,
			});
		}
		else {
			// Catch 401
			if (response.status === 401)  {
				// try to relog from firebase
				try {
					const token = await firebase.auth().currentUser.getIdToken(true);
					if (token && token !== '') {
						await dispatch(setUserToken(token));
						return callApi(datas);
					}
				}
				catch (e) {
					// Really unlogged, fail and go to relog
				}
			}

			errorCatcher({status: response.status, error:code, json, datas, api});
			return false;
		}
	}

	// Handle 204
	if (response.status === 204) return true;

	// Parsing response
	try {
		const json = await response.json();
		return json;
	} catch (error) {
		errorCatcher({error, api, datas});
		return;
	}

}


const errorCatcher = async ({error, status, json}) => {
	const dispatch = getDispatcher();

	// particular action depending on status Code
	switch (status) {
		case 401:
			await dispatch(doLogout());
			window.location.reload(true);
			return;
		case 426:
			dispatch(setUpdateRequired());
			return;
		case 418:
			dispatch(setMaintenanceMode(json.message));
			return;
		default:
			logError(status, error);
			dispatch(setApiError(status));
			return;
	}
}
