import { get, put } from './index';
import mapper from '../mappers/drive';
import firebase from '../config/firebase';

export const setPassword = ({planningId, ...query}) => {
	const endpoint = `drive/${planningId}`;
	const params = mapper.setPassword.toApi(query);
	return put({endpoint, params});
}

export const retrieveFiles = async ({planningId, userId}) => {
	const endpoint = `drive/${planningId}/${userId}`;
	return get({endpoint});
}

export const uploadFile = ({planningId, userId, folder, file, type, content}) => {
	const ref = firebase.storage().ref();
	const fileRef = ref.child(`${planningId}/${userId}/${folder}/${file}`);
	const blob = new Blob([content], {type: `${type}`});
	return fileRef.put(blob, {
		contentType: type
	});
}

export const deleteFile = ({planningId, userId, folder, file}) => {
	const ref = firebase.storage().ref();
	const fileRef = ref.child(`${planningId}/${userId}/${folder}/${file}`);
	return fileRef.delete();
}

export const downloadFile = async ({planningId, userId, folder, file}) => {
	const ref = firebase.storage().ref();
	const fileRef = ref.child(`${planningId}/${userId}/${folder}/${file}`);

	return new Promise(resolve => {
		fileRef.getDownloadURL().then(url => {
			var xhr = new XMLHttpRequest();
			xhr.responseType = '';
			xhr.onload = () => {
				const { response } = xhr;
				resolve(response)
			};
			xhr.open('GET', url);
			xhr.send();
		}).catch(error => {
			console.error("An error has occured", error); // eslint-disable-line
			resolve();
		});
	})
}

export const getDriveSpace = async ({planningId}) => {
	const endpoint = `drive/${planningId}/space_used`;
	return get({endpoint});
}
