import React, { memo } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import VerifiedUser from '@material-ui/icons/VerifiedUser';

import TermsContent from './terms/TermsContent';

import getLexique from '../locales';

const Terms = memo(({lexique}) => (
	<div className="legals column mt40">
		<Card className="mb20">
			<CardContent>
				<div className="flex aic mb20">
					<Avatar className="avatar--primary mr10 avatar--small">
						<VerifiedUser />
					</Avatar>
					<Typography component="h4" variant="h5">
						{lexique.title}
					</Typography>
				</div>
				<TermsContent />
			</CardContent>
		</Card>
	</div>
))

const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).terms,
});

export default connect(mapStateToProps)(Terms);
