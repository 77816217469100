export default {
	title: `Rendez-vous à confirmer`,
	card: {
		tooltips: {
			profile: `Voir le profil du {p}`,
			gotoDay: `Afficher le planning du jour`,
			delete: `Annuler le rendez-vous`,
		},
		createdBy: `Pris le {0}`,
		confirm: `Confirmer le rendez-vous`
	}
}
