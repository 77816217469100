import React, { memo } from 'react';
import { connect } from 'react-redux';
import _sortBy from "lodash.sortby";
import moment from 'moment-timezone';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';

import LogsIcon from '@material-ui/icons/ListAlt';
import TodayIcon from '@material-ui/icons/Today';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import WebIcon from '@material-ui/icons/Web';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import GavelIcon from '@material-ui/icons/Gavel';
import InfoCircleOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import getLexique from '../../locales';

const LogsContent = memo(({logs, loading, lexique, datetimeFormat, dateFormatLong, users}) => {

    if (loading || users.length === 0) return <LinearProgress color="secondary" />

    if (logs.length === 0) return <div className="logs-content pad20">
        {lexique.noEntries}
    </div>

    const renderLogs = () => {
        return <div className="logs--entries">
            {renderLogsEntries(logs)}
        </div>
    }

    const renderLogsEntries = entries => {
        const displayEntries = _sortBy(entries, [o => moment().unix() - moment(o.at).unix()]);
        return <List dense>
            {displayEntries.map(renderEntry)}
        </List>
    }

    const renderEntry = entry => {
        return <ListItem dense divider className={`log-item log-item--${entry.id}`} key={entry.id}>
            <ListItemIcon>{getIcon(entry)}</ListItemIcon>
            <ListItemText
                primary={getText(entry)}
                secondary={moment(entry.at).format(datetimeFormat)}
            />
        </ListItem>
    }

    const getIcon = entry => {
        switch (entry.action) {
            case "appointment-add":
                return <TodayIcon className="green" />
            case "appointment-delete":
                return <TodayIcon className="red" />
            case "appointment-update":
                return <TodayIcon className="blue" />
            case "appointment-confirm":
                return <CheckCircleOutlineIcon className="green" />
            case "blocked-slot-add":
                return <CalendarTodayIcon className="red" />
            case "blocked-slot-delete":
                return <CalendarTodayIcon className="green" />
            case "billing-card-add":
                return <CreditCardIcon className="green" />
            case "billing-update-plan":
                return <CreditCardIcon className="blue" />
            case "planning-day-close":
                return <LockOutlinedIcon className="red" />
            case "planning-day-open":
                return <LockOpenIcon className="green" />
            case "planning-day-update-user-note":
                return <InfoOutlinedIcon className="blue" />
            case "planning-day-update-pro-note":
                return <NoteOutlinedIcon className="blue" />
            case "planning-day-update-schedules":
                return <ScheduleOutlinedIcon className="blue" />
            case "unblock-slot":
                return <BuildOutlinedIcon className="green" />
            case "website-update":
                return <WebIcon className="blue" />
            case "website-update-infos":
                return <WebIcon className="blue" />
            case 'planning-appointmentType-add':
                return <AssignmentOutlinedIcon className="green" />
            case 'planning-appointmentType-delete':
                return <AssignmentOutlinedIcon className="red" />
            case 'planning-appointmentType-update':
                return <AssignmentOutlinedIcon className="blue" />
            case 'planning-appointmentType-profile-update':
                return <AssignmentIndOutlinedIcon className="blue" />
            case 'planning-appointmentType-profile-delete':
                return <AssignmentIndOutlinedIcon className="red" />
            case 'planning-configuration-update':
                return <SettingsOutlinedIcon className="blue" />
            case 'planning-profile-update':
                return <PermContactCalendarOutlinedIcon className="blue" />
            case 'user-request-access':
                return <InputOutlinedIcon className="green" />
            case 'add-user':
                return <PersonAddOutlinedIcon className="green" />
            case 'accept-user':
                return <PersonOutlinedIcon className="green" />
            case 'block-user':
                return <PersonOutlinedIcon className="red" />
            case 'set-attend':
                return !entry.pvpp ? <EventAvailableOutlinedIcon className="green" /> : <EventBusyOutlinedIcon className="red" />
            case 'manually-create-user':
                return <PersonAddOutlinedIcon className="green" />
            case 'planning-appointmentType-specificSchedule-add':
            case 'add-global-specific-schedules':
                return <ScheduleOutlinedIcon className="green" />
            case 'planning-appointmentType-specificSchedule-update':
                return <ScheduleOutlinedIcon className="blue" />
            case 'planning-appointmentType-specificSchedule-delete':
                return <ScheduleOutlinedIcon className="red" />
			case 'create-user':
				return <PersonAddOutlinedIcon className="green" />
			case 'create-password':
				return <VpnKeyOutlinedIcon className="green" />
			case 'verify-email':
				return <EmailOutlinedIcon className="green" />
			case 'accept-pro-terms':
			case 'accept-terms':
				return <GavelIcon className="green" />
			case 'update-information':
				return <InfoCircleOutlinedIcon className="green" />
			case 'family-member-update':
				return <PeopleOutlineOutlinedIcon className="blue" />
			case 'family-member-add':
				return <PeopleOutlineOutlinedIcon className="green" />
			case 'family-member-delete':
				return <PeopleOutlineOutlinedIcon className="red" />
			case 'password-reset':
				return <VpnKeyOutlinedIcon className="red" />
			case 'change-password':
				return <VpnKeyOutlinedIcon className="blue" />
			case 'account-delete-request':
				return <DeleteOutlinedIcon className="red" />
			case 'account-delete-confirm':
				return <DeleteForeverOutlinedIcon className="red" />
        	default:
                return <LogsIcon className="default" />
        }
    }

    const getText = entry => {
        switch (entry.action) {
            case "appointment-add":
            case "appointment-delete":
                return  lexique.logs[entry.action]
                          .replace('{0}', entry.by)
                          .replace('{1}', entry.user_name)
                          .replace('{2}', moment(entry.start).format(datetimeFormat));
            case "appointment-confirm":
            case "blocked-slot-add":
            case "blocked-slot-delete":
                return  lexique.logs[entry.action]
                        .replace('{0}', entry.by)
                        .replace('{1}', moment(entry.start).format(datetimeFormat));
            case "unblock-slot":
                return  lexique.logs[entry.action]
                        .replace('{0}', entry.by)
                        .replace('{1}', moment(entry.slot).format(datetimeFormat));
            case "appointment-update":
                if (entry.from.start !== entry.to.start) return     lexique.logs["appointment-update-start"]
                                                                      .replace('{0}', entry.by)
                                                                      .replace('{1}', moment(entry.from.start).format(datetimeFormat))
                                                                      .replace('{2}', moment(entry.to.start).format(datetimeFormat))
                  return     lexique.logs[entry.action]
                               .replace('{0}', entry.by)
                               .replace('{1}', moment(entry.from.start).format(datetimeFormat))
            case 'planning-day-close':
            case 'planning-day-open':
				return  lexique.logs[entry.action]
					  .replace('{0}', entry.by)
					  .replace('{1}', moment(entry.day).format(dateFormatLong))
            case 'planning-day-update-user-note':
            case 'planning-day-update-pro-note':
            case 'planning-day-update-schedules':
                return  lexique.logs[entry.action]
                          .replace('{0}', entry.by)
                          .replace('{1}', moment(entry.date).format(dateFormatLong))
            case 'website-update':
            case 'website-update-infos':
                return  lexique.logs[entry.action]
                          .replace('{0}', entry.by)
                          .replace('{1}', entry.website_id)
            case 'planning-appointmentType-add':
            case 'planning-appointmentType-delete':
            case 'planning-appointmentType-update':
            case 'planning-appointmentType-profile-update':
            case 'planning-appointmentType-profile-delete':
            case 'planning-appointmentType-specificSchedule-add':
            case 'planning-appointmentType-specificSchedule-update':
            case 'planning-appointmentType-specificSchedule-delete':
                return   lexique.logs[entry.action]
                          .replace('{0}', entry.by)
                          .replace('{1}', entry.appointmentType || entry.from.label)
            case 'add-user':
            case 'accept-user':
            case 'manually-create-user':
            case 'block-user': {
                const user = users.find(u => u.id === entry.user_id);
                return   lexique.logs[entry.action]
                          .replace('{0}', entry.by)
                          .replace('{1}', user.mainMemberName)
            }
            case 'set-attend': {
                const user = users.find(u => u.id === entry.user_id);
                return   lexique.logs[`${entry.action}-${entry.pvpp ? 'true' : 'false'}`]
                          .replace('{0}', entry.by)
                          .replace('{1}', user.mainMemberName)
            }
			case 'family-member-update':
			case 'family-member-add':
			case 'family-member-delete': {
				const user = users.find(u => u.id === entry.who);
				return 	lexique.logs[entry.action]
                          .replace('{0}', user.mainMemberName)
                          .replace('{1}', entry.member ? entry.member.first_name : entry.from.first_name )
			}
            default:{
				const user = entry.by || (users.find(u => u.id === entry.who).mainMemberName);
                return  lexique.logs[entry.action]
                          .replace('{0}', user)
			}
        }
    }

    return (
        <div className="logs-content">
            {renderLogs()}
        </div>
    )

});

const mapStateToProps = ({env, logs, plannings}) => ({
	lexique: getLexique(env.locale).logs.content,
    datetimeFormat: getLexique(env.locale).config.datetimeFormatLong,
    dateFormatLong: getLexique(env.locale).config.dateFormatLong,
    users: plannings && plannings[plannings.selected] && plannings[plannings.selected].usersList ? plannings[plannings.selected].users : [],
    logs: logs.logs,
    loading: logs.loading,
});

export default connect(mapStateToProps)(LogsContent);
