import { get, put, post } from './index';
import mapper from '../mappers/users';

export const acceptUser = ({planningId, userId}) => {
    const endpoint = `plannings/${planningId}/users/${userId}/confirm`;
	return put({endpoint});
}

export const blockUser = ({planningId, userId}) => {
	const endpoint = `plannings/${planningId}/users/${userId}/block`;
	return put({endpoint});
}

export const getShortInfos = ({userId}) => {
	const endpoint = `users/${userId}`;
	return get({endpoint});
}

export const getUserDetails = ({planningId, userId}) => {
	const endpoint = `plannings/${planningId}/users/${userId}`;
	return get({endpoint});
}

export const searchUser = email => {
    const endpoint = `users/search`;
    const params = mapper.searchUser.toApi(email);
	return get({
        endpoint,
        params,
        catchErrors: [404],
    });
}

export const addUser = ({planningId, userId}) => {
    const endpoint = `plannings/${planningId}/users/${userId}/add`;
	return post({endpoint});
}

export const createUser = ({planningId, user}) => {
	const endpoint = `plannings/${planningId}/users/create`;
	const params = mapper.createUser.toApi(user);
	return post({endpoint, params});
}

export const sendMailing = ({planningId, ...datas}) => post({
	endpoint: `plannings/${planningId}/users/mailing`,
	params: mapper.sendMailing.toApi(datas),
})