import global from './global';

export default {
	title: `Configuration du site web`,
	information: {
		title: `Informations`,
		edit: `Modifier`,
		active: `Activé`,
		vitalCard: {
			ok: `Carte vitale acceptée`,
			nok: `Carte vitale non acceptée`
		},
		paymentMethods: {
			title: `Modes de paiement acceptés :`,
			cash: `Espèce`,
			cheque: `Chèque`,
			'credit-card': `Carte de crédit`,
			amex: `American Express`,
		},
		edition: {
			title: `Modifier la section Informations`,
			labels: {
				free: `Champ libre`,
				showVitalCard: `Afficher les informations sur la carte vitale`,
				acceptVitalCard: `Carte vitale acceptée`,
				showPayments: `Afficher les moyens de paiement acceptés`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	adminAlert: {
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			message: `Message`,
			title: `Modifier le message d'information`,
			labels: {
				level: `Couleur du fond du message`,
				value: `Message d'information`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	school: {
		title: `Cursus / Formations`,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier le cursus / formations`,
			labels: {
				free: `Champs libre`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	free: {
		title: ``,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier le texte libre`,
			labels: {
				free: ``,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	free2: {
		title: ``,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier le texte libre 2`,
			labels: {
				free: ``,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	free3: {
		title: ``,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier le texte libre 3`,
			labels: {
				free: ``,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	pricing: {
		title: `Tarifs`,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier les tarifs`,
			labels: {
				free: `Champs libre`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	direction: {
		title: `Coordonnées & accès`,
		edit: `Modifier`,
		active: `Activé`,
		edition: {
			title: `Modifier les coordonnées`,
			labels: {
				free: `Champs libre`,
				additionalInfos: `Informations complémentaires`,
				address: `Addresse du cabinet`,
				lat: `Latitude`,
				lng: `Longitude`,
				help: `Définissez les coordonnées GPS afin d'afficher la carte GoogleMap. Vous pouvez obtenir des coordonnées GPS à partir d'une adresse sur ce site : https://www.coordonnees-gps.fr/`
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	contact: {
		title: `Contact`,
		edit: `Modifier`,
		active: `Activé`,
		phoneNumbers: {
			direct: `Téléphone :`,
			emergency: `Urgence :`,
			secretariat: `Secrétariat :`,
			portable: `Portable :`
		},
		edition: {
			title: `Modifier les contacts`,
			labels: {
				free: `Champs libre`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	schedule: {
		title: `Horaires`,
		edit: `Modifier`,
		active: `Activé`,
		days: global.days,
		edition: {
			title: `Modifier les horaires`,
			labels: {
				free: `Champs libre`,
			},
			toolbar: global.toolbar,
			cancel: `Annuler`,
			confirm: `Modifier`,
		}
	},
	topbar: {
		title: {
			title: `Modifier les informations`,
			edit: `Modifier le titre`,
			confirm: `Valider`,
			cancel: `Annuler`,
			infos: `Ces informations servent au référencement sur les différents moteurs de recherche.`,
			labels: {
				title: `Titre du site`,
				subtitle: `Sous-titre`,
				description: `Description`,
			},
		},
		inbox: {
			title: `Configurer la messagerie`,
			edit: `Configurer la messagerie`,
			confirm: `Valider`,
			cancel: `Annuler`,
			labels: {
				success: `Message de confirmation après envoi du message`,
				information: `Message d'information`,
				active: `Activer la messagerie`,
			},
			message: `Message`,
			messageShort: 'Mess.',
		},
		theme: {
			title: `Choix du thème`,
			edit: `Changer le thème`,
			cancel: `Annuler`,
			confirm: `Valider`,
			chunk: `Votre site utilise un template personnalisé. Vous ne pouvez pas changer le thème`,
			themes: {
				blue: "Blue",
				duckGreen: 'Duck Green',
				red: "Red",
				blackWhite: "Black & White",
				green: 'Green',
				pink: 'Pink',
				rose: 'Rose',
				purple: 'Purple',
				comet: 'Comet',
				cream: 'Cream',
				sinbad: 'Sinbad',
				lavender: 'Lavender',
			}
		}
	}
}
