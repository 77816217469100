export default {
	title: `Vos {p}s`,
	showFamily: `Regrouper les {p}s par famille`,
	showBlocked: `Afficher les {p}s bloqués`,
	addNew: `Ajouter un {p}`,
	sendMailing: 'Envoyer un email à vos {p}s',
	toConfirm: {
		title: `Vos {p}s à valider`,
	},
	search: {
		label: 'Rechercher un {p}',
		placeholder: 'Recherchez un nom, email, ou numéro de téléphone',
		hasMore: '{p}s',
	},
	family: {
		family: `Famille {0}`,
		yo: ` ans`,
		confirm: `Accepter cette famille`,
		block: `Bloquer cette famille`,
		show: `Voir les détails`,
		hasMore: 'familles',
		secureDrive: `Dossier sécurisé`,
		email: `Envoyer un email`,
	},
	mailing: {
		title: 'Envoyer un email à vos {p}s',
		send: 'Envoyer',
		subject: 'Sujet',
		toasts: {
			success: 'Message envoyé à vos {p}s !'
		}
	}
}
