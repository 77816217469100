export default {
    title: 'Facturation de vos rendez-vous',
    unregistered: {
        title: `Vous n'avez pas activé la facturation de vos rendez-vous`,
        body: `En activant cette option, vous pourrez, à la suite de votre rendez-vous, envoyer une facture à votre patient/client payable directement depuis votre site web.
        
Pour gérer les paiements en ligne, Sprel est associé avec la plateforme Stripe. En associant les frais de fonctionnement de Stripe, et de Sprel, pour permettre le paiment en ligne, une commission de **4%** est appliquée à chaque transaction.
Par exemple, si vous facturez une consulation 25€, vous toucherez 24€ et 1€ sera prélevé en tant que frais de fonctionnement.

Les paiements effectués sur la plateforme sont stockés dans un **portefeuille virtuel** qui sera automatiquement versé sur votre compte bancaire le **dernier jour de chaque mois**

Les transactions sont **bloquées** pendant une période pouvant atteindre **7 jours** suivant la date de la transaction. Cette période est requise par Stripe pour vérifier l'authenticité de chaque transaction via ses algorithmes anti-fraude. Ce qui veut dire qu'une transaction effectuée le 25, pourrait n'être versée sur votre compte qu'en fin du mois suivant.


Si vous souhaitez activer la facturation de vos rendez-vous, veuillez remplir les informations supplémentaires suivantes. Veuillez noter qu'une vérification de l'identité pourra être demandée s'il est impossible à Stripe de vérifier vos informations.`,
            form: {
                firstName: 'Prénom',
                lastName: 'Nom',
                dob: `Date de naissance`,
                address: 'Adresse de votre cabinet',
                postalCode: 'Code postal',
                city: 'Ville',
                email: 'Adresse email',
                iban: 'Votre IBAN (les facturations seront versées sur ce compte)',
                accept: 'Je certifie que les informations entrées sont exactes, et autorise Sprel à les communiquer à Stripe',
                accept2: `J'accepte les conditions générales d'utilisation de la facturation des rendez-vous, et ai bien pris connaissance que :
- la plateforme Stripe, partenaire de Sprel, s'occupera de la partie transactionnelle des paiements,
- chaque transaction fera l'objet de frais de commission de **4%**,
- les versements des facturations seront effectuées **une fois par mois, le dernier jour de chaque mois**
- Chaque transaction est **bloquée** pour un période pouvant atteindre **7 jours** afin de permettre à Stripe d'executer ses algorithmes auti-fraude.`,
                submit: 'Activer la facturation des rendez-vous',
                errorStripe: 'Une erreur est présente dans votre formulaire, et nous empeche de le valider. Les raisons les plus fréquentes sont une erreur dans la date de naissance ou le code postal. Merci de vérifier vos informations et re-valider le formulaire.'
            }
    },
    documents: {
        title: 'Vérification de votre identité',
        body: `Avant de pouvoir recevoir des versements, Stripe doit vérifier votre identité. Merci d'envoyer les pièces justificatives demandées avant de pouvoir recevoir le paiement de vos patients`,
        errorFile: `Ce fichier n'est pas valide`,
        frontId: `### Pièce d'identité RECTO
Glissez-déposer votre carte d'identité ou passeport ici. Ou cliquez pour choisir votre fichier

Formats acceptés : image **JPG** ou **PNG**

\`Si vous avez votre carte d'identité ou passeport au format PDF, vous pouvez utiliser un convertisseur en ligne pour le transformer en image, par exemple https://pdftoimage.com\``,
        backId: `### Pièce d'identité VERSO
Glissez-déposer votre carte d'identité ou passeport ici. Ou cliquez pour choisir votre fichier
        
Formats acceptés : image **JPG** ou **PNG**
        
\`Si vous avez votre carte d'identité ou passeport au format PDF, vous pouvez utiliser un convertisseur en ligne pour le transformer en image, par exemple https://pdftoimage.com\``,
        frontAdd: `### Justificatif de domiciliation du cabinet
Glissez-déposer votre justificatif de domiciliation. Facture EDF, Opérateur internet,...
        
Formats acceptés : image **JPG** / **PNG**, ou document **PDF**`,
        submit: 'Envoyer les documents'
    },
    waiting: {
        title: 'En cours de vérification',
        body: `Vos pièces justificatives sont en cours d'approbation par Stripe. Cette action peut prendre quelques jours.
        
Si vous n'avez pas envoyé de pièces justificatives, veuillez attendre quelques minutes et recharger cette page.
        
N'hesitez pas à nous contacter si vous avez besoin de plus d'informations.

Vous pouvez déjà commencer à proposer le paiement à vos patients. Vous ne pourrez cependant pas recevoir les fonds sur votre compte tant que vos pièces justificatives n'ont pas été approuvées. `
    },
    create: {
        title: 'Envoyer une facture à {0}',
        amount: 'Saisissez le montant de la facture pour le rendez-vous de **{0}** du **{1}**',
        cta: 'Envoyer la facture',
        cancel: 'Cancel',
        error: `Le montant que vous avez entré n'est pas valide. Merci de vérifier votre saisie. Le montant minimal pour les paiements en ligne est de 10€`,
        confirm: {
            title: `Veuillez confirmez l'opération`,
            body: `Confirmez l'envoi d'une facture de **{0}**€ à **{1}**

Frais de transaction estimés pour cette facture : **{2}**€`,
            cta: 'Je confirme le montant',
        },
        alreadyExisting: {
            title: 'Attention !',
            body: 'Une facture existe déjà pour ce rendez-vous. Voulez-vous vraiment créer une nouvelle facture ? La facture déjà existante ne sera **PAS** annulée.',
            cta: 'Envoyer une nouvelle facture',
        }
    },
    manage: {
        unpaid: {
            title: 'Factures en attente de règlement',
            noEntry: 'Aucune facture en attente de réglement',
        },
        list: {
            header: {
                date: 'Date facturation',
                state: 'Status',
                who: '{P}',
                stateDate: 'Date status',
                amount: 'Facturé',
                fees: 'Commission',
                finalAmount: 'Montant'
            }
        },
        states: {
            unpaid: 'Impayé',
            paid: 'Payé',
            canceled: 'Annulé',
        },
        balances: {
            pending: 'Fonds en attente de vérification par Stripe',
            available: 'Fonds en attente de transfert',
            transit: 'Fonds en cours de transfert vers votre banque'
        },
        search: {
            title: 'Listing de vos factures',
            label: 'Choisissez une période',
            noEntry: 'Aucun résultat pour cette période',
        },
        cancelPayment: {
            title: 'Annuler une facture',
            alert: {
                title: `Confirmez l'annulation de la facture`,
                body: `Annuler la facture de **{0}** d'un montant de **{1}€** ?`,
            },
            confirm: 'Je confirme',
            cancel: 'Annuler'
        }
    },
    rib: {
        tooltip: 'Versements effectués sur le RIB suivant. Cliquez pour mettre à jour',
        title: 'Mettez à jour votre RIB',
        body: 'Entrez le RIB sur lequel seront effectués les versements',
        confirm: 'Valider',
        cancel: 'Annuler'
    }
}