export default {
	dashboard: `Dashboard`,
	planning: `Agenda`,
	rdvs: `Rendez-vous à confirmer`,
	users: `Vos {p}s`,
	inbox: `Messages`,
	account: `Votre compte`,
	settings: `Paramètres du planning`,
	websites: `Configuration du Site web`,
	logs: `Historique d'activités`,
	payments: 'Facturation de vos rendez-vous'
}
