export default {
	title: `Messages`,
	tabs: {
		emails: `Boite de réception`,
		favorites: `Messages favoris`,
		archived: `Messages archivés`,
		deleted: `Messages supprimés`
	},
	list: {
		messagesDeletion: `Les messages dans la corbeille datant de plus de 30 jours seront automatiquement supprimés`,
		noMessage: `Aucun message`,
	},
	details: {
		chooseOne: `Sélectionnez un message pour afficher son contenu`,
		reply: `Répondre`,
		markAsUnread: `Marquer comme non lu`,
		archive: `Archiver`,
		unarchive: `Désarchiver`,
		unarchiveAndMarkAsUnread: `Désarchiver et marquer comme non lu`,
		delete: `Supprimer`,
		deleteForever: `Supprimer définitivement`,
		restore: `Restaurer`,
		sentAt: `Envoyé le `,
		didNotRespond: `Vous n'avez pas répondu à ce message`,
		replies: `Vous avez répondu {0} fois à ce message`,
		replied: `Vous avez répondu le {0}`,
	},
	send: {
		title: `Envoyer un message`,
		to: `A`,
		send: `Envoyer`,
		includeQuestion: `Inclure le message d'origine`,
		subject: `Sujet`,
		body: `Message`,
		drophere: `Déposez votre fichier ici`,
		fileRejected: `Ce type de fichier n'est pas autorisé, veuillez choisir une image ou un document PDF`,
		fileTooLarge: `Ce fichier est trop volumineux, votre fichier doit faire maximum 5 Mo`,
		attachment: `Pièce jointe`,
	},
}
