import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';

import { closeModaleConflicts } from '../../actions/ui';

import getLexique from '../../locales';
import { userName } from '../../libs/formatters';

class Conflicts extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleConflicts();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm();
	}

	renderInner() {
		const { lexique, config, event, conflicts } = this.props;

		if (!event) return <div />;

		const duration = event.duration || Math.round(moment(event.end).diff(event.start, "minutes"));
		const durationLabel = moment().startOf('day').add(duration, "minutes");

		const myEventLabel = `${moment(event.start).format(config.datetimeFormatLong)} (${durationLabel.format('H:mm')})`;

		return (
			<Fragment>
				<DialogTitle id="modale-conflicts-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="warning" show>
						{lexique.body.replace('{0}', myEventLabel)}
					</Message>
					<div className="conficted-event flex flex-c">
						{ conflicts.map(conflict => <ConflictCard key={conflict.id} {...conflict} config={config} lexique={lexique} />)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show, isMobile } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-conflicts-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const ConflictCard = ({start, duration, typeId, with:w, config, lexique}) => (
	<Card className="conflict-event">
        <CardContent>
			<Typography color="textSecondary">
				{moment(start).format(config.dateFormatLong)}, {moment(start).format('HH:mm')} - {moment(start).add(duration, 'minutes').format('HH:mm')}
          </Typography>
		  {typeId === "blocked" && <Typography variant="h6">
			  {lexique.blockedSlots}
		  </Typography> }
		  {typeId !== "blocked" && <Typography variant="h6">
			  {w.id ? userName(w) : w.lastName}
		  </Typography>}
		</CardContent>
	</Card>
)

const mapStateToProps = ({env, ui:{device, modales:{conflicts:{show, conflicts, onConfirm, event}}}}) => ({
	isMobile: device.isMobile,
	lexique: getLexique(env.locale).modales.conflicts,
	config: getLexique(env.locale).config,
    show,
	conflicts,
	onConfirm,
	event,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleConflicts }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Conflicts);
