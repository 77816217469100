import React, { memo } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { Mic as MicIcon, MicOff as MicOffIcon } from '@material-ui/icons';
import { Tooltip } from 'react-tippy';
import { useLocalAudioToggle, useVideoContext } from '../hooks';
import { useLexique } from '../../../locales';

const useStyle = makeStyles(() => ({
    icon: {
        color: '#fff',
        border: '1px solid #fff',
        marginRight: 4,
        marginLeft: 4,
    }
}))

const ToggleAudioButton = ({ disabled }) => {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
    const classes = useStyle();

    const lexique = useLexique('visio.localControls');

    return (
        <Tooltip position="top" title={lexique[`mic${isAudioEnabled ? 'Off' : 'On'}`]}>
            <IconButton
                onClick={toggleAudioEnabled}
                disabled={!hasAudioTrack || disabled}
                className={classes.icon}
            >
                { isAudioEnabled ? <MicIcon /> : <MicOffIcon /> }
            </IconButton>
        </Tooltip>
    )

}

export default memo(ToggleAudioButton)