import login from './fr-FR/login';
import header from './fr-FR/header';
import toolbar from './fr-FR/toolbar';
import global from './fr-FR/global';
import event from './fr-FR/event';
import appointment from './fr-FR/appointment';
import errors from './fr-FR/errors';
import dashboard from './fr-FR/dashboard';
import modales from './fr-FR/modales';
import toasts from './fr-FR/toasts';
import calendar from './fr-FR/calendar';
import settings from './fr-FR/settings';
import users from './fr-FR/users';
import appointmentsToConfirm from './fr-FR/appointmentsToConfirm';
import terms from './fr-FR/terms';
import inbox from './fr-FR/inbox';
import websites from './fr-FR/websites';
import account from './fr-FR/account';
import drive from './fr-FR/drive';
import logs from './fr-FR/logs';
import visio from './fr-FR/visio';
import payments from './fr-FR/payments';

export default {
	config: {
		label: 'Français',
		currency: "EUR",
		currencySymbol: "€",
		iso3: "FRA",
		iso: 'fr-FR',
		monthFormat: 'MMMM YYYY',
		dateFormat: 'DD/MM/YYYY',
		dateFormatLongNoYear: 'dddd DD MMMM',
		dateFormatLong: 'dddd DD MMMM YYYY',
		dateFormatLongNoDay: 'DD MMMM YYYY',
		datetimeFormat: 'DD/MM/YYYY - HH:mm',
		datetimeFormatLong: 'dddd DD MMMM YYYY à HH:mm',
		timeFormat: 'HH:mm',
	},
	login,
	header,
	toolbar,
	global,
	event,
	appointment,
	errors,
	dashboard,
	modales,
	toasts,
	calendar,
	settings,
	users,
	appointmentsToConfirm,
	terms,
	inbox,
	websites,
	account,
	drive,
	logs,
	visio,
	payments
}
