import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RichTextEditor from 'react-rte';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Save';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import { closeDriveFileEditor } from '../../actions/ui';
import { saveFile } from '../../actions/drive';

import getLexique from '../../locales';
import { getToolbar } from '../../config/website';
import { templates } from '../../config/drive';

class FileEditor extends PureComponent {

    state = {
		saving: false,
		title: moment().format(this.props.config.dateFormatLong),
		errors: [],
		template: '',
		hasChanged: false,
		openCloseConfirmation: false,
		rte: RichTextEditor.createEmptyValue(),
		folder: "global"
	}

    componentDidMount() {
        const { newFile, content, folder, file } = this.props.editor;

		if (!newFile && content && content !== '') {
            this.setState({
                rte: RichTextEditor.createValueFromString(content, 'markdown'),
                title: file,
                folder
            })
        }
	}

    onChangeTitle = ({target:{value:title}}) => {
        this.setState({title, hasChanged: true});
    }

    closeEditorDrive = () => {
        if (this.state.hasChanged) {
            this.setState({openCloseConfirmation: true});
            return;
        }

        this.confirmCloseEditor();
    }

    closeModaleConfirmation = () => this.setState({openCloseConfirmation: false});

    confirmCloseEditor = () => this.props.actions.closeDriveFileEditor();

    getToolbar = () => {
		const { toolbar } = this.props.lexique;
		return getToolbar(toolbar);
	}

    chooseTemplate = template => () => {
        const rte = template.content === '' ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(template.content, 'markdown');
        this.setState({template: template.id, rte});
    }

    onChangeText = rte => {
        this.setState({rte, hasChanged: true});
    }

    handleChangeFolder = ({target:{value:folder}}) => {
        this.setState({folder, hasChanged: this.state.hasChanged || (folder !== this.state.folder)});
    }

    saveFile = async () => {
        const { rte, title, folder, hasChanged } = this.state;
        const { newFile } = this.props;

        if (title === '') {
            this.setState({errors: ['title']});
            return;
        }

        if (!hasChanged) {
            this.confirmCloseEditor();
            return;
        }

        const content = rte.toString('markdown');

        this.setState({saving: true});
        await this.props.actions.saveFile({content, title, folder, newFile});

        this.confirmCloseEditor();
    }

    renderChooseTemplate = () => {
        const { lexique } = this.props;
        return (
			<>
            <div className="drive-header flex aic">
                <DialogTitle className="fg1 flex" id="modale-drive-editor-title">
                    <span className="fg1">
                        {lexique.chooseTemplate}
                    </span>
                    <IconButton onClick={this.closeEditorDrive}>
                        <Close />
                    </IconButton>
                </DialogTitle>
            </div>
            <DialogContent>
                <div className="drive-editor-template-chooser">
                    {
                        templates.map(template => (
                            <Button key={template.id} onClick={this.chooseTemplate(template)}>
                                <span className="icon">{template.icon}</span>
                                <span className="name">{lexique.templates[template.id]}</span>
                            </Button>
                        ))
                    }
                </div>
            </DialogContent>
            </>
    	)
    }

    render() {

        const { saving, title, errors, template, rte, openCloseConfirmation } = this.state;
        const { editor, lexique } = this.props;

        if (editor.newFile && template === '') {
            return this.renderChooseTemplate();
        }

    	return (
			<>
            <div className="drive-header flex aic">
                <DialogTitle className="fg1 flex" id="modale-drive-editor-title">
                    <TextField
                        className="fg1"
                        variant="outlined"
                        value={title}
                        onChange={this.onChangeTitle}
                        placeholder={lexique.titlePlaceholder}
                        error={errors.indexOf('title') > -1}
                        required
                        label={lexique.titlePlaceholder}
                        disabled={saving || !editor.newFile}
                    />
                    <div className="w20" />
                    <Button variant="contained" color="primary" onClick={this.saveFile} className="mr5" disabled={saving} disableElevation>
                        <Save className="mr5" />
                        {lexique.save}
                        {saving && <ButtonCircularProgress />}
                    </Button>
                </DialogTitle>
                <IconButton onClick={this.closeEditorDrive} disabled={saving}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <div className="drive-editor-playground">
                    <RichTextEditor
						autoFocus
                        disabled={saving}
                        className="rte fullh bkg"
                        toolbarConfig={this.getToolbar()}
                        value={rte}
                        onChange={this.onChangeText}
                    />
                </div>
            </DialogContent>
            <Dialog open={openCloseConfirmation}>
                <DialogTitle>{lexique.confirmClose.title}</DialogTitle>
                <DialogContent>{lexique.confirmClose.body}</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.closeModaleConfirmation}>
                        {lexique.confirmClose.cancel}
                    </Button>
                    <Button color="primary" onClick={this.confirmCloseEditor}>
                        {lexique.confirmClose.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
            </>
    	)
    }
}

const mapStateToProps = ({env, drive:{appointments}, ui:{modales:{driveFileEditor}}}) => ({
    lexique: getLexique(env.locale).drive.editor,
    config: getLexique(env.locale).config,
    editor: driveFileEditor,
    appointments
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeDriveFileEditor, saveFile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FileEditor);
