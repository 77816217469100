import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';

import Details from './Details';

import { resetDisplayedMessage } from '../../actions/messages';

const MessageDetails = memo(({isMobileDisplay, message, actions}) => {

	const onCloseDialog = () => {
		actions.resetDisplayedMessage();
	}

	if (isMobileDisplay) return (
		<Dialog
			open={!!message.id || message.loading}
			fullScreen
		>
			<DialogTitle disableTypography className="inbox-mobile-title">
				<div className="flex1" />
				<IconButton onClick={onCloseDialog}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Details />
			</DialogContent>
		</Dialog>
	);

	return (
		<div className="inbox-message-details">
			<Details />
		</div>
	)
});


const mapStateToProps = ({ui, message}) => ({
	isMobileDisplay: ui.device.isMobileDisplay,
	message
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ resetDisplayedMessage }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetails);
