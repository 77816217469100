import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import getLexique from '../../locales';

import ModalTransitionZoom from './ModalTransitionZoom';

const JsError = ({lexique}) => (
	<Dialog
		open={true}
		onClose={null}
		disableBackdropClick
		disableEscapeKeyDown
		TransitionComponent={ModalTransitionZoom}
	>
		<DialogTitle>
			{lexique.title}
		</DialogTitle>
		<DialogContent>
			<Typography component="p" paragraph>
				{lexique.body1}<br />
				{lexique.body2}
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => window.location.reload(true)} color="primary">
				{lexique.close}
			</Button>
		</DialogActions>
	</Dialog>
);

const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).global.jsError,
});

export default connect(mapStateToProps)(JsError)
