import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { closeModaleConfirmationChangeEvent } from '../../actions/ui';

import getLexique from '../../locales';
import { userName } from '../../libs/formatters';

class ConfirmationChangeEvent extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleConfirmationChangeEvent();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm();
	}

	renderInner() {
		const { lexique, config, event, eventTo } = this.props;

		if (!event) return <div />;

		const from = moment(event.start).format(config.datetimeFormatLong);
		const to = moment(eventTo).format(config.datetimeFormatLong);

		return (
			<Fragment>
				<DialogTitle id="mmodale-confirmation-change-event-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="information" show>
						<Markdown>
						{
							lexique.body
								.replace('{0}', userName(event.with))
								.replace('{1}', from)
								.replace('{2}', to)
						}
						</Markdown>
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-confirmation-change-event-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{confirmationChangeEvent:{show, eventTo, onConfirm, event}}}}) => ({
	lexique: getLexique(env.locale).modales.confirmationChangeEvent,
	config: getLexique(env.locale).config,
    show,
	eventTo,
	onConfirm,
	event,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleConfirmationChangeEvent }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationChangeEvent);
