import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as USERS from '../constants/users';

const initialState = {
	loading: true,
	user: null,
};

const reducers = {
	[USERS.LOAD_REQUEST]: state => update(state, {
		loading: { $set: true }
	} ),
	[USERS.LOAD_COMPLETE]: (state, {payload: datas}) => update(state, {
		loading: { $set: false },
		user: { $set: datas }
	} ),
}


export default createReducer(initialState, reducers);
