import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import _deburr from 'lodash.deburr';
import moment from 'moment-timezone';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Person from '@material-ui/icons/Person';
import Pets from '@material-ui/icons/Pets';
import Contacts from '@material-ui/icons/Contacts';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';

import getLexique, { _lp } from '../../locales';
import { userName, userNameReversed } from '../../libs/formatters';

class NameInput extends PureComponent {

    state = { suggestions: [], openPicker: false, search: '' }

    onChange = (event, { method }) => {
        const { onChange } = this.props;

        if (method === "type") {
            return onChange(event);
        }
    };

    openMobileUserPicker = () => {
        this.handleSuggestionsFetchRequested({value: ''});
        this.setState({openPicker: true});
    }

    onClickPickerUser = user => () => {
        this.props.onPickUser(user);
        this.setState({openPicker: false, search: ''});
    }

    closeMobileUserPicker = () => {
        this.setState({openPicker: false});
    }

    onChangeMobileSearch = e => {
        this.setState({search: e.target.value});
        this.handleSuggestionsFetchRequested({value: e.target.value});
    }

    renderMobile = () => {
        const { lexique, isUserPicked, pickedUser, disabled, onChange, value, mode } = this.props;
        const { openPicker, search, suggestions } = this.state;
        return (
            <Fragment>
                <div className="flex1">
                    <TextField
                        required
                        id="appointment-user"
                        label={_lp(lexique.fields.user)}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        variant="outlined"
                        disabled={disabled}
                        InputProps={isUserPicked ? {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {pickedUser && pickedUser.isAnimal ? <Pets /> : <Person /> }
                                </InputAdornment>
                            )
                        } : {}}
                    />
                </div>
                {!disabled && mode === "new" && <div>
                    <IconButton
                        onClick={this.openMobileUserPicker}
                    >
                        <Contacts />
                    </IconButton>
                </div>}
                <Dialog
                    open={openPicker}
                    fullWidth
                    TransitionComponent={ModalTransitionZoom}
                    onClose={this.closeMobileUserPicker}
                >
                    <DialogTitle>
                        <TextField
                            autoFocus
                            id="user-list-search"
                            fullWidth
                            value={search}
                            label={_lp(lexique.fields.userSearch)}
                            onChange={this.onChangeMobileSearch}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <List>
                        { suggestions.map(user => {
							let age;
							if (user.birthdate) {
								const bday = moment(user.birthdate);
	                    		const today = moment();
	                    		age = today.diff(bday, 'years');
							}
                            const fullName = userName(user);
                            const matches = match(fullName, search);
                            const parts = parse(fullName, matches);


                            const allParts = parts.map((part, index) => {
                                return part.highlight ? (
                                    <span key={String(index)} style={{ fontWeight: 500 }}>
                                        {part.text}
                                    </span>
                                    ) : (
                                    <span key={String(index)} style={{ fontWeight: 300 }}>
                                        {part.text}
                                    </span>
                                );
                            })

                            return (
                            <ListItem button key={`${user.id}-${user.familyMemberId}`} onClick={this.onClickPickerUser(user)}>
                                <ListItemText
                                    primary={allParts}
                                    secondary={`${lexique.search[user.gender]}${age ? ` - ${age} ${lexique.search.yo}` : ''}`}
                                />
                            </ListItem>
                            );
                        })}
                        </List>
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }

    renderInput = inputProps => {
        const { lexique, isError } = this.props;
        const { ref, ...other } = inputProps;
        return <TextField
            fullWidth
            required
            error={isError}
            label={_lp(lexique.fields.user)}
            variant="outlined"
            InputProps={{
                inputRef: ref,
                ...other,
            }}
        />
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        const { usersList, isMobile, useAnimals } = this.props;

        const inputValue = _deburr(value.trim().toLowerCase());
        if (inputValue.length === 0) {
            return this.setState({
                suggestions: [],
            });
        }

        let index = 0;
        const limit = isMobile ? 8 : 8;
        const suggestions =  usersList.filter(user => {
			if (user.isBlocked) return false;
            if (user.isAnimal && !useAnimals) return false;

            const fullName1 = userName(user);
            const fullName2 = userNameReversed(user);

            if (_deburr(fullName1).toLowerCase().indexOf(inputValue) > -1 || _deburr(fullName2).toLowerCase().indexOf(inputValue) > -1) {
                index++;
                if (index > limit) return false;
                return true;
            }

            return false;
        });

        this.setState({
            suggestions,
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    }

    renderSuggestionsContainer = ({containerProps, children}) => {
        return (
            <Paper {...containerProps} square>
                {children}
            </Paper>
        );
    }

    getSuggestionValue = suggestion => {
        this.props.onPickUser(suggestion);
        return userName(suggestion);
    }

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const { lexique } = this.props;
        const fullName = userName(suggestion);
        const matches = match(fullName, query);
        const parts = parse(fullName, matches);

		let age;
		if (suggestion.birthdate) {
	        const bday = moment(suggestion.birthdate);
			const today = moment();
			age = today.diff(bday, 'years');
		}

        return (
            <MenuItem selected={isHighlighted} component="div" className="fw-w">
                <div className="w100 prel name-input_search_result_name">
                    {suggestion.isAnimal && <Pets className="ico--15 mr5" />}
                    {parts.map((part, index) => {
                        return part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                {part.text}
                            </span>
                            ) : (
                            <span key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </span>
                        );
                    })}
                </div>
                <Typography variant="caption" className="prel name-input_search_result_age">
                    {suggestion.isAnimal ? lexique.search.pets[suggestion.gender] : lexique.search[suggestion.gender]}
					{age ? ` - ${age} ${lexique.search.yo}` : ''}
					{suggestion.phoneNumber ? ` - ${suggestion.phoneNumber}` : ''}
                </Typography>
            </MenuItem>
        );
    }

    renderDesktop = () => {
        const { value, disabled, mode, isUserPicked, pickedUser, isError } = this.props;
        if (mode === "edit") return this.renderMobile();
        return (
            <Autosuggest
                renderInputComponent={this.renderInput}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={{
                    value,
                    onChange: this.onChange,
                    disabled,
                    required: true,
                    error: isError,
                    autoFocus: mode === "new",
                    startAdornment: isUserPicked ? (
                        <InputAdornment position="start">
                            {pickedUser && pickedUser.isAnimal ? <Pets /> : <Person /> }
                        </InputAdornment>
                    ) : <span />
                }}
            />
        )
    }

    render() {
		const { isMobile } = this.props;

    	return (
    		<div className="flex1 flex aic">
                {isMobile ? this.renderMobile() : this.renderDesktop() }
    		</div>
    	)
    }
}

const mapStateToProps = ({env, ui}) => ({
	lexique: getLexique(env.locale).appointment,
    isMobile: ui.device.isMobile,
});

export default connect(mapStateToProps)(NameInput);
