import { memo, useEffect, useRef } from 'react';
import { useActiveSinkId } from './hooks';

const AudioTrack = ({ track }) => {
    const [ activeSinkId ] = useActiveSinkId();
    const audioEl = useRef();

    useEffect(() => {
        audioEl.current = track.attach();
        document.body.appendChild(audioEl.current);
        return () => track.detach().forEach(el => el.remove());
    }, [track]);

    useEffect(() => {
        audioEl.current?.setSinkId?.(activeSinkId);
    }, [activeSinkId]);

    return null;
}

export default memo(AudioTrack)