import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as LOGS from '../constants/logs';
import * as PLANNING from '../constants/planning';

const initialState = {
	filters: {
		period: "today",
		pickedUser: null,
		who: 'all',
		mode: 'of',
	},
	loading: false,
	logs: []
};

const reducers = {
	[LOGS.LOAD_START]: state => update(state, {
		loading: { $set: true }
	} ),
	[LOGS.FILTER_UPDATE]: (state, {payload:{filter, value}}) => update(state, {
		filters: {
			[filter]: { $set: value }
		}
	} ),
	[LOGS.LOAD_COMPLETE]: (state, {payload:logs}) => update(state, {
		loading: { $set: false },
		logs: { $set: logs }
	} ),
	[PLANNING.SET_PLANNING]: state => update(state, {
		logs: { $set: [] }
	} ),
}


export default createReducer(initialState, reducers);
