import { combineReducers } from 'redux';

import env from './env';
import ui from './ui';
import user from './user';
import plannings from './plannings';
import appointment from './appointment';
import users from './users';
import message from './message';
import website from './website';
import drive from './drive';
import logs from './logs';

const reducers = combineReducers({
	env,
	ui,
	user,
	plannings,
	appointment,
	users,
	message,
	website,
	drive,
	logs
});


export default reducers;
