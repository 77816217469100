import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Today from '@material-ui/icons/Today';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import getLexique from '../../locales';

class CalendarToolbar extends PureComponent {

	state = {
		open: false,
		selectedDate: moment()
	}

	openPickerDate = () => {
		this.setState({open: true})
	}

	handlePickChange = selectedDate => {
		this.setState({selectedDate, open: false});
		this.props.onNavigate('DATE', selectedDate)
	}

	getMonthLabelToolbar = () => {
		const { date, config, planningConf } = this.props;

		const addDays = !planningConf.showWeekEnd ? 5 : (planningConf.showSunday ? 6 : 5);
		const endView = moment(date).add(addDays, "days");

		const startMonth = moment(date).format('MMMM');
		const endMonth = moment(endView).format('MMMM');

		// Differents Months
		if (startMonth !== endMonth) {
			const startYear =  moment(date).format('YYYY');
			const endYear =  moment(endView).format('YYYY');
			if (startYear !== endYear) {
				// Different year
				return `${moment(date).format(config.monthFormat)} - ${moment(endView).format(config.monthFormat)}`;
			}

			// Same year
			return `${startMonth} - ${endMonth} ${startYear}`;

		}

		// Same months
		return moment(date).format(config.monthFormat);
	}

    onPrev = () => this.props.onNavigate('PREV');
    onNext = () => this.props.onNavigate('NEXT');

    render() {
        const { lexique, locale, planningConf, isTablet } = this.props;
		const { selectedDate } = this.state;

    	return (
    		<div className="planning_toolbar flex aic">
                <div className="tal">
                    <IconButton onClick={this.onPrev}><ChevronLeft /></IconButton>
                </div>
				<div className="tac" style={{width: '250px'}}>
					<Button onClick={this.openPickerDate} size="small" color="primary">
						<Today className="mr10" />
						{lexique.openDatePicker}
					</Button>
					<div className="planning_toolbar_picker">
						<MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
							<DatePicker
								ref={this.picker}
								label=""
								todayLabel={lexique.today}
								showTodayButton
								format="DD/MM/YYY"
								value={selectedDate}
								onChange={this.handlePickChange}
								cancelLabel={lexique.cancel}
								autoOk
								open={this.state.open}
								leftArrowIcon={<KeyboardArrowLeft />}
								rightArrowIcon={<KeyboardArrowRight />}
								shouldDisableDate={date => {
									const dayOfWeek = date.weekday();
									if (dayOfWeek >= 5 && !planningConf.showWeekEnd) return true;
									if (dayOfWeek === 6 && !planningConf.showSunday) return true;

									return false;
								}}
							/>
						</MuiPickersUtilsProvider>
					</div>
				</div>
                <div className="flex1 tac planning_toolbar_month">
					<Typography variant={isTablet ? "h6" : 'h4'}>
						{this.getMonthLabelToolbar()}
					</Typography>
				</div>
				{!isTablet && <div className="tac" style={{width: '250px'}}>
				</div>}
                <div className="tar">
                    <IconButton onClick={this.onNext}><ChevronRight /></IconButton>
                </div>
    		</div>
    	)
    }
}


const mapStateToProps = ({env, plannings, ui}) => ({
	lexique: getLexique(env.locale).event.toolbar,
    config: getLexique(env.locale).config,
	locale: env.locale,
	planningConf: plannings[plannings.selected].configuration,
	isTablet: ui.device.isTablet,
});

export default connect(mapStateToProps)(CalendarToolbar);
