import React, { memo } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useLexique } from '../../locales';
import routes from '../../config/routes';
import { usePlanning, useBalance } from '../../hooks/planning';


const PaymentsBalance = () => {

    const lexique = useLexique('dashboard.payments');
    const [planning] = usePlanning();

    const balance = useBalance();

    if (!planning || !planning.canCreatePayments || !balance) return null;

    const b = balance?.amount || 0;

    return (
        <Paper className="dashboard_widget pad20 tac">
            <Typography variant="subtitle1" className="widget_title">{lexique.title}</Typography>
            <Typography variant="h1" color="primary" className="widget_number">{(b/100)}€</Typography>
            <Button component={Link} to={routes.payments} color="primary" className="mt10">{lexique.cta}</Button>
        </Paper>
    )

};

export default memo(PaymentsBalance);