import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

import AppointmentsTypes from './settings/AppointmentsTypes';
import Planning from './settings/Planning';
import Appointments from './settings/Appointments';
import Profile from './settings/Profile';
import Notifications from './settings/Notifications';

import getLexique from '../locales';

class Settings extends PureComponent {

	state = { tab: 0 };

	handleChangeTab = (e, tab) => {
		this.setState({tab});
	}

	render() {
		const { lexique, isMobile, loading } = this.props;
		const { tab } = this.state;
		return (
			<div className="settings pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.title}</Typography>
				</div>
				<div className="settings-container mt20">
					<Paper>
						<Tabs
							value={tab}
							onChange={this.handleChangeTab}
							indicatorColor="primary"
							textColor="primary"
							centered={!isMobile}
							variant={isMobile ? "scrollable" : "standard"}
            				scrollButtons="auto"
						>
							<Tab label={lexique.tabs.appointmentsTypes} />
							<Tab label={lexique.tabs.profile} />
							<Tab label={lexique.tabs.planning} />
							<Tab label={lexique.tabs.appointment} />
							<Tab label={lexique.tabs.notifications} />
						</Tabs>
					</Paper>
					<div className="mt20">
						{ loading && <LinearProgress color="secondary" /> }
						{!loading && <SwipeableViews index={tab} animateHeight disabled>
							<AppointmentsTypes />
							<Profile />
							<Planning />
							<Appointments />
							<Notifications />
						</SwipeableViews>}
					</div>
				</div>
			</div>
		)
	}
}


const mapStateToProps = ({env, ui, plannings}) => ({
	lexique: getLexique(env.locale).settings,
	isMobile: ui.device.isMobile,
	loading: plannings[plannings.selected] ? plannings[plannings.selected].loading : true,
});

export default connect(mapStateToProps)(Settings);
