import global from './global';

const outdatedDatas = `Il semblerait que votre application ne soit pas à jour avec les données en base de données. Nous vous recommandons fortement de recharger cette page pour forcer le rafraichissement des données. Votre action n'a pas pu être réalisée, nous vous invitons à la réitérer après avoir rechargé la page.`

export default {
	confirmationChangeEvent: {
		title: `Confirmez le déplacement du rendez-vous`,
		body: `Voulez vous déplacer votre rendez-vous avec **{0}**, du **{1}** au **{2}** ?`,
		confirm: `Déplacer le rendez-vous`,
		cancel: `Annuler`
	},
	conflicts: {
		title: `Attention !`,
		body: `Votre rendez-vous du {0}, est en conflit avec le ou les rendez-vous suivants :`,
		cancel: `Annuler`,
		confirm: `Valider le rendez-vous`,
		blockedSlots: `Créneaux bloqués`,
	},
	error: {
		title: `Action impossible`,
		ok: `Ok`,
		errors: {
			default: `Impossible d'effectuer cette action`,
			'move/slot/past': `Impossible de déplacer le rendez-vous sur un créneau déjà passé.`,
			'move/user/slot/unavailable': `Le {p} a déjà un rendez-vous planifié sur ce créneau. Impossible de déplacer votre rendez-vous sur ce créneau.`,
			'create/user/slot/unavailable': `Le {p} a déjà un rendez-vous planifié sur ce créneau. Impossible de créer ce rendez-vous.`,
			'internal-error': `Désolé, une erreur interne est survenue...`,
			'update/user/slot/unavailable': `Le {p} a déjà un rendez-vous planifié sur ce créneau. Impossible de modifier ce rendez-vous.`,
			'move/conflict/unhandled': outdatedDatas,
			'create/conflict/unhandled': outdatedDatas,
			'update/conflict/unhandled': outdatedDatas,
		}
	},
	confirmDeleteAppointment: {
		title: `Annuler un rendez-vous`,
		cancel: `Retour`,
		confirm: `Annuler le rendez-vous`,
		confirmShort: `Annuler le RDV`,
		body: `Voulez-vous annuler le rendez-vous du **{0}** avec **{1}** ? Cette action est irréversible et ne pourra pas être annulée.`
	},
	confirmAppointment: {
		title: `Confirmer un rendez-vous`,
		cancel: `Retour`,
		confirm: `Confirmer le rendez-vous`,
		confirmShort: `Confirmer le RDV`,
		body: `Voulez-vous confirmer le rendez-vous du **{0}** avec **{1}** ?`
	},
	confirmDiscardDuplicates: {
		title: `Attention !`,
		cancel: `Retour`,
		confirm: `Rejeter`,
		body: `Vous avez des rendez-vous dupliqués qui ne sont pas sauvegardés. Voulez-vous vraiment rejeter ces rendez-vous ?`
	},
	confirmToggleCloseDay: {
		cancel: `Annuler`,
		open: {
			title: `Ouvrir le jour à la réservation ?`,
			body: `Voulez-vous ouvrir le **{0}** à la réservation en ligne ?`,
			confirm: `Ouvrir le jour`
		},
		close: {
			title: `Fermer le jour à la réservation ?`,
			body: `Voulez-vous fermer le **{0}** à la réservation en ligne ? Vous seul pourrez ajouter des rendez-vous pour ce jour.`,
			confirm: `Fermer le jour`
		}
	},
	editDayInformationMessage: {
		cancel: `Annuler`,
		confirm: `Sauvegarder`,
		remove: `Supprimer le message`,
		removeShort:  `Supprimer`,
		title: `Message d'information`,
		body: `Message aux {p}s pour le **{0}**.`,
		message: `Message`,
		messageShort: 'Mess.',
		toolbar: global.toolbar,
	},
	editDayNotes: {
		cancel: `Annuler`,
		confirm: `Sauvegarder`,
		remove: `Supprimer la note`,
		removeShort:  `Supprimer`,
		title: `Notes personnelles`,
		body: `Notes personnelles pour le **{0}**. Ces notes ne seront visible que par vous et les praticiens ayant accès à votre planning. Elles ne seront jamais visible pour vos {p}s.`,
		label: `Vos notes`,
	},
	editDaySpecificSchedules: {
		title: `Modifier le planning du {0}`,
		cancel: `Annuler`,
		confirm: `Sauvegarder`,
		close: `Fermer`,
		undefined: `Non défini`,
		blocked: `Rendez-vous bloqués`,
		notWorked: `Non travaillé`,
		titleAll: `Tous les types de rendez-vous`,
		infoAll: `Modifier les plannings de tous les types de rendez-vous`,
		unavailable: `Désolé, cette fonctionnalité n'est pour l'instant disponible que sur un ordinateur de bureau. N'hésitez pas à envoyer un mail à support@sprel.io pour nous informer de l'importance, pour vous, d'avoir cette fonctionnalité sur tablette et smartphone.`,
		actions: {
			block: `Bloquer ce jour`,
			regular: `Par défaut`,
			specific: `Définir ce jour`,
		},
		labels: {
			specificDay: `Horaires définis pour ce jour`,
			specificPeriod: `Horaires définis pour la période du {0} au {1}`,
			regular: `Horaires par défaut`,
		}
	},
	editDaySpecificSchedulesConfirmDiscard: {
		title: `Attention !`,
		cancel: `Retour`,
		confirm: `Rejeter`,
		body: `Vous avez des horaires spécifiques définis qui ne sont pas sauvegardés. Voulez-vous rejeter vos modifications de planning ?`
	},
	pickSchedule: {
		title: `Définissez votre planning`,
		cancel: 'Retour',
		confirm: 'Valider',
		delete: `Supprimer`,
		from: 'De',
		to: 'à',
	},
	adminAddPractitioner: {
		title: `Ajouter un praticien`,
		cancel: `Annuler`,
		confirm: `Créer`,
		fields: {
			particule: 'Particule',
			firstName: `Prénom`,
			lastName: 'Nom',
			phoneNumber: 'Numéro de téléphone',
			email: `Adresse email`,
		}
	},
	addEditAppointmentType: {
		title: {
			new: `Ajouter un type de rendez-vous`,
			edit: `Modifier le type de rendez-vous`,
		},
		cancel: `Annuler`,
		confirm: {
			new: `Ajouter`,
			edit: `Modifier`,
		},
		label: `Titre`,
		defaultPaymentAmount: 'Prix prestation',
		chooseColor: `Choisissez une couleur`,
		changeColor: `Changer de couleur`,
		activeForUser: `Réservable par vos {p}s`,
		onlyRealSlot: `Ouvrir seulement aux intervalles du créneau`,
		isVisio: `Rendez-vous en téléconsultation`,
		duration: `Durée par défaut`,
		scheduleClosed: `Fermé`,
		infos: {
			onlyRealSlot: `Si vous cochez cette case, les créneaux ne seront ouvert que pour les intervalles de temps définis pour ce type de rendez-vous.

Par exemple, si votre planning est défini pour avoir des créneaux toutes les 15 minutes, et que vous ouvrez un rendez-vous d'une durée de 45 minutes, entre 9h00 et 12h00 :
- Si vous cochez cette case, les rendez-vous proposés au {p} pour ce type de rendez-vous seront : 9h00, 9h45, 10h30, 11h15.
- Si vous ne cochez pas cette case, vos {p}s pourront prendre rendez-vous toutes les 15 minutes, à savoir 9h15, 9h30, 9h45, 10h00... Si un {p} prend rendez-vous à 9h30, il bloquera automatiquement les créneaux de 9h30 à 10h15, mais laissera libre le créneau de 9h15, pour un autre type de rendez-vous.`,
			isVisio: `Si vous cochez cette case, les rendez-vous seront créés en téléconsultation.

Votre {p} pourra rejoindre une salle d'attente virtuelle 15mins avant le début de la téléconsultation. Vous serez prévenu directement dans Sprel que votre {p} est présent dans la salle d'attente virtuelle et pourrez le rejoindre à tout moment.

Vous pourrez, directement depuis l'espace de téléconsultation, envoyer des pièces jointes à votre {p}, qui lui seront transmis par email sans aucun stockage sur les serveurs de Sprel.

La téléconsultation est chiffré "point-à-point" ce qui signifie que le flux vidéo ne transite à aucun moment par les serveurs de Sprel.

Les moyens de paiement ne sont pas pris en charge, vous devrez vous entendre en amont avec votre {p} concernant le règlement de la consultation.`,
		}
	},
	deleteAppointmentType: {
		title: `Supprimer un type de rendez-vous`,
		alert: `Voulez-vous vraiment supprimer le type de rendez-vous **{0}** ? Cette action est irréversible et ne pourra être annulée`,
		cancel: `Annuler`,
		confirm: `Oui, supprimer`,
	},
	editAppointmentTypeProfile: {
		title: {
			new: `Ajouter un profil au type de rendez-vous`,
			edit: `Modifier le profil du type de rendez-vous`,
		},
		delete: `Supprimer`,
		cancel: `Annuler`,
		confirm: {
			new: `Ajouter`,
			edit: `Modifier`,
		},
		labels: {
			geoLocation: `Coordonnées GPS`,
			address: `Adresse`,
			phoneNumber: `Numéro de téléphone`,
			lat: `Latitude`,
			lng: `Longitude`,
		},
		infos: `Définissez les coordonnées GPS afin de fournir une précision plus importante à vos {p}s. Vous pouvez obtenir des coordonnées GPS à partir d'une adresse sur ce site : https://www.coordonnees-gps.fr/`
	},
	editAppointmentTypeSpecificSchedule: {
		isForAll: `Vous pouvez ici définir des horaires spécifiques qui s'appliqueront à tous vos types de rendez-vous pour la période choisie. Vous pouvez notamment définir des périodes de vacances en fermant tous les jours.`,
		title: {
			new: `Ajouter une plage d'horaires`,
			edit: `Modifier la plage d'horaires`,
		},
		scheduleClosed: `Fermé`,
		cancel: `Annuler`,
		confirm: {
			new: `Ajouter`,
			edit: `Modifier`,
		},
		labels: {
			from: `Du...`,
			to: `Au...`,
		},
	},
	confirmAcceptUser: {
		title: `Accepter une famille`,
		body: `Voulez-vous accepter la famille **{0}** ? Ses membres pourront alors prendre rendez-vous par internet.`,
		cancel: `Annuler`,
		confirm: `Accepter`,
	},
	confirmBlockUser: {
		title: `Bloquer une famille`,
		body: `Voulez-vous bloquer la famille **{0}** ? Ses membres ne pourront plus prendez rendez-vous par internet`,
		cancel: `Annuler`,
		confirm: `Bloquer`,
	},
	userDetails: {
		title: `Famille {0}`,
		recover: `Accepter cette famille`,
		delete: `Bloquer cette famille`,
		notFilled: `Non renseigné`,
		memberSince: `{p}s depuis le {0}`,
		userCertified: `Adresse email confirmée`,
		close: `Fermer`,
		tabs: {
			profile: `Informations`,
			members: `Famille`,
			upcomingAppointments: `Rendez-vous à venir`,
			pastAppointments: `Rendez-vous passés`,
			payments: 'Factures',
		},
		payments: {
			noEntry: 'Aucune facture disponible',
		}
	},
	addNewUser: {
		title: `Ajouter un {p}`,
		email: `Adresse email`,
		hasResult: `Cet email correspond à l'utilisateur Sprel suivant :`,
		alreadyInYours: `Ce {p} fait déjà parti de votre liste de {p}s`,
		doNotExists: `Ce {p} n'existe pas dans la base d'utilisateurs Sprel. Cliquez sur le bouton "Créer le {p}" ci-dessous afin de le créer.`,
		addToMyList: `Ajouter à ma liste de {p}s`,
		create: `Créer le {p}`,
		infosCreate: `Ce {p} recevra un code temporaire sur son adresse email afin de finaliser son inscription. A sa première identification, il devra changer son mot de passe, et accepter les conditions d'utilisation du site`,
		close: `Fermer`,
		firstName: `Prénom`,
		lastName: `Nom`,
		gender: `Genre`,
		options: {
			male: `Homme`,
			female: `Femme`,
			other: `Autre`,
		},
		calendar: {
			ok: `Ok`,
			cancel: `Annuler`,
			invalid: `La date de naissance doit être au format JJ/MM/AAAA`,
			ddmmyyyy: 'JJ/MM/AAAA',
		},
		phoneNumber: `Numéro de téléphone`,
		birthdate: `Date de naissance`,
	}
}
