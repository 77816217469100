import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Button, IconButton } from '@material-ui/core';
import { VideocamOutlined as VisioIcon, Close as CloseIcon, Update as DelayIcon } from '@material-ui/icons';

import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import Markdown from '../ui/Markdown';

import { config } from '../../config/env';
import { getShortInfos, addRequestUserToStore, acceptUserComplete, blockUserComplete } from '../../actions/users';
import { deleteStoreAppointment, createStoreAppointment, getAppointment, addAppointmentToConfirmToStore, removeAppointmentToConfirmFromStore, resetAppointmentInConfirmList, confirmStoreAppointment } from '../../actions/appointment';
import { addMessageToStore } from '../../actions/messages';
import { openVisioRoom, openModale, receivedFilesFromVisio } from '../../actions/ui';
import { getPaymentsBalance } from '../../actions/planning';

import getLexique from '../../locales';

Pusher.logToConsole = true;

class Subscriber extends PureComponent {

	channels = [];
	pusher = null;

	componentDidMount() {
		if (config.pusherClient !== '') {
			this.pusher = new Pusher(config.pusherClient, {
				cluster: 'eu',
				encrypted: true
			});

			const { hasAccessTo } = this.props;
			if (hasAccessTo.default) this.channels.push(this.createToChannel(hasAccessTo.default));
			hasAccessTo.others.forEach(channel => {
				this.channels.push(this.createToChannel(channel.id));
			})
		}
	}

	createToChannel = channel => {
		const chan = this.pusher.subscribe(`channel-p-${channel}`);
		chan.bind('appointment-deleted', this.onAppointmentDeleted);
		chan.bind('appointment-updated', this.onAppointmentUpdated);
		chan.bind('appointment-confirmed', this.onAppointmentConfirmed);
		chan.bind('appointment-new', this.onNewAppointment);
		chan.bind('user-request-access', this.onUserRequestAccess);
		chan.bind('user-accepted', this.onAcceptUser);
		chan.bind('user-blocked', this.onBlockUser);
		chan.bind('new-message', this.onReceiveNewMessage);
		chan.bind('visio-waiting', this.onUserWaitingInRoom);
		chan.bind('visio-files-received', this.onReceiveFiles);
		chan.bind('refresh-balance', this.onPaymentReceived);

		return chan;
	}

	deleteChannel = channel => {
		this.pusher.unsubscribe(`channel-p-${channel}`);
	}

	unsubscribeFromChannel = () => {
		if (!this.pusher) return;

		const { hasAccessTo } = this.props;
		if (hasAccessTo.default) this.deleteChannel(hasAccessTo.default);
		hasAccessTo.others.forEach(channel => this.deleteChannel(channel));
		this.channels.map(channel => channel.unbind());
	}

	componentWillUnmount() {
		this.unsubscribeFromChannel();
	}

	render() {
		const { locale } = this.props;

		moment.locale(locale);

		return null;
	}

	/* EVENTS HANDLERS */
	/* USERS */
	onUserRequestAccess = async ({userId, planningId}) => {
		const { plannings, actions:{getShortInfos, addRequestUserToStore}, lexique } = this.props;
		// Checking if planning is loaded
		const planning = plannings[planningId];
		if (!planning) return;

		const users = planning.usersToAccept;
		if (users === null) return;

		// Checking if not already in queue list
		if (users.indexOf(userId) > -1) return;

		// We shoud load it indeed;
		const family = await getShortInfos({ planningId, userId });
		addRequestUserToStore({
			planningId,
			user: family,
		});

		// Toast it!
		toast.success(<Markdown>
			{lexique.userRequest.replace('{0}', family.mainMemberName)}
		</Markdown>);
	}

	onAcceptUser = ({appToken, ...datas}) => {
		if (this.props.appToken === appToken) return;

		const { plannings, actions:{acceptUserComplete} } = this.props;
		// Checking if planning is loaded
		const planning = plannings[datas.planningId];
		if (!planning) return;

		const users = planning.usersToAccept;
		if (users === null) return;

		acceptUserComplete(datas);
	}

	onBlockUser = ({appToken, ...datas}) => {
		if (this.props.appToken === appToken) return;

		const { plannings, actions:{blockUserComplete} } = this.props;
		// Checking if planning is loaded
		const planning = plannings[datas.planningId];
		if (!planning) return;

		const users = planning.usersToAccept;
		if (users === null) return;

		blockUserComplete(datas);
	}

	/* APPOINTMENTS */
	onAppointmentConfirmed = ({appToken, byUserId, planningId, date, id, by, user}) => {
		if (this.props.appToken === appToken) return;

		const { plannings, actions: { removeAppointmentToConfirmFromStore, confirmStoreAppointment }, currentUserId, config, lexique, selectedPlanning } = this.props;

		// Toast
		if (byUserId !== currentUserId && selectedPlanning === planningId) {
			toast.success(<Markdown>
				{
					lexique.appointments.confirmed
					 .replace('{0}', moment(date).format(config.datetimeFormatLong))
					 .replace('{1}', user)
					 .replace('{2}', by)
				 }
			</Markdown>);
		}
		if (!plannings[planningId] || !plannings[planningId].appointmentsToConfirm) return;

		// Remove from list
		removeAppointmentToConfirmFromStore({
	        planningId,
	        id,
	    })

		// Update in store
		const dateYmd = moment(date).format('YYYY-MM-DD');
		const planning = plannings[planningId];
		if (!planning) return;
		const days = planning.days;
		if (!days) return;
		const storeDay = days[dateYmd];
		if (!storeDay) return;

		confirmStoreAppointment({
            planningId,
            id,
            day: dateYmd,
            confirmed: {
                at: moment().format(),
                by,
            }
        })
	}

	onNewAppointment = async ({appToken, byUserId, user, planningId, date, id}) => {
		if (this.props.appToken === appToken) return;

		const { plannings, actions: { createStoreAppointment, getAppointment, addAppointmentToConfirmToStore }, currentUserId, selectedPlanning, config, lexique } = this.props;

		const dateYmd = moment(date).format('YYYY-MM-DD');
		/* TOAST IT if i'm not the one who made the event & i'm on right planning */
		if (byUserId !== currentUserId && user && selectedPlanning === planningId) {
			toast.success(<Markdown>
				{
					lexique.appointments.new
					 .replace('{0}', moment(date).format(config.datetimeFormatLong))
					 .replace('{1}', user)
				 }
			</Markdown>);
		}

		// Checking if planning is loaded
		const planning = plannings[planningId];
		if (!planning) return;

		// Getting new apointment datas
		const app = await getAppointment({
			practitionerId: planningId,
			id,
		});

		// Cheking if should add appointment to ToBeConfirmedList
		if (app.confirmed.at === '') {
			// Should add
			addAppointmentToConfirmToStore({
				planningId,
				event: {
					...app,
					id
				},
			});
		}

		// Checking if day is loaded
		const days = planning.days;
		if (!days) return;

		const storeDay = days[dateYmd];
		if (!storeDay) return;

		// Add to store
		createStoreAppointment({
			event: {
				...app,
				id,
			},
			day: dateYmd,
			planningId
		});
	}

	onAppointmentUpdated = async ({appToken, byUserId, user, planningId, fromDate, toDate, id}) => {
		if (this.props.appToken === appToken) return;

		const { plannings, actions: { deleteStoreAppointment, createStoreAppointment, getAppointment, resetAppointmentInConfirmList }, currentUserId, selectedPlanning, config, lexique } = this.props;

		// Toast if not me
		const fromYmd = moment(fromDate).format('YYYY-MM-DD');
		const toYmd = moment(toDate).format('YYYY-MM-DD');

		/* TOAST IT if i'm not the one who made the event & i'm on right planning */
		if (byUserId !== currentUserId && selectedPlanning === planningId && fromYmd !== toYmd) {
			toast.info(<Markdown>
				{
					lexique.appointments.update
					 .replace('{0}', moment(fromDate).format(config.datetimeFormatLong))
					 .replace('{1}', user)
					 .replace('{2}', moment(toDate).format(config.datetimeFormatLong))
				 }
			</Markdown>);
		}

		// Checking if planning is loaded && day is loaded
		const planning = plannings[planningId];
		if (!planning) return;

		// Getting appointment information
		const app = await getAppointment({
			practitionerId: planningId,
			id,
		});

		resetAppointmentInConfirmList({
			planningId,
			event: {
				...app,
				id
			},
		});

		const days = planning.days;
		if (!days) return;

		const storeFromDay = days[fromYmd];
		const storeToDay = days[toYmd];
		if (storeFromDay) {
			deleteStoreAppointment({
				planningId,
				day: fromYmd,
				id,
			});
		}

		if (storeToDay) {
			createStoreAppointment({
				event: {
					...app,
					id,
				},
				day: toYmd,
				planningId
			});
		}
	}

	onAppointmentDeleted = ({ byUserId, appToken, user, planningId, date, id}) => {

		if (this.props.appToken === appToken) return;

		const { plannings, actions: { deleteStoreAppointment, removeAppointmentToConfirmFromStore }, currentUserId, config, lexique, selectedPlanning } = this.props;

		/* TOAST IT if i'm not the one who made the event & i'm on right planning */
		if (user && byUserId !== currentUserId && selectedPlanning === planningId) {
			toast.error(<Markdown>
				{
					lexique.appointments.deleted
					 .replace('{0}', moment(date).format(config.datetimeFormatLong))
					 .replace('{1}', user)
				 }
			</Markdown>);
		}

		// Checking if planning is loaded && day is loaded
		const planning = plannings[planningId];
		const day = moment(date).format('YYYY-MM-DD');
		if (!planning) return;

		removeAppointmentToConfirmFromStore({
			planningId,
			id,
		})

		const days = planning.days;
		if (!days) return

		const storeDay = days[day];
		if (!storeDay) return;

		deleteStoreAppointment({
			planningId,
			day,
			id,
		});
	}

	/* MESSAGES */
	onReceiveNewMessage = ({id, name, email, subject, planningId}) => {
		const { plannings, selectedPlanning, lexique, actions: {addMessageToStore} } = this.props;

		/* Toast */
		if (selectedPlanning === planningId) {
			toast.success(<Markdown>
				{
					lexique.messages.new
					 .replace('{0}', name)
					 .replace('{1}', email)
					 .replace('{2}', subject)
				 }
			</Markdown>);
		}

		/* Store */
		const planning = plannings[planningId];
		if (!planning) return;
		addMessageToStore({
			planningId,
			id,
			name,
			email,
			subject,
			sentAt: moment().format(),
			state: 'active'
		})
	}

	/* VISIO */
	onUserWaitingInRoom = ({user, planningId, date, id}) => {
		const { lexique, config, actions:{openVisioRoom, openModale}, selectedPlanning, visioDatas } = this.props;

		if (selectedPlanning !== planningId) return;

		// Already in this visio, do nothing;
		if (visioDatas.open && visioDatas.planningId === planningId && visioDatas.eventId === id) return null;

		const toastId = toast.info(<>
			<Markdown>
			{
				lexique.userWaitingInRoom.body
				 .replace('{0}', user)
				 .replace('{1}', moment(date).format(config.timeFormat))
			 }
			</Markdown>
			<Button onClick={() => {
				openModale({
					modale: 'sendVisioDelayMessage',
					user,
					planningId,
					eventId: id
				})
			}}>
				<DelayIcon className="ico" />
				{lexique.userWaitingInRoom.delay}
			</Button>
			<Button onClick={() => {
				openVisioRoom({
					planningId,
					eventId: id,
				})
				toast.dismiss(toastId);
			}} variant="contained" disableElevation color="primary">
				<VisioIcon className="ico" />
				{lexique.userWaitingInRoom.join}
			</Button>
			<IconButton size="small" className="toast-close" onClick={() => {
				toast.dismiss(toastId);
			}}>
				<CloseIcon fontSize="inherit" />
			</IconButton>
		</>, {
			autoClose: false,
			closeOnClick: false,
			draggable: false,
			position: 'bottom-right',
		});
	}

	onReceiveFiles = datas => {
		this.props.actions.receivedFilesFromVisio(datas);
	}

	/* PAYMENTS */
	onPaymentReceived = ({planningId}) => {
		if (this.props.selectedPlanning !== planningId) return;
		this.props.actions.getPaymentsBalance(planningId);
	}
}

const mapStateToProps = ({env:{locale}, env, user, plannings, ui}) => ({
	config: getLexique(locale).config,
	lexique: getLexique(locale).toasts.pusher,
	appToken: env.appToken,
	locale,
	currentUserId: user.id,
	hasAccessTo: user.hasAccessTo,
	plannings,
	selectedPlanning: plannings.selected,
	visioDatas: ui.visio,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getShortInfos, addRequestUserToStore, deleteStoreAppointment, createStoreAppointment, acceptUserComplete, blockUserComplete, getAppointment, addAppointmentToConfirmToStore, removeAppointmentToConfirmFromStore, resetAppointmentInConfirmList, confirmStoreAppointment, addMessageToStore, openVisioRoom, openModale, getPaymentsBalance, receivedFilesFromVisio }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);
