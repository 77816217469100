import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Close from '@material-ui/icons/Close';
import Send from '@material-ui/icons/Send';
import AttachFile from '@material-ui/icons/AttachFile';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import { send } from '../../actions/messages';

import getLexique from '../../locales';

const MAX_SIZE = 1024*1024*5;

class FormSend extends PureComponent {

	state = { loading: false, subject: '', body: '', includeQuestion: true, file: null, fileBase64: null };

	mapPropsToState = () => {
		const { subject, body, includeQuestion } = this.props;
		this.setState({
			subject: subject && subject !== '' ? `Re: ${subject}` : '',
			body : body || '',
			includeQuestion: includeQuestion !== null && includeQuestion !== undefined ? includeQuestion : true,
			fileBase64: null,
			file: null,
		});
	}

	componentDidUpdate = o => {
		if (o.id !== this.props.id) this.mapPropsToState();
	}

	componentDidMount() {
		this.mapPropsToState();
	}

	onChangeInput = field => ({target:{value}}) => this.setState({[field]: value});

	onChangeCheckbox = field => ({target:{checked}}) => this.setState({[field]: checked});

	onSendMessage = async () => {
		this.setState({loading: true});
		const { actions: {send}, email, name, id, onClose } = this.props;
		const { body, subject, includeQuestion, file, fileBase64 } = this.state;

		if (body === '') return;

		await send({
			email,
			name,
			subject,
			body,
			includeQuestion,
			originalMessageId: id || null,
			attachment: file ? {
				content: fileBase64,
				type: file.type,
				name: file.name,
			} : null,
		});
		this.setState({loading: false});
		onClose();
	}

	/* DROP */
	onDropFile = files => {
		const { lexique } = this.props;
		if (files.length === 0) {
			window.alert(lexique.fileRejected);
			return;
		}

		const file = files[0];
		if (file.size > MAX_SIZE) {
			window.alert(lexique.fileTooLarge);
			return;
		}

		this.setState({file});
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64 = reader.result;
			const formattedBase64 = base64.replace(`data:${file.type};base64,`, '');
			this.setState({fileBase64: formattedBase64});
		};

	}

	deleteAttachment = () => {
		this.setState({file: null, fileBase64: null});
	}

	render() {
		const { onClose, lexique, email, name, displaySwitch } = this.props;
		const { subject, body, includeQuestion, loading, file } = this.state;

		const showSwitch = displaySwitch === undefined || displaySwitch === true || displaySwitch === null;

		return <>
			<DialogTitle className="send-email-header flex aic" disableTypography>
				<Typography variant="h6" className="fg1 pad10 padl20">
					{lexique.title}
				</Typography>
				<IconButton onClick={onClose} disabled={loading}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Dropzone
					noClick
					multiple={false}
					accept={['application/pdf', 'image/*']}
					onDrop={this.onDropFile}
				>
					{({getRootProps, getInputProps, open, isDragActive, isDragReject, isDragAccept}) => (
					<div className={cn('dropzone', {
		                'dropzone--accept': isDragAccept,
		                'dropzone--reject': isDragReject
		            })} {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive &&
						<div className="dropzone-placeholder">
								<div className="content-inner">
									<Typography variant="h5" className="dropzone-drophere">
										{lexique.drophere}
									</Typography>
								</div>
						</div>}
						<div className="send-email-form">
							<TextField
								label={lexique.to}
								defaultValue={`${name} <${email}>`}
								disabled
								fullWidth
								margin="normal"
								variant="outlined"
							/>
							<TextField
								label={lexique.subject}
								value={subject}
								disabled={loading}
								fullWidth
								margin="normal"
								onChange={this.onChangeInput('subject')}
								variant="outlined"
							/>
							<div className="flex aic">
								<IconButton onClick={file ? this.deleteAttachment : open} disabled={loading}>
									{ file ? <Close /> : <AttachFile /> }
								</IconButton>
								{ file &&
									<Typography variant="overline" className="ml10">
										{ file.name }
									</Typography>
								}
								{ !file &&
									<Button onClick={open} disabled={loading}>
										{ lexique.attachment }
									</Button>
								}
							</div>
							<TextField
								autoFocus
								label={lexique.body}
								value={body}
								disabled={loading}
								fullWidth
								multiline
								rows={10}
								margin="normal"
								onChange={this.onChangeInput('body')}
								variant="outlined"
							/>
							{ showSwitch &&
							<FormControlLabel
								disabled={loading}
								control={
									<Switch
										checked={includeQuestion}
										onChange={this.onChangeCheckbox('includeQuestion')}
										color="primary"
									/>
								}
								label={lexique.includeQuestion}
							/>
							}
						</div>
					</div>
					)}
				</Dropzone>
			</DialogContent>
			<DialogActions>
				<Button disabled={loading} onClick={this.onSendMessage} color="primary" variant="contained" size="large" disableElevation>
					{lexique.send}
					{!loading && <Send className="ml5" />}
					{loading && <ButtonCircularProgress />}
				</Button>
			</DialogActions>
		</>;

	}
}


const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).inbox.send,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ send }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSend);
