import React from 'react';
import { connect } from 'react-redux';
import nl2br from 'react-nl2br';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ModalTransitionZoom from './ModalTransitionZoom';

import { closeModale } from '../../actions/ui';

const DebugModale = ({message, show, actions}) => (
	<Dialog
		open={show}
		onClose={() => actions.closeModale({modale: 'debugModale'})}
		TransitionComponent={ModalTransitionZoom}
	>
		<DialogTitle>DEBUG</DialogTitle>
		<DialogContent>
			<DialogContentText>
				{nl2br(message)}
			</DialogContentText>
		</DialogContent>
	</Dialog>
)

const mapStateToProps = ({ui}) => ({
	show: ui.modales.debugModale.show,
	message: ui.modales.debugModale.message || '',
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModale }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(DebugModale);
