import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash.isequal';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Check from '@material-ui/icons/Check';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';
import Message from '../ui/Message';

import getLexique, { _lp } from '../../locales';
import { updatePlanningProfile } from '../../actions/planning';

/*
@TODO
Profile picture
Expertises
*/

class Profile extends PureComponent {

	state = { address: '', lat: '', lng: '', phoneNumber: '',  loading: false, complete: false };

	mapPropsToInnerState = () => {
		if (!this.props.profile || Object.keys(this.props.profile).length === 0) return;

		const { address, phoneNumber, geoLocation } = this.props.profile;
		const geoSplitted = geoLocation ? geoLocation.split(',') : ['', ''];
		this.setState({address, phoneNumber, lat:geoSplitted[0], lng: geoSplitted[1] });
	}

	componentDidMount() {
		if (this.props.profile) this.mapPropsToInnerState();
	}

	componentDidUpdate(oldProps) {
		if (!_isEqual(oldProps.profile, this.props.profile)) this.mapPropsToInnerState();
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});

	submitForm = async () => {
		const { selectedPlanning, actions:{updatePlanningProfile}} = this.props;
		const { address, phoneNumber, lat, lng } = this.state;
		this.setState({loading: true});

		await updatePlanningProfile({
			planningId: selectedPlanning,
			address,
			phoneNumber,
			geoLocation: lat !== '' && lng !== '' ? `${lat},${lng}` : '',
		});

		this.setState({loading: false, complete: true});
		setTimeout(() => this.setState({complete: false}), 3000);
	}

	render() {
		const { lexique } = this.props;
		const {  address, lat, lng, phoneNumber, loading, complete } = this.state;

		return (
			<div className="planning-configuration-container">
				<Message level="information" show className="mt20 mb20 tac">{_lp(lexique.body)}</Message>
				<div className="form-row">
					<Typography component='div' variant="body1">{lexique.labels.address}</Typography>
					<TextField
						disabled={loading}
						value={address}
						onChange={this.handleChange('address')}
					/>
				</div>
				<Typography component='div' variant="caption" className="tac mt20">{_lp(lexique.labels.geoLocation)}</Typography>
				<div className="form-row">
					<Typography component='div' variant="body1">{lexique.labels.lat}</Typography>
					<TextField
						disabled={loading}
						value={lat}
						onChange={this.handleChange('lat')}
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{lexique.labels.lng}</Typography>
					<TextField
						disabled={loading}
						value={lng}
						onChange={this.handleChange('lng')}
					/>
				</div>
				<div className="form-row">
					<Typography component='div' variant="body1">{lexique.labels.phoneNumber}</Typography>
					<TextField
						disabled={loading}
						value={phoneNumber}
						onChange={this.handleChange('phoneNumber')}
					/>
				</div>
				<div className="form-row">
					<div />
					<div>
						<Button
							disabled={loading}
							variant="contained"
							className="button--success"
							onClick={this.submitForm}
							disableElevation
						>
							{lexique.save}
							{loading && <ButtonCircularProgress />}
							{complete && <Check className="ml10" />}
						</Button>
					</div>
				</div>
				<div className="form-row" />
			</div>
		);
	}
}

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.profile,
	locale: env.locale,
	selectedPlanning: plannings.selected,
	profile: plannings[plannings.selected] ? plannings[plannings.selected].profile : null,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updatePlanningProfile }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
