export default {
	welcome: `Bienvenue {0}`,
	todayAppointments: {
		title: `Rendez-vous aujourd'hui`,
		cta: `Afficher l'agenda`,
	},
	appointmentsToConfirm: {
		title: `Rendez-vous à confirmer`,
		cta: `Voir les rendez-vous`,
	},
	usersToConfirm: {
		title: `{p}s à accepter`,
		cta: `Voir tous les {p}s`
	},
	unreadMessages: {
		title: `Messages non lus`,
		cta: `Voir tous les messages`,
	},
	driveSpace: {
		title: `Stockage sécurisé`,
		caption: `Vous disposez d'un espace sécurisé de {0}`,
	},
	payments: {
		title: 'Porte-monnaie virtuel',
		cta: 'Gérer ma facturation'
	}
}
