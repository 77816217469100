export default {
    title: 'Téléconsultation',
    joinRoom: 'Rejoindre la téléconsultation',
    noRtc: {
        title: 'Erreur',
        body: 'Désolé, votre navigateur ne supporte pas le module de téléconsultation. Veuillez télécharger et utiliser un navigateur récent, tel que Google Chrome, Microsoft Edge, ou Mozilla Firefox. Sur mobile, le système de téléconsultation fonctionne sur Android, en utilisant Chrome, et sur iOS en utilisant Safari.',
        close: 'Fermer la téléconsultation'
    },
    preflight: {
        inprogress: 'Verification de la qualité de votre connexion...',
        error: 'Impossible de vous connecter au réseau. Veuillez vérifier votre connexion internet.',
        red: `Très mauvaise Connexion au réseau. Votre téléconsultation risque d'être fortement dégradée et instable.`,
        yellow: `Mauvaise connexion au réseau. Votre téléconsultation risque d'être dégradée.`,
        green: 'Votre connexion devrait vous permettre une téléconsultation optimale.'
    },
    mediaError: {
        NotAllowedError: {
            title: 'Erreur de permissions.',
            body: '`Veuillez accepter les **permissions du navigateur** pour autoriser Sprel à accéder à votre micro et votre webcam. La téléconsultation ne **pourra pas** fonctionner sans ces autorisations.'
        },
        NotFoundError: {
            title: 'Impossible de trouver votre webcam et votre micro',
            body: `Sprel n'arrive pas à acceder à votre webcam et votre micro. Veuillez vérifier qu'ils sont bien branchés, et que vous avez autorisé Sprel à les utiliser.`
        },
        MediaError: {
            title: `Impossible d'accéder aux médias`,
        },
        NoAudioVideo: {
            title: 'Aucune webcam ni micro détecté !',
            body: `Votre patient ne sera pas en mesure de vous voir, ni de vous entendre.`
        },
        NoAudio: {
            title: 'Aucun micro détecté !',
            body: `Votre patient ne sera pas en mesure de vous entendre.`
        },
        NoVideo: {
            title: 'Aucune webcam détectée !',
            body: `Votre patient ne sera pas en mesure de vous voir.`
        }
    },
    reconnecting: {
        title: 'Connexion perdue',
        body: 'Tentative de reconnexion en cours...',
    },
    waiting: 'En attente de ',
    endCall: 'Terminer la téléconsultation',
    filesSender: {
        title: 'Envoyer des pièces jointes',
        cancel: 'Annuler',
        send: 'Envoyer',
        chooseFile: 'Déposez vos pièces jointes ici, ou cliquez pour choisir vos fichiers. Fichiers acceptés : PDF et images',
        dropHere: 'Déposez vos fichiers ici',
        errorFiles: `Le(s) fichier(s) suivant n'est pas accepté : {0}`,
    },
    delay: {
        title: `Prevenir {0} d'un retard`,
        body1: `Prévenir d'un retard de`,
        body2: 'minutes',
        preview: `Bonjour. Veuillez m'excuser, j'aurai un retard de {0} minutes. Merci de ne pas quitter la salle d'attente. Cordialement.`,
        cancel: 'Annuler',
        send: 'Envoyer'
    },
    minimize: 'Réduire en mode fenêtré',
    maximize: 'Plein écran',
    localControls: {
        micOn: 'Activer le micro',
        micOff: 'Couper le micro',
        camOn: 'Activer la webcam',
        camOff: 'Couper la webcam',
    },
    filesReceiver: {
        infos: {
            title: 'Fichiers reçus',
            body: 'Les fichiers reçus seront automatiquement supprimés des serveurs de Sprel lorsque vous terminerez la téléconsultation',
        },
        errorOpen: `Vous utilisez un adblocker qui empêche l'ouverture du fichier. Veuillez désactiver votre adblocker sur Sprel pour pouvoir ouvrir les fichiers reçus. Attention, en désactivant votre adblocker, la page va se recharger, ce qui mettra fin à la téléconsultation et détruira les fichiers. Pensez à prévenir votre patient.`
    }
}