import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { closeModaleDeleteAppointmentType } from '../../actions/ui';

import getLexique from '../../locales';

class ConfirmDeleteAppointmentType extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleDeleteAppointmentType();
	}
	onConfirm = () => {
		this.props.onConfirm()
		this.onClose();
	}

    render() {
		const { show, lexique, appointment } = this.props;

		if (!appointment) return null;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-remove-appointment-type-dialog-title"
			>
				<DialogTitle id="modale-remove-appointment-type-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="error" show>
						<Markdown>
							{lexique.alert.replace('{0}', appointment.label)}
						</Markdown>
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} variant="contained" color="primary" className="button--danger" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{modales:{deleteAppointmentType:{show, onConfirm, appointment}}}}) => ({
	lexique: getLexique(env.locale).modales.deleteAppointmentType,
    show,
	onConfirm,
	appointment,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleDeleteAppointmentType }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteAppointmentType);
