import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import {useRoomState} from '../hooks';
import { useLexique } from '../../../locales';

import Alert from '../../ui/Alert';


const useStyle = makeStyles(() => ({
    alert: {
        position: "absolute",
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)'
    }
}))

const ReconnectingNotification = () => {
    const roomState = useRoomState();
    const classes = useStyle();
    const lexique = useLexique("visio.reconnecting")

    if (roomState !== 'reconnecting') return null;

    return (
        <div
            className={classes.alert}
        >
            <Alert severity="error" title={lexique.title} content={lexique.body} md />
        </div>
    );
}

export default memo(ReconnectingNotification)