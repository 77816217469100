import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import FamilyCard from './FamilyCard';

import getLexique, { _lp } from '../../locales';

class UsersToConfirm extends PureComponent {

	render() {
		const { lexique, users } = this.props;

        const toConfirm = users.filter(({isRequestingAccess}) => isRequestingAccess);

		if (toConfirm.length === 0) return null;

		return (
			<div>
                <div className="section_title tal">
					<Typography variant="h4">{_lp(lexique.title)}</Typography>
				</div>
				<div className="users-container mt20">
                    { toConfirm.map(user => <FamilyCard key={user.id} {...user} />) }
				</div>
			</div>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).users.toConfirm,
	users: plannings[plannings.selected] && plannings[plannings.selected].users ? plannings[plannings.selected].users : [],
});

export default connect(mapStateToProps)(UsersToConfirm);
