import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _sortBy from 'lodash.sortby';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import AppointmentCard from './appointmentsToConfirm/AppointmentCard';

import getLexique from '../locales';

class AppointmentsToConfirm extends PureComponent {

	render() {
		const { lexique, loading, appointments, appointmentsTypes } = this.props;

		if (loading) return <LinearProgress color="secondary" />

		return (
			<div className="appointments-to-confirm pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.title}</Typography>
				</div>
				<div className="appointments-container mt20 flex ait">
					{ _sortBy(appointments, [o => moment(o.start).unix()]).map(app => <AppointmentCard key={app.id} {...app} type={appointmentsTypes.find(a => a.id === app.typeId)} />) }
				</div>
			</div>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).appointmentsToConfirm,
	appointments: plannings[plannings.selected] ? plannings[plannings.selected].appointmentsToConfirm : [],
	appointmentsTypes: plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes : [],
	loading: plannings[plannings.selected] ? plannings[plannings.selected].loading : true,
});

export default connect(mapStateToProps)(AppointmentsToConfirm);
