import moment from 'moment-timezone';

export const getEventStyle = datas => {
    return {
        top: `${getEventTop(datas)}px`,
    }
}

export const getEventTop = ({start, time, step, slotSize}) => {
    const ms = moment(start).hours()*60 + moment(start).minutes();
    const mt = moment(time).hours()*60 + moment(time).minutes();
    const diff = mt-ms;

    const index = diff/step;

    return index*getSlotHeight(slotSize)
}

export const getEventHeight = ({duration, slotSize, step}) => {
    return duration/step*getSlotHeight(slotSize);
}

export const getSlotHeight = slotSize => {
    switch (slotSize) {
        case "dense":
            return 16;
        default:
        case "normal":
            return 20;
        case "large":
            return 25;
        case "x-large":
            return 32;
    }
}

export const getEventWidth = ({id, start, created, conflicts, calendarColumnWidth}) => {
    //return `${90/(conflicts.length+1)}%`;
    if (conflicts.length === 0) return calendarColumnWidth*0.9;

    const sorted = [{id, start, created:{at:created}}, ...conflicts].sort((a,b) => {
        if (moment(a.start).isAfter(moment(b.start))) return 1;
        if (moment(a.start).isBefore(moment(b.start))) return -1;

        if (moment(a.created.at).isAfter(moment(b.created.at))) return 1;
        return -1;
    });

    const index = sorted.findIndex(a => a.id === id);

    return calendarColumnWidth*(0.9-(index/10));
}

export const getEventX = () => {
    return 0;
}

export const getEventZIndex = ({width, calendarColumnWidth}) => {
    const ratio = Math.ceil(width/(calendarColumnWidth*.9)*100);
    return 120-ratio;
}
