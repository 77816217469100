import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import RichTextEditor from 'react-rte';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { closeModaleEditDayInformationMessage } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';


class EditDayInformationMessage extends PureComponent {

	state = { editorValue: '', level: ''};

	onClose = () => {
		this.props.actions.closeModaleEditDayInformationMessage();
	}

	mapPropsToInnerState = () => {
		const { day } = this.props;
		if (!day) return;
		this.setState({
			editorValue: day.params && day.params.information && day.params.information.value ? RichTextEditor.createValueFromString(day.params.information.value, 'markdown') : RichTextEditor.createEmptyValue(),
			level: day.params && day.params.information ? day.params.information.level || 'information' : 'information',
		})
	}

	componentDidMount() {
		this.mapPropsToInnerState();
	}

	componentDidUpdate = oldProps => {
		if (!oldProps.day || (oldProps.day.start !== this.props.day.start) || (!oldProps.show && this.props.show)) this.mapPropsToInnerState();
	}

	onChange = value => this.setState({editorValue: value});

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm({
			value: this.state.editorValue.toString('markdown'),
			level: this.state.level,
		});
	}

	onRemove = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm({
			value: '',
			level: 'information',
		});
	}

	changeLevel = level => () => {
		this.setState({level});
	}

	renderInner() {
		const { lexique, config, day, isMobile } = this.props;

		if (!day) return <div />;

		const { editorValue, level } = this.state;

		const toolbarConfig = {
			// Optionally specify the groups to display (displayed in the order listed).
			display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
			INLINE_STYLE_BUTTONS: [
				{label: lexique.toolbar.bold, style: 'BOLD'},
				{label: lexique.toolbar.italic, style: 'ITALIC'},
				{label: lexique.toolbar.underline, style: 'UNDERLINE'}
			],
			BLOCK_TYPE_DROPDOWN: [
				{label: lexique.toolbar.normal, style: 'unstyled'},
				{label: lexique.toolbar.title1, style: 'header-one'},
				{label: lexique.toolbar.title2, style: 'header-two'},
				{label: lexique.toolbar.title3, style: 'header-three'}
			],
		};

		return (
			<Fragment>
				<DialogTitle id="modale-day-information-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Typography component="div">
						<Markdown>
							{_lp(lexique.body.replace('{0}', moment(day.day).format(config.dateFormatLong)))}
						</Markdown>
					</Typography>
					<div className="flex mt10 mb10">
						{
							['information', 'success', 'warning', 'error'].map(v => <Chip
								key={v}
						        label={isMobile ? lexique.messageShort : lexique.message}
						        onClick={this.changeLevel(v)}
						        className={`chip chip--${v}`}
						      />)
						}
					</div>
					<Message level={level} show component='div'>
						<RichTextEditor
							autoFocus
							className="rte"
							toolbarConfig={toolbarConfig}
							value={editorValue}
							onChange={this.onChange}
						/>
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onRemove} color="primary">
						{isMobile ? lexique.removeShort : lexique.remove}
					</Button>
					<Button onClick={this.onConfirm} color="primary" variant="contained" size="large" disableElevation>
						{lexique.confirm}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show, isMobile } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				fullScreen={isMobile}
				onClose={this.onClose}
				aria-labelledby="modale-day-information-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{device:{isMobile}, modales:{editDayInformationMessage:{show, onConfirm, day}}}}) => ({
	lexique: getLexique(env.locale).modales.editDayInformationMessage,
	config: getLexique(env.locale).config,
    show,
	onConfirm,
	day,
	isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleEditDayInformationMessage }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDayInformationMessage);
