const DEV = {
	api: 'http://localhost:5000/sprel-api-stg/us-central1/api',
	apiVersion: '5.0.0',
	GATracker: '',
	GMapKey: `AIzaSyDnhjqfgIDcxIz97HEf_dtiqA88oPI_aNE`,
	stripeKey: `pk_test_HQJKCj4ERB5APDqG1Jb2AjTL`,
	sentryDSN: ``,
	pusherClient: `77cfdb83f0f3cbf7419d`,
	proUrl: `http://localhost:3002`,
	firebase: {
	    apiKey: "AIzaSyBouJbxiCAqNxbtiK_HxsHUGdpTDyFF_d0",
	    authDomain: "sprel-api-stg.firebaseapp.com",
		storageBucket: "gs://sprel-api-stg.appspot.com",
	},
}

const STAGING = {
	api: 'https://us-central1-sprel-api-stg.cloudfunctions.net/api',
	apiVersion: '5.0.0',
	GATracker: 'UA-105574315-2',
	GMapKey: `AIzaSyDnhjqfgIDcxIz97HEf_dtiqA88oPI_aNE`,
	stripeKey: `pk_test_HQJKCj4ERB5APDqG1Jb2AjTL`,
	sentryDSN: `https://fbc69958c63941f882c420e43d8bf8fd@sentry.io/1189250`,
	pusherClient: `77cfdb83f0f3cbf7419d`,
	proUrl: `https://pro-staging.sprel.fr`,
	firebase: {
	    apiKey: "AIzaSyBouJbxiCAqNxbtiK_HxsHUGdpTDyFF_d0",
	    authDomain: "sprel-api-stg.firebaseapp.com",
		storageBucket: "gs://sprel-api-stg.appspot.com",
	},
}

/* Firebase is a prod environement without ovh domain names */
const FIREBASE = {
	api: 'https://us-central1-sprel-api-c7a9b.cloudfunctions.net/api',
	apiVersion: '5.0.0',
	GATracker: 'UA-39680703-5',
	GMapKey: `AIzaSyDnhjqfgIDcxIz97HEf_dtiqA88oPI_aNE`,
	stripeKey: `pk_live_xiq47ZqPtlEfc0hQmoDyS20s`,
	sentryDSN: `https://33af8f4c5bcc47998c3014fddc824c29@sentry.io/1189251`,
	pusherClient: `a80709ba2d9de3a103f6`,
	proUrl: `https://sprel-pro.firebaseapp.com`,
	firebase: {
	    apiKey: "AIzaSyBb02poSs7PoEPAAHTJ_8Gdc4fmNEqDKRY",
	    authDomain: "sprel-api-c7a9b.firebaseapp.com",
		storageBucket: "gs://sprel-api-c7a9b.appspot.com",
	},
}

const PROD = {
	api: 'https://us-central1-sprel-api-c7a9b.cloudfunctions.net/api',
	apiVersion: '5.0.0',
	GATracker: 'UA-39680703-5',
	GMapKey: `AIzaSyDnhjqfgIDcxIz97HEf_dtiqA88oPI_aNE`,
	stripeKey: `pk_live_xiq47ZqPtlEfc0hQmoDyS20s`,
	sentryDSN: `https://33af8f4c5bcc47998c3014fddc824c29@sentry.io/1189251`,
	pusherClient: `a80709ba2d9de3a103f6`,
	proUrl: `https://sprel.pro`,
	firebase: {
	    apiKey: "AIzaSyBb02poSs7PoEPAAHTJ_8Gdc4fmNEqDKRY",
	    authDomain: "sprel-api-c7a9b.firebaseapp.com",
		storageBucket: "gs://sprel-api-c7a9b.appspot.com",
	},
}

const getEnv = () => {
	const location = window.location.hostname;

	if (location.indexOf('localhost') > -1) return ENV_DEV;

	if (location.indexOf('staging') > -1 || location.indexOf('demo.') > -1) return ENV_STAGING;

	if (location.indexOf('firebaseapp.com') > -1) return ENV_FIREBASE;

	return ENV_PROD;
}

const getConfig = () => {
	switch (getEnv()) {
		case ENV_DEV:
			return DEV;
		case ENV_STAGING:
			return STAGING;
		case ENV_FIREBASE:
			return FIREBASE;
		default:
			return PROD;
	}
}

export const ENV_DEV = "env---dev";
export const ENV_STAGING = "env---staging";
export const ENV_PROD = "env---prod";
export const ENV_FIREBASE = "env---firebase";

export const DEV_DEBUG = false;

export const env = getEnv();

export const config = getConfig();

export const COOKIES = {
	email: "COOKIE_SprelProApp_email",
};


export const RELEASE = '2021-02-1'