import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';

import Email from '@material-ui/icons/Email';
import Archive from '@material-ui/icons/Archive';
import Delete from '@material-ui/icons/Delete';

import { changeMessageFolder } from '../../actions/messages';

import getLexique from '../../locales';

const Folders = memo(({isMobile, lexique, active, actions}) => {

	const changeActiveTab = tab => () => {
		actions.changeMessageFolder(tab);
	}

	return (
	<div className="messages-sections mt20 mb20">
		<Button color={active === 'emails' ? "primary" : "inherit"} onClick={changeActiveTab('emails')}>
			<Email />
			{!isMobile && <span className="ml5">{lexique.tabs.emails}</span>}
		</Button>
		<Button color={active === 'archived' ? "primary" : "inherit"} onClick={changeActiveTab('archived')}>
			<Archive />
			{!isMobile && <span className="ml5">{lexique.tabs.archived}</span>}
		</Button>
		<Button color={active === 'deleted' ? "primary" : "inherit"} onClick={changeActiveTab('deleted')}>
			<Delete />
			{!isMobile && <span className="ml5">{lexique.tabs.deleted}</span>}
		</Button>
	</div>
	);
});

const mapStateToProps = ({env, ui, plannings}) => ({
	lexique: getLexique(env.locale).inbox,
	isMobile: ui.device.isMobile,
	active: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.active : ''
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ changeMessageFolder }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
