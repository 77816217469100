import _random from 'lodash.random';
import randomstring from 'randomstring';

export const randBool = () => _random(0,1) === 0;
export const randFromArray = arr => arr[_random(0, arr.length-1)];
export const randString = v => randomstring.generate(v || 10);
export const rand = (min, max) => _random(min, max);
export const randTime = (min, max) => {
    const h = rand(min || 0, max || 23);
    const m = rand(0,59);
    return `${h<10?`0${h}`:h}:${m<10?`0${m}`:m}`;
}
