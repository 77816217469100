import React from 'react';

import { ReactComponent as File } from '../assets/svg/file-light.svg';
import { ReactComponent as FileWritten } from '../assets/svg/file-alt-light.svg';

export const templates = [
    {
        id: "empty",
        content: '',
        icon: <File />
    },
    {
        id: "global",
        content: `## Antécédents médicaux et chirurgicaux
-


## Examens complémentaires
-
`,
        icon: <FileWritten />
    },
    {
        id: 'appointment',
        content: `## Motif de consultation
-


## Bilan
-


## Traitement
-


## Conseils
-
`,
        icon: <FileWritten />
    }
];

export const MAX_FILE_SIZE = 5*1024*1024;
export const FREE_DRIVE_SPACE = 2*1024*1024*1024;
