import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

import Folders from './inbox/Folders';
import MessagesList from './inbox/MessagesList';
import MessageDetails from './inbox/MessageDetails';

import getLexique from '../locales';
import { resetDisplayedMessage, loadMessages } from '../actions/messages';

class Inbox extends PureComponent {

	componentDidMount() {
		this.checkLoadMessages();
	}

	componentDidUpdate = oldProps => {
		if (oldProps.selectedPlanning !== this.props.selectedPlanning) this.checkLoadMessages();
	}

	checkLoadMessages = () => {
		if (this.props.messagesNotLoaded) this.props.actions.loadMessages();
	}

	componentWillUnmount() {
		this.props.actions.resetDisplayedMessage();
	}

	render() {
		const { loading, lexique, messagesNotLoaded } = this.props;

		if (loading || messagesNotLoaded) return <LinearProgress color="secondary" />

		return (
			<div className="inbox pad20">
				<div className="section_title tal">
					<Typography variant="h4">{lexique.title}</Typography>
				</div>

				<Folders />
				<Divider />
				<div className="inbox-container flex">
					<MessagesList />
					<MessageDetails />
				</div>

			</div>
		)
	}
}


const mapStateToProps = ({env, ui, plannings}) => ({
	lexique: getLexique(env.locale).inbox,
	isMobile: ui.device.isMobile,
	selectedPlanning: plannings.selected,
	loading: plannings[plannings.selected] ? plannings[plannings.selected].loading : true,
	messagesNotLoaded: !plannings[plannings.selected] || !plannings[plannings.selected].inbox || !plannings[plannings.selected].inbox.messages
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ resetDisplayedMessage, loadMessages }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
