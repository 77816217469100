import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _sortBy from "lodash.sortby";

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Schedule from '@material-ui/icons/Schedule';
import Assignment from '@material-ui/icons/Assignment';
import Note from '@material-ui/icons/Note';
import ArrowBack from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Today from '@material-ui/icons/Today';
import Phone from '@material-ui/icons/Phone';
import Person from '@material-ui/icons/Person';
import VisioIcon from '@material-ui/icons/VideocamOutlined';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import ButtonCircularProgress from '../ui/ButtonCircularProgress';
import NameInput from '../ui/NameInput';

import { closeAppointment, updateFieldAppointment, createAppointment, checkConflicts, updateAppointment } from '../../actions/appointment';
import { unblockSlotByRule } from '../../actions/planning';
import { openModaleConflicts, openModaleError } from '../../actions/ui';

import getLexique, { _lp } from '../../locales';
import { userName } from '../../libs/formatters';
import { getSlotMatchingBlockingRule } from '../../libs/checkers';
import { getAFIcon } from '../../config/theme';

class Appointment extends PureComponent {

	state = {
		loading: false,
		errors: [],
		withUser: null,
		withLastname: '',
		start: '',
		phoneNumber: '',
		af1: '',
		af2: '',
		af3: '',
		appointmentType: '',
		duration: '',
		notes: '',
	}

	componentDidUpdate(oldProps) {
		const { show: oShow } = oldProps.appointment;
		const { show } = this.props.appointment;

		if (show && !oShow) {
			this.mapPropsToState()
		}
	}

	mapPropsToState = () => {
		const { event } = this.props.appointment
		this.setState({
			withUser: event.with.id && event.with.id !== '' ? event.with : null,
			withLastname: event.with.lastName,
			start: moment(event.start),
			phoneNumber: event.with.phoneNumber || '',
			af1: event.additionalField0 || '',
			af2: event.additionalField1 || '',
			af3: event.additionalField2 || '',
			appointmentType: event.typeId,
			duration: event.duration,
			notes: event.information.admin,
		});
	}

	onClose = () => {
		this.setState({loading: false, errors:[]});
		this.props.actions.closeAppointment();
	}

	blockSlots = async () => {
		this.confirmBlockSlots();
	}

	unblockSlot = async () => {
		const  { start } = this.state;
		this.props.actions.unblockSlotByRule(start);
		this.onClose();
	}

	confirmBlockSlots = async () => {
		await this.setState({appointmentType: "blocked"});
		this.confirmCreateAppointment();
	}

	buildEventFromState = () => {
		const { selectedPlanningId, appointment:{mode, event:ref}} = this.props;
		const  { withUser, withLastname, start, phoneNumber, af1, af2, af3, appointmentType, duration, notes } = this.state;
		return {
			...ref,
			practitionerId: selectedPlanningId,
			day: moment(start).format('YYYY-MM-DD'),
			id: mode === "new" ? "" : ref.id,
			start,
			duration,
			additionalField0: af1 || null,
			additionalField1: af2 || null,
			additionalField2: af3 || null,
			information: {
				admin: notes,
			},
			typeId: appointmentType,
			with: withUser || {
				phoneNumber,
				lastName: withLastname
			},
		};
	}

	onConfirm = async () => {
		const { actions:{checkConflicts, openModaleConflicts}, appointment:{mode, event:ref}, selectedPlanning, lexique} = this.props;
		const  { withUser, withLastname, start, appointmentType, duration } = this.state;

		let errors = [];
		// Checking errors
		if (!withUser && withLastname === '') errors.push('name');
		if (!duration || duration === '' || duration === 0) errors.push('duration');
		if (!appointmentType || appointmentType === '') errors.push('typeId');
		if (!start || start === '') errors.push('start');
		if (moment(start).isBefore(moment())) errors.push('start');
		
		const appointmentsTypes  = selectedPlanning.appointmentsTypes;
		const app = appointmentsTypes.find(a => a.id === appointmentType);
		if (app?.isVisio && !withUser) {
			errors.push('typeId');
			errors.push('name');
			window.alert(_lp(lexique.errorVisio));
		}

		if (errors.length > 0) return this.setState({errors});

		const event = this.buildEventFromState();

		this.setState({loading: true, conflicts:[]});
		if (mode === "new") {
			// Create a new appointment
			const conflicts = await checkConflicts({event, to: {
				start: event.start,
				end: moment(event.start).add(event.duration, 'minutes').format(),
			}});


			if (conflicts.length > 0) {
				this.setState({loading: false});
				openModaleConflicts({
					conflicts,
					onConfirm: this.confirmCreateAppointmentWithConflicts(conflicts),
					event,
				});
				return;
			}

			this.confirmCreateAppointment();
		}
		else {
			// UPDATE existing
			if (ref.start !== event.start || ref.duration !== event.duration) {
				//Date or duration has changed, so we need to check conflicts
				const to = {
					start: event.start,
					end: moment(event.start).add(event.duration, 'minutes').format(),
				};
				const conflicts = await checkConflicts({event, to});

				if (conflicts.length > 0) {
					this.setState({loading: false});
					openModaleConflicts({
						conflicts,
						onConfirm: this.confirmUpdateAppointmentWithConflicts(conflicts),
						event,
					});
					return;
				}
			}
			this.confirmUpdateAppointment();
		}
	}

	confirmUpdateAppointmentWithConflicts = conflicts => () => {
		this.confirmUpdateAppointment(conflicts);
	}

	confirmUpdateAppointment = async (conflicts=[]) => {
		const { actions:{updateAppointment, openModaleError}, appointment:{event:ref}} = this.props;

		const event = this.buildEventFromState();

		this.setState({loading: true});

		const res = await updateAppointment({
			practitionerId: event.practitionerId,
			conflicts,
			from: { ...ref },
			to: { ...event }
		});

		if (res.error) {
			// An error occured;
			this.setState({loading: false});
			openModaleError(`update/${res.code}`);
			return;
		}

		this.setState({loading: false});
		this.onClose();
	}

	confirmCreateAppointmentWithConflicts = conflicts => () => {
		this.confirmCreateAppointment(conflicts);
	}
	confirmCreateAppointment = async (conflicts=[]) => {
		const { actions:{createAppointment, openModaleError}} = this.props;
		this.setState({loading: true});

		const event = this.buildEventFromState();
		// All good > add it baby !
		const res = await createAppointment({
			conflicts,
			...event,
		});
		if (res.error) {
			// An error occured;
			this.setState({loading: false});
			openModaleError(`create/${res.code}`);
			return;
		}

		this.setState({loading: false});
		this.onClose();
	}

	onChangeInput = field => ({target:{value}}) => this.setState({[field]: value});
	onChangeName = ({target:{value}}) => this.setState({withLastname: value, withUser: null});

	onChangeStart = start => this.setState({start});
	onChangeType = ({target:{value}}) => {
		const { selectedPlanning } = this.props;
		const {appointmentType, duration } = this.state;
		const appointmentsTypes = selectedPlanning.appointmentsTypes;
		const currentAppointment = appointmentsTypes.find(a => a.id === appointmentType);
		const nextAppointment = appointmentsTypes.find(a => a.id === value);

		let newDuration = duration;
		// Current apointment exists
		if (currentAppointment) {
			if (duration === currentAppointment.duration) newDuration = nextAppointment.duration;
		}
		else {
			// First time we choose an appointment type, we check if current duration is step or not
			if (duration === selectedPlanning.configuration.step) newDuration = nextAppointment.duration;
		}

		this.setState({appointmentType: value, duration: newDuration});
	}

	onPickerUser = user => {
		this.setState({withUser: user, phoneNumber: user.phoneNumber});
	}

	renderAdditionFields = () => {
		const { appointmentsAdditionalFields } = this.props;
		const { loading } = this.state;

		if (!appointmentsAdditionalFields || appointmentsAdditionalFields.length === 0) return null;

		return (
			<div className="additional-fields">
				{ appointmentsAdditionalFields.map((aaf, i) => {
					const field = this.state[`af${(i+1)}`];
					if (!aaf || aaf.label === '') return null;
					const Icon = getAFIcon(aaf.icon);
					return (
						<div className="flex aic row" key={aaf.label}>
							<div className="mr20">
								<Icon color="primary" />
							</div>
							<TextField
								id={`appointment-additionalField${i}`}
								label={aaf.label}
								variant="outlined"
								value={field}
								onChange={this.onChangeInput(`af${(i+1)}`)}
								fullWidth
								disabled={loading}
							/>
						</div>
					)
				}) }
			</div>
		)
	}

	renderInnerModale = () => {
		const { appointment: {mode}, isMobile, lexique, selectedPlanning, locale, config, useAnimals } = this.props;

		if (!selectedPlanning) return <div />;

		const configPlanning = selectedPlanning.configuration;
		const appointmentsTypes  = selectedPlanning.appointmentsTypes;

		if (!configPlanning || !appointmentsTypes) return  <div />;

		const { loading, errors, phoneNumber, notes, duration, appointmentType, withLastname, withUser, start } = this.state;
		const displayName = withUser !== null ? userName(withUser) : withLastname;

		const durationSteps = moment().startOf('day').add(configPlanning.step, 'minutes');


		const matchingRule = getSlotMatchingBlockingRule({
			start,
			day: selectedPlanning.days[moment(start).format('YYYY-MM-DD')],
			rules: configPlanning.rules,
		});
		const openRule = matchingRule && matchingRule.type === "manualEmergency";

		return (
			<>
				<DialogTitle id="appointment-dialog-title">
					<div className="flex aic">
						{isMobile && <IconButton onClick={this.onClose} className="mr10">
							<ArrowBack />
						</IconButton>}
						<div>{lexique.title[mode]}</div>
					</div>
				</DialogTitle>
				<DialogContent className="appointment-dialog-form">
					{ /* NAME */}
					<div className="flex aic row">
						<div className="mr20">
							<Person color="primary" />
						</div>
						<NameInput
							mode={mode}
							isError={errors.indexOf('name') > -1}
							usersList={selectedPlanning.usersList || []}
							value={displayName}
							disabled={loading || (withUser !== null && mode === "edit")}
							onChange={this.onChangeName}
							onPickUser={this.onPickerUser}
							isUserPicked={withUser !== null}
							pickedUser={withUser}
							useAnimals={useAnimals}
						/>
					</div>
					{/* Additional fields */}
					{this.renderAdditionFields()}
					{/*PHONE*/}
					<div className="flex aic row">
						<div className="mr20">
							<Phone color="primary" />
						</div>
						<div className="flex1">
							<TextField
								id="appointment-phone"
								label={lexique.fields.phoneNumber}
								value={phoneNumber}
								onChange={this.onChangeInput('phoneNumber')}
								fullWidth
								variant="outlined"
								disabled={loading || withUser !== null}
							/>
						</div>
					</div>
					{/*DATETIME*/}
					<div className="flex aic row">
						<div className="mr20">
							<Today color="primary" />
						</div>
						<div className="flex1">
							<MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale={locale}>
								<DateTimePicker
									id="appointment-datetime"
									required
									error={errors.indexOf('start') > -1}
									label={lexique.fields.schedule}
									fullWidth
									inputVariant="outlined"
									disabled={loading}
									ampm={false}
									disablePast
									todayLabel={lexique.today}
									showTodayButton
									format={config.datetimeFormatLong}
									value={start}
									onChange={this.onChangeStart}
									cancelLabel={lexique.cancel}
									minutesStep={5}
									timeIcon={<Schedule />}
									dateRangeIcon={<Today />}
									leftArrowIcon={<KeyboardArrowLeft />}
									rightArrowIcon={<KeyboardArrowRight />}
									shouldDisableDate={date => {
										const dayOfWeek = date.weekday();
										if (dayOfWeek >= 5 && !configPlanning.showWeekEnd) return true;
										if (dayOfWeek === 6 && !configPlanning.showSunday) return true;

										return false;
									}}
								/>
							</MuiPickersUtilsProvider>
						</div>
					</div>
					{/*CONSULTATION TYPE*/}
					<div className="flex aic row">
						<div className="mr20">
							<Assignment color="primary" />
						</div>
						<div className="flex1">
							<FormControl required variant="outlined" fullWidth>
								<InputLabel required htmlFor="appointment-type" error={errors.indexOf('typeId') > -1}>
									{lexique.fields.appointmentType}
								</InputLabel>
								<Select
									error={errors.indexOf('typeId') > -1}
									required
									id="appointment-type"
									value={appointmentType}
									onChange={this.onChangeType}
									fullWidth
									disabled={loading}
									className="select_appointment"
									label={lexique.fields.appointmentType}
								>
									{
										_sortBy(appointmentsTypes, ['label']).map(app => (
											<MenuItem value={app.id} key={app.id} className='appointment_type'>
												<span className="appointment_type-color" style={{backgroundColor: app.color}} />
												<span className="appointment_type-label">{app.label}</span>
												{app?.isVisio && <span className="appointment_type-ico"><VisioIcon /></span>}
											</MenuItem>
										))
									}
								</Select>
							</FormControl>
						</div>
					</div>
					{/*DURATION*/}
					<div className="flex aic row">
						<div className="mr20">
							<Schedule color="primary" />
						</div>
						<div className="flex1">
							<FormControl required variant="outlined" fullWidth>
								<InputLabel required htmlFor="appointment-duration" error={errors.indexOf('duration') > -1}>
									{lexique.fields.duration}
								</InputLabel>
								<Select
									error={errors.indexOf('duration') > -1}
									required
									id="appointment-duration"
									value={duration}
									onChange={this.onChangeInput('duration')}
									fullWidth
									disabled={loading}
									input={
										<OutlinedInput
											labelWidth={160}
										/>
									}
								>
									{
										Array(32).fill().map((v, i) => {
											const dur = moment(durationSteps).add(i*configPlanning.step, 'minutes');
											return (
												<MenuItem key={dur.format()} value={(i+1)*configPlanning.step}>
													{ dur.format('H:mm') }
												</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</div>
					</div>
					{/*ADMIN NOTE*/}
					<div className="flex aic row">
						<div className="mr20">
							<Note color="primary" />
						</div>
						<div className="flex1">
							<TextField
								id="appointment-note-admin"
								label={lexique.fields.information}
								value={notes}
								onChange={this.onChangeInput('notes')}
								fullWidth
								multiline
								rows={4}
								disabled={loading}
								variant="outlined"
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					{mode === "new" && !openRule && <div className="flex1">
						<Button disabled={loading} onClick={this.blockSlots} color="secondary" variant="outlined">
							{lexique.block}
						</Button>
					</div>}
					{mode === "new" && openRule && <div className="flex1">
						<Button disabled={loading} onClick={this.unblockSlot} color="secondary">
							{lexique.unblock}
						</Button>
					</div>}
					<Button disabled={loading} onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button disabled={loading} onClick={this.onConfirm} color="primary" variant="contained" disableElevation size="large">
						{lexique.confirm[mode]}
						{loading && <ButtonCircularProgress />}
					</Button>
				</DialogActions>
			</>
		)
	}

    render() {
		const { isMobile, appointment } = this.props;
		const { show } = appointment;
		const { loading } = this.state;
    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullScreen={isMobile}
				fullWidth
				disableBackdropClick={loading}
				disableEscapeKeyDown={loading}
				onClose={this.onClose}
				aria-labelledby="appointment-dialog-title"
			>
				{ this.renderInnerModale() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, appointment, ui, plannings}) => ({
	lexique: getLexique(env.locale).appointment,
    config: getLexique(env.locale).config,
	locale: env.locale,
	appointment,
	isMobile: ui.device.isMobile,
	selectedPlanningId: plannings.selected || null,
	selectedPlanning: plannings && plannings[plannings.selected] ? plannings[plannings.selected] : null,
	useAnimals: plannings[plannings.selected] && plannings[plannings.selected].env ? plannings[plannings.selected].env.useAnimals || false : false,
	appointmentsAdditionalFields: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.appointmentsAdditionalFields : [],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeAppointment, updateFieldAppointment, createAppointment, checkConflicts, openModaleConflicts, openModaleError, updateAppointment, unblockSlotByRule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
