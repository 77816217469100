import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Popover from '@material-ui/core/Popover';
import Drawer from '@material-ui/core/Drawer';

import EventQuickView from './EventQuickView';
import DuplicationPanel from './DuplicationPanel';
import { closeAppointmentQuickView, closeDuplicationDrawer } from '../../actions/ui';

class UtilityViews extends PureComponent {

	closeEventPopover = () => {
		this.props.actions.closeAppointmentQuickView();
	}

	closeEventDrawerDuplicate = () => {
		this.props.actions.closeDuplicationDrawer();
	}

	renderMobile() {
		const { event, show } = this.props;
		return (
			<Drawer
				anchor="bottom"
				open={show}
				onClose={this.closeEventPopover}
			>
				<EventQuickView event={event} onClose={this.closeEventPopover} />
			</Drawer>
		);
	}

	renderDesktop() {
		const { event, show, appointmentId, actions } = this.props;
		const dom = document.querySelector(`.event-${appointmentId}`);
		
		if (show && (!dom || !event)) {
			actions.closeAppointmentQuickView();
			return null;
		}

		return (
			<Popover
				open={show}
				className="event_popover"
				anchorEl={dom}
				onClose={this.closeEventPopover}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<EventQuickView event={event} onClose={this.closeEventPopover} />
			</Popover>
		);

	}

	renderDrawerDuplicate = () => {
		const { duplicateMode: {showDrawer, loading} } = this.props;

		return (
			<Drawer
				anchor="right"
				className="drawer-duplicate"
				open={showDrawer}
				onClose={this.closeEventDrawerDuplicate}
				ModalProps={{
					disableBackdropClick: loading,
					disableEscapeKeyDown: loading,
				}}

			>
				<DuplicationPanel />
			</Drawer>
		);
	}

	render() {
		const { isMobile } = this.props;
		return (
			<Fragment>
				{isMobile ? this.renderMobile() : this.renderDesktop()}
				{ this.renderDrawerDuplicate() }
			</Fragment>
		);
	}
}

const getEvent = ({plannings, planningId, day, appointmentId}) => {
	if (!plannings) return null;
	if (!plannings[planningId]) return null;
	if (!plannings[planningId].days) return null;
	if (!plannings[planningId].days[day]) return null;
	if (!plannings[planningId].days[day].appointments) return null;
	return plannings[planningId].days[day].appointments.find(a => a.id === appointmentId);
}

const mapStateToProps = ({plannings, ui:{device:{isMobile}, quickView, duplicateMode}}) => ({
	appointmentId: quickView.appointmentId,
	show: quickView.show,
	event: getEvent({
		plannings,
		planningId: quickView.planningId,
		day: quickView.day,
		appointmentId: quickView.appointmentId,
	}),
	duplicateMode,
	isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeAppointmentQuickView, closeDuplicationDrawer }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UtilityViews);
