import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import getLexique from '../../locales';
import { doLogout } from '../../actions/user';

import ModalTransitionZoom from './ModalTransitionZoom';

const JsError = ({lexique, actions:{doLogout}}) => (
	<Dialog
		open={true}
		onClose={null}
		disableBackdropClick
		disableEscapeKeyDown
		TransitionComponent={ModalTransitionZoom}
	>
		<DialogTitle>
			{lexique.title}
		</DialogTitle>
		<DialogContent>
			<Typography component="p" paragraph>
				{lexique.body}
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => doLogout()} color="primary">
				{lexique.close}
			</Button>
		</DialogActions>
	</Dialog>
);

const mapStateToProps = ({env}) => ({
	lexique: getLexique(env.locale).global.notAuthorized,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ doLogout }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(JsError)
