import * as Sentry from "@sentry/react";
import { config, RELEASE } from '../config/env';
import { getStore } from '../config/store';

let isSentryEnabled = false;

export const initialize = () => {
	if (config.sentryDSN !== '') {
	    Sentry.init({
			dsn: config.sentryDSN,
			autoSessionTracking: true,
			release: RELEASE,
		}).install();
	    isSentryEnabled = true;
	}
}

export default (error, errorInfo) => {
    const extra = {
        info: errorInfo,
        store: getStore()
    };

    if (isSentryEnabled) Sentry.captureException(error, { extra });
    else {
		//eslint-disable-next-line
        console.log("[Sentry] captureException", error, extra);
    }
}


export const setUser = user => {
	if (isSentryEnabled) {
		Sentry.configureScope(scope => {
			scope.setUser(user);
		});
	}
	else {
		//eslint-disable-next-line
		console.log('[Sentry] SetUserContext', user);
	}
}

export const unsetUser = () => {
	if (isSentryEnabled) {
		Sentry.configureScope(scope => {
			scope.setUser(null);
			scope.clear();
		});
	}
	else {
		//eslint-disable-next-line
		console.log('[Sentry] resetUserContext');
	}
}