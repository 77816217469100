import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import { loadPlanningDay } from '../../actions/planning';
import getLexique from '../../locales';
import routes from '../../config/routes';

class TodayAppointments extends PureComponent {

	componentDidMount() {
		this.loadToday();
	}

    componentDidUpdate = oldProps => {
		if (oldProps.planningId !== this.props.planningId && this.props.planningId) this.loadToday();
    }

    loadToday = () => {
		const { planningId, actions:{loadPlanningDay}, today } = this.props;

        loadPlanningDay({
            planningId: planningId,
            day: today,
        });
    }

    getTodayAppointmentsCount = () => {
        const { planning, today } = this.props;

        if (!planning) return null;

        const day = planning.days[today];
        if (!day) return null;

		const filtered = day.appointments ? day.appointments.filter(a => a.typeId !== "blocked") : [];

        return filtered.length;
    }

	render() {
		const { lexique, planning, today } = this.props;

        const loading = !planning || !planning.days[today] || !planning.days[today].appointments;

		return (
			<Paper className="dashboard_widget pad20 tac">
                <Typography variant="subtitle1" className="widget_title">{lexique.title}</Typography>
                {loading && <CircularProgress />}
                <Typography variant="h1" color="primary" className="widget_number">{this.getTodayAppointmentsCount()}</Typography>
                <Button component={Link} to={routes.planning} color="primary" className="mt10">{lexique.cta}</Button>
			</Paper>
		);
	}
}


const mapStateToProps = ({env, ui, plannings}) => ({
	lexique: getLexique(env.locale).dashboard.todayAppointments,
	isMobile: ui.device.isMobile,
    planning: plannings[plannings.selected],
    planningId: plannings.selected,
    today: moment().format('YYYY-MM-DD')
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ loadPlanningDay }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TodayAppointments);
