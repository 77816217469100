import React, { memo } from 'react';

import Appointment from './modales/Appointment';
import Conflicts from './modales/Conflicts';
import ConfirmationChangeEvent from './modales/ConfirmationChangeEvent';
import Error from './modales/Error';
import ConfirmDeleteAppointment from './modales/ConfirmDeleteAppointment';
import ConfirmAppointment from './modales/ConfirmAppointment';
import ConfirmDiscardDuplicates from './modales/ConfirmDiscardDuplicates';
import ConfirmToggleCloseDay from './modales/ConfirmToggleCloseDay';
import EditDayInformationMessage from './modales/EditDayInformationMessage';
import EditDayNotes from './modales/EditDayNotes';
import EditDaySpecificSchedules from './modales/EditDaySpecificSchedules';
import EditDaySpecificSchedulesConfirmDiscard from './modales/EditDaySpecificSchedulesConfirmDiscard';
import PickSchedule from './modales/PickSchedule';
import ConfirmDeleteAppointmentType from './modales/ConfirmDeleteAppointmentType';
import AddEditAppointmentType from './modales/AddEditAppointmentType';
import EditAppointmentTypeProfile from './modales/EditAppointmentTypeProfile';
import EditAppointmentTypeSpecificSchedule from './modales/EditAppointmentTypeSpecificSchedule';
import ConfirmAcceptUser from './modales/ConfirmAcceptUser';
import ConfirmBlockUser from './modales/ConfirmBlockUser';
import UserDetails from './modales/UserDetails';
import DriveContainer from './modales/DriveContainer';
import DriveFileEditor from './modales/DriveFileEditor';
import SendUserEmail from './modales/SendUserEmail';
import AddNewUser from './modales/AddNewUser';
import DebugModale from './ui/DebugModale';
import Visio from './visio/Visio';
import VisioSendDelay from './visio/SendDelay';
import CreatePayment from './payments/CreatePayment';

import AdminAddPractitioner from './modales/admin/AddPractitioner';

export default memo(() => (
	<>
		<Appointment />
		<Conflicts />
		<ConfirmationChangeEvent />
		<Error />
		<ConfirmDeleteAppointment />
		<ConfirmAppointment />
		<ConfirmDiscardDuplicates />
		<ConfirmToggleCloseDay />
		<EditDayInformationMessage />
		<EditDayNotes />
		<EditDaySpecificSchedules />
		<EditDaySpecificSchedulesConfirmDiscard />
		<PickSchedule />
		<ConfirmDeleteAppointmentType />
		<AddEditAppointmentType />
		<EditAppointmentTypeProfile />
		<EditAppointmentTypeSpecificSchedule />

		<ConfirmAcceptUser />
		<ConfirmBlockUser />
		<UserDetails />

		<DriveContainer />
		<DriveFileEditor />

		<SendUserEmail />
		<AddNewUser />

		<AdminAddPractitioner />

		<Visio />
		<VisioSendDelay />

		<CreatePayment />

		<DebugModale />
	</>
));
