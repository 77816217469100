export default {
	title: `Paramètres du planning`,
	tabs: {
		appointmentsTypes: `Types de rendez-vous`,
		planning: `Votre planning`,
		profile: `Votre profil`,
		appointment: `Prise de rendez-vous`,
		notifications: `Vos notifications`,
	},
	appointmentsTypes: {
		add: `Ajouter un type de rendez-vous`,
		addShechedule: `Ajouter une période`,
	},
	appointmentType: {
		allowedByUser: `Réservable par vos {p}s`,
		notAllowedByUser: `Non réservable par vos {p}s`,
		scheduleClosed: `Fermé`,
		period: `Période du {0} au {1}`,
		periodShort: `Du {0} au {1}`,
		delete: `Supprimer`,
		edit: `Modifier`,
		specificSchedules: `Plages d'horaires spécifiques`,
		specificProfile: `Profil spécifique`,
		noProfile: `Aucun profil spécifique défini. Les mails pour ce type de rendez-vous utiliseront votre profil par défaut. Si vous souhaitez définir un profil (adresse, téléphone) spécifique pour ce type de rendez vous, vous pouvez`,
		addProfile: `Ajouter un profil`,
		editProfile: `Modifier le profil`,
		removeProfile: `Supprimer le profil`,
		noSpecificSchedule: `Aucune plage d'horaires défini. Vous pouvez définir des plages d'horaire spécifique pour une période donnée. Ces créneaux d'ouverture s'appliqueront automatiquement pour la période.`,
		addSpecificSchedule: `Ajouter une plage`,
		editSpecificSchedule: `Modifier la plage`,
		deleteSpecificSchedule: `Supprimer`,
	},
	planning: {
		labels: {
			start: `Début affichage de votre planning`,
			end: `Fin affichage de votre planning`,
			step: `Afficher un créneau toutes les X minutes`,
			showWeekEnd: `Afficher les week-ends`,
			showSunday: `Afficher le dimanche`,
			rollingDays: `Planning sous forme de jours glissants`,
			highlightBookable: `Mettre en avant les créneaux reservables`,
			highlightNonBookable: `Mettre en avant les créneaux non réservables`,
			highlightBlockedByRule: `Mettre en avant les créneaux bloqués par une règle`,
			highContrastMobile: `Contraste élevé sur mobile et tablette`,
			highContrastDesktop: `Contraste élevé sur ordinateur`,
			displayEventTooltip: `Afficher l'infobulle sur les rendez-vous`,
			autoConfirmEventChange: `Confirmer automatiquement les changements de date des rendez-vous`,
		},
		help: {
			rollingDays: `Si vous activez cette option, le jour en cours sera toujours affiché en premier, à gauche du planning`,
			highlightBookable: `Si vous activez cette option, les créneaux étant réservable par au moins un type de rendez-vous seront affichés en vert`,
			highlightNonBookable: `Si vous activez cette option, les créneaux n'étant réservable par aucun type de rendez-vous seront affichés en rouge`,
			highlightBlockedByRule: `Si vous activez cette option, les créneaux étant bloqués par des règles de gestion (créneaux d'urgence,...) seront affichés en orange sur votre planning`,
			highContrastMobile: `Activez cette option si vous avez des difficultés à voir les couleurs claires / pastel sur mobile`,
			highContrastDesktop: `Activez cette option si vous avez des difficultés à voir les couleurs claires / pastel sur votre ordinateur`,
			displayEventTooltip: `Activez cette option pour afficher une infobulle sur les rendez-vous, au survol de la souris, avec les détails du rendez-vous`,
			autoConfirmEventChange: `Activez cette option si vous souhaitez confirmer automatiquement le changement de date d'un rendez-vous que vous avez déplacé avec un glissé/déposé`
		},
		save: `Enregistrer`
	},
	notifications: {
		labels: {
			notificationsEmail: `Adresse pour vos notifications email`,
			notificationEmailOnAdd: `Quand un {p} prend rendez-vous`,
			notificationEmailOnCancel: `Quand un {p} annule un rendez-vous`,
		},
		save: `Enregistrer`
	},
	appointments: {
		labels: {
			acceptOnlyCertified: `N'accepter que les {p}s avec une adresse email confirmée`,
			shouldConfirmAppointment: `Vous devez confirmer les rendez-vous`,
			shouldAcceptUser: `Vos {p}s doivent demander un accès avant de pouvoir prendre rendez-vous`,
			preventBookingBefore: `Empecher vos {p}s de prendre rendez-vous moins de X minute(s) avant l'heure du rendez-vous`,
			allowCancelUntil: `Vos {p}s peuvent annuler leur rendez-vous jusqu'à X minute(s) avant le rendez-vous`,
			bookableForNext: `Vos {p}s peuvent prendre rendez-vous pour les X prochains jours`,
			sendReminderAt: `Vos {p}s recevront un rappel par email X heure(s) avant leur rendez-vous`,
			doNotAcceptNewUser: `Empêcher tous nouveaux {p} de prendre rendez-vous`,
			publicHoliday: {
				"jourDeLAn": `Fermer pour le jour de l'an`,
				"feteDuTravail": `Fermer pour la fête du travail`,
				"victoireDesAllies": `Fermer pour la Victoire des Alliés`,
				"feteNationale": `Fermer pour la Fête Nationale`,
				"assomption": `Fermer pour l'Assomption`,
				"toussaint": `Fermer pour La Toussaint`,
				"armistice": `Fermer pour l'Armistice`,
				"noel": `Fermer pour Noël`,
				"lundiDePaques": `Fermer pour le Lundi de Paques`,
				"ascension": `Fermer pour le Jeudi de l'Ascension`,
				"pentecote": `Fermer pour le Lundi de Pentecôte`
			}
		},
		save: `Enregistrer`
	},
	profile: {
		body: `Ces informations seront présentes dans les mails envoyés à vos {p}s, ainsi que sur votre site web.`,
		labels: {
			address: `Votre adresse`,
			phoneNumber: `Votre numéro de téléphone`,
			emailNotifications: `Adresse email pour vos notifications`,
			lat: `Latitude`,
			lng: `Longitude`,
			geoLocation: `Définissez les coordonnées GPS afin de fournir une précision plus importante à vos {p}s. Vous pouvez obtenir des coordonnées GPS à partir d'une adresse sur ce site : https://www.coordonnees-gps.fr/`,
		},
		save: `Enregistrer`
	}
}
