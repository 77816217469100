import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import moment from 'moment-timezone';

import * as USER from '../constants/user';
import * as PLANNING from '../constants/planning';
import * as APPOINTMENT from '../constants/appointment';
import * as USERS from '../constants/users';
import * as MESSAGES from '../constants/messages';

const initialState = {
	selected: '',
	viewDate: null,
	scrollTime: new Date(),
	default: '',
};

const reducers = {
	[USER.INFOS_SUCCESS]: (state, {payload:user}) => update(state, {
		default: { $set: user.hasAccessTo.default || user.hasAccessTo.others[0].id },
	} ),
	[PLANNING.LOAD_REQUEST]: (state, {payload:id}) => update(state, {
		[id]: {
			$set: {
				loading: true,
				configuration: {},
				days: {},
			}
		},
	} ),
	[PLANNING.LOAD_COMPLETE]: (state, {payload}) => update(state, {
		[payload.id]: {
			$merge: {
				loading: false,
				...payload,
			}
		},
		selected: { $set: payload.id }
	}),
	[PLANNING.PATIENTS_LOAD_COMPLETE]: (state, {payload}) => update(state, {
		[payload.id]: {
			$merge: payload
		}
	}),
	[PLANNING.LOAD_ERROR]: (state, {payload:id}) => update(state, {
		[id]: {
			$set: null
		},
	} ),
	[PLANNING.UPDATE_VIEW_DATE]: (state, {payload:date}) => update(state, {
		viewDate: { $set: date },
	} ),
	[PLANNING.UPDATE_SCROLL_TIME]: state => update(state, {
		scrollTime: { $set: new Date() },
	} ),
	[PLANNING.LOAD_DAY_REQUEST]: (state, {payload:{planningId, day}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					$set: {
						loading: true,
						appointments: [],
					}
				}
			},
		},
	} ),
	[PLANNING.LOAD_DAYS_REQUEST]: (state, {payload:{planningId, days}}) => update(state, {
		[planningId]: {
			days: {
				$merge: days.reduce((p, day) => Object.assign(p, {
					[day]: {
						loading: true,
						appointments: []
					}
				}), {})
			},
		},
	} ),
	[PLANNING.LOAD_DAY_COMPLETE]: (state, {payload:{planningId, day, appointments, params, unblockedSlots}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					$merge: {
						unblockedSlots,
						appointments,
						params,
						loading: false,
					}
				}
			},
		},
	} ),
	[PLANNING.LOAD_DAYS_COMPLETE]: (state, {payload:{planningId, days}}) => update(state, {
		[planningId]: {
			days: {
				$merge: days.reduce((p, day) => Object.assign(p, {
					[day.day]: {
						updatedAt: moment().format(),
						loading: false,
						...day
					}
				}), {})
			},
		},
	} ),
	[PLANNING.LOAD_DAY_ERROR]: (state, {payload:{planningId, day}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					$set: null
				}
			},
		},
	} ),
	[PLANNING.SET_PLANNING]: (state, {payload:id}) => update(state, {
		selected: { $set: id }
	} ),
	[APPOINTMENT.UPDATE]: (state, {payload:{planningId, day, event, id}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					updatedAt: { $set: moment().format() },
					appointments: {
						[state[planningId].days[day].appointments.findIndex(a => a.id === id)] : {
							$set: {
								...event,
								updatedAt: moment().format()
							},
						}
					}
				}
			}
		}
	} ),
	[APPOINTMENT.DELETE]: (state, {payload:{planningId, day, id}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					updatedAt: { $set: moment().format() },
					appointments: {
						$set: state[planningId].days[day].appointments.filter(a => a.id !== id),
					}
				}
			}
		}
	} ),
	[APPOINTMENT.CREATE]: (state, {payload:{planningId, day, event}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					updatedAt: { $set: moment().format() },
					appointments: {
						$push: [event],
					}
				}
			}
		}
	} ),
	[APPOINTMENT.CONFIRM]: (state, {payload:{planningId, day, id, confirmed}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					appointments: {
						[state[planningId].days[day].appointments.findIndex(a => a.id === id)]: {
							$merge: {
								confirmed,
								updatedAt: moment().format()
							}
						}
					}
				}
			}
		}
	} ),
	[PLANNING.DAY_UPDATE]: (state, {payload:{planningId, day, datas}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					params: {
						$merge: datas,
					}
				}
			}
		}
	} ),
	[PLANNING.ADD_APPOINTMENT_TYPE]: (state, {payload:{planningId, id, schedules, activeForUser, onlyRealSlot, color, label, duration, isVisio}}) => update(state, {
		[planningId]: {
			appointmentsTypes: {
				$push: [{id, schedules, activeForUser, onlyRealSlot, color, label, duration, isVisio}]
			}
		}
	} ),
	[PLANNING.DELETE_APPOINTMENT_TYPE]: (state, {payload:{planningId, appointmentTypeId}}) => update(state, {
		[planningId]: {
			appointmentsTypes: {
				$set: state[planningId].appointmentsTypes.filter(a => a.id !== appointmentTypeId)
			}
		}
	} ),
	[PLANNING.UPDATE_APPOINTMENT_TYPE]: (state, {payload:{planningId, appointmentTypeId, ...datas}}) => update(state, {
		[planningId]: {
			appointmentsTypes: {
				$set: state[planningId].appointmentsTypes.map(a => a.id === appointmentTypeId ? {...a, ...datas} : a)
			}
		}
	} ),
	[PLANNING.UPDATE_APPOINTMENT_TYPE_PROFILE]: (state, {payload:{planningId, appointmentTypeId, profile}}) => update(state, {
		[planningId]: {
			appointmentsTypes: {
				$set: state[planningId].appointmentsTypes.map(a => a.id === appointmentTypeId ? {...a, profile} : a)
			}
		}
	} ),
	[PLANNING.ADD_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES]: (state, {payload:{planningId, id, appointmentTypeId, from, to, schedules, fromUnix }}) => {
		const index = state[planningId].appointmentsTypes.findIndex(a => a.id === appointmentTypeId);
		return update(state, {
			[planningId]: {
				appointmentsTypes: {
					[index]: {
						specificSchedules: state[planningId].appointmentsTypes[index].specificSchedules ? {
							$push: [{id, from, to, schedules, fromUnix}]
						} : {
							$set: [{id, from, to, schedules, fromUnix}]
						}
					}
				}
			}
		} )
	},
	[PLANNING.UPDATE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES]: (state, {payload:{planningId, appointmentTypeId, id, from, to, schedules, fromUnix}}) => {
		const aIndex = state[planningId].appointmentsTypes.findIndex(a => a.id === appointmentTypeId);
		const sIndex = state[planningId].appointmentsTypes[aIndex].specificSchedules.findIndex(s => s.id === id);
		return update(state, {
			[planningId]: {
				appointmentsTypes: {
					[aIndex]: {
						specificSchedules: {
							[sIndex]: {
								$set: {id, from, to, schedules, fromUnix}
							}
						}
					}
				}
			}
		} );
	},
	[PLANNING.DELETE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES]: (state, {payload:{planningId, appointmentTypeId, id}}) => {
		const aIndex = state[planningId].appointmentsTypes.findIndex(a => a.id === appointmentTypeId);
		const scs = state[planningId].appointmentsTypes[aIndex].specificSchedules;
		return update(state, {
			[planningId]: {
				appointmentsTypes: {
					[aIndex]: {
						specificSchedules: {
							$set: scs.filter(sc => sc.id !== id)
						}
					}
				}
			}
		} );
	},
	[PLANNING.CONFIG_UPDATE]: (state, {payload:{planningId, ...datas}}) => update(state, {
		[planningId]: {
			configuration: {
				$merge: {...datas}
			}
		}
	} ),
	[PLANNING.PROFILE_UPDATE]: (state, {payload:{planningId, ...datas}}) => update(state, {
		[planningId]: {
			profile: {
				$merge: {...datas}
			}
		}
	} ),
	[APPOINTMENT.ADD_TO_CONFIRM_LIST]: (state, {payload:{planningId, event}}) => update(state, {
		[planningId]: {
			appointmentsToConfirm: { $push: [event] }
		}
	} ),
	[APPOINTMENT.REMOVE_FROM_CONFIRM_LIST]: (state, {payload:{planningId, id}}) => update(state, {
		[planningId]: {
			appointmentsToConfirm: { $set: state[planningId].appointmentsToConfirm.filter(a => a.id !== id) }
		}
	} ),
	[USERS.ACCEPT]: (state, {payload:{planningId, userId}}) => update(state, {
		[planningId]: {
			usersToAccept: {
				$set: state[planningId].usersToAccept.filter(f => f !== userId)
			},
			users: {
				$set: state[planningId].users.map(f => f.id === userId ? {...f, isRequestingAccess: false, isBlocked: false} : f)
			},
			usersList: {
				$set: state[planningId].usersList.map(f => f.id === userId ? ({...f, isBlocked: false}) : f)
			}
		}
	} ),
	[USERS.BLOCK]: (state, {payload:{planningId, userId}}) => update(state, {
		[planningId]: {
			usersToAccept: {
				$set: state[planningId].usersToAccept.filter(f => f !== userId)
			},
			users: {
				$set: state[planningId].users.map(f => f.id === userId ? ({...f, isRequestingAccess: false, isBlocked: true}) : f)
			},
			usersList: {
				$set: state[planningId].usersList.map(f => f.id === userId ? ({...f, isBlocked: true}) : f)
			}
		}
	} ),
	[USERS.ADD]: (state, {payload:{planningId, user, userList}}) => update(state, {
		[planningId]: {
			users: {
				$push: [user]
			},
			usersList: {
				$push: userList
			}
		}
	} ),
	[USERS.REQUEST_ACCESS]: (state, {payload:{planningId, user}}) => update(state, {
		[planningId]: {
			users: { $push: [user] },
			usersToAccept: { $push: [user.id] },
			usersList: { $push: user.family.map(member => ({
				id: user.id,
				isBlocked: user.isBlocked,
				email: user.email,
				phoneNumber: user.phoneNumber,
				address: user.address,
				familyMemberId: member.id,
				firstName: member.firstName,
				lastName: member.lastName,
				birthdate: member.birthdate,
				gender: member.gender,
			}))}
		}
	} ),
	[MESSAGES.RECEIVED_NEW]: (state, {payload:{planningId, ...datas}}) => update(state, {
		[planningId]: {
			inbox: {
				unread: { $set : state[planningId].inbox.unread+1 },
				messages: state[planningId].inbox.messages ? {
					$push: [{...datas}],
				} : { $set: null },
			}
		}
	} ),
	[MESSAGES.CHANGE_FOLDER]: (state, {payload:{planningId, folder}}) => update(state, {
		[planningId]: {
			inbox: {
				active: { $set: folder },
			}
		}
	} ),
	[MESSAGES.GET_ALL_COMPLETE]: (state, {payload:{planningId, messages}}) => update(state, {
		[planningId]: {
			inbox: {
				messages: {
					$set: messages || []
				},
			}
		}
	}),
	[MESSAGES.GET_COMPLETE]: (state, {payload:{planningId, id, openedAt}}) => update(state, {
		[planningId]: {
			inbox: {
				unread: {
					$set: !state[planningId].inbox.messages.find(m => m.id === id).openedAt ? state[planningId].inbox.unread-1 : state[planningId].inbox.unread,
				},
				messages: {
					[state[planningId].inbox.messages.findIndex(m => m.id === id)]: {
						openedAt: { $set: openedAt }
					}
				},
			}
		}
	}),
	[MESSAGES.UPDATE_STATE]: (state, {payload:{planningId, messageId, state: st}}) => update(state, {
		[planningId]: {
			inbox: {
				messages: {
					[state[planningId].inbox.messages.findIndex(m => m.id === messageId)]: {
						state: { $set: st }
					}
				},
			}
		}
	}),
	[MESSAGES.SET_UNREAD]: (state, {payload:{planningId, messageId}}) => update(state, {
		[planningId]: {
			inbox: {
				unread: {
					$set: state[planningId].inbox.unread+1
				},
				messages: {
					[state[planningId].inbox.messages.findIndex(m => m.id === messageId)]: {
						openedAt: { $set: null }
					}
				},
			}
		}
	}),
	[MESSAGES.REMOVE]: (state, {payload:{planningId, messageId}}) => update(state, {
		[planningId]: {
			inbox: {
				messages: {
					$set: state[planningId].inbox.messages.filter(m => m.id !== messageId)
				},
			}
		}
	}),
	[PLANNING.BILLING_REQUEST]: (state, {payload:planningId}) => update(state, {
		[planningId]: {
			billing: {
				loading: { $set: true },
			}
		}
	} ),
	[PLANNING.BILLING_COMPLETE]: (state, {payload:{planningId, datas}}) => update(state, {
		[planningId]: {
			billing: {
				$set: {
					loading: false,
					loaded: true,
					...datas
				}
			}
		}
	} ),
	[PLANNING.BILLING_ADD_CARD]: (state, {payload:{planningId, card}}) => update(state, {
		[planningId]: {
			billing: {
				creditCard: {
					$set: card
				}
			}
		}
	} ),
	[PLANNING.BILLING_PLAN_PAYMENT_UPDATE]: (state, {payload:{planningId, plan}}) => update(state, {
		[planningId]: {
			billing: {
				plan: {
					type: { $set: plan }
				}
			}
		}
	} ),
	[PLANNING.SET_DRIVE_HASH]: (state, {payload:{planningId, hash}}) => update(state, {
		[planningId]: {
			driveHash: { $set: hash }
		}
	} ),
	[PLANNING.GLOBAL_SPECIFIC_SHEDULES]: (state, {payload:{planningId, id, datas}}) => update(state, {
		[planningId]: {
			appointmentsTypes: {
				$set: state[planningId].appointmentsTypes.map(a => ({
					...a,
					specificSchedules: [...a.specificSchedules, {
						id,
						...datas
					}]
				}))
			}
		}
	} ),
	[PLANNING.UNBLOCK_SLOT_RULE]: (state, {payload:{planningId, day, start}}) => update(state, {
		[planningId]: {
			days: {
				[day]: {
					unblockedSlots: { $push: [start] }
				}
			}
		}
	}),
	[PLANNING.PAYMENTS_REQUEST]: (state, {payload:planningId}) => update(state, {
		[planningId]: {
			payments: {
				loading: { $set: true },
			}
		}
	} ),
	[PLANNING.PAYMENTS_COMPLETE]: (state, {payload:{planningId, datas}}) => update(state, {
		[planningId]: {
			payments: {
				$set: {
					loading: false,
					loaded: true,
					...datas
				}
			}
		}
	} ),
	[PLANNING.PAYMENTS_BALANCE]: (state, {payload:{planningId, ...datas}}) => update(state, {
		[planningId]: {
			paymentsBalance: {
				$set: {
					...datas
				}
			}
		}
	}),
}

export default createReducer(initialState, reducers);
