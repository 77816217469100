import { get, put, post } from './index';
import mapper from '../mappers/user';
import firebase from '../config/firebase';

export const doLogin = async query => {
	const mapped = mapper.doLogin.toApi(query);
	try {
		const res = await firebase.auth().signInWithEmailAndPassword(mapped.email, mapped.password);
		const token = await res.user.getIdToken();
		return {email: mapped.email, token};
		/* Getting current token */
	} catch (e) {
		return {
			error: true,
			status: 403,
			code: e.code,
		}
	}
}

export const getUser = () => {
	const endpoint = `users/self`;
	return get({
		endpoint,
		catchErrors: [403],
	});
}

export const acceptTerms = () => {
	const endpoint = `users/self/accept-terms`;
	return put({ endpoint });
}

export const doLogout = async () => {
	try {
		await firebase.auth().signOut();
		return true;
	} catch (e) {
		//Error at loggout, strange but we could assume we have logged out"
		return true;
	}
}


export const manageDevice = params => {
	const endpoint = `users/self/devices`;
	return post({
		endpoint,
		params
	});
}
