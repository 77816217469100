import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';

import Rnd from '../modules/rnd';

import { getEventTop, getEventHeight, getEventWidth, getSlotHeight, getEventX, getEventZIndex } from '../../libs/getters';
import { checkEventsConflicts } from '../../libs/checkers';
import { defaultColorsTypes } from '../../config/theme';

import EventDetails from './EventDetails';

class Event extends PureComponent {

    constructor(props) {
        super(props);
        const { slotSize } = this.props;

        this.stepHeight = getSlotHeight(slotSize);

        const style = this.getSlotStyle();
        this.allowDragCheck = false;

        this.state = {
            isResizing: false,
            isDragging: false,
            slotDelta: 0,
			dragToHour: null,
            ...style,
        }
    }

    componentDidMount = () => {
        this.agendaDom = document.querySelector('.agenda--content');
    }

    getSlotStyle = () => {
        const { slotSize, step, agendaStart, dayAppointments, calendarColumnWidth, event } = this.props;
        const { duration, start, id, created } = event;

        const conflicts = checkEventsConflicts({
            id,
            to: {
                start,
                end: moment(start).add(duration, "minutes")
            },
            appointments: dayAppointments
        })

        this.slotHeight = getEventHeight({
            slotSize,
            duration,
            step
        });

        this.initialY = getEventTop({
            start: moment(agendaStart, "HHmm"),
            time: start,
            step,
            slotSize
        });

        this.initialX = getEventX()

        this.slotWidth = getEventWidth({
            start,
            id,
            created: created.at,
            conflicts,
            calendarColumnWidth
        });

        this.initialZIndex = getEventZIndex({
            width: this.slotWidth,
            calendarColumnWidth
        });

        return {
            slotHeight: this.slotHeight,
            slotWidth: this.slotWidth,
            slotPosX: this.initialX,
            slotPosY: this.initialY,
            slotZIndex: this.initialZIndex
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.calendarColumnWidth !== this.props.calendarColumnWidth) {
            this.setState({...this.getSlotStyle()});
        }
    }
    startResize = () => this.setState({isResizing: true});
    stopResize = (_, __, ___, delta) => {
        this.setState({
            isResizing: false,
            slotHeight: this.slotHeight,
        });

        if (delta.height === 0) return;

        const { event, onMoveEvent, step } = this.props;
        const addSteps = delta.height / this.stepHeight;
        const addMinutes = addSteps*step;

        const end = moment(event.start).add(event.duration+addMinutes, "minutes")

        onMoveEvent({
            event,
            start: event.start,
            end
        });
    };

    onClickEvent = () => {
        if (this.allowDragCheck) return;
        const { event, onSelectEvent} = this.props;
        onSelectEvent(event);
    }

    getEventClassnames = () => {
        const { selectedAppointmentId, duplicateMode, event } = this.props;
        const { start, id, isADuplicated, confirmed, typeId } = event;
        const { isResizing, isDragging } = this.state;

		return cn(`event event-${id}`, {
            'event--resizing': isResizing,
            'event--dragging': isDragging,
            'event--selected': id === selectedAppointmentId,
            'event--not-selected': selectedAppointmentId && id !== selectedAppointmentId,
            'event--past': moment(start).isBefore(moment()),
            'event--is-duplicate-mode': duplicateMode,
            'event--is-a-duplicate': duplicateMode && isADuplicated === true,
            'event--tbc': confirmed.at === '',
            'event--blocked': typeId === "blocked",
        })
    }

    getEventBackgroundColor = () => {
        const { appointmentsTypes, event:{typeId} } = this.props;
		const type = appointmentsTypes.find(a => a.id === typeId);
		const color = type ? type.color : defaultColorsTypes[typeId === "blocked" ? 'blocked' : 'default'];

        return color;
    }

    onDragStart = () => {
        this.setState({isDragging: true});
        if (this.timeoutDrag) clearTimeout(this.timeoutDrag);
        this.timeoutDrag = setTimeout(() => this.allowDragCheck = true, 300);
    }

    resetAllowDrag = () => {
		this.draggingNode = null;
        if (this.timeoutDrag) clearTimeout(this.timeoutDrag);
        setTimeout(() => this.allowDragCheck = false, 50);
    }

	onDrag = (_, {x, y}) => {
		this.lastTravelCoords = {x, y};
		const travel = this.getTravel({x, y});
		this.setState({
			slotPosX: this.initialX + x,
			slotPosY: y,
			dragToHour: travel ? travel.start : null
		});
	}

    onDragStop = () => {
        const { event, onMoveEvent } = this.props;
        this.setState({
			isDragging: false,
			dragToHour: null,
			slotPosX: this.initialX,
			slotPosY: this.initialY
		});
        this.resetAllowDrag();
		if (!this.lastTravelCoords) return;

        const travel = this.getTravel(this.lastTravelCoords);
		this.lastTravelCoords = null;
		if (!travel) return;
		if (!this.allowDragCheck) return;

        onMoveEvent({
            event,
            ...travel
        });

    }

    getTravel = coords => {
        const { calendarColumnWidth, days, date, event, step } = this.props;
        if (!coords) return;

        const travelY = coords.y - this.initialY;
        const travelX = coords.x - this.initialX;

		const travelColumns = Math.ceil(travelX/calendarColumnWidth);
        const travelLines = Math.ceil(travelY/this.stepHeight);

        if (travelColumns === 0 && travelLines === 0) return null;
        const { start, duration } = event;
        const index = days.indexOf(date);

		const tryIndex = index+travelColumns
		const useIndex =  tryIndex >= days.length ? days.length - 1 : tryIndex;

        const targetDay = days[useIndex];
        const targetStart = moment(start).add(step*travelLines, "minutes").format('HHmm');

        const newStart = moment(`${targetDay} ${targetStart}`, 'YYYY-MM-DD HHmm');
        const end = moment(newStart).add(duration, "minutes");

    	return {
    		start: newStart,
    		end,
    	}
    }

    render() {
        const { event:{start, typeId}, calendarColumnWidth, hasRight, duplicateMode, isTouch } = this.props;
        const { slotHeight, isDragging, blockClick, slotPosX, slotPosY, slotWidth, slotZIndex, dragToHour } = this.state;

        const isPast = moment(start).isBefore(moment());

        const className = this.getEventClassnames();
        const backgroundColor = this.getEventBackgroundColor();
        const allowDragAndResize = !isPast && hasRight && typeId !== "blocked" && !duplicateMode;

        const maximize = isDragging && blockClick;

        if (isTouch) {
            return <div
                className={className}
                style={{
                    position: 'absolute',
                    backgroundColor,
                    zIndex: slotZIndex,
                    width: slotWidth,
                    height: slotHeight,
                    top: slotPosY,
                    left: slotPosX,
                }}
                onClick={this.onClickEvent}
            >
                <EventDetails
                    event={this.props.event}
                />
            </div>
        }

        return (
            <Rnd
                className={className}
                style={{
                    backgroundColor,
                    zIndex: slotZIndex,
                }}
                maxWidth="90%"
                disableDragging={!allowDragAndResize}
                enableResizing={{
                    bottom: allowDragAndResize,
                    bottomLeft: false,
                    bottomRight: false,
                    left: false,
                    right: false,
                    top: false,
                    topLeft: false,
                    topRight: false,
                }}
                resizeGrid={[1, this.stepHeight]}
                dragGrid={[calendarColumnWidth, this.stepHeight]}
                size={{
                    width: maximize ? calendarColumnWidth*0.9 : slotWidth,
                    height: slotHeight,
                }}
                position={{
                    x: slotPosX,
                    y: slotPosY,
                }}
                default={{
                    x: this.initialX,
                    y: this.initialY,
                    height: `${slotHeight}px`,
                }}
                resizeHandleClasses={{
                    bottom: "time-slot-handler"
                }}
                bounds=".agenda--content"

                onResizeStart={this.startResize}
                onResizeStop={this.stopResize}
                onDragStart={this.onDragStart}
                onDragStop={this.onDragStop}
                onClick={this.onClickEvent}
				onDrag={this.onDrag}
            >
                <EventDetails
                    event={this.props.event}
					displayHour={isDragging && dragToHour ? dragToHour : null}
                />
            </Rnd>
        )
    }
}


const mapStateToProps = ({plannings, ui}) => ({
    calendarColumnWidth: ui.calendarColumnWidth,
    agendaStart: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.start || '0900' : '0900',
    step: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.step || 15 : 15,
    appointmentsTypes : plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes : [],
    selectedAppointmentId: ui.quickView.show ? ui.quickView.appointmentId : null,
    duplicateMode: ui.duplicateMode.show,
    isTouch: ui.device.isTouch
});

export default connect(mapStateToProps)(Event);
