import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import SprelClientPro from './components/SprelClientPro';
import store from './config/store';
import history from './config/history';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={ store }>
		<Router history={history}>
			<SprelClientPro />
		</Router>
    </Provider>
);

// Hot reloading
if (module && module.hot) {
    module.hot.accept();
}

/*
if (process.env.NODE_ENV !== 'production') {
  const {whyDidYouUpdate} = require('why-did-you-update');
  whyDidYouUpdate(React);
}*/
