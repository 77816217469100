import { createAction} from 'redux-actions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import * as PLANNING from '../constants/planning';
import * as api from '../api/planning';
import mapper from '../mappers/planning';
import getLexique, { _lp } from '../locales';


const loadPlanningRequest = createAction(PLANNING.LOAD_REQUEST);
const loadPlanningComplete = createAction(PLANNING.LOAD_COMPLETE);
const getPlanningPatientsComplete = createAction(PLANNING.PATIENTS_LOAD_COMPLETE);
const loadPlanningError = createAction(PLANNING.LOAD_ERROR);
const loadPlanningDayRequest = createAction(PLANNING.LOAD_DAY_REQUEST);
const loadPlanningDaysRequest = createAction(PLANNING.LOAD_DAYS_REQUEST);
const loadPlanningDayComplete = createAction(PLANNING.LOAD_DAY_COMPLETE);
const loadPlanningDaysComplete = createAction(PLANNING.LOAD_DAYS_COMPLETE);
const loadPlanningDayError = createAction(PLANNING.LOAD_DAY_ERROR);
export const setPlanning = createAction(PLANNING.SET_PLANNING);
export const updateViewDate = createAction(PLANNING.UPDATE_VIEW_DATE);
export const updateScrollTime = createAction(PLANNING.UPDATE_SCROLL_TIME);
const updatePlanningDay = createAction(PLANNING.DAY_UPDATE);
const addOtherPlanning = createAction(PLANNING.ADD_OTHER);
const createAppointmentTypeComplete = createAction(PLANNING.ADD_APPOINTMENT_TYPE);
const deleteAppointmentTypeComplete = createAction(PLANNING.DELETE_APPOINTMENT_TYPE);
const updateAppointmentTypeComplete = createAction(PLANNING.UPDATE_APPOINTMENT_TYPE);
const updateAppointementTypeProfileComplete = createAction(PLANNING.UPDATE_APPOINTMENT_TYPE_PROFILE);
const addAppointmentTypeSpecificScheduleComplete = createAction(PLANNING.ADD_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES);
const updateAppointmentTypeSpecificScheduleComplete = createAction(PLANNING.UPDATE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES);
const deleteAppointmentTypeSpecificScheduleInStore = createAction(PLANNING.DELETE_APPOINTMENT_TYPE_SPECIFIC_SCHEDULES);
const updatePlanningConfigComplete = createAction(PLANNING.CONFIG_UPDATE);
const updatePlanningProfileComplete = createAction(PLANNING.PROFILE_UPDATE);
const getBillingInfosRequest = createAction(PLANNING.BILLING_REQUEST);
const getBillingInfosComplete = createAction(PLANNING.BILLING_COMPLETE);
const addBillingCard = createAction(PLANNING.BILLING_ADD_CARD);
const updatePlanPaymentStore = createAction(PLANNING.BILLING_PLAN_PAYMENT_UPDATE);
export const setDriveHash = createAction(PLANNING.SET_DRIVE_HASH);
const addGlobalSpecificSheduleStore = createAction(PLANNING.GLOBAL_SPECIFIC_SHEDULES);
const unblockSlotByRuleStore = createAction(PLANNING.UNBLOCK_SLOT_RULE);

const getPaymentsInfosRequest = createAction(PLANNING.PAYMENTS_REQUEST);
const getPaymentsInfosComplete = createAction(PLANNING.PAYMENTS_COMPLETE);
const setPaymentsBalance = createAction(PLANNING.PAYMENTS_BALANCE);

export const loadPlanning = query => async dispatch => {
    dispatch(loadPlanningRequest(query.id));

    const res = await api.loadPlanning(query);
	if (res) {
    	const mapped = mapper.loadPlanning.fromApi(res);
    	if (query.dispatch === undefined || query.dispatch) await dispatch(loadPlanningComplete(mapped));

        // Loading practitioner patients
        const patients = await api.getPlanningPatients(query);
        const mappedPatients = mapper.getPlanningPatients.fromApi(patients);
        dispatch(getPlanningPatientsComplete(mappedPatients));

    	return mapped;
	}

	dispatch(loadPlanningError(query.id));
	return false;
}

export const loadPlanningDay = query => async (dispatch, getState) => {

	/* Checking if day is already loaded */
	const { plannings } = getState();
	if (!plannings) return;

	const selectedPlanning = plannings[query.planningId];
	if (!selectedPlanning) return;

	const days = selectedPlanning.days;
	if (!days) return;

	const day = selectedPlanning.days[query.day];
	if (day && day.appointments) {
		// Day already exists in store, no need to load it
		return day.appointments;
	}

	// Day is not present, we have to load it

    dispatch(loadPlanningDayRequest(query));

    const res = await api.loadPlanningDay(query);
	if (res) {
	    const mapped = mapper.loadPlanningDay.fromApi(res);
	    if (query.dispatch === undefined || query.dispatch) await dispatch(loadPlanningDayComplete(mapped));
	    return mapped;
	}

	dispatch(loadPlanningDayError({
		planningId: query.planningId,
		day: query.day
	}));
	return [];
}

export const loadPlanningDays = requestDays => async (dispatch, getState) => {
    /* Checking if day is already loaded */
	const { plannings } = getState();
	if (!plannings) return;

	const selectedPlanning = plannings[plannings.selected];
	if (!selectedPlanning) return;

	const days = selectedPlanning.days;
	if (!days) return;

    const loadDays = requestDays.filter(d => {
        const day = selectedPlanning.days[d];
    	if (day && day.appointments) {
    		return false
    	}
        return true;
    });

    if (loadDays.length === 0) return;

    const query = {days: loadDays, planningId: plannings.selected};

    dispatch(loadPlanningDaysRequest(query));
    const res = await api.loadPlanningDays(query);

    const mapped = mapper.loadPlanningDays.fromApi(res);
    dispatch(loadPlanningDaysComplete({
        days: mapped,
        planningId: plannings.selected
    }));
}


export const closePlanningDay = query => async (dispatch, getState) => {
	dispatch(updatePlanningDay({
		planningId: query.planningId,
		day: query.day,
		datas: {
			isClosed: true
		}
	}));

	await api.closePlanningDay(query);

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.planningDay;
	toast.error(lexique.closed);
}

export const openPlanningDay = query => async (dispatch, getState) => {
	dispatch(updatePlanningDay({
		planningId: query.planningId,
		day: query.day,
		datas: {
			isClosed: false
		}
	}));

	await api.openPlanningDay(query);

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.planningDay;
	toast.success(lexique.opened);
}

export const updateDayInformationMessage = query => async (dispatch, getState) => {
    dispatch(updatePlanningDay({
		planningId: query.planningId,
		day: query.day,
		datas: {
			information: {
                value: query.value,
                level: query.level
            }
		}
	}));

    await api.updateDayInformationMessage(query);

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.planningDay;
	toast.success(_lp(lexique.informationChanged));
}

export const updateDayNotes = query => dispatch => {
    dispatch(updatePlanningDay({
		planningId: query.planningId,
		day: query.day,
		datas: {
			note: query.value
		}
	}));

    api.updateDayNotes(query);
}

export const updateDaySpecificSchedules = query => async (dispatch, getState) => {
    dispatch(updatePlanningDay({
		planningId: query.planningId,
		day: query.day,
		datas: {
			specificSchedules: query.value
		}
	}));

    await api.updateDaySpecificSchedules(query);

    const { env } = getState();
	const lexique = getLexique(env.locale).toasts.planningDay;
	toast.success(lexique.planningUpdated);
}

export const createPlanning = query => async dispatch => {
    const res = await api.createPlanning(query);
    const mapped = mapper.createPlanning.fromApi(res);

    dispatch(addOtherPlanning({
        id: mapped.id,
        particule: query.particule || '',
        firstName: query.firstName || '',
        lastName: query.lastName || '',
    }))
}

export const createAppointmentType = query => async dispatch => {
	const res = await api.createAppointmentType(query);
	const mapped = mapper.createAppointmentType.fromApi(res);

	dispatch(createAppointmentTypeComplete({
        id: mapped.id,
		planningId: query.planningId,
		...query
    }))
}

export const deleteAppointmentType = query => async dispatch => {
    await api.deleteAppointmentType(query);
	dispatch(deleteAppointmentTypeComplete(query))
}

export const updateAppointmentType = query => async dispatch => {
	await api.updateAppointmentType(query);
	dispatch(updateAppointmentTypeComplete(query))
}

export const updateAppointmentTypeProfile = query => dispatch => {
    if (query.profile) api.updateAppointmentTypeProfile(query);
    else api.deleteAppointmentTypeProfile(query);

	dispatch(updateAppointementTypeProfileComplete(query))
}

export const addAppointmentTypeSpecificSchedule = query => async dispatch => {
	const res = await api.addAppointmentTypeSpecificSchedule(query);
	const mapped = mapper.addAppointmentTypeSpecificSchedule.fromApi(res);

	dispatch(addAppointmentTypeSpecificScheduleComplete({
        id: mapped.id,
		...query,
    }))
}

export const updateAppointmentTypeSpecificSchedule = query => dispatch => {
	api.updateAppointmentTypeSpecificSchedule(query);
	dispatch(updateAppointmentTypeSpecificScheduleComplete({
        id: query.specificScheduleId,
		...query
    }))
	return true;
}

export const deleteAppointmentTypeSpecificSchedule = query => dispatch => {
	api.deleteAppointmentTypeSpecificSchedule(query);
	dispatch(deleteAppointmentTypeSpecificScheduleInStore({
        id: query.specificScheduleId,
		...query
    }))
	return true;
}

export const updatePlanningConfig = query => async dispatch => {
	await api.updatePlanningConfig(query);

	dispatch(updatePlanningConfigComplete(query));
	return true;
}

export const updatePlanningProfile = query => async dispatch => {
	await api.updatePlanningProfile(query);

	dispatch(updatePlanningProfileComplete(query));
	return true;
}

/* Billing */
export const getBillingInfos = () => async (dispatch, getState) => {
	const { plannings } = getState();
	const {loaded} = plannings[plannings.selected].billing;
	// Billing already loaded
	if (loaded) return;

	dispatch(getBillingInfosRequest(plannings.selected));

	const res = await api.getBillingInfos(plannings.selected);
	const mapped = mapper.getBillingInfos.fromApi(res);

	dispatch(getBillingInfosComplete({
		planningId: plannings.selected,
		datas: mapped,
	}));
}

export const addCreditCardInformation = datas => async (dispatch, getState) => {
	const { plannings:{selected}, env } = getState();

	const query = {
		...datas,
		planningId: selected,
	}

	await api.addCreditCardInformation(query);
	dispatch(addBillingCard({
		card: mapper.addCreditCardInformation.fromApi(datas.card),
		planningId: selected,
	}));

	const lexique = getLexique(env.locale).toasts.account;
	toast.success(lexique.cardAdded);
}

export const updatePlanPayment = plan => async (dispatch, getState) => {
	const { plannings, env } = getState();

	const query = {
		plan,
		id: plannings[plannings.selected].billing.plan.id,
		planningId: plannings.selected,
	}

	dispatch(updatePlanPaymentStore({
		plan,
		planningId: plannings.selected,
	}));

	await api.updatePlanPayment(query);

	const lexique = getLexique(env.locale).toasts.account;
	toast.success(lexique.planUpdated);
}

export const addGlobalSpecificShedule = datas => async (dispatch, getState) => {
    const { plannings, env } = getState();

    const res = await api.addGlobalSpecificShedule({
        planningId: plannings.selected,
        datas,
    });

    const { id } = mapper.addGlobalSpecificShedule.fromApi(res);
    dispatch(addGlobalSpecificSheduleStore({planningId: plannings.selected, id, datas}));

    const lexique = getLexique(env.locale).toasts.specificSchedules;
	toast.success(lexique.added);
}

/* Allow slot by rule */
export const unblockSlotByRule = start => async (dispatch, getState) => {
	const { plannings, env } = getState();

	const day = moment(start).format('YYYY-MM-DD');
	const datas = {
		day,
		start: moment(start).format(),
		planningId: plannings.selected,
	}

	dispatch(unblockSlotByRuleStore(datas));

	await api.unblockSlotByRule(datas);

	const lexique = getLexique(env.locale).toasts.unblockSlotByRule;
	toast.success(lexique.done);
}


/* PAYMENTS */
export const getPaymentsInfos = force => async (dispatch, getState) => {

	const { plannings } = getState();
	
	const payments = plannings?.[plannings?.selected]?.payments;
	// Payments already loaded
	if (payments?.loaded && !force) return;


	dispatch(getPaymentsInfosRequest(plannings.selected));

	const res = await api.getPaymentsInfos(plannings.selected);
	const mapped = mapper.getPaymentsInfos.fromApi(res);

	dispatch(getPaymentsInfosComplete({
		planningId: plannings.selected,
		datas: mapped,
	}));

}

export const registerToPayments = datas => async dispatch => {
	const res = await api.registerToPayments(datas);
	if (res.error) return res;
	dispatch(getPaymentsInfos(true));
	return {}
}

export const uploadLegalsDocuments = datas => async () => {
	const res = await api.uploadLegalsDocuments(datas);
	if (res.error) return res;
	const tokens = mapper.uploadLegalsDocuments.fromApi(res);
	return tokens;
}

export const updateLegalsDocuments = datas => async dispatch => {
	const res = await api.updateLegalsDocuments(datas);
	if (res.error) return res;
	dispatch(getPaymentsInfos(true));
	return {}
}

export const createPayment = datas => async (_, getState) => {
	const res = await api.createPayment(datas);

	const { env } = getState();
	const lexique = getLexique(env.locale).toasts.payments;

	if (res.error) {
		if (res.status === 500) {
			toast.error(lexique.error);
			return res;
		}
		if (res.status === 422) {
			return {
				...res,
				...mapper.createPayment.fromApi(res),
			}
		}
	}

	toast.success(lexique.success);

	return {};
}

export const getPaymentsBalance = id => async dispatch => {

	const res = await api.getPaymentsBalance(id);
	const mapped = mapper.getPaymentsBalance.fromApi(res);
	dispatch(setPaymentsBalance({
		planningId: id,
		...mapped
	}));

}

export const getUnpaidPayments = id => async () => {
	const res = await api.getUnpaidPayments(id);
	const mapped = mapper.getUnpaidPayments.fromApi(res);
	return mapped;
}

export const getPaymentsOverPeriod = datas => async () => {
	const res = await api.getPaymentsOverPeriod(datas);
	const mapped = mapper.getPaymentsOverPeriod.fromApi(res);
	return mapped;
}

export const getPaymentsByUser = datas => async () => {
	const res = await api.getPaymentsByUser(datas);
	const mapped = mapper.getPaymentsByUser.fromApi(res);
	return mapped;
}

export const cancelPayment = datas => async () => {
	await api.cancelPayment(datas);
	return true;
}

export const updatePaymentIban = datas => async dispatch => {

	await api.updatePaymentIban(datas);

	await dispatch(getPaymentsInfos(true));
	return true;
}