import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import moment from 'moment-timezone';

import * as APPOINTMENT from '../constants/appointment';
import * as UI from '../constants/ui';

const appointmentDetails = {
	practitionerId: '',
	day: '',
	id: '',
	start: moment().format(),
	duration: 0,
	information: {
		user: '',
		admin: ''
	},
	typeId: '',
	rangePicked: false,
	with: {
		id: '',
		email: '',
		phoneNumber: '',
		familyMemberId: '',
		firstName: '',
		lastName: ''
	}
}

const initialState = {
	mode: '',
	show: false,
	event: {...appointmentDetails},
	edition: {...appointmentDetails},
	duplicates: [],
};

const reducers = {
	[APPOINTMENT.OPEN]: (state, {payload:event}) => update(state, {
		mode: { $set: 'edit' },
		show: { $set: true },
		event: { $set: event },
		edition: { $set: event },
	} ),
	[APPOINTMENT.OPEN_NEW]: (state, {payload:event}) => update(state, {
		mode: { $set: 'new' },
		show: { $set: true },
		event: { $set: event },
		edition: { $set: event },
	}),
	[APPOINTMENT.CLOSE]: state => update(state, {
		mode: { $set: '' },
		show: { $set: false },
	} ),
	[APPOINTMENT.CHANGE_TYPE_ID]: (state, {payload:value})  => update(state, {
		edition: {
			typeId: { $set: value }
		}
	} ),
	[APPOINTMENT.CHANGE_DURATION]: (state, {payload:value})  => update(state, {
		edition: {
			duration: { $set: value }
		}
	} ),
	[APPOINTMENT.CHANGE_START_DATE]: (state, {payload:value})  => update(state, {
		edition: {
			start: { $set: value }
		}
	} ),
	[APPOINTMENT.CHANGE_PHONE_NUMBER]: (state, {payload:value})  => update(state, {
		edition: {
			with: {
				phoneNumber: { $set: value }
			}
		}
	} ),
	[APPOINTMENT.CHANGE_INFO_ADMIN]: (state, {payload:value})  => update(state, {
		edition: {
			information: {
				admin: { $set: value }
			}
		}
	} ),
	[APPOINTMENT.CHANGE_NAME]: (state, {payload:value})  => update(state, {
		edition: {
			with: {
				firstName: { $set: '' },
				id: { $set: '' },
				email: { $set: '' },
				familyMemberId: { $set: '' },
				lastName: { $set: value }
			}
		}
	} ),
	[APPOINTMENT.CHOOSE_USER]: (state, {payload:{id, familyMemberId, firstName, lastName, email, phoneNumber, isAnimal}})  => update(state, {
		edition: {
			with: {
				id: { $set: id },
				email: { $set: email },
				phoneNumber: { $set: phoneNumber },
				familyMemberId: { $set: familyMemberId },
				firstName: { $set: firstName },
				lastName: { $set: lastName },
				isAnimal: { $set: isAnimal },
			}
		}
	} ),
	[APPOINTMENT.UPDATE_FIELD]: (state, {payload:{field, value}})  => update(state, {
		edition: {
			[field]: { $set: value }
		}
	} ),
	[UI.DUPLICATEMODE_ENTER]: (state, {payload:event})  => update(state, {
		duplicates: { $set: [] },
		event: { $set: event }
	} ),
	[APPOINTMENT.DUPLICATE_ADD]: (state, {payload:event})  => update(state, {
		duplicates: { $push: [event] },
	} ),
	[APPOINTMENT.DUPLICATE_REMOVE]: (state, {payload:id})  => update(state, {
		duplicates: { $set: state.duplicates.filter(e => e.id !== id) },
	} ),
	[APPOINTMENT.DUPLICATES_SAVE_REQUEST]: state  => update(state, {
		duplicates: { $set: state.duplicates.map(e => ({...e, status: "loading"})) },
	} ),
	[APPOINTMENT.DUPLICATE_SAVE_COMPLETE]: (state, {payload:{id, status}})  => update(state, {
		duplicates: {
			[state.duplicates.findIndex(a => a.id === id)]: {
				status: { $set: status }
			}
		},
	} ),
}


export default createReducer(initialState, reducers);
