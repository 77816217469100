import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import Delete from '@material-ui/icons/Delete';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import VisioIcon from '@material-ui/icons/VideocamOutlined';

import getLexique from '../../locales';
import { removeDuplicateEvent, confirmDuplicateAppointments } from '../../actions/appointment';
import { closeDuplicationDrawer, exitDuplicationMode, openModaleConfirmDiscardDuplicates } from '../../actions/ui';

import { userName } from '../../libs/formatters';

class DuplicationPanel extends PureComponent {

	state = { complete: false }

	onClickCancel = () => {
		const { duplicates, actions:{openModaleConfirmDiscardDuplicates} } = this.props;
		if (duplicates.length === 0) {
			this.cancelDuplicateMode();
			return;
		}

		// We have defined duplicated event, and user wants to discard, ask confirmation
		openModaleConfirmDiscardDuplicates({
			onConfirm: this.cancelDuplicateMode,
		});
	}

	cancelDuplicateMode = () => {
		const { actions: { closeDuplicationDrawer, exitDuplicationMode } } = this.props;
		this.setState({complete: false});
		closeDuplicationDrawer();
		exitDuplicationMode();
	}

	onRemove = id => () => {
		this.props.actions.removeDuplicateEvent(id);
	}

	onClose = () => {
		this.props.actions.closeDuplicationDrawer();
	}

	onClickConfirm = async () => {
		const { duplicates, actions:{confirmDuplicateAppointments}, selectedPlanning } = this.props;
		await confirmDuplicateAppointments({
			appointments: duplicates,
			practitionerId: selectedPlanning
		});
		this.setState({complete: true});
	}

	render() {
		const { lexique, event, duplicates, config, appointmentsTypes, loading } = this.props;
		const { complete } = this.state;

		const type = appointmentsTypes.find(a => a.id === event.typeId);
		const label = type ? type.label : '-';

		return (
			<Fragment>
				<DialogContent>
					{/* Recap duplicated appointment */}
					<Typography variant="h5">{lexique.title}</Typography>
					<Card className="mt10">
						<CardContent>
							<Typography color="textSecondary">
								{ moment(event.start).format(config.datetimeFormatLong) }
							</Typography>
							<div className="flex aic">
								{ event.with.id && event.with.id !== '' && <Person /> }
								<Typography variant="h6">
									{ event.with.id && event.with.id !== '' ? userName(event.with) : event.with.lastName }
								</Typography>
							</div>
							<Typography className="flex aic mt10 duplicated-event_label">
								<Assignment className="mr10" />
								<span className='label'>{label}</span>
								{type?.isVisio && <span className='icon'><VisioIcon /></span>}
							</Typography>
						</CardContent>
					</Card>
					{/* List of duplicated events */}
					<Typography variant="h5" className="mt20">{lexique.titleTo}</Typography>
					<div className="duplicate-events flex flex-c mt10">
						{
							duplicates.map(e => <PreviewEvent
													key={e.id}
													{...e}
													onRemove={this.onRemove(e.id)}
													config={config}
													lexique={lexique}
												/>
										)
						}
					</div>
				</DialogContent>
				{/* Actions */}
				<DialogActions>
					{ complete && <Button onClick={this.cancelDuplicateMode} color="primary">
						{lexique.close}
					</Button> }
					{!loading && !complete && <Fragment>
						<Button onClick={this.onClickCancel} color="primary">
							{lexique.discard}
						</Button>
						<Button onClick={this.onClickConfirm} color="primary" variant="contained" size="large" disableElevation>
							{lexique.confirm}
						</Button>
					</Fragment> }
				</DialogActions>
			</Fragment>
		)
	}
}

const PreviewEvent = ({start, config, status, onRemove}) => (
	<Paper className="duplicate-event flex aic">
		<Typography color="textSecondary" className="flex1">
			{ moment(start).format(config.datetimeFormatLong) }
		</Typography>
		{(!status || status === '') && <IconButton onClick={onRemove}><Delete /></IconButton>}
		{status === 'loading' && <CircularProgress size={21} />}
		{status === 'success' && <CheckCircle nativeColor='#43a047' />}
		{status === 'error' && <Error nativeColor='#f44336' />}
	</Paper>
)

const mapStateToProps = ({env, ui, appointment, plannings}) => ({
	lexique: getLexique(env.locale).event.duplicate,
	config: getLexique(env.locale).config,
	loading: ui.duplicateMode.loading,
	event: appointment.event,
	duplicates: appointment.duplicates,
	selectedPlanning: plannings.selected,
	appointmentsTypes : plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes : [],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeDuplicationDrawer, removeDuplicateEvent, exitDuplicationMode, openModaleConfirmDiscardDuplicates, confirmDuplicateAppointments }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicationPanel);
