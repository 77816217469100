import React, { useCallback, memo } from 'react';
import { makeStyles, ButtonBase, IconButton, CircularProgress } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
// import Video from 'twilio-video';
import cn from "classnames";

import LocalVideoPreview from './LocalVideoPreview';
import SettingsMenu from './ui/SettingsMenu';
import ToggleAudioButton from './ui/ToggleAudioButton';
import ToggleVideoButton from './ui/ToggleVideoButton';
// import PreflightTest from './PreflightTest';

import { useGetToken, useVideoContext } from './hooks';
import { useLexique } from '../../locales';
import { closeVisio } from '../../actions/ui';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    joinContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    localPreviewContainer: {
        position: 'relative',
        border: '1px solid #707070',
        width: '50%',
        maxWidth: '50%',
        minWidth: '50%',
        [theme.breakpoints.down('md')]: {
            width: '75%',
            maxWidth: '75%',
            minWidth: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            minWidth: '100%',
        }
    },
    videoControls: {
        position: 'absolute',
        bottom: 4,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
    },
    joinRoom: {
        marginLeft: 24,
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            paddingRight: 16,
            paddingLeft: 16,
            marginLeft: 0,
            marginTop: 48
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        }
    },
    joinButton: {
        position: 'relative',
        backgroundColor: '#00701a',
        padding: 24,
        borderRadius: 30,
        color: '#fff',
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        transition: 'all 0.2s ease-out',
    },
    joinLoading: {
        paddingRight: 48,
    },
    disabled: {
        backgroundColor: '#707070',
    },
    joinLoad: {
        position: 'absolute',
        color: '#fff',
        top: 19,
        right: 12,
    },
    close: {
        position: 'absolute',
        color: '#fff',
        top: 0,
        right: 0,
    }
}));

const DeviceSelectionScreen = ({ planningId, eventId }) => {
    const classes = useStyles();
    const [getToken, isFetching] = useGetToken();
    const { connect, isAcquiringLocalTracks, isConnecting, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();
    const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

    const showLoader = isFetching || isConnecting;

    const dispatch = useDispatch();

    const _onCloseVisio = useCallback(() => {
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        dispatch(closeVisio());
    }, [removeLocalAudioTrack, removeLocalVideoTrack, dispatch]);

    const lexique = useLexique('visio');

    const handleJoin = () => {
        getToken(planningId, eventId).then(token => connect(token));
    };

    return (
        <div className={classes.joinContainer}>
            <div className={classes.localPreviewContainer}>
                <LocalVideoPreview className={classes.myVideo} />
                <SettingsMenu />
                <div className={classes.videoControls}>
                    <ToggleAudioButton disabled={disableButtons} />
                    <ToggleVideoButton disabled={disableButtons} />
                </div>
            </div>
            <div className={classes.joinRoom}>
                <ButtonBase
                    className={cn(classes.joinButton, showLoader && classes.joinLoading, isAcquiringLocalTracks && classes.disabled)}
                    onClick={handleJoin}
                    disabled={disableButtons}
                >
                    {lexique.joinRoom}
                    {showLoader && <CircularProgress className={classes.joinLoad} size={25} />}
                </ButtonBase>
                {/* {Video.testPreflight && <PreflightTest />} */}
            </div>
            <IconButton className={classes.close} onClick={_onCloseVisio}>
                <CloseIcon />
            </IconButton>
        </div>
    );
}

export default memo(DeviceSelectionScreen)