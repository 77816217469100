import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import getLexique from '../../locales';
import routes from '../../config/routes';

class UnreadMessages extends PureComponent {

	render() {
		const { lexique, useContactForm, count } = this.props;

        if (!useContactForm) return null;

		return (
			<Paper className="dashboard_widget pad20 tac">
                <Typography variant="subtitle1" className="widget_title">{lexique.title}</Typography>
                <Typography variant="h1" color="primary" className="widget_number">{count}</Typography>
                <Button component={Link} to={routes.inbox} color="primary" className="mt10">{lexique.cta}</Button>
			</Paper>
		);
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).dashboard.unreadMessages,
    useContactForm: plannings[plannings.selected] && plannings[plannings.selected].env && plannings[plannings.selected].env.contactForm,
	count: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.unread : 0,
});

export default connect(mapStateToProps)(UnreadMessages);
