import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDevice } from '../../actions/ui';

class DeviceChecker extends PureComponent {

	componentDidMount() {
		window.addEventListener('resize', this.onWindowResize);
		this.onWindowResize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
	}

	getDeviceFromWidth = wi => {
		switch (wi) {
			case 6:
				return "desktop";
			case 5:
				return "desktop-small";
			case 4:
				return "tablet-landscape";
			case 3:
				return "tablet-portrait";
			default:
			case 2:
				return "mobile";
		}
	}

	onWindowResize = () => {
		if (!this.ref) return;

		const { device, actions:{setDevice}} = this.props;

		const style = window.getComputedStyle(this.ref);
		const wi = parseInt(style.width, 10);
		const newDeviceType = this.getDeviceFromWidth(wi);

		if (newDeviceType !== device) {
			setDevice(newDeviceType);
		}
	}

	render() {
		return <div id="resize-size-checker" ref={ref => this.ref = ref} />;
	}
}

const mapStateToProps = ({ui:{device}}) => ({
	device: device.type,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({setDevice}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceChecker);
