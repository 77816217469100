import React from 'react';

export default {
	noInternet: `Connexion...`,
	api: {
		title: `Oups...`,
		body: `Il semblerait que l'application ait rencontré un problème, veuillez recharger la page ou fermer cette fenêtre pour réinitialiser votre application

Si le problème persiste, veuillez essayer mettre à jour votre navigateur, ou de changer pour un navigateur plus récent (Chrome, Firefox, Edge)`,
		close: `Recharger l'application`
	},
	update: {
		title: `Nouvelle version disponible`,
		body: `Vous utilisez une version obsolète de l'application, veuillez recharger l'application pour la mettre à jour.

Si ce message persiste, veuillez recharger l'application en vidant vos caches internet, en appuyant simultanément sur les touches CTRL+SHIFT+R (CMD+SHIFT+R sur Mac) ou CTRL+F5 (CMD+F5 sur Mac)`,
		close: `Recharger l'application`
	},
	maintenance: {
		title: `Maintenance en cours`,
		body: `L'application est actuellement en cours de maintenance. Veuillez réessayer ultérieurement.

Message d'information :`,
},
	outdated: {
		title: `Votre navigateur est obsolète`,
		body: `Le navigateur internet que vous utilisez est obsolète. Cette application risque de ne pas fonctionner correctement`,
		whatToDo: () => (<div>Nous vous recommandons de mettre à jour votre navigateur, ou de télécharger et d'utiliser un navigateur récent, tel que : <br />
- <a href="https://www.google.com/chrome/" rel="noopener noreferrer">Google Chrome</a><br />
- <a href="https://www.mozilla.org/fr/firefox/new" rel="noopener noreferrer">Mozilla Firefox</a><br />
- <a href="https://www.microsoft.com/fr-fr/windows/microsoft-edge" rel="noopener noreferrer">Microsoft Edge</a><br />
<br />
Veuillez noter qu'Internet Explorer (ie) n'est plus supporté, Microsoft ayant annoncé sa fin de vie pour le remplacer par Edge
</div>),
	detected: ({name, os, version}) => (<div>
		<em>Votre configuration détectée :</em><br />
		<strong>Navigateur :</strong> {name}<br />
		<strong>Version :</strong> {version} {getMinimumVersion({name})}<br />
		<strong>Système d'exploitation :</strong> {os}<br />
</div>),
	close: `Fermer`,
	}
}

const getMinimumVersion = ({name}) => {
	if (name === "chrome") return " (Requiert version 60 ou plus)";
	if (name === "firefox") return " (Requiert version 60 ou plus)";
	if (name === "safari") return  "(Requiert version 10 ou plus)";
	if (name === "edge") return " (Requiert version 16 ou plus)";

	return null;
}
