import React, { memo } from 'react';
import _sortBy from 'lodash.sortby';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import Desktop from './list/Desktop';

const MessagesList = memo(({active, messages}) => {
	const getListFromTab = () => {
		const sorted = _sortBy(messages, [o => moment(o.sentAt).unix()]).reverse();

		if (active === 'emails') return sorted.filter(m => m.state === 'active');
		if (active === 'archived') return sorted.filter(m => m.state === "archived");
		if (active === 'deleted') return sorted.filter(m => m.state === "deleted");

		return sorted;
	}

	const displayMessages = getListFromTab();

	// Someday if we want to use a real mobile design.... :)
	//return isMobileDisplay ? null : <Desktop messages={displayMessages} />
	return  <Desktop messages={displayMessages} />
});


const mapStateToProps = ({ui, plannings}) => ({
	isMobileDisplay: ui.device.isMobileDisplay,
	messages: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.messages : [],
	active: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.active : '',
});

export default connect(mapStateToProps)(MessagesList);
