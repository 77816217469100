import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getLexique from '../../locales';
import { selectWebsite } from '../../actions/websites';

class WebsiteChooser extends PureComponent {

	componentDidMount() {
		const { websites, actions: {selectWebsite}} = this.props;
		if (websites.length !== 1) return;

		const [website] = websites;
		selectWebsite(website.id);
	}

	render() {
		const { websites } = this.props;
		if (websites.length <= 1) return null;


		return (
			<div className="websites_choose">

			</div>
		)
	}
}


const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).websites,
	websites: plannings[plannings.selected] ? (plannings[plannings.selected].websites || []) : [],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ selectWebsite }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteChooser);
