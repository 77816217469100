import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Message from '../ui/Message';
import Markdown from '../ui/Markdown';

import { closeModaleConfirmDeleteAppointment } from '../../actions/ui';

import getLexique from '../../locales';
import { userName } from '../../libs/formatters';

class ConfirmDeleteAppointment extends PureComponent {

	onClose = () => {
		this.props.actions.closeModaleConfirmDeleteAppointment();
	}

	onConfirm = () => {
		const { onConfirm } = this.props;
		this.onClose();
		onConfirm();
	}

	renderInner() {
		const { lexique, config, event, isMobile } = this.props;

		if (!event) return <div />;

		const date = moment(event.start).format(config.datetimeFormatLong);
		const user = event.with.id && event.with.id !== '' ? userName(event.with) : event.with.lastName;

		return (
			<Fragment>
				<DialogTitle id="modale-delete-appointment-dialog-title">
					{lexique.title}
				</DialogTitle>
				<DialogContent>
					<Message level="error" show>
						<Markdown>
							{lexique.body.replace('{0}', date).replace('{1}', user)}
						</Markdown>
					</Message>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.onClose} color="primary">
						{lexique.cancel}
					</Button>
					<Button onClick={this.onConfirm} variant="contained" color="primary" className="button--danger" disableElevation>
						{lexique[isMobile ? 'confirmShort' : 'confirm']}
					</Button>
				</DialogActions>
			</Fragment>
		)
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				onClose={this.onClose}
				aria-labelledby="modale-delete-appointment-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{device:{isMobile}, modales:{confirmDeleteAppointment:{show, onConfirm, event}}}}) => ({
	lexique: getLexique(env.locale).modales.confirmDeleteAppointment,
	config: getLexique(env.locale).config,
    show,
	onConfirm,
	event,
	isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleConfirmDeleteAppointment }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteAppointment);
