import { useEffect, useRef, memo } from 'react';
import { useLocalVideoToggle, useVideoContext, isMobile } from './hooks';

const AttachVisibilityHandler = () => {
    const { room } = useVideoContext();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const shouldRepublishVideoOnForeground = useRef(false);

    useEffect(() => {
        if (isMobile) {
            const handleVisibilityChange = () => {
                // We don't need to unpublish the local video track if it has already been unpublished
                if (document.visibilityState === 'hidden' && isVideoEnabled) {
                    shouldRepublishVideoOnForeground.current = true;
                    toggleVideoEnabled();

                    // Don't publish the local video track if it wasn't published before the app was backgrounded
                } else if (shouldRepublishVideoOnForeground.current) {
                    shouldRepublishVideoOnForeground.current = false;
                    toggleVideoEnabled();
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);
            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, [isVideoEnabled, room, toggleVideoEnabled]);

    return null;
}

export default memo(AttachVisibilityHandler);