import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _isEqual from 'lodash.isequal';
import _sortBy from 'lodash.sortby';

import Button from '@material-ui/core/Button';
import AddCircleOutline from '@material-ui/icons/AddCircleOutlined';
import Schedule from '@material-ui/icons/Schedule';

import AppointmentType from './AppointmentType';

import getLexique from '../../locales';

import { openModaleAddEditAppointmentType, closeModaleAddEditAppointmentType, updateModaleAddEditAppointmentType, openModaleEditAppointmentProfile, openModaleEditAppointmentSpecificSchedule, openModaleDeleteAppointmentType } from '../../actions/ui';
import { createAppointmentType, updateAppointmentType, updateAppointmentTypeProfile, addAppointmentTypeSpecificSchedule, updateAppointmentTypeSpecificSchedule, deleteAppointmentType, deleteAppointmentTypeSpecificSchedule, addGlobalSpecificShedule } from '../../actions/planning';

class AppointmentsTypes extends PureComponent {

	static contextTypes = {
		swipeableViews: PropTypes.object.isRequired,
	};

	openModaleAdd = () => {
		this.props.actions.openModaleAddEditAppointmentType({
			appointment: null,
			onConfirm: this.onAddAppointmentType
		});
	}

	componentDidUpdate = oldProps => {
		if (!_isEqual(oldProps.appointmentsTypes, this.props.appointmentsTypes)) this.context.swipeableViews.slideUpdateHeight();
	}

	onAddAppointmentType = async datas => {

		const { selectedPlanning, actions: { updateModaleAddEditAppointmentType, createAppointmentType, closeModaleAddEditAppointmentType } } = this.props;

		updateModaleAddEditAppointmentType({ loading: true });

		await createAppointmentType({
			planningId: selectedPlanning,
			...datas
		});

		updateModaleAddEditAppointmentType({ loading: false });

		closeModaleAddEditAppointmentType();
	}

	openModaleDeleteAppointment = a => () => {
		this.props.actions.openModaleDeleteAppointmentType({
			appointment: a,
			onConfirm: this.onDeleteAppointment(a.id)
		});
	}

	onDeleteAppointment = id => async () => {
		const { selectedPlanning, actions: { deleteAppointmentType } } = this.props;
		deleteAppointmentType({
			planningId: selectedPlanning,
			appointmentTypeId: id,
		});
	}

	openModaleEditAppointment = a => () => {
		this.props.actions.openModaleAddEditAppointmentType({
			appointment: a,
			onConfirm: this.onEditAppointment(a.id)
		});
	}

	onEditAppointment = id => async datas => {
		const { selectedPlanning, actions: { updateModaleAddEditAppointmentType, updateAppointmentType, closeModaleAddEditAppointmentType } } = this.props;

		updateModaleAddEditAppointmentType({ loading: true });
		await updateAppointmentType({
			planningId: selectedPlanning,
			appointmentTypeId: id,
			...datas
		});
		updateModaleAddEditAppointmentType({ loading: false });
		closeModaleAddEditAppointmentType();
	}

	openModaleEditAppointmentProfile = a => () => {
		this.props.actions.openModaleEditAppointmentProfile({
			appointment: a,
			onConfirm: this.onEditAppointmentProfile(a.id)
		});
	}

	onEditAppointmentProfile = id => datas => {
		const { selectedPlanning, actions: { updateAppointmentTypeProfile } } = this.props;

		updateAppointmentTypeProfile({
			planningId: selectedPlanning,
			appointmentTypeId: id,
			profile: datas,
		});
	}

	openModaleEditAppointmentSpecificSchedule = a => id => () => {
		this.props.actions.openModaleEditAppointmentSpecificSchedule({
			appointment: a,
			specificSchedule: id ? a.specificSchedules.find(v => v.id === id) : null,
			onConfirm: this.onEditAppointmentSpecificSchedule({
				appointmentTypeId: a.id,
				specificScheduleId: id,
			})
		});
	}

	openModaleAddGlobalSpecificSchedule = () => {
		this.props.actions.openModaleEditAppointmentSpecificSchedule({
			appointment: "global",
			isForAll: true,
			specificSchedule: null,
			onConfirm: this.onAddGlobalSpecificSchedule
		});
	}

	onEditAppointmentSpecificSchedule = ids => datas => {
		const { selectedPlanning, actions: { updateAppointmentTypeSpecificSchedule, addAppointmentTypeSpecificSchedule } } = this.props;

		if (ids.specificScheduleId === null) {
			addAppointmentTypeSpecificSchedule({
				planningId: selectedPlanning,
				...ids,
				...datas,
			});
			return;
		}

		updateAppointmentTypeSpecificSchedule({
			planningId: selectedPlanning,
			...ids,
			...datas,
		});
	}

	onAddGlobalSpecificSchedule = async datas => {
		updateModaleAddEditAppointmentType({ loading: true });
		await this.props.actions.addGlobalSpecificShedule(datas);
		updateModaleAddEditAppointmentType({ loading: false });
	}

	onDeleteSpecificSchedule = a => id => () => {
		const { selectedPlanning, actions: { deleteAppointmentTypeSpecificSchedule } } = this.props;

		deleteAppointmentTypeSpecificSchedule({
			planningId: selectedPlanning,
			appointmentTypeId: a.id,
			specificScheduleId: id,
		});
	}

	render() {
		const { lexique, appointmentsTypes } = this.props;
		return (
			<div className="appointment-types">
				<div className="appointment-types-topbar">
					<Button color="secondary" onClick={this.openModaleAddGlobalSpecificSchedule} variant="contained" disableElevation>
						<Schedule className="mr10" />
						{lexique.addShechedule}
					</Button>
					<Button color="primary" onClick={this.openModaleAdd} variant="contained" disableElevation>
						<AddCircleOutline className="mr10" />
						{lexique.add}
					</Button>
				</div>
				<div className="appointment-types-container flex pad10 fw-w">
					{
						_sortBy(appointmentsTypes, ['label']).map(a => (
							<AppointmentType
								key={a.id}
								appointment={a}
								onDelete={this.openModaleDeleteAppointment(a)}
								onEdit={this.openModaleEditAppointment(a)}
								onEditProfile={this.openModaleEditAppointmentProfile(a)}
								onEditSpecificSchedule={this.openModaleEditAppointmentSpecificSchedule(a)}
								onDeleteSpecificSchedule={this.onDeleteSpecificSchedule(a)}
							/>
						))
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.appointmentsTypes,
	selectedPlanning: plannings.selected,
	appointmentsTypes: plannings[plannings.selected] ? plannings[plannings.selected].appointmentsTypes || [] : [],
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ openModaleDeleteAppointmentType, openModaleAddEditAppointmentType, updateModaleAddEditAppointmentType, closeModaleAddEditAppointmentType, createAppointmentType, updateAppointmentType, updateAppointmentTypeProfile, openModaleEditAppointmentProfile, openModaleEditAppointmentSpecificSchedule, addAppointmentTypeSpecificSchedule, updateAppointmentTypeSpecificSchedule, deleteAppointmentType, deleteAppointmentTypeSpecificSchedule, addGlobalSpecificShedule }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsTypes);
