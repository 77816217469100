import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import getLexique, { _lp } from '../../locales';
import routes from '../../config/routes';

class usersToAccept extends PureComponent {

	render() {
		const { lexique, configuration, usersToAccept } = this.props;

        if (!configuration) return null;
        if (!configuration.shouldAcceptUser) return null;

		return (
			<Paper className="dashboard_widget pad20 tac">
                <Typography variant="subtitle1" className="widget_title">{_lp(lexique.title)}</Typography>
                <Typography variant="h1" color="primary" className="widget_number">{usersToAccept.length}</Typography>
                <Button component={Link} to={routes.users} color="primary" className="mt10">{_lp(lexique.cta)}</Button>
			</Paper>
		);
	}
}


const mapStateToProps = ({env, ui, plannings}) => ({
	lexique: getLexique(env.locale).dashboard.usersToConfirm,
	isMobile: ui.device.isMobile,
	configuration: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration : null,
	usersToAccept: plannings[plannings.selected] && plannings[plannings.selected].usersToAccept ? plannings[plannings.selected].usersToAccept : [],
});

export default connect(mapStateToProps)(usersToAccept);
