import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash.isequal';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import Check from '@material-ui/icons/Check';

import ButtonCircularProgress from '../ui/ButtonCircularProgress';

import getLexique, { _lp } from '../../locales';
import { updatePlanningConfig } from '../../actions/planning';

class Planning extends PureComponent {

	state = { loading: false, complete: false, notificationsEmail: '', notificationEmailOnAdd: false, notificationEmailOnCancel: false };

	mapPropsToInnerState = () => {
		if (!this.props.configuration || Object.keys(this.props.configuration).length === 0) return;

		this.setState({
			...this.props.configuration,
		});
	}

	componentDidMount() {
		if (this.props.configuration) this.mapPropsToInnerState();
	}

	componentDidUpdate(oldProps) {
		if (!_isEqual(oldProps.configuration, this.props.configuration)) this.mapPropsToInnerState();
	}

	handleChange = field => ({target:{value}}) => this.setState({[field]: value});
	handleChecked = field => ({target:{checked}}) => this.setState({[field]: checked});

	submitForm = async () => {
		const { selectedPlanning, actions:{updatePlanningConfig}} = this.props;
		this.setState({loading: true});

		await updatePlanningConfig({
			planningId: selectedPlanning,
			...this.state,
		});

		this.setState({loading: false, complete: true});
		setTimeout(() => this.setState({complete: false}), 3000);
	}

	render() {
		const { lexique } = this.props;
		const { loading, complete, notificationsEmail, notificationEmailOnAdd, notificationEmailOnCancel } = this.state;

		return (
			<div className="planning-configuration-container">
				<div className="form-row">
					<Typography component='div' variant="body1">{lexique.labels.notificationsEmail}</Typography>
					<TextField
						disabled={loading}
						value={notificationsEmail}
						onChange={this.handleChange('notificationsEmail')}
						type="text"
					/>
				</div>
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">
						{_lp(lexique.labels.notificationEmailOnAdd)}
					</Typography>
					<Switch
						checked={notificationEmailOnAdd}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('notificationEmailOnAdd')}
					/>
				</div>
				<div className="form-row form-row--switch">
					<Typography component='div' variant="body1">
						{_lp(lexique.labels.notificationEmailOnCancel)}
					</Typography>
					<Switch
						checked={notificationEmailOnCancel}
						disabled={loading}
						color="primary"
						onChange={this.handleChecked('notificationEmailOnCancel')}
					/>
				</div>
				<div className="form-row">
					<div />
					<div>
						<Button
							disabled={loading}
							variant="contained"
							className="button--success"
							onClick={this.submitForm}
							disableElevation
						>
							{lexique.save}
							{loading && <ButtonCircularProgress />}
							{complete && <Check className="ml10" />}
						</Button>
					</div>
				</div>
				<div className="form-row" />
			</div>
		);
	}
}

const mapStateToProps = ({env, plannings}) => ({
	lexique: getLexique(env.locale).settings.notifications,
	selectedPlanning: plannings.selected,
	configuration: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updatePlanningConfig }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
