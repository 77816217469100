import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import { updateStoreAppointment } from '../../actions/appointment';

class Timer extends PureComponent {

	componentDidMount() {
		this.interv = setInterval(this.checkAppointments, 5*60*1000);
	}

	componentWillUnmount() {
		clearInterval(this.interv);
	}

	checkAppointments = () => {
		const { currentPlanning, selectedPlanning, actions:{updateStoreAppointment}} = this.props;
		if (!currentPlanning) return;
		const today = moment().format('YYYY-MM-DD')

		const days = currentPlanning.days[today];
		if (!days) return;

		const appointments = days.appointments;
		if (!appointments) return;

		const now = moment();
		appointments.forEach(a => {
			if (a.isPast) return;
			if (a.typeId === "blocked") return;

			if (moment(a.start).isBefore(now)) {
				updateStoreAppointment({
					planningId: selectedPlanning,
		            day: today,
		            id: a.id,
		            event: {
		                ...a,
		                isPast: true,
		            }
				})
			}
			return;
		})
	}

	render() {
		return null;
	}
}


const mapStateToProps = ({plannings}) => ({
	currentPlanning: plannings[plannings.selected],
	selectedPlanning: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ updateStoreAppointment }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
