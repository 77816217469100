export default {
	title: `Identifiez-vous`,
	email: `Votre adresse email`,
	password: `Votre mot de passe`,
	login: `Se connecter`,
	forgotten: {
		button: `Mot de passe oublié ?`,
		title: `Réinitialisez votre mot de passe`,
		description: `Renseignez votre adresse email pour réinitialiser votre mot de passe`,
		send: `Réinitialiser mon mot de passe`,
		sendOk: `Un email vient de vous être envoyé avec un lien permettant de réinitialiser votre mot de passe. Attention, ce lien n'est valable qu'une heure.`,
		gotIt: `Ok !`,
	},
	cgu: {
		title: `Veuillez lire et accepter les Conditions générales d'utilisation pour pouvoir utiliser le service`,
		accept: `J'accepte les conditions d'utilisation`,
	},
	errors: {
		emailEmpty: `Veuillez renseigner votre adresse email`,
		passwordEmpty: `Veuillez renseigner votre mot de passe`,
		'403': `Désolé, cette adresse email est inexistante dans notre base de données`,
		'403-login': `Votre Email et/ou mot de passe est invalide`,
		emailInvalid: `Cette adresse email est invalide`,
	},
	createAccount: `Vous n'avez pas de compte ? Créez-en un maintenant !`,
}
