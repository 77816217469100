import React, { memo, useMemo } from 'react';
import Publication from './Publication';
import {usePublications} from '../hooks';


const ParticipantTracks = ({
    participant,
    videoOnly,
    isLocalParticipant,
}) => {
    const publications = usePublications(participant);

    const filteredPublications = useMemo(() => publications.filter(p => !p.trackName.includes('screen')), [publications]);

    return (
        <>
            {filteredPublications.map(publication => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    participant={participant}
                    isLocalParticipant={isLocalParticipant}
                    videoOnly={videoOnly}
                    // videoPriority={videoPriority}
                />
            ))}
        </>
    );
}

export default memo(ParticipantTracks)