import 'moment/locale/fr';
import _get from 'lodash.get';
//import 'moment/locale/en-gb';

import fr_FR from './fr-FR';
//import en_GB from './en-GB';

import { getStore } from '../config/store';
import { useSelector } from '../libs/redux';

const lexique = {
	'fr-FR': fr_FR,
	//'en-GB': en_GB,
};
const defaultLanguage = "fr-FR";

export default locale => lexique[locale] ? lexique[locale] : lexique[defaultLanguage];

export const useLexique = prefix => {
	const locale = useSelector(({env}) => env.locale);
	return _get(lexique, `${locale}.${prefix}`);
}

export const useLp = t => {
	const wording = useSelector(({plannings}) => plannings[plannings.selected]?.env?.patient);

	if (!t) return '';

	return t.replace(new RegExp('{p}', 'g'), wording || 'patient');
}

export const _lp = t => {
	if (!t) return '';

	const { plannings } = getStore();
	const p = getPatientValue(plannings);
	const pM = `${p.charAt(0).toUpperCase()}${p.slice(1)}`;

	return t.replace(new RegExp('{p}', 'g'), p).replace(new RegExp('{P}', 'g'), pM);
}

export const getPatientValue = plannings => {
	if (!plannings) return '';
	const selectedPlanning = plannings.selected;
	if (!selectedPlanning || selectedPlanning === '') return ''
	const planning = plannings[selectedPlanning];
	if (!planning) return '';
	if (!planning.env) return '';
	return planning.env.patient;
}
