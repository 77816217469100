import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from 'react-tippy';
import nl2br from 'react-nl2br';

import Person from '@material-ui/icons/Person';
import Pets from '@material-ui/icons/Pets';
import PersonOutline from '@material-ui/icons/PersonOutlined';
import Assignment from '@material-ui/icons/Assignment';
import Note from '@material-ui/icons/Note';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import VisioIcon from '@material-ui/icons/VideocamOutlined';

import { getAFIcon } from '../../config/theme';
import { userName } from '../../libs/formatters';
import getLexique from '../../locales';

class Event extends Component {

    render() {
        const {planningConf, event, config, appointmentsAdditionalFields, appointmentsTypes, isTouch, displayHour} = this.props;
        const { start, duration, with:{isAnimal, id:userId, main}, created, typeId} = event;


		const appointmentType = appointmentsTypes.find(a => a.id === typeId);

        const m = moment(displayHour || start);

        const renderIcon = () => {
            if (!userId) return null;

            if (created.byUser) return isAnimal ? <Pets /> : <Person />;

            return isAnimal ? <Pets className="ico--stroked" /> : <PersonOutline />;
		}
		
		const renderVisio = () => {
			if (!appointmentType?.isVisio) return;
			return <VisioIcon />
		}

    	const renderTooltip = () => {
    		return <div className="event_tooltip">
    			<Typography variant="caption" className="caption" paragraph>
    				{m.format(config.dateFormatLong)}, {m.format(config.timeFormat)} - {moment(start).add(duration, "minutes").format(config.timeFormat)}
    			</Typography>

    			<Typography variant="h5" className="caption flex ait title" paragraph={!isAnimal}>
    				{ renderIcon() } <span className="ml5">{userName(event.with)}</span>
    			</Typography>

    			{isAnimal && main && <Typography variant="h6" className="caption flex ait title" paragraph>
    				{userName(main)}
    			</Typography>}

    			{appointmentType && <Typography component="div" className="caption flex ait" paragraph>
    				<Assignment className="mr10" />
    				<span>{appointmentType.label}</span>
    			</Typography>}

    			{appointmentsAdditionalFields.map((aaf, i) => {
    				const field = event[`additionalField${i}`];
    				if (!field || field === "") return null;
    				if (!aaf || aaf.label === '') return null;

    				const Icon = getAFIcon(aaf.icon);

    				return <div className="event_popover_add-field" key={aaf.label}>
    					<Typography component="div" className="caption flex ait" paragraph>
    						<Icon className="mr10" />
    						<span className="label">{aaf.label}</span><span>{field}</span>
    					</Typography>
    				</div>
    			})}

    		</div>
    	}

        const renderNoteIcon = () => {
            if (event.information && event.information.admin && event.information.admin !== "") {
                return <Tooltip touchHold html={<span>{nl2br(event.information.admin)}</span>}>
                    <Note />
                </Tooltip>
            }

            return null;
        }

        const renderInfoIcon = () => {
            if (event.information && event.information.user && event.information.user !== "") {
                return <Tooltip touchHold html={<span>{nl2br(event.information.user)}</span>}>
                    <InfoOutlined />
                </Tooltip>
            }

            return null;
        }

    	const renderSlot = () => {
    		return <div className="event_body flex jc-c">
    			<div className="event_body_name">
    				<div className="flex aic">
						{ renderVisio() }
						{ renderIcon() }
    					<Typography variant="body2" noWrap>{isAnimal && main ? userName(main) : userName(event.with)}</Typography>
                        { renderInfoIcon() }
                        { renderNoteIcon() }
    				</div>
    			</div>
    			<div className="event_body_time">{m.format(config.timeFormat)}</div>
    		</div>
    	}

    	if (typeId === "blocked" || !planningConf.displayEventTooltip || isTouch) return renderSlot();

    	return (
    		<Tooltip touchHold html={renderTooltip()}>
    			{renderSlot()}
    		</Tooltip>
    	)
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.event.id !== this.props.event.id || nextProps.displayHour !== this.props.displayHour;
    }
}


const mapStateToProps = ({env, plannings, ui}) => ({
	lexique: getLexique(env.locale).login,
    config: getLexique(env.locale).config,
	appointmentsAdditionalFields: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.appointmentsAdditionalFields : [],
	planningConf: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration : {},
	appointmentsTypes: plannings[plannings.selected] && plannings[plannings.selected] && plannings[plannings.selected].appointmentsTypes,
	isTouch: ui.device.isTouch,
});

export default connect(mapStateToProps)(Event);
