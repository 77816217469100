import React, { memo, useCallback } from 'react';
import { TextField, Button, FormGroup, FormControlLabel, Checkbox, Backdrop, LinearProgress } from '@material-ui/core';
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';
import cn from 'classnames';

import { useLexique } from '../../locales';
import { useCoolState } from '../../hooks/react';
import { useDispatch } from '../../libs/redux';
import { useProfile, usePlanning } from '../../hooks/planning';
import { registerToPayments } from '../../actions/planning';

import Alert from '../ui/Alert';
import Markdown from '../ui/Markdown';

const RegisterToPayments = () => {

    const lexique = useLexique('payments.unregistered');

    return (
        <div>
            <Alert severity="info" title={lexique.title} md content={lexique.body} />
            <RegisterForm />
        </div>
    )

}


const ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    style: {
      base: {
        fontSize: '24px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
};
const RegisterForm = memo(() => {

    const [state, setState] = useCoolState({
        dob: "",
        address: "",
        city: "",
        postalCode: "",
        email: '',
        accept: false,
        accept2: false,
        errors: [],
        loading: false,
    });

    const dispatch = useDispatch();

    const stripe = useStripe();
    const elements = useElements();

    const profile = useProfile();
    const [planning] = usePlanning();

    const lexique = useLexique('payments.unregistered.form')

    const onChange = useCallback((key, value) => setState({[key]: value}), [setState]);


    const onSubmitRegister = useCallback(async () => {

        if (!stripe || !elements) return null;

        setState({
            loading: true,
            errors: []
        });

        let errors = [];

        const ibanElement = elements.getElement(IbanElement);
        const ibanToken = await stripe.createToken(ibanElement, {
            currency: 'eur',
            account_holder_name: `${profile.firstName} ${profile.lastName}`,
            account_holder_type: 'individual',
        });
        if (!ibanToken.token) {
            errors.push('iban')
        }

        if (state.dob.trim() === "") errors.push('dob');
        if (state.address.trim() === "") errors.push('address');
        if (state.postalCode.trim() === "") errors.push('postalCode');
        if (state.city.trim() === "") errors.push('city');
        if (state.email.trim() === "") errors.push('email');
        if (!state.accept) errors.push('accept');
        if (!state.accept2) errors.push('accept2');
        
        if (errors.length > 0) {
            setState({errors, loading: false});
            return;
        }

        const [day, month, year] = state.dob.split('/');
        const usePhoneNumber = (profile.phoneNumber.startsWith('0') ? `+33${profile.phoneNumber.slice(1)}` : profile.phoneNumber).replace(/\s/g,'');

        // Create Account Token
        const accountResult = await stripe.createToken('account', {
            business_type: 'individual',
            individual: {
                email: state.email,
                address: {
                    country: 'FR',
                    city: state.city,
                    postal_code: state.postalCode,
                    line1: state.address
                },
                dob: {
                    day: parseInt(day, 10),
                    month: parseInt(month, 10),
                    year: parseInt(year, 10),
                },
                first_name: profile.firstName,
                last_name: profile.lastName,
                phone: usePhoneNumber,
            },
            tos_shown_and_accepted: true,
        });
        
        if (!accountResult.token) {
            setState({errors: ['stripe'], loading: false});
            return;
        }

        const res = await dispatch(registerToPayments({
            tokenAccount: accountResult.token.id,
            tokenIban: ibanToken.token.id,
            planningId: planning.id,
            email: state.email,
        }));

        if (res.error) {
            setState({
                loading: false,
                errors: ['stripe']
            });
        }


    }, [state, stripe, elements, profile, setState, planning.id, dispatch]);

    return (
        <div className="payments_unregistered_splitter">
            <div className='payments_form'>
                <Input
                    disabled
                    label={lexique.firstName}
                    value={profile.firstName}
                />
                <Input
                    disabled
                    label={lexique.lastName}
                    value={profile.lastName}
                />
                <Input
                    id="email"
                    label={lexique.email}
                    value={state.email}
                    onChange={onChange}
                    error={state.errors.includes('email')}
                />
                <Input
                    id="dob"
                    label={lexique.dob}
                    value={state.dob}
                    onChange={onChange}
                    placeholder='JJ/MM/AAAA'
                    error={state.errors.includes('dob')}
                />
                <Input
                    id="address"
                    label={lexique.address}
                    value={state.address}
                    onChange={onChange}
                    error={state.errors.includes('address')}
                />
                <div className="payments_city">
                    <Input
                        id="postalCode"
                        label={lexique.postalCode}
                        value={state.postalCode}
                        onChange={onChange}
                        className="payments_city_postal"
                        error={state.errors.includes('postalCode')}
                    />
                    <Input
                        id="city"
                        label={lexique.city}
                        value={state.city}
                        onChange={onChange}
                        error={state.errors.includes('city')}
                    />
                </div>
            </div>
            <div className="payments_iban_confirm">
                <div className={cn('payments_iban-label', state.errors.includes('iban') && 'error')}>{lexique.iban}</div>
                <IbanElement options={ELEMENT_OPTIONS} />
                <div className='payments_acceptances'>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        className={state.errors.includes('accept') ? 'error' : ''}
                        value="top"
                        control={<Cb checked={state.accept} id="accept" onChange={onChange} />}
                        label={<Markdown>{lexique.accept}</Markdown>}
                        labelPlacement="end"
                    />
                </FormGroup>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        className={state.errors.includes('accept2') ? 'error' : ''}
                        value="top"
                        control={<Cb checked={state.accept2} id="accept2" onChange={onChange} />}
                        label={<Markdown>{lexique.accept2}</Markdown>}
                        labelPlacement="end"
                    />
                </FormGroup>
                </div>
                {state.errors.includes('stripe') && <Alert severity="error" content={lexique.errorStripe} />}
                <div className="payments_submit-container">
                    <Button color="primary" onClick={onSubmitRegister} variant="contained" disableElevation>{lexique.submit}</Button>
                </div>
            </div>
            {state.loading && <Backdrop open className="payment_backdrop-loader">
                    <LinearProgress color="secondary" />
            </Backdrop>}
        </div>
    );
});

const Input = memo(({id, onChange, ...props}) => {
 
    const _onChange = useCallback(({target:{value}}) => onChange(id, value), [id, onChange]);

    return (
        <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            onChange={_onChange}
            {...props}
        />
    )

})

const Cb = memo(({id, checked, onChange}) => {
    const _onChange = useCallback(({target:{checked}}) => onChange(id, checked), [id, onChange]);

    return (
        <Checkbox
            color="primary"
            checked={checked}
            onChange={_onChange}
        />
    )
});

export default memo(RegisterToPayments);