import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';

import AgendaContent from './AgendaContent';
import AgendaHeader from './AgendaHeader';

import { loadPlanningDays } from '../../actions/planning';

class Week extends PureComponent {

    componentDidMount() {
        this.loadDays();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.dateYmd !== this.props.dateYmd) this.loadDays();
    }

    loadDays = () => {
        const daysToLoad = this.getDisplayDay();
        this.props.actions.loadPlanningDays(daysToLoad);
    }

    getDisplayDay = () => {
        const { date, showWeekEnd, showSunday, rollingDays, isMobile } = this.props;

        if (isMobile) return [moment(date).format('YYYY-MM-DD')];

        const days = Array(7).fill().map((_,i) => {
            const day = moment(date).startOf(rollingDays ? 'day' : 'week').add(i, 'days');
            const weekDay = day.weekday();

            if (    weekDay < 5
                    || (weekDay === 5 && showWeekEnd)
                    || (weekDay === 6 && showWeekEnd && showSunday)
            ) return day.format('YYYY-MM-DD');

            return null;
        }).filter(d => d !== null);

        return days;
    }

    render() {
        const { showWeekEnd, showSunday, onSelectSlot, onSelectEvent, onMoveEvent } = this.props;
        const days = this.getDisplayDay();
        const displayDays = !showWeekEnd ? 5 : (showSunday ? 7 : 6);
        return <div className="calendar">
            <AgendaHeader
                days={days}
                displayDays={displayDays}
            />
            <AgendaContent
                days={days}
                onSelectSlot={onSelectSlot}
                onSelectEvent={onSelectEvent}
                onMoveEvent={onMoveEvent}
            />
        </div>
    }
}


const mapStateToProps = ({plannings, ui}, {date}) => ({
	showWeekEnd: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.showWeekEnd || false : false,
	showSunday: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.showSunday || false : false,
    rollingDays: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.rollingDays || false : false,
	dateYmd: moment(date).format('YYYY-MM-DD'),
    isMobile: ui.device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ loadPlanningDays }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Week);
