import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Dashboard from '@material-ui/icons/Dashboard';
import Today from '@material-ui/icons/Today';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import People from '@material-ui/icons/People';
import Email from '@material-ui/icons/Email';
import AccountBox from '@material-ui/icons/AccountBox';
import Settings from '@material-ui/icons/Settings';
import Web from '@material-ui/icons/Web';
import LogsIcon from '@material-ui/icons/ListAlt';
import PaymentIcon from '@material-ui/icons/Payment';

import getLexique, { _lp } from '../../locales';
import routes from '../../config/routes';
import { closeLeftMenu, openLeftMenu } from '../../actions/ui';
import { getUserRightsOnPlanning } from '../../libs/checkers';

import logo from '../../assets/logo-white.png';

import Tooltip from './Tooltip';

const icons = {
	dashboard: Dashboard,
	planning: Today,
	rdvs: AssignmentTurnedIn,
	users: People,
	inbox: Email,
	account: AccountBox,
	settings: Settings,
	websites: Web,
	logs: LogsIcon,
	payments: PaymentIcon
};

class ToolBar extends PureComponent {

	handleCloseMenu = () => {
		const { closeLeftMenu } = this.props.actions;
		closeLeftMenu();
	}

	handleOpenMenu = () => {
		const { openLeftMenu } = this.props.actions;
		openLeftMenu();
	}

	renderItem = item => {
		const { isMobile } = this.props;
		return isMobile ? this.renderItemMobile(item) : this.renderItemDesktop(item);
	}

	renderItemMobile = item => {
		const { lexique, usersToAccept, appointmentsToConfirm, unreadMessages } = this.props;
		const Icon = icons[item];

		let badgeValue = 0;
		if (item === 'users' && usersToAccept.length > 0) badgeValue = usersToAccept.length;
		if (item === 'rdvs' && appointmentsToConfirm.length > 0)  badgeValue = appointmentsToConfirm.length;
		if (item === 'inbox' && unreadMessages > 0) badgeValue = unreadMessages;

		if (badgeValue > 0) {
			return (
				<ListItem onClick={this.handleCloseMenu} component={NavLink} to={routes[item]} key={item} button className="menu_left_item">
					<Badge className="badge--decal-bl badge--nope flex1" badgeContent={badgeValue} color="secondary">
						<ListItemIcon>
							<Icon />
						</ListItemIcon>
						<ListItemText inset primary={_lp(lexique[item])} />
					</Badge>
				</ListItem>
			)
		}

		return (
			<ListItem onClick={this.handleCloseMenu} component={NavLink} to={routes[item]} key={item} button exact className="menu_left_item">
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
				<ListItemText inset primary={_lp(lexique[item])} />
			</ListItem>
		);
	}

	renderItemDesktop = item => {
		const { lexique, usersToAccept, appointmentsToConfirm, unreadMessages } = this.props;
		const Icon = icons[item];

		let badgeValue = 0;
		if (item === 'users' && usersToAccept.length > 0) badgeValue = usersToAccept.length;
		if (item === 'rdvs' && appointmentsToConfirm.length > 0) badgeValue = appointmentsToConfirm.length;
		if (item === 'inbox' && unreadMessages > 0) badgeValue = unreadMessages;

		if (badgeValue > 0) {
			return (
				<Badge key={item} className="badge--decal-bl badge--nope" badgeContent={badgeValue} color="secondary">
					<Tooltip title={_lp(lexique[item])} trigger="mouseenter" position="right">
						<ListItem component={NavLink} to={routes[item]} button className="menu_left_item">
							<ListItemIcon>
								<Icon />
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				</Badge>
			)
		}

		return (
			<Tooltip key={item} title={_lp(lexique[item])} trigger="mouseenter" position="right">
				<ListItem exact component={NavLink} to={routes[item]} button className="menu_left_item">
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
				</ListItem>
			</Tooltip>
		)
	}

	renderContent = () => {
		const { env, configuration, user, selectedPlanning } = this.props;

		const rights = getUserRightsOnPlanning(user, selectedPlanning);

		const firstSection = [
			'planning',
			configuration && configuration.shouldConfirmAppointment && rights.confirmAppointments ? 'rdvs' : null,
			'users'
		].filter(f => f !== null);
		const showInbox = env && env.contactForm === true && rights.inbox;
		const showWebsite = env && env.websites === true && rights.website;
		const showSettings = rights.settings;
		const showAccount = rights.account;
		const showLogs = rights.logs;
		const showPayments = env && env.usePayments === true && rights.payments;

		return (
			<div className="menu-left_content">
				<List>
					{ this.renderItem('dashboard') }
				</List>
				<Divider />
				<List>
					{ firstSection.map(item => this.renderItem(item)) }
				</List>
				<Divider />
				<List>
					{ showInbox && this.renderItem('inbox') }
					{ showSettings && this.renderItem('settings') }
					{ showWebsite && this.renderItem('websites') }
					{ showLogs && this.renderItem('logs') }
				</List>
				{showPayments && <>
					<Divider />
					<List>
						{ this.renderItem('payments') }
					</List>
				</>}
				{ showAccount && <>
					<Divider />
					<List>
						{ this.renderItem('account') }
					</List>
				</>}
			</div>
		)
	}

	render() {
		const { open, logged, isMobile } = this.props;
		if (!logged) return null;

		if (isMobile) return (
			<SwipeableDrawer onOpen={this.handleOpenMenu} open={open} onClose={this.handleCloseMenu} className="menu-left-mobile">
				<div className="menu-left-logo" style={{
					backgroundImage: `url(${logo})`,
				}}>
				</div>
				<Divider />
				{ this.renderContent() }
			</SwipeableDrawer>
		)

		return (
			<Drawer
				variant="permanent"
				className="menu-left-desktop"
			>
				{ this.renderContent() }
			</Drawer>
		);
	}
}


const mapStateToProps = ({env, ui, user, plannings}) => ({
	open: ui.openMenu,
	logged: env.logged,
	isMobile: ui.device.isMobile,
	lexique: getLexique(env.locale).toolbar,
	env: plannings[plannings.selected] && plannings[plannings.selected].env ? plannings[plannings.selected].env : null,
	configuration: plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration : null,
	usersToAccept: plannings[plannings.selected] && plannings[plannings.selected].usersToAccept ? plannings[plannings.selected].usersToAccept : [],
	appointmentsToConfirm: plannings[plannings.selected] && plannings[plannings.selected].appointmentsToConfirm ? plannings[plannings.selected].appointmentsToConfirm : [],
	unreadMessages: plannings[plannings.selected] && plannings[plannings.selected].inbox ? plannings[plannings.selected].inbox.unread : 0,
	user,
	selectedPlanning: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeLeftMenu, openLeftMenu }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolBar));
