import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import Drive from '../loaders/Drive';

import getLexique from '../../locales';
import { closeModaleDrive } from '../../actions/ui';

class DriveContainer extends PureComponent {

	renderInner() {
		const { isMobile, lexique, show, actions } = this.props;

		if (isMobile) return <>
			<DialogContent>
				{ lexique.notAvailableOnMobile }
			</DialogContent>
			<DialogActions>
				<Button onClick={actions.closeModaleDrive}>
					{ lexique.close }
				</Button>
			</DialogActions>
		</>
		if (!show) return <div />;

        return <Drive />
	}

    render() {
		const { show } = this.props;

    	return (
			<Dialog
				open={show || false}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
                maxWidth="lg"
				disableBackdropClick
				disableEscapeKeyDown
				aria-labelledby="modale-drive-dialog-title"
			>
				{ this.renderInner() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env:{locale}, ui:{device:{isMobile}, modales:{drive:{show}}}}) => ({
    show,
	isMobile,
	lexique: getLexique(locale).drive,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleDrive }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DriveContainer);
