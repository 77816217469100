import React, { PureComponent, Fragment, memo } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SwipeableViews from 'react-swipeable-views';
import { Tooltip } from 'react-tippy';
import _sortBy from 'lodash.sortby';

import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import MoreVert from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory';
import Schedule from '@material-ui/icons/Schedule';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Pets from '@material-ui/icons/Pets';
import Close from '@material-ui/icons/Close';

import UserCard from '../users/UserCard';
import ModalTransitionZoom from '../ui/ModalTransitionZoom';
import { PaymentsHeader, PaymentItem } from '../payments/PaymentsManage';

import { closeModaleUserDetails, openModaleConfirmAcceptUser, openModaleConfirmBlockUser } from '../../actions/ui';
import { updateViewDate } from '../../actions/planning';
import { blockUser, acceptUser } from '../../actions/users';

import history from '../../config/history';
import routes from '../../config/routes';

import getLexique, { _lp, useLexique } from '../../locales';
import { userName } from '../../libs/formatters';
import { usePaymentsByUser } from '../../hooks/planning';

class UserDetails extends PureComponent {

	state = { refMenu : null, tab: 0 };

	onClose = () => {
		this.setState({tab: 0});
		this.props.actions.closeModaleUserDetails();
	}

	handleChangeTab = (e, tab) => {
		this.setState({tab});
	}

	closeQuickActionsMenu = () => {
		this.setState({refMenu: null});
	}

	openQuickActionsMenu = e => {
		this.setState({refMenu: e.target});
	}

	onChangeSwipe = tab => this.setState({tab})

	jumpToAppointment = appointment => () => {
		this.props.actions.updateViewDate(moment(appointment.start));
		this.onClose();
		history.push(routes.planning);
	}

	unblockUser = () => {
		const { user: {mainMemberName}, actions:{openModaleConfirmAcceptUser} } = this.props;
		openModaleConfirmAcceptUser({
			familyName: mainMemberName,
			onConfirm: this.onConfirmAccept,
		});
	}

	onConfirmAccept = () => {
		const { selectedPlanning, actions: { acceptUser }, user:{id}} = this.props;

		acceptUser({
			planningId: selectedPlanning,
			userId: id
		});
		this.onClose();
	}

	blockUser = () => {
		const { user:{mainMemberName}, actions:{openModaleConfirmBlockUser} } = this.props;
		openModaleConfirmBlockUser({
			familyName: mainMemberName,
			onConfirm: this.onConfirmBlock,
		});
	}

	onConfirmBlock = () => {
		const { selectedPlanning, actions: { blockUser }, user:{id}} = this.props;

		blockUser({
			planningId: selectedPlanning,
			userId: id
		});
		this.onClose();
	}

	renderLoading = () => {
		return (
			<DialogContent>
				<LinearProgress color="secondary" />
			</DialogContent>
		);
	}

	renderPaneProfile = () => {
		const { lexique, user, config } = this.props;
		return (
			<div className="user-details-pane">
				<Typography variant="body1" paragraph component="div">
					<Email className="ico--inline mr10" color="primary" />
					<span>{user.email}</span>
					{ user.isCertified && <Tooltip touchHold animation="perspective" position="left" title={lexique.userCertified}>
						<VerifiedUser className="ico--inline ml10" color="secondary" />
					</Tooltip> }
				</Typography>
				<Typography variant="body1" paragraph>
					<Phone className="ico--inline mr10" color="primary" />
					<span>{user.phoneNumber}</span>
				</Typography>
				<Typography variant="body1" paragraph>
					<StoreMallDirectory className="ico--inline mr10" color="primary" />
					<span>{user.address || lexique.notFilled}</span>
				</Typography>
				<Typography variant="body1" paragraph>
					<Schedule className="ico--inline mr10" color="primary" />
					<span>{_lp(lexique.memberSince.replace('{0}', moment(user.since).format(config.dateFormatLong)))}</span>
				</Typography>
			</div>
		)
	}

	renderPaneFamily = () => {
		const { user } = this.props;
		return (
			<div className="users-members-container user-details-pane">
				{user.family.map(member => <UserCard key={member.id} {...member} shortDetails showActionMenu={false} email={user.email} />)}
			</div>
		);
	}

	renderPaneAppointments = appointments => {
		const { config, appointmentsTypes } = this.props;

		if (!appointmentsTypes) return <div />;
		return (
			<div className="users-members-container user-details-pane">
				{appointments.map(app => {
					const appType = appointmentsTypes.find(a => a.id === app.typeId);
					const style = appType ? { backgroundColor: appType.color } : null;
					return (
						<Card key={app.id} className="user-card">
							<CardHeader
								avatar={
									<Avatar className={`avatar-gender`} style={style}>
										{app.with.isAnimal ? <Pets /> : app.with.firstName[0] }
									</Avatar>
								}
								action={
									<IconButton onClick={this.jumpToAppointment(app)}>
										<ChevronRight />
									</IconButton>
								}
								title={moment(app.start).format(config.datetimeFormatLong)}
								subheader={`${userName(app.with)} / ${appType ? appType.label : '-'}`}
							/>
						</Card>
					)
				})}
			</div>
		)
	}

	renderPaneUpcomingAppointments = () => {
		const { user:{appointments} } = this.props;
		const now = moment();
		return this.renderPaneAppointments(
			_sortBy(
				appointments.filter(({start}) => moment(start).isSameOrAfter(now)),
				[o => moment(o.start).unix()]
			)
		 );
	}

	renderPanePastAppointments = () => {
		const { user:{appointments} } = this.props;
		const now = moment();
		const unix = moment().unix();
		return this.renderPaneAppointments(
			_sortBy(
				appointments.filter(({start}) => moment(start).isBefore(now)),
				[o => unix-moment(o.start).unix()]
			)
		);
	}

	renderContent = () => {
		const { lexique, user, isMobile } = this.props;
		const { refMenu, tab } = this.state;
		return (
			<Fragment>
				<DialogTitle className="user-details-title">
					{user.isBlocked && <Delete className="mr10" />}
					<div className="flex1">
						{ lexique.title.replace('{0}', user.mainMemberName)}
					</div>
					<IconButton className="float-menu-more" onClick={this.openQuickActionsMenu}>
						<MoreVert />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Tabs
						value={tab}
						onChange={this.handleChangeTab}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
        				scrollButtons="auto"
					>
						<Tab label={lexique.tabs.profile} />
						<Tab label={lexique.tabs.members} />
						<Tab label={lexique.tabs.upcomingAppointments} />
						<Tab label={lexique.tabs.pastAppointments} />
						<Tab label={lexique.tabs.payments} />
					</Tabs>
					<div className="user-details mt20">
						<SwipeableViews index={tab} animateHeight onChangeIndex={this.onChangeSwipe}>
							{this.renderPaneProfile()}
							{this.renderPaneFamily()}
							{this.renderPaneUpcomingAppointments()}
							{this.renderPanePastAppointments()}
							<Payments id={user.id} />
						</SwipeableViews>
					</div>
				</DialogContent>
				<Menu
					anchorEl={refMenu}
					open={refMenu !== null}
					onClose={this.closeQuickActionsMenu}
					onClick={this.closeQuickActionsMenu}
				>
					{user.isBlocked && <MenuItem onClick={this.unblockUser}>
						<ListItemIcon>
                            <VerifiedUser />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.recover} />
					</MenuItem>}
					{!user.isBlocked && <MenuItem onClick={this.blockUser}>
						<ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.delete} />
					</MenuItem>}
					{isMobile && <Divider />}
					{isMobile && <MenuItem onClick={this.onClose}>
						<ListItemIcon>
                            <Close />
                        </ListItemIcon>
                        <ListItemText inset primary={lexique.close} />
					</MenuItem>}
				</Menu>
			</Fragment>
		)
	}

    render() {
		const { show, loading, isMobile } = this.props;

    	return (
			<Dialog
				open={show}
				TransitionComponent={ModalTransitionZoom}
				fullWidth
				maxWidth="lg"
				onClose={this.onClose}
				fullScreen={isMobile}
			>
				{ loading ? this.renderLoading() : this.renderContent() }
			</Dialog>
    	)
    }
}

const mapStateToProps = ({env, ui:{device, modales:{userDetails:{show}}}, users, plannings}) => ({
	lexique: getLexique(env.locale).modales.userDetails,
	config: getLexique(env.locale).config,
	appointmentsTypes: plannings && plannings[plannings.selected] && plannings[plannings.selected].appointmentsTypes ? plannings[plannings.selected].appointmentsTypes : null,
    show,
	selectedPlanning: plannings.selected,
	loading: users.loading,
	user: users.user,
	isMobile: device.isMobile,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ closeModaleUserDetails, updateViewDate, blockUser, acceptUser, openModaleConfirmAcceptUser, openModaleConfirmBlockUser }, dispatch)
});


const Payments = memo(({id}) => {

	const [items, loading, onCancelItem] = usePaymentsByUser(id);
	const lexique = useLexique("modales.userDetails");

	if (loading) return (<div className="user-details-payments"><LinearProgress color="secondary" /></div>)

	if (items.length === 0) return <div className="user-details-payments">{lexique.noEntry}</div>

	return (
		<div className="user-details-payments">
			<PaymentsHeader />
			<div className="payments-items-container">
				{items.map(item => <PaymentItem key={item.id} {...item} onCanceled={onCancelItem} />)}
			</div>
		</div>
	)

});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
