import React, { memo } from 'react';
import {useTrack} from '../hooks';
import AudioTrack from '../AudioTrack';
import VideoTrack from '../VideoTrack';

const Publication = ({ publication, isLocalParticipant, videoOnly, videoPriority }) => {
    const track = useTrack(publication);

    if (!track) return null;

    if (track.kind === 'video') {
        return (
            <VideoTrack
                track={track}
                priority={videoPriority}
                isLocal={track.name.includes('camera') && isLocalParticipant}
            />
        );
    }

    if (track.kind === 'audio') {
        if (videoOnly) return null;
        return (
            <AudioTrack track={track} />
        )
    }
       
    return null;
}

export default memo(Publication)