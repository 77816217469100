import React, { memo, useCallback, useState } from 'react';
import { LinearProgress, Typography, Select, MenuItem, FormControl, InputLabel, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Block as CancelIcon, Receipt as ReceiptIcon, AccountBalance as RibIcon } from '@material-ui/icons'
import { useDispatch } from 'react-redux';
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Tooltip } from 'react-tippy';

import { useUnpaidPayments, useBalance, usePaymentsPeriod, usePayments, usePlanning } from '../../hooks/planning';
import { useLexique, _lp } from '../../locales';

import Alert from '../ui/Alert';
import { cancelPayment, updatePaymentIban } from '../../actions/planning';
import { openUserDetails } from '../../actions/users';

const PaymentsManage = () => {

    return (
        <div className="payments-manage">
            <Balance />
            <div className="payments-manage_columns">
                <UnpaidPayments />
                <SearchPayments />
            </div>
        </div>
    )

}

const Balance = memo(() => {

    const balance = useBalance();
    const lexique = useLexique('payments.manage.balances');
    const config = useLexique('config');

    return (
        <div className='payments-manage_balances'>
            <div className='payments-manage_balance balance--transit'>
                <span className="label">{lexique.transit.replace('{0}', moment().endOf('month').format(config.dateFormatLong))}</span>
                <span className="amount">{(balance.transit / 100)}€</span>
            </div>
            <div className='payments-manage_balance balance--available'>
                <span className="label">{lexique.available.replace('{0}', moment().endOf('month').format(config.dateFormatLong))}</span>
                <span className="amount">{(balance.available / 100)}€</span>
            </div>
            <div className='payments-manage_balance balance--pending'>
                <span className="label">{lexique.pending}</span>
                <span className="amount">{(balance.pending / 100)}€</span>
            </div>
            <RibUpdate />
        </div>
    )

});

const UnpaidPayments = memo(() => {

    const [unpaid, loading, onCancelItem] = useUnpaidPayments();
    const lexique = useLexique('payments.manage');

    return (
        <div className="payments-manage_unpaid">
            <Typography variant="h6" gutterBottom>{lexique.unpaid.title}</Typography>
                {loading && <LinearProgress color="primary" />}
                {!loading && unpaid.length === 0 && lexique.unpaid.noEntry}
                {unpaid.length > 0 && (<>
                    <PaymentsHeader />
                    <div className="payments-items-container">
                        {unpaid.map(item => <PaymentItem key={item.id} {...item} onCanceled={onCancelItem} />)}
                    </div>
                </>)}
        </div>
    )

});

const SearchPayments = memo(() => {

    const _onChangeSelect = useCallback(({target:{value}}) => setPeriod(value), []);

    const [period, setPeriod] = useState('');
    const [items, loading, onCancelItem] = usePaymentsPeriod(period);
    const config = useLexique('config');
    const lexique = useLexique('payments.manage.search');
    const now = moment();
    const start = moment([2020, 4, 1]);
    const diff = Math.ceil(now.diff(start, 'months', true));

    return (
        <div className='payments-search'>
            <Typography variant="h6" gutterBottom>{lexique.title}</Typography>
            <div className="payments-search--select">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="payments-period">
                        {lexique.label}
                    </InputLabel>
                    <Select
                        onChange={_onChangeSelect}
                        label={lexique.label}
                        fullWidth
                        value={period}
                    >
                        {Array.from({length: diff}).map((_, i) => {
                            const value = moment(now).startOf("month").subtract(i, 'months')
                            const f = moment(value).format('YYYY-MM-DD');
                            return <MenuItem key={value} value={f}>{value.format(config.monthFormat)}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className='payments-search--results'>
                {loading && <LinearProgress color="primary" />}
                {!loading && items.length === 0 && period !== '' && <div>{lexique.noEntry}</div>}
                {items.length > 0 && (<>
                    <PaymentsHeader />
                    <div className="payments-items-container">
                        {items.map(item => <PaymentItem key={item.id} {...item} onCanceled={onCancelItem} />)}
                    </div>
                </>)}
            </div>
        </div>
    )

});

export const PaymentsHeader = () => {

    const lexique = useLexique('payments.manage.list.header');

    return (
        <div className="payment-item payments-header">
            <div className="pi--cell pi--date">{lexique.date}</div>
            <div className="pi--cell pi--state">{_lp(lexique.state)}</div>
            <div className="pi--cell pi--who">{_lp(lexique.who)}</div>
            <div className="pi--cell pi--amount --amount">{_lp(lexique.amount)}</div>
            <div className="pi--cell pi--amount --fees">{_lp(lexique.fees)}</div>
            <div className="pi--cell pi--amount --collected">{_lp(lexique.finalAmount)}</div>
            <div className='pi-cell pi--action' />
        </div>
    )

}

export const PaymentItem = memo(({createdAt, userName, amount, fees, finalAmount, userId, short, ...props}) => {
    const config = useLexique('config');
    const dispatch = useDispatch();

    const _onClickUser = useCallback(() => {
        dispatch(openUserDetails(userId))
    }, [dispatch, userId]);

    return (
        <div className="payment-item">
            <div className="pi--cell pi--date">{moment(createdAt).format(config.datetimeFormat)}</div>
            <div className="pi--cell pi--state"><State {...props} /></div>
            {!short && <div className="pi--cell pi--who"><Button size="small" onClick={_onClickUser}>{userName}</Button></div> }
            <div className={cn('pi--cell', 'pi--amount --amount', short && 'grow')}>{(amount / 100)}€</div>
            {!short && <div className="pi--cell pi--amount --fees">{(fees / 100)}€</div> }
            {!short && <div className="pi--cell pi--amount --collected">{(finalAmount / 100)}€</div> }
            <div className='pi-cell pi--action'><Action userName={userName} amount={amount} {...props} /></div>
        </div>
    )
});

const State = memo(({paid, canceled, paidAt, canceledAt}) => {
    const lexique = useLexique('payments.manage.states')
    const config = useLexique('config');

    if (!paid && !canceled) {
        return <div className='state state--unpaid'>
            <span className="label">{lexique.unpaid}</span>
        </div>
    }

    if (canceled) {
        return <div className='state state--canceled'>
            <span className="label">{lexique.canceled}</span>
            <span className="date">{moment(canceledAt).format(config.datetimeFormat)}</span>
        </div>
    }

    return <div className='state state--paid'>
        <span className="label">{lexique.paid}</span>
        <span className="date">{moment(paidAt).format(config.datetimeFormat)}</span>
    </div>

});

const Action = memo(({id, paid, canceled, userName, amount, onCanceled, planningId, receiptUrl}) => {

    const [showConfirm, setShowConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const _onShowConfirm = useCallback(() => setShowConfirm(true), []);
    const _onHideConfirm = useCallback(() => setShowConfirm(false), []);

    const _onCancelPayment = useCallback(async () => {

        setLoading(true);

        await dispatch(cancelPayment({
            planningId,
            id
        }));
        
        setLoading(false);
        setShowConfirm(false)
        onCanceled(id);

    }, [onCanceled, id, planningId, dispatch])

    const _openReceipt = useCallback(() => {

        window.open(receiptUrl);

    }, [receiptUrl]);

    const lexique = useLexique('payments.manage');

    if (canceled) return null;

    if (!paid) return (<>
        <IconButton size="small" onClick={_onShowConfirm}>
            <CancelIcon fontSize="inherit" />
        </IconButton>
        <Dialog open={showConfirm} onClose={_onHideConfirm} disableBackdropClick={loading} disableEscapeKeyDown={loading}>
            <DialogTitle>{lexique.cancelPayment.title}</DialogTitle>
            <DialogContent>
                <Alert severity='warning' title={lexique.cancelPayment.alert.title} md content={lexique.cancelPayment.alert.body.replace('{0}', userName).replace('{1}', (amount / 100))} />
            </DialogContent>
            <DialogActions>
                <Button onClick={_onHideConfirm} color="primary" disabled={loading}>
					{lexique.cancelPayment.cancel}
                </Button>
                <Button variant="contained" color="primary" className="button--danger" disableElevation disabled={loading} onClick={_onCancelPayment}>
                    {lexique.cancelPayment.confirm}
                    {loading && <CircularProgress className='ml5' size={15} />}
                </Button>
            </DialogActions>
        </Dialog>
    </>)

    if (!receiptUrl) return null;

    return (
        <IconButton size="small" onClick={_openReceipt}>
            <ReceiptIcon fontSize="inherit" />
        </IconButton>
    )

});


const ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    style: {
      base: {
        fontSize: '24px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
};
const RibUpdate = () => {

    const payments = usePayments();
    const [planning] = usePlanning();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const _openModal = useCallback(() => setOpenModal(true), []);
    const _closeModal = useCallback(() => setOpenModal(false), []);

    const stripe = useStripe();
    const elements = useElements();

    const _onSubmit = useCallback(async () => {
        setLoading(true);

        const ibanElement = elements.getElement(IbanElement);
        const ibanToken = await stripe.createToken(ibanElement, {
            currency: 'eur',
            account_holder_name: `${planning.profile.firstName} ${planning.profile.lastName}`,
            account_holder_type: 'individual',
        });

        if (!ibanToken.token) {
            setLoading(false);
            return;
        }

        await dispatch(updatePaymentIban({
            tokenIban: ibanToken.token.id,
            planningId: planning.id,
        }));

        setLoading(false);
        setOpenModal(false);

    }, [stripe, elements, planning, dispatch]);

    const lexique = useLexique('payments.rib')

    if (!payments.iban) return null;

    return (
        <div className="payments-rib">
            <Tooltip title={lexique.tooltip}>
                <Button onClick={_openModal}>
                    <RibIcon /> {payments.iban.country} ... {payments.iban.last4}
                </Button>
            </Tooltip>
            <Dialog open={openModal} disableEscapeKeyDown={loading} disableBackdropClick={loading} onClose={_closeModal} size="md" fullWidth>
                <DialogTitle>{lexique.title}</DialogTitle>
                <DialogContent>
                    <p>{lexique.body}</p>
                    <div className="payments-add-rib">
                        <IbanElement options={ELEMENT_OPTIONS} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_closeModal} color="primary" disabled={loading}>
                        {lexique.cancel}
                    </Button>
                    <Button variant="contained" color="primary" disableElevation disabled={loading} onClick={_onSubmit}>
                        {lexique.confirm}
                        {loading && <CircularProgress className='ml5' size={15} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

export default memo(PaymentsManage)
